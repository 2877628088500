import React, { useState } from 'react';
import M from 'materialize-css';
import axiosAuth from '../../../utils/axiosAuth';
// import { getColors } from '../../../utils/colors';
import Button from '../../common/button/Button';
import './CompanyProfile.scss';

function CompanyProfile(props) {
  const { user, setUser } = props;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(user.name);
  const [lastName, setLastName] = useState(user.lastName);
  const [twoFAEnabled, setTwoFAEnabled] = useState(user.has2FAEnabled || false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  // const colors = getColors(30);
  const { notificationPreferences } = user;
  const [riskTripsN, setRiskTripsN] = useState(
    notificationPreferences?.riskTrips !== false ? true : false
  );
  const [reportsN, setReportsN] = useState(
    notificationPreferences?.reports !== false ? true : false
  );
  const [noDownloadUsersN, setNoDownloadUsersN] = useState(
    notificationPreferences?.noDownloadUsers !== false ? true : false
  );

  function editProfile(e) {
    e.preventDefault();
    setLoading(true);

    axiosAuth
      .post('/api/managers/profile', {
        name,
        lastName,
        twoFAEnabled
      })
      .then((res) => {
        // Update user
        setUser(res.data.user);
        setLoading(false);
        M.toast({
          html: 'Perfil editado con éxito',
          classes: 'success-toast'
        });
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error al actualizar tu perfil';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        setLoading(false);
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
      });
  }

  function editPassword(e) {
    e.preventDefault();
    setLoading(true);

    axiosAuth
      .post('/api/managers/password', {
        currentPassword,
        password1,
        password2
      })
      .then((res) => {
        setLoading(false);
        setCurrentPassword('');
        setPassword1('');
        setPassword2('');
        M.toast({
          html: 'Contraseña actualizada con éxito',
          classes: 'success-toast'
        });
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error al actualizar tu contraseña';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        setLoading(false);
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
      });
  }

  function editNotifPreferences(e) {
    e.preventDefault();
    setLoading(true);

    axiosAuth
      .post('/api/managers/notif-pref', {
        riskTripsN,
        reportsN,
        noDownloadUsersN
      })
      .then((res) => {
        setLoading(false);
        M.toast({
          html: 'Preferencias actualizadas con éxito',
          classes: 'success-toast'
        });
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error al actualizar tus preferencias';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        setLoading(false);
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
      });
  }

  return (
    <div id="company-profile">
      <form id="personal-info-form" onSubmit={editProfile}>
        <div className="row">
          <div className="col s12">
            <h6 className="sub">Perfil</h6>
          </div>
          <div className="input-field col s12 m4">
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="nombre" className="active">
              Nombre:
            </label>
          </div>
          <div className="input-field col s12 m4">
            <input
              id="lastName"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <label htmlFor="apellido" className="active">
              Apellido:
            </label>
          </div>
          <div className="input-field col s12 m4">
            <input id="phone" type="text" value={user.phone} disabled />
            <label htmlFor="celular" className="active">
              Celular:
            </label>
          </div>
          <div className="input-field col s12 m4">
            <p>
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  checked={twoFAEnabled}
                  onChange={(e) => setTwoFAEnabled(!twoFAEnabled)}
                />
                <span>Habilitar 2FA</span>
              </label>
            </p>
          </div>
        </div>
        <div className="right-align">
          <Button
            variant="yellow-button"
            loading={loading}
            disabled={loading}
            type="submit"
          >
            Editar perfil
          </Button>
        </div>
      </form>
      <div className="divider"></div>
      <form id="password-info-form" onSubmit={editPassword}>
        <div className="row">
          <div className="col s12">
            <h6 className="sub">Contraseña</h6>
          </div>
          <div className="input-field col s12 m4">
            <input
              id="current"
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <label htmlFor="current" className="active">
              Contraseña actual:
            </label>
          </div>
          <div className="input-field col s12 m4">
            <input
              id="password1"
              type="password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
            />
            <label htmlFor="new" className="active">
              Nueva contraseña:
            </label>
          </div>
          <div className="input-field col s12 m4">
            <input
              id="password2"
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
            <label htmlFor="newRepeat" className="active">
              Confirmar contraseña:
            </label>
          </div>
        </div>
        <div className="right-align">
          <Button
            variant="yellow-button"
            loading={loading}
            disabled={loading}
            type="submit"
          >
            Actualizar contraseña
          </Button>
        </div>
      </form>
      <div className="divider"></div>
      <form id="notifications-form" onSubmit={editNotifPreferences}>
        <div className="row">
          <div className="col s12">
            <h6 className="sub">Notificaciones</h6>
          </div>
          <div className="col s12">
            <p>Viajes realizados</p>
          </div>
          <div className="input-field col s12 m4">
            <p>
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  checked={riskTripsN}
                  onChange={(e) => setRiskTripsN(!riskTripsN)}
                />
                <span>Viajes de riesgo</span>
              </label>
            </p>
          </div>
          <div className="col s12">
            <p>Registros</p>
          </div>
          <div className="input-field col s12 m4">
            <p>
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  checked={reportsN}
                  onChange={(e) => setReportsN(!reportsN)}
                />
                <span>Todos</span>
              </label>
            </p>
          </div>
          <div className="col s12">
            <p>Otros</p>
          </div>
          <div className="input-field col s12 m4">
            <p>
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  checked={noDownloadUsersN}
                  onChange={(e) => setNoDownloadUsersN(!noDownloadUsersN)}
                />
                <span>Usuarios sin descarga</span>
              </label>
            </p>
          </div>
        </div>
        <div className="right-align">
          <Button
            variant="yellow-button"
            loading={loading}
            disabled={loading}
            type="submit"
          >
            Actualizar preferencias
          </Button>
        </div>
      </form>
      {/* {colors.map((color, idx) => {
        const ret = [];

        ret.push(
          <div className="color" style={{ background: color }}>
            {color}
          </div>
        );

        if (
          idx === 5 ||
          idx === 11 ||
          idx === 17 ||
          idx === 23 ||
          idx === 29 ||
          idx === 35
        )
          ret.push(<div className="sep"></div>);

        return ret;
      })} */}
    </div>
  );
}

export default CompanyProfile;
