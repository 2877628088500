/* eslint max-len: 0 */

import React from 'react';

function Ranking2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <circle className="cls-1" cx="141.73" cy="191.34" r="63.78" />
        <circle className="cls-1" cx="141.73" cy="191.34" r="35.43" />
        <polygon
          className="cls-1"
          points="28.35 28.35 255.12 28.35 255.12 70.87 141.73 127.56 28.35 70.87 28.35 28.35"
        />
        <line className="cls-1" x1="99.21" y1="106.3" x2="99.21" y2="28.35" />
        <line className="cls-1" x1="184.25" y1="106.3" x2="184.25" y2="28.35" />
      </g>
    </svg>
  );
}

export default Ranking2;
