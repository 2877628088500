import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import HeaderSection from '../../common/header-section/HeaderSection';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import Error from '../../common/error/Error';
import SideModal from '../../common/side-modal/SideModal';
import Icon from '../../common/airbag-icons/Icon';
import Button from '../../common/button/Button';
import axiosAuth from '../../../utils/axiosAuth';
import AddGroup from './add-user-group/AddUserGroup';
import './UserGroups.scss';

function UserGroups(props) {
  const [groups, setGroups] = useState(null);
  const [error, setError] = useState(null);
  const [newGroupOpen, setNewGroupOpen] = useState(false);
  const videoUrl =
    'https://www.youtube.com/embed/37Oj69wKF4w?list=PLPbFMY-3HtQvwCS0QjdMy56MiOY3Q9wJQ';
  // Search for groups
  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchGroups() {
    axiosAuth
      .get(`/api/user-groups`)
      .then((res) => {
        const { groups } = res.data;
        setGroups(groups);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }

  function renderGroupsTable() {
    // Loading info
    if (!groups) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    if (!groups.length) {
      return (
        <div id="no-data">
          <Icon icon="crash" id="error-icon" />
          <p>Sin grupos</p>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="groups-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th className="center-align">Administradores</th>
              <th className="center-align">Conductores</th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group, i) => (
              <tr key={group.id} className="group-row">
                <td>
                  <span className="pos-index">{i + 1}.</span>
                  <Link to={`/grupo?groupId=${group.id}`}>{group.name}</Link>
                </td>
                <td className="center-align">
                  {group.leadersIds ? group.leadersIds.length : '0'}
                </td>
                <td className="center-align">
                  {group.driversIds ? group.driversIds.length : '0'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  // API error handler
  if (error) return <Error error={error} />;

  return (
    <div id="_company-groups_">
      <HeaderSection
        title="Grupos"
        // eslint-disable-next-line max-len
        body="Puedes dividir a tus operadores por grupos para llevar un mejor control y seguimiento."
        videoUrl={videoUrl}
      />
      <div className="row text-right" id="actions">
        <div className="col s12 right-align">
          <Button
            variant="yellow-button"
            id="add-group-btn"
            onClick={() => {
              setNewGroupOpen(true);

              // Google Analytics
              ReactGA.event({
                category: 'user_groups',
                action: 'open_new_user_group'
              });
              // Posthog
              posthog.capture('open_new_user_group', {
                category: 'user_groups',
                isCustomEvent: true
              });
            }}
            type="button"
          >
            Nuevo grupo
            <Icon icon="more" id="add-group-icon" />
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col s12">{renderGroupsTable()}</div>
      </div>
      {/* ADD NEW Group */}
      <SideModal isOpen={newGroupOpen} setIsOpen={setNewGroupOpen}>
        {newGroupOpen && (
          <AddGroup fetchGroups={fetchGroups} setIsOpen={setNewGroupOpen} />
        )}
      </SideModal>
    </div>
  );
}

export default UserGroups;
