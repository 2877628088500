/* eslint max-len: 0 */

import React from 'react';

function Police(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="6px"
      {...props}
    >
      <path d="M141.73,259.93a5.52,5.52,0,0,1-.81-.06C51.21,245.11,23.35,126.43,23.35,84.87a5,5,0,0,1,2.76-4.48L139.5,23.69a5,5,0,0,1,4.47,0l113.38,56.7a5,5,0,0,1,2.77,4.48c0,41.56-27.87,160.24-117.58,175A5.52,5.52,0,0,1,141.73,259.93ZM33.39,87.93c.67,22.07,8.31,58.58,24.23,90.43,14.24,28.49,40.36,63.93,84.11,71.5,43.75-7.57,69.88-43,84.11-71.5,15.92-31.85,23.56-68.36,24.23-90.43L141.73,33.75Z" />
      <path d="M172.29,189.07a7.56,7.56,0,0,1-3.51-.87L141.73,174l-27,14.22a7.5,7.5,0,0,1-10.89-7.91L109,150.16,87.07,128.83A7.5,7.5,0,0,1,91.23,116l30.25-4.4L135,84.22a7.51,7.51,0,0,1,13.46,0L162,111.63l30.25,4.4a7.5,7.5,0,0,1,4.16,12.8l-21.89,21.33,5.17,30.13a7.52,7.52,0,0,1-7.39,8.78Zm-30.56-25.74a5,5,0,0,1,2.33.57L169,177l-4.76-27.74a5,5,0,0,1,1.44-4.42L185.8,125.2l-27.85-4a5,5,0,0,1-3.76-2.74L141.73,93.18l-12.45,25.23a5,5,0,0,1-3.77,2.74l-27.84,4,20.15,19.64a5,5,0,0,1,1.44,4.42L114.5,177l24.9-13.1A5,5,0,0,1,141.73,163.33Zm49.07-37.41h0Z" />
    </svg>
  );
}

export default Police;
