import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import numeral from 'numeral';
import ReactTooltip from 'react-tooltip';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import InputRange from 'react-input-range';
import axiosAuth from '../../../utils/axiosAuth';
import roundNumber from '../../../utils/roundNumber';
import Error from '../../common/error/Error';
import Icon from '../../common/airbag-icons/Icon';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import ManualVideo from '../../common/manual-video/ManualVideo';
import HeaderSection from '../../common/header-section/HeaderSection';
import './CompanyRanking.scss';

function getGroupFromArray(arr, user) {
  let ret = '';

  // Admin users ignore groupId
  if (user.authRole === 'admin') {
    return ret;
  }

  if (arr && typeof arr === 'object' && arr.length) {
    // Assign first group in array
    ret = arr[0].id;
  }

  return ret;
}

const FRESH_TOTALS = {
  score: 0,
  trips: 0,
  duration: 0,
  distance: 0
};
const MIN_KM_RANGE = 0;
const MAX_KM_RANGE = 30000;

function CompanyRanking(props) {
  const { user, company } = props;
  const error = null;
  // const [error, setError] = useState(null);
  const { groups } = user;
  const [group, setGroup] = useState(getGroupFromArray(groups, user));
  const [originalDrivers, setOriginalDrivers] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [feature, setFeature] = useState(6);
  const [month, setMonth] = useState(dayjs().format('YYYY-MM'));
  const [sort, setSort] = useState({ key: 'Value', order: 'asc' });
  const [totals, setTotals] = useState(FRESH_TOTALS);
  const [playVideo, setPlayVideo] = useState(false);
  const videoUrl =
    'https://www.youtube.com/embed/KDALx7PQh2c?list=PLPbFMY-3HtQvwCS0QjdMy56MiOY3Q9wJQ';
  const actualMonth = dayjs().format('YYYY-MM');
  const [minMaxKm, setMinMaxKm] = useState({
    min: MIN_KM_RANGE,
    max: MAX_KM_RANGE
  });

  useEffect(() => {
    setDrivers(null);
    setTotals(FRESH_TOTALS);

    // Build query params
    const queryParams = {};
    // feature id
    queryParams.feature = feature;
    queryParams.type = 'accumulated';
    // Group limited manager
    if (user.authRole === 'group-limited' || group) {
      queryParams.groupId = group;
    }
    if (month !== actualMonth) {
      queryParams.type = 'consolidated';
      queryParams.targetDate = dayjs(month).startOf('M').format('YYYY-MM-DD');
    } else {
      queryParams.type = 'accumulated';
      queryParams.targetDate = dayjs().subtract(1, 'd').format('YYYY-MM-DD');
    }

    // Get company drivers
    axiosAuth
      .get('/api/ranking', { params: queryParams })
      .then((res) => {
        const { drivers } = res.data;
        // Save original array for front-end filtering
        setOriginalDrivers(drivers);
        // Get array with distance range filter applied
        const filteredDrivers = applyDistanceRange(minMaxKm, drivers);
        // Set ranking
        setDrivers(filteredDrivers);
        // Get totals
        calculateTotals(filteredDrivers);
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        // setError(err);
        setDrivers([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feature, group, month]);

  useEffect(() => {
    if (originalDrivers && originalDrivers.length) {
      // Get array with distance range filter applied
      const filteredDrivers = applyDistanceRange(minMaxKm, originalDrivers);
      // Set ranking
      setDrivers(filteredDrivers);
      // Get totals
      calculateTotals(filteredDrivers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minMaxKm]);

  function calculateTotals(drivers) {
    // Get totals sum if ranking exists
    if (drivers && drivers.length) {
      let scoreSum = 0;
      let tripsCount = 0;
      let durationSum = 0;
      let distanceSum = 0;
      let activeDriversCount = 0;
      drivers.forEach((driver) => {
        if (driver.Value) {
          scoreSum += driver.Value;
          activeDriversCount++;
        }
        tripsCount += driver.Trips;
        durationSum += driver.Duration;
        distanceSum += driver.Distance;
      });
      setTotals({
        score: scoreSum / activeDriversCount,
        trips: tripsCount,
        duration: durationSum,
        distance: distanceSum
      });
    } else {
      // Default to empty totals
      setTotals(FRESH_TOTALS);
    }
  }

  function handleScoreColor(number) {
    if (number >= 80) {
      return 'good';
    } else if (number >= 60) {
      return 'regular';
    } else if (number < 60 && number > 0) {
      return 'bad';
    } else {
      return '';
    }
  }

  function handleSort(key) {
    // Sort another column
    if (sort.key !== key) {
      setDrivers(sortArrayByKey(drivers, key, 'asc'));
      setSort({ key, order: 'asc' });
    } else {
      // Sort same column
      const order = sort.order === 'asc' ? 'desc' : 'asc';
      setDrivers(sortArrayByKey(drivers, key, order));
      setSort({ key, order });
    }
  }

  function sortArrayByKey(array, key, order) {
    let arrayCopy = [];

    // Check that array is valid to make spread operator
    if (array && array.length) {
      arrayCopy = [...array];

      if (order === 'desc') {
        arrayCopy.sort(function compare(a, b) {
          if (a[key] < b[key]) return -1;
          if (a[key] > b[key]) return 1;
          return 0;
        });
      } else if (order === 'asc') {
        arrayCopy.sort(function compare(a, b) {
          if (b[key] < a[key]) return -1;
          if (b[key] > a[key]) return 1;
          return 0;
        });
      }
    }

    return arrayCopy;
  }

  function checkMinDate() {
    const companyCreated = dayjs(company.created);
    const startOf2023 = dayjs('2023-01-01'); // We backfilled starting 2023

    // return company creation date if it happened after start of 2023
    if (companyCreated.isAfter(startOf2023)) {
      return companyCreated.format('YYYY-MM');
    }

    // Limit to start of 2023
    return startOf2023.format('YYYY-MM');
  }

  function applyDistanceRange(minMaxKm, drivers) {
    const { min, max } = minMaxKm;

    const newRanking = drivers.filter(
      (driver) => driver.Distance >= min && driver.Distance <= max
    );

    return newRanking;
  }

  // On hold because of distance range filter
  // function renderCaretDiff(driver) {
  //   const ret = [];

  //   // Validate that we have all data
  //   if (
  //     driver.Place &&
  //     driver.PreviousRanking &&
  //     driver.PreviousRanking.Place
  //   ) {
  //     // Up and down caret
  //     if (driver.Place < driver.PreviousRanking.Place) {
  //       const caret = (
  //         <FontAwesomeIcon
  //           className="up"
  //           key={driver.userId + 'caret'}
  //           icon="caret-up"
  //         />
  //       );
  //       ret.push(caret);
  //     } else if (driver.Place > driver.PreviousRanking.Place) {
  //       const caret = (
  //         <FontAwesomeIcon
  //           className="down"
  //           key={driver.userId + 'caret'}
  //           icon="caret-down"
  //         />
  //       );
  //       ret.push(caret);
  //     }

  //     // Place difference number
  //     if (driver.Place !== driver.PreviousRanking.Place) {
  //       const diff = (
  //         <span className="jump" key={driver.userId + 'jump'}>
  //           {driver.PreviousRanking.Place - driver.Place}
  //         </span>
  //       );
  //       ret.push(diff);
  //     }
  //   }

  //   return ret;
  // }
  // <span className="pos-diff">{renderCaretDiff(driver)}</span>

  function renderDriversTable() {
    // Loader en lo que cargan los conductores
    if (!drivers) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // Si no hay conductores
    if (!drivers.length) {
      return (
        <div id="no-data">
          <Icon icon="ranking" id="error-icon" />
          <p>Sin conductores</p>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="ranking-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>
                <span
                  className="pointer"
                  onClick={() => handleSort('fullName')}
                >
                  Nombre
                  {sort.key === 'fullName' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-up' : 'chevron-down'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              {feature !== '7' ? (
                <th>
                  <span className="pointer" onClick={() => handleSort('Value')}>
                    Calificación
                    {sort.key === 'Value' && (
                      <FontAwesomeIcon
                        icon={
                          sort.order === 'desc' ? 'chevron-up' : 'chevron-down'
                        }
                        className="sort-arrow"
                      />
                    )}
                  </span>
                </th>
              ) : null}
              <th>
                <span
                  className="pointer"
                  onClick={() => handleSort('Distance')}
                >
                  Distancia recorrida
                  {sort.key === 'Distance' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-up' : 'chevron-down'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {drivers.map((driver, idx) => (
              <tr key={driver.userId} className="ranking-row">
                <td>
                  <span className="pos-index">{idx + 1}.</span>
                  <Link to={`/conductor?driverId=${driver.userId}`}>
                    {driver.fullName}
                  </Link>
                </td>
                {feature !== '7' ? (
                  <td
                    className={`score ${handleScoreColor(
                      Math.round(driver.Value * 100) / 100
                    )}`}
                  >
                    {driver.Value ? roundNumber(driver.Value) : '0'}
                  </td>
                ) : null}
                <td>
                  {driver.Distance
                    ? `${numeral(roundNumber(driver.Distance)).format(
                        '0,0'
                      )} km`
                    : 0}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  // API error handler
  if (error) return <Error error={error} />;

  return (
    <div id="_ranking_">
      <div className="row" id="main-title">
        <div className="col s12">
          <HeaderSection
            title="Ranking"
            // eslint-disable-next-line max-len
            body="La clasificación mensual de tus operadores."
            videoUrl={videoUrl}
          />
        </div>
      </div>
      <div className="row" id="filters">
        <div className="filter-input col s12 m3">
          <label htmlFor="month" className="active">
            Mes
          </label>
          <input
            type="month"
            id="month"
            name="start"
            max={dayjs().format('YYYY-MM')}
            min={checkMinDate()}
            value={month}
            className="ranking-month-filter"
            onChange={(e) => setMonth(e.target.value)}
          />
        </div>

        <div className="filter-input col s12 m3">
          <label htmlFor="feature" className="active">
            Parámetro
          </label>
          <select
            className="browser-default"
            id="feature"
            defaultValue={feature}
            onChange={(e) => {
              const filterValue = e.target.value;
              setFeature(filterValue);

              // Google Analytics
              if (filterValue === '6') {
                ReactGA.event({
                  category: 'ranking',
                  action: 'filter_ranking_all'
                });
                // Posthog
                posthog.capture('filter_ranking_all', {
                  category: 'ranking',
                  isCustomEvent: true
                });
              } else if (filterValue === '1') {
                ReactGA.event({
                  category: 'ranking',
                  action: 'filter_ranking_acc'
                });
                // Posthog
                posthog.capture('filter_ranking_acc', {
                  category: 'ranking',
                  isCustomEvent: true
                });
              } else if (filterValue === '2') {
                ReactGA.event({
                  category: 'ranking',
                  action: 'filter_ranking_deacc'
                });
                // Posthog
                posthog.capture('filter_ranking_deacc', {
                  category: 'ranking',
                  isCustomEvent: true
                });
              } else if (filterValue === '3') {
                ReactGA.event({
                  category: 'ranking',
                  action: 'filter_ranking_phone_usage'
                });
                // Posthog
                posthog.capture('filter_ranking_phone_usage', {
                  category: 'ranking',
                  isCustomEvent: true
                });
              } else if (filterValue === '4') {
                ReactGA.event({
                  category: 'ranking',
                  action: 'filter_ranking_speeding'
                });
                // Posthog
                posthog.capture('filter_ranking_speeding', {
                  category: 'ranking',
                  isCustomEvent: true
                });
              } else if (filterValue === '5') {
                ReactGA.event({
                  category: 'ranking',
                  action: 'filter_ranking_curves'
                });
                // Posthog
                posthog.capture('filter_ranking_curves', {
                  category: 'ranking',
                  isCustomEvent: true
                });
              } else if (filterValue === '7') {
                ReactGA.event({
                  category: 'ranking',
                  action: 'filter_ranking_distance'
                });
                // Posthog
                posthog.capture('filter_ranking_distance', {
                  category: 'ranking',
                  isCustomEvent: true
                });
              }
            }}
          >
            <option value="6">Calificación general</option>
            <option value="1">Aceleración</option>
            <option value="2">Frenado</option>
            <option value="3">Uso del celular</option>
            <option value="4">Exceso de velocidad</option>
            <option value="5">Curvas</option>
            <option value="7">Distancia</option>
          </select>
        </div>

        <div className="filter-input col s12 m3">
          <label htmlFor="groups-select" className="active">
            Grupo
          </label>
          {groups ? (
            <select
              className="browser-default"
              id="groups-select"
              value={group}
              onChange={(e) => setGroup(e.target.value)}
            >
              {user.authRole === 'admin' ? (
                <option key="gp-all" value="">
                  Todos
                </option>
              ) : null}
              {groups.map((gp) => {
                return (
                  <option key={gp.id} value={gp.id}>
                    {gp.name}
                  </option>
                );
              })}
            </select>
          ) : (
            <select className="browser-default" id="groups-select">
              <option value="" disabled>
                Sin grupos
              </option>
            </select>
          )}
        </div>
        <div className="filter-input col s12 m3" id="range-filter">
          <label htmlFor="minMaxKm" className="active" id="minMaxKmLabel">
            Rango de km
          </label>
          <InputRange
            id="minMaxKm"
            maxValue={MAX_KM_RANGE}
            minValue={MIN_KM_RANGE}
            formatLabel={(value) => `${value} km`}
            value={minMaxKm}
            step={100}
            onChange={(value) => {
              if (value.min >= MIN_KM_RANGE && value.max <= MAX_KM_RANGE) {
                setMinMaxKm(value);
              }
            }}
          />
        </div>
      </div>
      <div className="row no-margin">
        <div className="col s12">
          <div className="row no-margin" id="ranking-info">
            {feature == 7 ? (
              <div className="col s6">
                <p className="title">
                  <Icon icon="graph" />
                  {totals.distance && drivers.length
                    ? roundNumber(totals.distance / drivers.length)
                    : 0}{' '}
                  km
                </p>
                <p className="subtitle">Promedio por usuario</p>
              </div>
            ) : (
              <div className="col s6">
                <p
                  className={`title ${handleScoreColor(
                    Math.round(totals.score * 100) / 100
                  )}`}
                >
                  <Icon icon="ranking" />
                  {totals.score ? roundNumber(totals.score) : 0}
                </p>
                <p className="subtitle">Calificación promedio</p>
              </div>
            )}
            <div className="col s6">
              <p className="title">
                <Icon icon="pin" />
                {numeral(totals.distance).format('0,0')} km
              </p>
              <p className="subtitle">Total de distancia recorrida</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row no-margin">
        <div className="col s12">{renderDriversTable()}</div>
      </div>
      {playVideo && (
        <ManualVideo
          videoUrl={videoUrl}
          playVideo={playVideo}
          setPlayVideo={setPlayVideo}
        />
      )}
      {/* Tooltips */}
      <ReactTooltip />
    </div>
  );
}

export default CompanyRanking;
