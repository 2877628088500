import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import axiosAuth from '../../../../utils/axiosAuth';
import { getColors } from '../../../../utils/colors';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import CoinsChart from '../../../common/chartjs/CoinsChart';
import CoinsCumulativeChart from '../../../common/chartjs/CoinsCumulativeChart';
import './GamificationCharts.scss';

function GamificationCharts(props) {
  const { user, groupId, fillNoLabelsArr, fillNoDataArr, DAYS_COUNT } = props;
  const [coins, setCoins] = useState(null);
  const [coinsLabel, setCoinsLabels] = useState(null);

  useEffect(() => {
    setCoins(null);

    // Build query params
    const queryParams = {
      days: 30 // Default last 30 days
    };
    // Add groupId if manager is group-limited
    if (user.authRole === 'group-limited' || groupId) {
      queryParams.groupId = groupId;
    }

    // Get coins info
    axiosAuth
      .get(`/api/charts/company/coins`, { params: queryParams })
      .then((res) => {
        const { coins: coinsRes, labels } = res.data;

        // Set labels
        if (labels && labels.length) {
          setCoinsLabels(labels.map((day) => dayjs(day).format('DD MMM')));
        } else {
          setCoinsLabels(fillNoLabelsArr(DAYS_COUNT));
        }

        const newCoins = {
          daily: null,
          dailyAverage: null,
          cumulative: null
        };

        // Set first graph coins
        const { daily, dailyAverage } = coinsRes;
        if (daily && daily.length && dailyAverage && dailyAverage.length) {
          newCoins['daily'] = daily;
          newCoins['dailyAverage'] = dailyAverage;
        } else {
          newCoins['daily'] = fillNoDataArr(DAYS_COUNT);
          newCoins['dailyAverage'] = fillNoDataArr(DAYS_COUNT);
        }

        // Set second graph coins
        const { cumulative } = coinsRes;
        if (cumulative && cumulative.length) {
          newCoins['cumulative'] = cumulative;
        } else {
          newCoins['cumulative'] = fillNoDataArr(DAYS_COUNT);
        }

        setCoins(newCoins);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  return (
    <div className="row no-margin" id="_gamitifcation-charts_">
      <div className="col s12 l8">
        <div className="graph-box coins-graphs">
          {coins && coins.dailyAverage && coins.daily && coinsLabel ? (
            <CoinsChart
              title={`Puntos otorgados`}
              labels={coinsLabel}
              data={[
                {
                  data: coins.daily,
                  label: 'Totales otorgadas',
                  type: 'bar'
                }
              ]}
              colors={[getColors(1)[0]]}
              displayLegend={false}
              max={Math.max(...coins.daily) + 2}
            />
          ) : (
            <AirbagLoader />
          )}
        </div>
      </div>
      <div className="col s12 l4">
        <div className="graph-box coins-graphs">
          {coins && coins.cumulative && coinsLabel ? (
            <CoinsCumulativeChart
              title={`Puntos acumulados`}
              labels={[...coinsLabel]}
              data={[{ data: [...coins.cumulative], label: '' }]}
              colors={[getColors(2)[1]]}
              displayLegend={false}
              max={Math.max(...coins.cumulative) + 5}
            />
          ) : (
            <AirbagLoader />
          )}
        </div>
      </div>
    </div>
  );
}

export default GamificationCharts;
