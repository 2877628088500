import dayjs from 'dayjs';
import getMarker from './getMarker';

// Display location logs in map
function displayLocationLogs(locationLogs, H, mapUI, mapObj) {
  // Declare group
  const group = new H.map.Group();

  for (const locationLog of locationLogs) {
    // Check for valid position
    const { position, created } = locationLog;
    const { lat, lon: lng } = position;
    if (!lat || !lng) continue;

    // Create a marker icon from an image URL:
    const icon = new H.map.Icon(getMarker('location_log'));

    // Create a marker using the previously instantiated icon
    const mapMarker = new H.map.Marker({ lat, lng }, { icon });
    // Set the text that the marker will show when clicked on
    const markerData = `<p class="last-seen">${dayjs(created).format(
      'HH:mm'
    )}</p>`;
    mapMarker.setData(markerData);

    // Add the event listener to listen clicks on marker
    mapMarker.addEventListener('tap', (event) => {
      const bubble = new H.ui.InfoBubble(
        { lat, lng },
        { content: event.target.getData() }
      );
      // Add the bubble to the UI
      mapUI.current.addBubble(bubble);
    });

    // retrieve all map objects
    // Add the marker to the map
    group.addObject(mapMarker);
  }

  // Add the group to the map
  mapObj.current.addObject(group);
}

export default displayLocationLogs;
