import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import M from 'materialize-css';
import axiosAuth from '../../../../../utils/axiosAuth';
import Button from '../../../../common/button/Button';
// eslint-disable-next-line max-len
// import SearchGroupInput from '../../../../common/search-group-input/SearchGroupInput';
import './EditGeneral.scss';

function EditGeneral(props) {
  const { driver, setDriver, setEditGeneralOpen } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: driver.name,
    lastName: driver.lastName,
    internalCompanyId: driver.internalCompanyId ? driver.internalCompanyId : '',
    birthdate: driver.birthdate ? driver.birthdate : '',
    hired: driver.hired ? driver.hired : '',
    civilStatus: driver.civilStatus ? driver.civilStatus : '',
    startStage: driver.startStage ? driver.startStage : '',
    nationality: driver.nationality ? driver.nationality : '',
    gender: driver.gender ? driver.gender : '',
    nationalId: driver.nationalId ? driver.nationalId : ''
  });
  const groups = driver.groups ? driver.groups : null;
  // const [hireDate, setHireDate] = useState(new Date());
  const hireDateRef = useRef(null);
  const birthdDateRef = useRef(null);
  // const [groups, setGroups] = useState(driver.groups ? driver.groups : null);

  function editGeneralInfo(e) {
    e.preventDefault();
    setLoading(true);

    // Make request
    axiosAuth
      .post('/api/users/edit-general-info', {
        ...data,
        groups,
        driverId: driver.id
      })
      .then((res) => {
        M.toast({
          html: 'Información guardada con éxito',
          classes: 'success-toast'
        });
        setDriver({ ...driver, ...data, groups });
        setLoading(false);
        setEditGeneralOpen(false);
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  return (
    <div id="_edit-general_">
      <div className="row">
        <div className="col s12">
          <h5 id="main-t">Editar información general</h5>
        </div>
      </div>
      <form onSubmit={editGeneralInfo}>
        <div className="row big-label">
          <div className="col s12">
            <p>Empresa</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6 input-field">
            <label className="active">ID interno</label>
            <input
              type="text"
              value={data.internalCompanyId}
              placeholder="Identificador único"
              onChange={(e) =>
                setData({ ...data, internalCompanyId: e.target.value })
              }
            />
          </div>
          {/* <div className="col s12 m6">
            <SearchGroupInput
              groups={groups}
              setGroups={setGroups}
              driverId={driver.id}
            />
          </div>*/}
          <div className="col s12 m6 input-field">
            <label className="active">Fecha de contratación</label>
            <DatePicker
              ref={hireDateRef}
              selected={data.hired ? new Date(dayjs(data.hired).format()) : ''}
              placeholderText="Seleccionar.."
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
              maxDate={new Date()}
              onChange={(date) =>
                setData({
                  ...data,
                  hired: dayjs(date).toISOString()
                })
              }
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Etapa de alta</label>
            <input
              type="text"
              value={data.startStage}
              placeholder="0, 1, 2, 3.."
              onChange={(e) => setData({ ...data, startStage: e.target.value })}
            />
          </div>
        </div>
        <div className="row big-label">
          <div className="col s12">
            <p>Personal</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6 input-field">
            <label className="active">Nombre</label>
            <input
              type="text"
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Apellidos</label>
            <input
              type="text"
              value={data.lastName}
              onChange={(e) => setData({ ...data, lastName: e.target.value })}
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Género</label>
            <select
              className="browser-default"
              defaultValue={data.gender}
              onChange={(e) => setData({ ...data, gender: e.target.value })}
            >
              <option value="" disabled>
                Seleccionar..
              </option>
              <option value="male">Masculino</option>
              <option value="female">Femenino</option>
              <option value="other">Otro</option>
            </select>
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Fecha de nacimiento</label>
            <DatePicker
              ref={birthdDateRef}
              selected={
                data.birthdate ? new Date(dayjs(data.birthdate).format()) : ''
              }
              placeholderText="Seleccionar.."
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
              onChange={(date) =>
                setData({
                  ...data,
                  birthdate: dayjs(date).toISOString()
                })
              }
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Estado civil</label>
            <select
              className="browser-default"
              defaultValue={data.civilStatus}
              onChange={(e) =>
                setData({ ...data, civilStatus: e.target.value })
              }
            >
              <option value="" disabled>
                Seleccionar..
              </option>
              <option value="single">Soltero</option>
              <option value="consensual-union">Unión libre</option>
              <option value="married">Casado</option>
              <option value="divorced">Divorciado</option>
              <option value="widowed">Viudo</option>
            </select>
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Nacionalidad</label>
            <select
              className="browser-default"
              defaultValue={data.nationality}
              onChange={(e) =>
                setData({ ...data, nationality: e.target.value })
              }
            >
              <option value="" disabled>
                Seleccionar..
              </option>
              <option value="mex">Mexicano</option>
              <option value="other">Otro</option>
            </select>
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">ID nacional</label>
            <input
              type="text"
              value={data.nationalId}
              onChange={(e) => setData({ ...data, nationalId: e.target.value })}
            />
          </div>
        </div>
        <div className="center-align">
          <Button
            variant="yellow-button"
            loading={loading}
            disabled={loading}
            type="submit"
          >
            Guardar información
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditGeneral;
