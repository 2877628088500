import React from 'react';
import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';
import './DriverHeartbeats.scss';

function DriverHeartbeats(props) {
  const {
    heartbeats,
    appStatus,
    isResting,
    lastSeen,
    firstLogin,
    isDriving,
    status
  } = props;
  const main = []; // Array to hold the pills

  function handlePermissions(permissions, heartbeats) {
    if (!heartbeats) return;

    const {
      locationPermissionGranted,
      notificationsPermissionGranted,
      motionActivityGranted,
      lowPowerModePermissionGranted
    } = heartbeats;

    const PERMISSIONS_DEFS = [
      {
        ok: locationPermissionGranted,
        slug: 'locationPermissionGranted',
        name: 'Permiso de ubicación'
      },
      {
        ok: notificationsPermissionGranted,
        slug: 'notificationsPermissionGranted',
        name: 'Permiso de notificaciones'
      },
      {
        ok: motionActivityGranted,
        slug: 'motionActivityGranted',
        name: 'Permiso de actividad física'
      },
      {
        ok: lowPowerModePermissionGranted,
        slug: 'lowPowerModePermissionGranted',
        name: 'Permiso de optimización de batería'
      }
    ];

    PERMISSIONS_DEFS.forEach((def) => {
      if (!def.ok) {
        permissions.push(
          <span className="not-ok" key={`${def.slug}`}>
            {def.name}
          </span>
        );
      }
    });
  }

  function handleSettings(settings, heartbeats) {
    if (!heartbeats) return;

    const { gpsEnabled, lowPowerMode, wifiConnection, networkConnection } =
      heartbeats;

    const SETTINGS_DEFS = [
      {
        ok: gpsEnabled,
        slug: 'gpsEnabled',
        name: 'Ubicación desactivada'
      },
      {
        ok: !lowPowerMode,
        slug: 'lowPowerMode',
        name: 'Ahorro de batería prendido'
      },
      {
        ok: networkConnection,
        slug: 'networkConnection',
        name: 'Sin conexión a internet'
      }
    ];

    SETTINGS_DEFS.forEach((def) => {
      if (!def.ok) {
        settings.push(
          <span className="not-ok" key={`${def.slug}`}>
            {def.name}
          </span>
        );
      }
    });

    // Handle internet connection method
    if (networkConnection) {
      if (wifiConnection) {
        settings.push(
          <span className="ok" key="wifiConnection">
            Conectado por WiFi
          </span>
        );
      } else {
        settings.push(
          <span className="ok" key="mobileConnection">
            Conectado por datos móviles
          </span>
        );
      }
    }
  }

  if (status === 'inactive') {
    // No information if driver is inactive
    const retLocal = (
      <span
        className="not-ok pointer"
        // eslint-disable-next-line max-len
        data-tip="Mantenemos su información pero no puede registrar viajes nuevos"
        key="disables-driver"
      >
        Conductor deshabilitado
      </span>
    );
    main.push(retLocal);
  } else if (firstLogin) {
    // Not configured users
    const retLocal = (
      <span
        className="not-ok pointer"
        data-tip="El operador no ha descargado la aplicación"
        key="1"
      >
        Conductor sin configurar
      </span>
    );
    main.push(retLocal);
  } else if (appStatus === 'inactive' && !isResting) {
    // No information if driver is inactive
    const retLocal = (
      <span
        className="no-info pointer"
        data-tip="Sus viajes no están siendo registrados"
        key="2"
      >
        Conductor desactivado
      </span>
    );
    main.push(retLocal);

    // Permissions
    handlePermissions(main, heartbeats);
    // Setting
    handleSettings(main, heartbeats);
  } else if (appStatus === 'active' && !isResting) {
    // Handle no heartbeats
    if (!heartbeats) {
      main.push(
        <span className="no-info" key="12">
          Información no disponible
        </span>
      );
    } else {
      // Permissions
      handlePermissions(main, heartbeats);
      // Setting
      handleSettings(main, heartbeats);

      // Stuck trips
      if (heartbeats.unsentTripCount) {
        const retLocal = (
          <span className="not-ok" key="unsentTripCount">
            {heartbeats.unsentTripCount} viaje
            {heartbeats.unsentTripCount > 1 ? 's' : null} atorado
            {heartbeats.unsentTripCount > 1 ? 's' : null}
          </span>
        );
        main.push(retLocal);
      }

      // Get last seen in hours
      let hoursSinceLastSeen = dayjs().diff(dayjs(lastSeen), 'h');
      // Check if heartbeat creation date is sooner
      const hoursSinceLastSeenHeartbeat = dayjs().diff(
        dayjs(heartbeats.date),
        'h'
      );
      if (hoursSinceLastSeenHeartbeat < hoursSinceLastSeen) {
        // Replace last seen date with heartbeat one
        hoursSinceLastSeen = hoursSinceLastSeenHeartbeat;
      }

      // Check user status based on last seen
      if (hoursSinceLastSeen > 120) {
        // Lost drivers
        const retLocal = (
          <>
            <span className="not-ok" key="3">
              Posible desinstalación de app
            </span>
            <span className="not-ok" key="4">
              Posible baja del operador
            </span>
          </>
        );
        main.push(retLocal);
      } else if (hoursSinceLastSeen <= 120 && hoursSinceLastSeen > 24) {
        // Warning for soon to be lost drivers
        main.push(
          <span
            className="attention"
            data-tip="El usuario dejó de registrar actividad reciente"
            key="5"
          >
            En riesgo de perderse
          </span>
        );
      } else if (hoursSinceLastSeen <= 24) {
        // Trip in progress or not
        if (isDriving) {
          main.push(
            <span className="ok" key="12">
              Viaje en curso
            </span>
          );
        } else {
          main.push(
            <span
              className="no-info"
              // eslint-disable-next-line max-len
              data-tip="Sus viajes se registrarán de manera automática"
              key="13"
            >
              Viaje no en curso
            </span>
          );
        }
      }
    }
  } else if (isResting) {
    main.push(
      <span
        className="no-info pointer"
        data-tip="Te recomendamos mantener desactivado al operador"
        key="1"
      >
        Conductor en descanso
      </span>
    );
  }

  return (
    <div id="_driver-heartbeats_">
      <div className="hb-container">{main}</div>
      <ReactTooltip />
    </div>
  );
}

export default DriverHeartbeats;
