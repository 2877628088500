/* eslint max-len: 0 */

import React from 'react';

function AuxActivity(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 24"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M20.6088 19.3419L12.7334 10.3689C13.4179 9.07082 13.6321 7.57581 13.3395 6.13775C13.0469 4.69969 12.2655 3.40724 11.1282 2.47985C9.99083 1.55246 8.56755 1.04731 7.10002 1.05018C5.6325 1.05305 4.2112 1.56376 3.07748 2.49559C1.94376 3.42742 1.1675 4.72292 0.880515 6.16211C0.593531 7.6013 0.813514 9.09546 1.50311 10.3909C2.19271 11.6863 3.30941 12.7031 4.66359 13.2686C6.01777 13.8341 7.52595 13.9135 8.93203 13.4933L10.0565 14.8066L12.0093 15.1068L11.8514 16.8195L12.5154 17.5811L12.9124 18.0343L13.2053 18.35L13.5463 18.7373L14.0108 19.2654L15.7081 19.3695L15.7146 21.2011L16.3005 21.8675L16.9172 22.5908L19.7292 23.0334C19.8601 23.0532 19.9937 23.0437 20.1204 23.0056C20.2472 22.9675 20.3639 22.9017 20.4622 22.8131C20.5605 22.7244 20.6379 22.6151 20.6888 22.493C20.7398 22.3708 20.763 22.2389 20.7568 22.1067L20.6088 19.3419Z" />
      <path d="M5.71153 6.46012C6.36446 6.46012 6.89377 5.93081 6.89377 5.27788C6.89377 4.62495 6.36446 4.09564 5.71153 4.09564C5.0586 4.09564 4.5293 4.62495 4.5293 5.27788C4.5293 5.93081 5.0586 6.46012 5.71153 6.46012Z" />
    </svg>
  );
}

export default AuxActivity;
