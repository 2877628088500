import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ManualVideo from '../../common/manual-video/ManualVideo';
import './HeaderSection.scss';

function HeaderSection(props) {
  const { title, body, videoUrl } = props;
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <>
      <div id="_header-section_">
        <div className="row" id="main-title">
          <div className="col s12">
            <h5>
              {title}
              {videoUrl && (
                <span>
                  <FontAwesomeIcon
                    icon="question-circle"
                    id="more-info"
                    onClick={() => setPlayVideo(true)}
                  />
                </span>
              )}
            </h5>
            <p>{body}</p>
          </div>
        </div>
      </div>
      {playVideo && (
        <ManualVideo
          videoUrl={videoUrl}
          playVideo={playVideo}
          setPlayVideo={setPlayVideo}
        />
      )}
    </>
  );
}

export default HeaderSection;
