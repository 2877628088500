import skins from '../utils/skins';

export function setCompanySkin(skin) {
  return {
    type: 'SET_COMPANY_SKIN',
    payload: skin
  };
}

export function unsetCompanySkin() {
  return {
    type: 'UNSET_COMPANY_SKIN',
    payload: skins['airbagtech.io']
  };
}
