import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import classNames from 'classnames';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import axiosAuth from '../../../utils/axiosAuth';
import Icon from '../../common/airbag-icons/Icon';
import SideModal from '../../common/side-modal/SideModal';
import AirbagLoaderLines from '../../common/airbag-loader/AirbagLoaderLines';
import Error from '../../common/error/Error';
import LoaderFullscreen from '../../common/loader-fullscreen/LoaderFullscreen';
import MessageCourtain from '../../common/message-courtain/MessageCourtain';
// eslint-disable-next-line max-len
import RenderDriversTable from './content-user-group/drivers-table-user-group/RenderDriversTable';
import RenderStats from './content-user-group/stats-user-group/RenderStats';
// eslint-disable-next-line max-len
import RenderLeadersTable from './content-user-group/leaders-table-user-group/RenderLeadersTable';
// eslint-disable-next-line max-len
import DeleteUserGroup from './functions-user-group/delete-user-group/DeleteUserGroup';
// eslint-disable-next-line max-len
// import RenderPerformance from './content-user-group/performance-user-group/RenderPerformance';
// eslint-disable-next-line max-len
import RenderRanking from './content-user-group/ranking-user-group/RenderRanking';
import EditGroup from './functions-user-group/edit-user-group/EditUserGroup';
import './UserGroup.scss';

function CompanyGroup(props) {
  // Get all data after mian url
  const queryString = window.location.search;
  // Get all params
  const urlParams = new URLSearchParams(queryString);
  const { history, user, company } = props;
  const [editGroupOpen, setEditGroupOpen] = useState(false);
  const groupId = urlParams.get('groupId');
  const [group, setGroup] = useState({});
  const [error, setError] = useState(null);
  const [showLoaderFullscreen, setShowLoaderFullscreen] = useState(false);
  const [section, setSection] = useState('admins');

  useEffect(() => {
    // Get group info
    fetchGroup();

    // Handle tab in params
    const location = urlParams.get('tab');
    if (
      location === 'admins' ||
      location === 'drivers' ||
      location === 'stats' ||
      // location === 'performance' ||
      location === 'ranking'
    ) {
      setSection(location);
    } else setSection('admins');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchGroup() {
    // Search for group
    axiosAuth
      .get(`/api/user-groups/group/${groupId}`)
      .then((res) => {
        const { group } = res.data;
        if (group) {
          const processedDrivers = insertLastSeen(group.drivers);
          setGroup({ ...group, drivers: processedDrivers });
        } else {
          setGroup(null);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }

  function insertLastSeen(rawDrivers) {
    const ret = [];

    rawDrivers.forEach((d) => {
      // Default a negative number for filtering
      d.lastSeenSince = -1;

      // Validate driver's position
      if (d.position && d.position.dateTime) {
        // Make diff negative to always have drivers with no locations last
        d.lastSeenSince = dayjs().diff(dayjs(d.position.dateTime), 'm');
      }

      // Add to array
      ret.push(d);
    });

    return ret;
  }

  function renderSection() {
    switch (section) {
      case 'admins':
        return <RenderLeadersTable group={group} />;
      case 'drivers':
        return <RenderDriversTable group={group} setGroup={setGroup} />;
      case 'stats':
        return (
          <RenderStats
            user={user}
            group={group}
            period="biweekly"
            company={company}
          />
        );
      // case 'performance':
      //   return (
      // <RenderPerformance driversIds={group.driversIds} company={company} />
      //   );
      case 'ranking':
        return <RenderRanking company={company} />;
      default:
        return <RenderLeadersTable group={group} />;
    }
  }

  // API error handler
  if (error) return <Error error={error} />;

  // Not found
  if (!group) {
    return (
      <MessageCourtain
        icon="user-slash"
        message="No se encontró el grupo"
        title="¡Oops!"
        link="/grupos"
      />
    );
  }

  // Info loading
  if (!Object.keys(group).length) {
    return <AirbagLoaderLines />;
  }

  return (
    <div id="_company-groups_">
      <div className="row">
        <div className="col s12 right-align">
          <Icon
            icon="settings"
            id="edit-icon"
            onClick={() => {
              setEditGroupOpen(true);

              // Google Analytics
              ReactGA.event({
                category: 'user_groups',
                action: 'open_edit_user_group'
              });
              // Posthog
              posthog.capture('open_edit_user_group', {
                category: 'user_groups',
                isCustomEvent: true
              });
            }}
          />
        </div>
      </div>
      <h5 id="main-title">Detalle de grupo</h5>
      <div className="row">
        <div className="col s12">
          <div className="row info-table">
            <div className="col s12 tableHeader">
              <p className="table-title">Información general</p>
            </div>
            <div className="col s6">
              <p className="subtitle">Nombre</p>
              <p className="tableValue">{group.name}</p>
            </div>

            <div className="col s6">
              <p className="subtitle">Creación</p>
              <p className="tableValue">
                {dayjs(group.created).format('DD/MM/YYYY')}
              </p>
            </div>
          </div>
        </div>
        <div className="col s12">
          <div id="group-menu">
            <div id="menu-container">
              <span
                onClick={() => {
                  setSection('admins');
                  history.push(
                    `${location.pathname}?groupId=${groupId}&tab=admins`
                  );

                  // Google Analytics
                  ReactGA.event({
                    category: 'group_details',
                    action: 'admins_tab'
                  });
                  // Posthog
                  posthog.capture('admins_tab', {
                    category: 'group_details',
                    isCustomEvent: true
                  });
                }}
                className={classNames('sec', {
                  active: section === 'admins'
                })}
              >
                <span className="sec-content">Administradores</span>
              </span>
              <span
                onClick={() => {
                  setSection('drivers');
                  history.push(
                    `${location.pathname}?groupId=${groupId}&tab=drivers`
                  );

                  // Google Analytics
                  ReactGA.event({
                    category: 'group_details',
                    action: 'drivers_tab'
                  });
                  // Posthog
                  posthog.capture('drivers_tab', {
                    category: 'group_details',
                    isCustomEvent: true
                  });
                }}
                className={classNames('sec', { active: section === 'drivers' })}
              >
                <span className="sec-content">Conductores</span>
              </span>
              <span
                onClick={() => {
                  setSection('stats');
                  history.push(
                    `${location.pathname}?groupId=${groupId}&tab=stats`
                  );
                  // Google Analytics
                  ReactGA.event({
                    category: 'group_details',
                    action: 'stats'
                  });
                  // Posthog
                  posthog.capture('stats', {
                    category: 'group_details',
                    isCustomEvent: true
                  });
                }}
                className={classNames('sec', { active: section === 'stats' })}
              >
                <span className="sec-content">Estadísticas</span>
              </span>
              {/* <span
                onClick={() => {
                  setSection('performance');
                  history.push(
                    `${location.pathname}?groupId=${groupId}&tab=performance`
                  );

                  // Google Analytics
                  ReactGA.event({
                    category: 'group_details',
                    action: 'performance'
                  });
                  // Posthog
                  posthog.capture('performance', {
                    category: 'group_details',
                    isCustomEvent: true
                  });
                }}
                className={classNames('sec', {
                  active: section === 'performance'
                })}
              >
                <span className="sec-content">Rendimiento</span>
              </span> */}
              <span
                onClick={() => {
                  setSection('ranking');
                  history.push(
                    `${location.pathname}?groupId=${groupId}&tab=ranking`
                  );

                  // Google Analytics
                  ReactGA.event({
                    category: 'group_details',
                    action: 'ranking'
                  });
                  // Posthog
                  posthog.capture('ranking', {
                    category: 'group_details',
                    isCustomEvent: true
                  });
                }}
                className={classNames('sec', {
                  active: section === 'ranking'
                })}
              >
                <span className="sec-content">Ranking</span>
              </span>
            </div>
          </div>
        </div>
        <div className="col s12">{renderSection()}</div>
        <DeleteUserGroup
          groupId={groupId}
          setShowLoaderFullscreen={setShowLoaderFullscreen}
          showLoaderFullscreen={showLoaderFullscreen}
          history={history}
        />
        {showLoaderFullscreen && <LoaderFullscreen />}
      </div>

      {/* Edit Group */}
      <SideModal isOpen={editGroupOpen} setIsOpen={setEditGroupOpen}>
        {editGroupOpen ? (
          <EditGroup
            companyGroup={group}
            fetchGroup={fetchGroup}
            id={groupId}
            setIsOpen={setEditGroupOpen}
          />
        ) : null}
      </SideModal>
    </div>
  );
}

export default withRouter(CompanyGroup);
