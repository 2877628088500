import activitiesDefinitions from '../activitiesDefinitions';
import getMarker from './getMarker';

// Function to add last location markers on the map
function addReportLocationMarkers(reportMarker, H, mapObj) {
  const key = reportMarker.type;
  const currentObjects = mapObj.current.getObjects();
  mapObj.current.removeObjects(currentObjects);
  // Find log information
  const logInfo = activitiesDefinitions.find((l) => l.slug === key);

  // Get marker icon
  const icon = new H.map.Icon(getMarker(logInfo.markerImg), {
    anchor: new H.math.Point(29, 85)
  });

  const marker = new H.map.Marker(
    { lat: reportMarker.lat, lng: reportMarker.lng },
    { icon }
  );
  mapObj.current.addObject(marker);
}

export default addReportLocationMarkers;
