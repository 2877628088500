/* eslint max-len: 0 */

import React from 'react';

function Manuals(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="29"
      viewBox="0 0 24 29"
      fill="none"
      {...props}
    >
      <path
        d="M23 4.05797V25.0475H4.85275V1H19.2003L23 4.05797Z"
        fill="white"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M22.6972 4.45914H19.6695C19.5766 4.47077 19.4822 4.46147 19.3934 4.43194C19.3045 4.4024 19.2233 4.35337 19.1559 4.28843C19.0884 4.22349 19.0363 4.14428 19.0034 4.0566C18.9704 3.96892 18.9575 3.875 18.9656 3.78169V1.32925"
        fill="white"
      />
      <path
        d="M22.6972 4.45914H19.6695C19.5766 4.47077 19.4822 4.46147 19.3934 4.43194C19.3045 4.4024 19.2233 4.35337 19.1559 4.28843C19.0884 4.22349 19.0363 4.14428 19.0034 4.0566C18.9704 3.96892 18.9575 3.875 18.9656 3.78169V1.32925"
        strokeMiterlimit="10"
      />
      <path
        d="M19.1473 6.9797V27.9692H1V3.92172H15.3475L19.1473 6.9797Z"
        fill="white"
        strokeMiterlimit="10"
      />
      <path
        d="M18.8445 7.37709H15.8168C15.7239 7.38872 15.6295 7.37943 15.5406 7.34989C15.4518 7.32035 15.3706 7.27132 15.3031 7.20638C15.2357 7.14144 15.1836 7.06223 15.1506 6.97455C15.1177 6.88688 15.1048 6.79295 15.1129 6.69964V4.25099"
        fill="white"
      />
      <path
        d="M18.8445 7.37709H15.8168C15.7239 7.38872 15.6295 7.37943 15.5406 7.34989C15.4518 7.32035 15.3706 7.27132 15.3031 7.20638C15.2357 7.14144 15.1836 7.06223 15.1506 6.97455C15.1177 6.88688 15.1048 6.79295 15.1129 6.69964V4.25099"
        strokeMiterlimit="10"
      />
      <path
        d="M2.87717 25.8612H17.2663"
        stroke="#B6BAB5"
        strokeWidth="0.25"
        strokeMiterlimit="10"
      />
      <path
        d="M2.87717 23.9083H17.2663"
        stroke="#B6BAB5"
        strokeWidth="0.25"
        strokeMiterlimit="10"
      />
      <path
        d="M2.87717 21.9554H17.2663"
        stroke="#B6BAB5"
        strokeWidth="0.25"
        strokeMiterlimit="10"
      />
      <path
        d="M2.87717 20.0026H17.2663"
        stroke="#B6BAB5"
        strokeWidth="0.25"
        strokeMiterlimit="10"
      />
      <path
        d="M2.87717 18.0459H17.2663"
        stroke="#B6BAB5"
        strokeWidth="0.25"
        strokeMiterlimit="10"
      />
      <path
        d="M2.87717 16.0931H17.2663"
        stroke="#B6BAB5"
        strokeWidth="0.25"
        strokeMiterlimit="10"
      />
      <path
        d="M2.87717 14.1402H17.2663"
        stroke="#B6BAB5"
        strokeWidth="0.25"
        strokeMiterlimit="10"
      />
      <path
        d="M2.87717 12.1873H17.2663"
        stroke="#B6BAB5"
        strokeWidth="0.25"
        strokeMiterlimit="10"
      />
      <path
        d="M2.87717 10.2345H17.2663"
        stroke="#B6BAB5"
        strokeWidth="0.25"
        strokeMiterlimit="10"
      />
      <path
        d="M2.87717 8.2816H17.2663"
        stroke="#B6BAB5"
        strokeWidth="0.25"
        strokeMiterlimit="10"
      />
      <path
        d="M6.54069 12.1873L6.65802 20.0783L7.70636 20.2372V20.7481H4.85275V20.2372L5.98814 20.0745V12.8421L4.85275 12.6983V12.1873H7.1538L10.0263 19.2229C10.0294 19.2293 10.0343 19.2347 10.0403 19.2385C10.0463 19.2423 10.0533 19.2443 10.0604 19.2443C10.0675 19.2443 10.0745 19.2423 10.0805 19.2385C10.0865 19.2347 10.0914 19.2293 10.0945 19.2229L12.8156 12.1873H15.2945V12.6983L14.1024 12.8345C14.0797 13.9207 14.0797 15.0296 14.0797 16.1498V16.7856C14.0797 18.1027 14.0797 18.7839 14.1024 20.101L15.2945 20.2372V20.7481H11.8429V20.2372L12.9178 20.1085C12.9556 18.818 12.9556 18.133 12.9556 16.7894V14.2537C12.9573 14.2238 12.9481 14.1942 12.9298 14.1705C12.9114 14.1468 12.8852 14.1304 12.8558 14.1244C12.8264 14.1185 12.7958 14.1233 12.7697 14.138C12.7435 14.1527 12.7236 14.1763 12.7134 14.2045L10.155 20.7481H9.53054L6.82833 14.1667C6.81817 14.1385 6.79819 14.1148 6.77205 14.1001C6.74591 14.0854 6.71534 14.0806 6.68595 14.0866C6.65655 14.0926 6.63029 14.1089 6.61196 14.1327C6.59362 14.1564 6.58445 14.1859 6.58611 14.2159L6.54069 12.1873Z"
        fill="#000"
        strokeWidth="0.25"
      />
    </svg>
  );
}

export default Manuals;
