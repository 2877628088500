import React from 'react';

function Day(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <circle className="cls-1" cx="141.73" cy="141.73" r="63.87" />
        <line
          className="cls-1"
          x1="141.73"
          y1="233.86"
          x2="141.73"
          y2="255.12"
        />
        <line className="cls-1" x1="141.73" y1="49.61" x2="141.73" y2="28.35" />
        <line className="cls-1" x1="49.61" y1="141.73" x2="28.35" y2="141.73" />
        <line
          className="cls-1"
          x1="233.86"
          y1="141.73"
          x2="255.12"
          y2="141.73"
        />
        <line className="cls-1" x1="77.87" y1="75.34" x2="63.13" y2="60.02" />
        <line
          className="cls-1"
          x1="205.6"
          y1="208.13"
          x2="220.34"
          y2="223.45"
        />
        <line className="cls-1" x1="77.87" y1="208.13" x2="63.13" y2="223.45" />
        <line className="cls-1" x1="205.6" y1="75.34" x2="220.34" y2="60.02" />
      </g>
    </svg>
  );
}

export default Day;
