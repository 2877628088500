import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import './DriverCoins.scss';

// 'Dictionary' with the reasons keys and string values
const reasons = {
  trip: 'Viaje',
  'daily-bonus': 'Bono del día',
  prizes: 'Premio',
  'tech-issue': 'Problema técnico',
  challenges: 'Reto',
  referral: 'Referido',
  'welcome-bonus': 'Bono de bienvenida',
  'company-movement': 'Movimiento de la compañía',
  bonus: 'Bono'
};

export const DriverCoinsItem = ({
  action,
  diff,
  reason,
  created,
  oldCoins,
  newCoins,
  description
}) => {
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

  const handleItemClick = () => {
    setIsDescriptionOpen(!isDescriptionOpen);
  };

  return (
    <li
      className={`driver-coins-item ${description ? 'has-description' : ''}`}
      onClick={handleItemClick}
    >
      {description && (
        <div className="description-caret">
          <FontAwesomeIcon
            icon={['fal', isDescriptionOpen ? 'caret-up' : 'caret-down']}
            className={`${action}`}
          />
        </div>
      )}
      <div className="icon-and-number">
        <FontAwesomeIcon
          icon={['fa', action === 'add' ? 'plus' : 'minus']}
          className={`${action}`}
        />
        <span>{Math.abs(Math.floor(diff))}</span>
      </div>
      <div className="coin-item date">
        <span>{dayjs(created).format('DD/MMM/YY')}</span>
      </div>
      <div className="coin-item reason">
        <span>{reasons[reason]}</span>
      </div>
      <div className="coin-item">
        <span>{Math.floor(oldCoins)}</span>
      </div>
      <div className="coin-item">
        <span>{Math.floor(newCoins)}</span>
      </div>
      {description && (
        <div className={`description ${isDescriptionOpen ? 'open' : false}`}>
          {description}
        </div>
      )}
    </li>
  );
};
