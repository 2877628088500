import getMarker from './getMarker';
import displayPolyline from './displayPolyline';
import displayLogMarker from './displayLogMarker';
import displaySanctions from './displaySanctions';

// Function to display a trip
function displayTrip(
  H,
  mapObj,
  mapUI,
  tripGroup,
  applyBoundingBox,
  trip,
  color,
  filters
) {
  // Declare map group
  const group = new H.map.Group();
  tripGroup.current = group;

  // Sanitize trip's location logs for polyline
  const waypoints = [];
  for (const locLog of trip.locationLogs) {
    waypoints.push({ lat: locLog.position.lat, lng: locLog.position.lon });
  }

  // Display trip polyline
  displayPolyline(H, mapObj, mapUI, group, waypoints, color, 8, 1, null);

  // If custom polylines are sent, display them
  if (trip.polylines) {
    const { phoneDistraction, midSpeeding, highSpeeding } = trip.polylines;

    // Handle phone distraction
    if (phoneDistraction && phoneDistraction.length) {
      // Display sections
      for (const section of phoneDistraction) {
        // Verify that each section has at least 2 points
        if (section.length > 1) {
          // Display outer border
          displayPolyline(
            H,
            mapObj,
            mapUI,
            group,
            section,
            '#015073',
            14,
            0,
            displaySanctions(section)
          );
          // Display a transparent overlay to trigger hover
          // in same space as main polyline
          displayPolyline(
            H,
            mapObj,
            mapUI,
            group,
            section,
            color,
            8,
            2,
            displaySanctions(section)
          );
        }
      }
    }

    // Handle mid speeding
    if (midSpeeding && midSpeeding.length) {
      // Display sections
      for (const section of midSpeeding) {
        // Verify that each section has at least 2 points
        if (section.length > 1) {
          displayPolyline(
            H,
            mapObj,
            mapUI,
            group,
            section,
            '#fdda0d',
            6,
            2,
            displaySanctions(section)
          );
        }
      }
    }

    // Handle high speeding
    if (highSpeeding && highSpeeding.length) {
      // Display sections
      for (const section of highSpeeding) {
        // Verify that each section has at least 2 points
        if (section.length > 1) {
          displayPolyline(
            H,
            mapObj,
            mapUI,
            group,
            section,
            '#e63228',
            6,
            3,
            displaySanctions(section)
          );
        }
      }
    }
  }

  // Add start marker
  const startIcon = new H.map.Icon(getMarker('start'), {
    anchor: new H.math.Point(29, 85)
  });
  const startMarker = new H.map.Marker(
    { lat: trip.startPosition.lat, lng: trip.startPosition.lon },
    { icon: startIcon }
  );
  group.addObject(startMarker);

  // Paint the log markers of the trip
  if (trip.logs && trip.logs.length) {
    for (const log of trip.logs) {
      // Get log type
      let key = log.type;
      // Check for log sub-type in metadata
      if (log.metadata && log.metadata.type) key = log.metadata.type;
      // If key is Speeding not add marker
      if (key == 'speeding') continue;
      // Filter log if filters param is present and key not included
      if (filters && !filters.includes(key)) {
        continue;
      }

      // Add log marker
      displayLogMarker(H, mapObj, mapUI, group, log);
    }
  }

  // Add end marker
  const endIcon = new H.map.Icon(getMarker('end'), {
    anchor: new H.math.Point(29, 85)
  });
  const endMarker = new H.map.Marker(
    { lat: trip.endPosition.lat, lng: trip.endPosition.lon },
    { icon: endIcon }
  );
  group.addObject(endMarker);

  // Add the group to the map
  mapObj.current.addObject(group);

  // Set minimum map zoom to fit all objects
  // Docs: https://developer.here.com/documentation/examples/maps-js/markers/zoom-to-set-of-markers
  if (applyBoundingBox) {
    mapObj.current.getViewModel().setLookAtData({
      bounds: group.getBoundingBox()
    });
  }
}

export default displayTrip;
