import React, { useState, useEffect, useMemo } from 'react';
import axiosAuth from '../../../../utils/axiosAuth';
import Error from '../../../common/error/Error';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import { DriverCoinsItem } from './DriverCoinsItem';
import Button from '../../../common/button/Button';

export const DriverCoins = ({ driver }) => {
  const [coinHistory, setCoinHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [error, setError] = useState(null);

  // Async function to fetch the first 25 elements
  const fetchFirstElements = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosAuth.get(
        `api/users/${driver.id}/coins-history`
      );
      setCoinHistory(data.coinHistory);
      setLastVisible(
        data.coinHistory ? data.coinHistory[data.coinHistory.length - 1] : null
      );
      setHasMore(data.coinHistory ? data.coinHistory.length === 25 : false);
    } catch (e) {
      setError(e);
      console.error(`Algo salíó mal: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Async function to fetch the next 25 elements starting from
  // the 'lastVisible' one
  const fetchMore = async () => {
    try {
      setIsLoadingMore(true);
      const { data } = await axiosAuth.get(
        `api/users/${driver.id}/coins-history/${lastVisible.created}`
      );
      setCoinHistory([...coinHistory, ...data.coinHistory]);
      setLastVisible(data.coinHistory[data.coinHistory.length - 1]);
      setHasMore(data.coinHistory.length === 25);
    } catch (e) {
      setError(e);
      console.error(`Algo salíó mal: ${e}`);
    } finally {
      setIsLoadingMore(false);
    }
  };

  // Call 'fetchFirstTenElements' when component mounts
  useEffect(() => {
    fetchFirstElements();
  }, []);

  // Use memoization to avoid unnecessary re-renders
  const coinsRows = useMemo(() => {
    return coinHistory?.map((item) => (
      <DriverCoinsItem {...item} key={item.id} />
    ));
  }, [coinHistory]);

  // If there is an error fetching documents, will render this component
  if (error) return <Error error={error} />;

  return (
    <div className="airbag-coins-container" id="coins-table">
      {isLoading ? (
        <AirbagLoader />
      ) : coinHistory && coinHistory.length > 0 ? (
        <div className="coin-history-content">
          <header className="coin-history-header">
            <div>Puntos</div>
            <div>Fecha</div>
            <div>Motivo</div>
            <div>Antes</div>
            <div>Después</div>
          </header>
          <ul className="coin-history">{coinsRows}</ul>

          {hasMore && (
            <Button
              variant="yellow-button"
              className="big-button coin-history-button"
              loading={isLoading || isLoadingMore}
              disabled={!hasMore}
              type="submit"
              onClick={fetchMore}
            >
              {!isLoadingMore ? 'Cargar más' : 'Cargando...'}
            </Button>
          )}
        </div>
      ) : (
        <div className="no-history">Sin historial</div>
      )}
    </div>
  );
};
