import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import M from 'materialize-css';
import Swal from 'sweetalert2';
import Button from '../../common/button/Button';
import Icon from '../../common/airbag-icons/Icon';
import Loader from '../../common/loader/Loader';
import TwoFAWizard from '../two-FA-wizard/TwoFAWizard';
import './Login.scss';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

function Login(props) {
  const { skin } = props;
  const [fullPageLoading, setFullPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passType, setPassType] = useState('password');
  const [requestTwoFa, setRequestTwoFa] = useState(false);
  const recaptchaToken = useRef('');

  useEffect(() => {
    // If access token is found, redirect to dashboard
    if (localStorage.getItem('aat')) {
      props.history.push('/');
      return;
    }

    setFullPageLoading(false);

    // Add reCaptcha
    // const script = document.createElement('script');
    // script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
    // script.addEventListener('load', handleLoaded);
    // document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleLoaded = (_) => {
  //   window.grecaptcha.ready((_) => {
  //     window.grecaptcha
  //       .execute(RECAPTCHA_SITE_KEY, { action: 'login' })
  //       .then((token) => {
  //         recaptchaToken.current = token;
  //         setFullPageLoading(false);
  //       });
  //   });
  // };

  function doLogin(e) {
    e.preventDefault();
    setLoading(true);

    // Make login
    axios
      .post('/api/auth/login', {
        email: email.toLowerCase(),
        password,
        recaptchaToken: recaptchaToken.current
      })
      .then((res) => {
        const { user, company, accessToken, refreshToken } = res.data;

        // Save user and company information in store
        props.setCompany(company);
        props.setUser(user);

        // Check if user has 2FA
        if (user.has2FAEnabled) {
          setLoading(false);
          // This will show 2FA section
          setRequestTwoFa(true);
        } else {
          // Save both tokens in localStorage
          localStorage.setItem('aat', accessToken);
          localStorage.setItem('art', refreshToken);
          // Save company ID
          localStorage.setItem('currentCompanyId', company.id);

          // Redirect to dashboard
          props.history.push('/');
        }
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';

        if (err.response && err.response.data) {
          const { message, failedAttempt } = err.response.data;

          // Failed attempt warning
          if (failedAttempt && failedAttempt > 1 && failedAttempt < 5) {
            // This will fire second time
            const swalConfig = {
              icon: 'question',
              title: '¿Olvidaste tu contraseña?',
              // eslint-disable-next-line max-len
              text: 'Da click al siguiente botón para restablecer tu contraseña'
            };

            if (failedAttempt === 3) {
              // On third attempt
              swalConfig.icon = 'warning';
              swalConfig.title = 'Tu cuenta será bloqueada';
              swalConfig.text =
                // eslint-disable-next-line max-len
                'Si no recuerdas tu contraseña, puedes seguir instrucciones para restablecerla dando click en el siguiente botón ';
            } else if (failedAttempt === 4) {
              // Last attempt
              swalConfig.icon = 'warning';
              swalConfig.title = 'Último intento';
              swalConfig.text =
                'Tu cuenta será bloqueada en el próximo intento fallido';
            }

            // Fire modal
            Swal.fire({
              icon: swalConfig.icon,
              title: swalConfig.title,
              text: swalConfig.text,
              confirmButtonColor: '#f6c700',
              confirmButtonText:
                // eslint-disable-next-line max-len
                '<span class="dark-color-text">Restablecer contraseña</span>',
              cancelButtonText: 'Cancelar',
              showCancelButton: true
            }).then((result) => {
              if (result.isConfirmed) {
                props.history.push('/forgot-password');
              }
            });
          } else if (failedAttempt && failedAttempt === 5) {
            // Account blocked
            Swal.fire({
              icon: 'error',
              title: 'Cuenta bloqueada',
              text: 'Ponte en contacto con soporte para recuperarla',
              confirmButtonColor: '#f6c700',
              confirmButtonText: '<span class="dark-color-text">Aceptar</span>'
            });
          } else {
            // Check if custom message was sent from back-end
            if (message && typeof message === 'string') {
              msg = message;
            }
            M.toast({
              html: msg,
              classes: 'error-toast'
            });
          }
        } else {
          M.toast({
            html: msg,
            classes: 'error-toast'
          });
        }

        setLoading(false);
      });
  }

  // Wait for page to load
  if (fullPageLoading) {
    return <Loader />;
  }

  return (
    <div id="_login_">
      <div className="outer">
        <div className="middle">
          <div className="inner">
            {requestTwoFa ? (
              <TwoFAWizard {...props} password={password} />
            ) : (
              <form id="formContainer" onSubmit={doLogin}>
                <div className="row">
                  <div id="logo-container">
                    <img
                      id="logoBlack"
                      src={skin.logoBlack}
                      alt="Company logo"
                    />
                  </div>
                  <h2 id="formTitle">Inicia sesión</h2>
                  <div className="input-field col">
                    <input
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email" className="active">
                      Correo electrónico:
                    </label>
                  </div>
                  <div className="input-field col">
                    <input
                      id="password"
                      type={passType}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Icon
                      icon="eye"
                      className={`eye-icon ${passType}`}
                      onClick={() => {
                        if (passType === 'password') {
                          setPassType('text');
                        } else {
                          setPassType('password');
                        }
                      }}
                    />
                    <label htmlFor="password" className="active">
                      Contraseña:
                    </label>
                  </div>
                  <div id="recoverContainer">
                    <Link to="/forgot-password">¿Olvidaste tu contraseña?</Link>
                  </div>
                  <div className="col s12 center-align">
                    <Button
                      variant="yellow-button"
                      className="big-button"
                      loading={loading}
                      disabled={loading}
                      type="submit"
                      data-action="login"
                      data-sitekey={RECAPTCHA_SITE_KEY}
                    >
                      Iniciar sesión
                    </Button>
                    <div id="terms-text">
                      Al iniciar sesión aceptas los
                      <br />
                      <a
                        href={`https://${skin.domain}/terms`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        términos y condiciones
                      </a>{' '}
                      y el{' '}
                      <a
                        href={`https://${skin.domain}/privacy`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        aviso de privacidad
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
