import React from 'react';
import deltaProtect from '../../../assets/logos/protected-delta.svg';
import './Footer.scss';

function Footer(props) {
  const { skin } = props;

  return (
    <div className="row" id="footer">
      <div className="col s12 m6 l5" id="info">
        <div id="logo-container">
          <img src={skin.logoWhite} id="logo" alt="Company logo" />
        </div>
        <p>CDMX, México</p>
        <p>{skin.contactEmail}</p>
        <p>
          {skin.companyName} &reg; {new Date().getFullYear()}
        </p>
      </div>
      <div className="col s12 m6 l3 links">
        <h5>Legal</h5>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://${skin.domain}/terms`}
            >
              Términos y condiciones
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://${skin.domain}/privacy`}
            >
              Aviso de privacidad
            </a>
          </li>
        </ul>
      </div>
      <div className="col s12 m12 l4 right-align">
        <a
          title="Delta Protect | Cybersec First"
          href="https://deltaprotect.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            id="deltaprotect"
            src={deltaProtect}
            alt="Delta Protect | CyberSec First"
          />
        </a>
      </div>
    </div>
  );
}

export default Footer;
