import React, { useState } from 'react';
import M from 'materialize-css';
import Button from '../../../common/button/Button';
import PhoneInput from '../../../common/phone-input/PhoneInput';
import axiosAuth from '../../../../utils/axiosAuth';
import './AddDriver.scss';

function AddDriver(props) {
  const { fetchUsers, setIsOpen, user, group, groups } = props;
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [emergencyPhone, setEmergencyPhone] = useState('');
  const [data, setData] = useState({
    name: '',
    lastName: '',
    email: '',
    group: group ? group : '',
    emergencyContact: {
      name: '',
      type: 'cellphone'
    },
    internalCompanyId: ''
  });

  function addDriver(e) {
    e.preventDefault();
    setLoading(true);

    // Make request
    axiosAuth
      .post('/api/users', {
        ...data,
        phone,
        emergencyContact: { ...data.emergencyContact, phone: emergencyPhone }
      })
      .then((res) => {
        M.toast({
          html: 'Conductor agregado con éxito',
          classes: 'success-toast'
        });
        fetchUsers(true);
        setLoading(false);
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  return (
    <div id="_add-driver_">
      <h5 id="main-t">Nuevo conductor</h5>
      <form onSubmit={addDriver}>
        {/* INFORMACIÓN GENERAL */}
        <div className="row big-label">
          <div className="col s12">
            <p>Datos del conductor</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6 input-field">
            <label className="active">Nombre *</label>
            <input
              type="text"
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Apellidos *</label>
            <input
              type="text"
              value={data.lastName}
              onChange={(e) => setData({ ...data, lastName: e.target.value })}
            />
          </div>
          <div className="col s12 m6">
            <PhoneInput phone={phone} setPhone={setPhone} label="Celular" />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Correo electrónico</label>
            <input
              type="email"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Grupo</label>
            <select
              className="browser-default"
              value={data.group}
              onChange={(e) => setData({ ...data, group: e.target.value })}
            >
              <option value="" disabled={user.authRole === 'group-limited'}>
                Sin grupo
              </option>
              {groups.map((gp, i) => (
                <option key={i} value={gp.id}>
                  {gp.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">ID interno</label>
            <input
              type="text"
              value={data.internalCompanyId}
              onChange={(e) =>
                setData({ ...data, internalCompanyId: e.target.value })
              }
            />
          </div>
        </div>

        {/* CONTACTO DE EMERGENCIA */}
        <div className="row big-label">
          <div className="col s12">
            <p>Contacto de emergencia</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6 input-field">
            <label className="active">Nombre</label>
            <input
              type="text"
              value={data.emergencyContact.name}
              onChange={(e) =>
                setData({
                  ...data,
                  emergencyContact: {
                    ...data.emergencyContact,
                    name: e.target.value
                  }
                })
              }
            />
          </div>
          <div className="col s12 m6">
            <PhoneInput
              phone={emergencyPhone}
              setPhone={setEmergencyPhone}
              label="Teléfono"
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Tipo de teléfono</label>
            <select
              className="browser-default"
              value={data.emergencyContact.type}
              onChange={(e) =>
                setData({
                  ...data,
                  emergencyContact: {
                    ...data.emergencyContact,
                    type: e.target.value
                  }
                })
              }
            >
              <option value="cellphone">Celular</option>
              <option value="landphone">Línea fija</option>
            </select>
          </div>
        </div>

        <div className="center-align">
          <Button
            variant="yellow-button"
            loading={loading}
            disabled={loading}
            type="submit"
          >
            Agregar conductor
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddDriver;
