import React, { useState, useEffect } from 'react';
import axiosAuth from '../../../utils/axiosAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoaderInner from '../loader-inner/LoaderInner';
import './SearchUserInput.scss';

function SearchUserInput(props) {
  const { setResult, searchVehicle, selectedProps, mandatory } = props;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState(null);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    // Check if a user has been passed through props
    if (selectedProps) setSelected(selectedProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function searchUser(name) {
    if (name) {
      setLoading(true);
      // Make request
      axiosAuth
        .get('/api/users', {
          params: { query: value }
        })
        .then((res) => {
          setSuggestions(res.data.drivers);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setSuggestions(null);
    }
  }

  function renderSuggestions() {
    // Loading
    if (loading || !suggestions) return <LoaderInner />;

    // No suggestions
    if (!suggestions.length) {
      return <p className="center-align">Sin resultados</p>;
    }

    return (
      <div>
        {suggestions.map((result, i) => (
          <p
            className="suggestion"
            onClick={() => {
              // Selected
              setSelected(result);
              // Result in parent component
              setResult(result);
              if (searchVehicle) {
                searchVehicle(result);
              }
              // Reset suggestions
              setSuggestions(null);
              // Reset input value
              setValue('');
            }}
            key={`${result.id}-${i}`}
          >
            {result.name} {result.lastName}
          </p>
        ))}
      </div>
    );
  }

  return (
    <React.Fragment>
      <label className="active">
        Conductor{' '}
        {mandatory && <span id="sui-mandatory">(campo obligatorio)</span>}
      </label>
      {selected ? (
        <p className="selected">
          {selected.name} {selected.lastName}
          <FontAwesomeIcon
            icon="times"
            onClick={() => {
              // Remove selected
              setSelected(null);
              // Remove result in parent component
              setResult(null);
              // Remove suggestions
              setSuggestions(null);
              // Reset inpur value
              setValue('');
            }}
          />
        </p>
      ) : (
        <input
          type="text"
          placeholder="Nombre del conductor"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyUp={(e) => searchUser(e.target.value)}
          onBlur={() => {
            // Super ugly fix
            setTimeout(() => {
              setValue('');
            }, 250);
          }}
        />
      )}
      {value && <div className="suggestions-box">{renderSuggestions()}</div>}
    </React.Fragment>
  );
}

export default SearchUserInput;
