import React, { useEffect } from 'react';
import { sanitizeDay } from '../../../utils/sanitizers';
import scheduleHours from '../../../utils/scheduleHours';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ScheduleForm.scss';

function sanitizeAction(action) {
  if (action === 'activation') return 'activación';
  return 'desactivación';
}

function ScheduleForm(props) {
  const { schedule, setSchedule, timezone, setTimezone, errors, setErrors } =
    props;

  useEffect(() => {
    scheduleValidation(schedule);
    // console.log('Valid Schedule:', scheduleValidation(schedule));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule]);

  function mergeActionsIntoSingleArray(schedule) {
    // We merge all actions from all days into single array
    const actions = [];

    schedule.forEach((day, dayIndex) => {
      // Save actions in single array
      day.actions.forEach((action, index) =>
        actions.push({ ...action, day: dayIndex, index })
      );
    });

    return actions;
  }

  function scheduleValidation(schedule) {
    const errorsArray = ['', '', '', '', '', '', ''];

    // We merge all actions from all days into single array
    const fullActions = mergeActionsIntoSingleArray(schedule);
    // console.log(schedule);
    // console.log(fullActions);

    // Loop through all actions
    let validationPassed = true;
    for (let i = 0; i < fullActions.length; i++) {
      const action = fullActions[i];

      // Get next action
      let nextAction;
      let closesLoop;
      if (i === fullActions.length - 1) {
        // Last item in array compares with first item
        nextAction = fullActions[0];
        closesLoop = true;
      } else {
        nextAction = fullActions[i + 1];
        closesLoop = false;
      }

      // Verify that fullActions has at least 2 items
      if (fullActions.length >= 2) {
        // They must be opposite
        if (action.type === nextAction.type) {
          if (action.type === 'activation') {
            errorsArray[action.day] =
              'No puedes tener dos activaciones seguidas';
          } else {
            errorsArray[action.day] =
              'No puedes tener dos desactivaciones seguidas';
          }

          validationPassed = false;
        }

        // Hours must be chronologically ordered in day
        if (action.day === nextAction.day) {
          if (action.hour >= nextAction.hour && !closesLoop) {
            errorsArray[action.day] = `La ${sanitizeAction(
              nextAction.type
            )} de las ${nextAction.hour} debe ocurrir después de las ${
              action.hour
            }`;
            validationPassed = false;
          }
        }

        // TODO: Check time gap between actions
      }
    }

    // fullActions length must be a pair number
    if (fullActions.length % 2 !== 0) {
      errorsArray[7] = 'El total de acciones debe de ser un número par';
      validationPassed = false;
    }

    // Loop through all days
    for (let j = 0; j < schedule.length; j++) {
      const scheduleDay = schedule[j];

      // Can't have active days with empty actions
      if (scheduleDay.active && !scheduleDay.actions.length) {
        errorsArray[j] =
          'No puedes tener días activos sin activaciones/desactivaciones';
        validationPassed = false;
      }
    }

    setErrors(errorsArray);
    return validationPassed;
  }

  function prevDayIndex(day) {
    // If day is first index return last index, else return day - 1
    if (!parseInt(day)) return 6;
    else return parseInt(day) - 1;
  }

  // Day checkbox is clicked
  function handleDayActivation(day) {
    const scheduleCopy = [...schedule];

    // Change opposite status
    scheduleCopy[day].active = !scheduleCopy[day].active;

    if (!scheduleCopy[day].active) {
      // If day is marked as inactive we empty actions array
      scheduleCopy[day].actions = [];
    } else {
      // When a day is marked as active, its actions array is empty
      // so start with early action (default to activation)
      scheduleCopy[day].actions = [
        { type: 'activation', hour: '07:00' },
        { type: 'deactivation', hour: '14:00' }
      ];

      // Get previous day data
      const prevDay = scheduleCopy[prevDayIndex(day)];
      // If previous day is active, get opposite action type
      if (prevDay.active) {
        // Get last item in prev day actions array
        const lastAction = prevDay.actions[prevDay.actions.length - 1];
        if (lastAction && lastAction.type === 'activation') {
          // Last action from prev day was activation,
          // so we insert a deactivation
          scheduleCopy[day].actions = [{ type: 'deactivation', hour: '07:00' }];
        }
      }
    }

    setSchedule(scheduleCopy);
  }

  // Add new action to day
  function addActionToDay(day) {
    const scheduleCopy = [...schedule];
    const scheduleDay = { ...scheduleCopy[day] };

    if (!scheduleDay.actions.length) {
      // TODO
      // Actions array is empty.
      // We must check status from prev action to determine desired action
      // const prevDayIndex = getPrevDayIndex(day);
      // const prevDay = scheduleCopy[prevDayIndex];

      // Add activation if there are no actions
      scheduleDay.actions.push({ type: 'activation', hour: '11:00' });
    } else {
      scheduleDay.actions.push({ type: 'activation', hour: '11:00' });
    }

    // Update state
    scheduleCopy[day] = scheduleDay;
    setSchedule(scheduleCopy);
  }

  // Remove specific action
  function removeActionFromDay(day, index) {
    const scheduleCopy = [...schedule];
    const scheduleDay = { ...scheduleCopy[day] };

    // Remove item from array
    const newActionsArray = [];
    for (let i = 0; i < scheduleDay.actions.length; i++) {
      const action = scheduleDay.actions[i];
      // Push action to new array, avoiding the one in specified index
      if (i !== index) newActionsArray.push(action);
    }
    scheduleDay.actions = newActionsArray;

    // If day has no actions, disable it
    if (!newActionsArray.length) {
      scheduleDay.active = false;
    }

    // Update state
    scheduleCopy[day] = scheduleDay;
    setSchedule(scheduleCopy);
  }

  // Activation or deactivation for action
  function handleTypeChange(day, index, value) {
    const scheduleCopy = [...schedule];
    const scheduleDay = { ...scheduleCopy[day] };

    // Update type
    scheduleDay.actions[index].type = value;

    // Update state
    setSchedule(scheduleCopy);
  }

  // Time of activation/deactivation
  function handleHourChange(day, index, value) {
    const scheduleCopy = [...schedule];
    const scheduleDay = { ...scheduleCopy[day] };

    // Update hour
    scheduleDay.actions[index].hour = value;

    // Update state
    scheduleCopy[day] = scheduleDay;
    setSchedule(scheduleCopy);
  }

  function renderScheduleTable() {
    // Schedule is an array that contains 7 elements of type
    // OBJECT (one for each day) with the following structure:
    // [{active: BOOLEAN, actions: ARRAY}, ...]
    return schedule.map((day, dayIndex) => {
      return (
        <div className="day-row" key={'schedule-row-' + dayIndex}>
          <div className="day-label">
            <label>
              <input
                type="checkbox"
                className="filled-in"
                onChange={(e) => handleDayActivation(dayIndex)}
                checked={day.active}
              />
              <span>{sanitizeDay(dayIndex)}</span>
            </label>
            <span className="inline-error">{errors[dayIndex]}</span>
          </div>
          {day.active && (
            <div className="day-box-content">
              {day.actions.map((action, actionIndex) => {
                return (
                  <div
                    className="select-inputs"
                    key={`sa-${dayIndex}-${actionIndex}`}
                  >
                    <span className="action-index">{actionIndex + 1}.</span>
                    <select
                      className="browser-default"
                      value={action.type}
                      onChange={(e) =>
                        handleTypeChange(dayIndex, actionIndex, e.target.value)
                      }
                    >
                      <option value="activation">Activación</option>
                      <option value="deactivation">Desactivación</option>
                    </select>
                    <select
                      className="browser-default"
                      value={action.hour}
                      onChange={(e) =>
                        handleHourChange(dayIndex, actionIndex, e.target.value)
                      }
                    >
                      {scheduleHours.map((hourOption, hourIndex) => (
                        <option
                          key={`sa-${dayIndex}-${actionIndex}-${hourIndex}`}
                          value={hourOption}
                        >
                          {hourOption}
                        </option>
                      ))}
                    </select>
                    <FontAwesomeIcon
                      icon="trash-alt"
                      className="delete-action"
                      onClick={() => removeActionFromDay(dayIndex, actionIndex)}
                    />
                  </div>
                );
              })}
              {day.actions.length < 6 && (
                <FontAwesomeIcon
                  icon="plus"
                  className="add-action"
                  onClick={() => addActionToDay(dayIndex)}
                />
              )}
            </div>
          )}
        </div>
      );
    });
  }

  return (
    <div id="_schedule-form_">
      <div className="row big-label">
        <div className="col s12">
          <span className="big-label-title">Zona horaria</span>
        </div>
      </div>
      <div className="row">
        <div className="col s12 input-field">
          <select
            className="browser-default"
            defaultValue={timezone}
            onChange={(e) => setTimezone(e.target.value)}
          >
            <option value="GMT-05">
              Tiempo del Sureste ET (GMT-05) - Quintana Roo, Colombia
            </option>
            <option value="GMT-06">
              Tiempo del Centro CT (GMT-06) - Ciudad de México, Guadalajara,
              Monterrey
            </option>
            <option value="GMT-07">
              Tiempo del Pacífico MT (GMT-07) - Baja California Sur, Sinaloa,
              Sonora
            </option>
            <option value="GMT-08">
              Tiempo del Noroeste PT (GMT-08) - Baja California
            </option>
          </select>
        </div>
      </div>
      <div className="row big-label">
        <div className="col s12">
          <span className="big-label-title">Horarios</span>
          <span className="inline-error">{errors[7]}</span>
        </div>
      </div>
      {renderScheduleTable()}
    </div>
  );
}

export default ScheduleForm;
