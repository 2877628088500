/* eslint max-len: 0 */

import React from 'react';

function LoadHandle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 21"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M12.2365 17.7785L20.7388 14.5613C20.8703 14.5113 20.9766 14.4112 21.0344 14.2829C21.0921 14.1547 21.0966 14.0087 21.0468 13.8771L20.5019 12.437C20.4521 12.3055 20.3521 12.1992 20.2239 12.1415C20.0958 12.0837 19.9499 12.0792 19.8184 12.129L9.80859 16.0155" />
      <path d="M1.08105 1.39649L2.92472 0.69901C3.04971 0.65179 3.18279 0.629654 3.31634 0.633868C3.44988 0.638081 3.5813 0.668562 3.70307 0.723567C3.82483 0.778573 3.93457 0.857026 4.02602 0.954447C4.11746 1.05187 4.18882 1.16635 4.23602 1.29135L10.1424 16.8996" />
      <path d="M7.05029 8.72822L14.6204 5.86351C14.8953 5.76028 15.1999 5.77009 15.4675 5.89081C15.7352 6.01152 15.9441 6.23332 16.0487 6.50768L18.5388 12.6132" />
      <path d="M12.4956 6.66762L11.1725 3.1728C11.1332 3.06898 11.074 2.97392 10.998 2.89302C10.922 2.81213 10.8309 2.747 10.7297 2.70134C10.6286 2.65568 10.5194 2.6304 10.4085 2.62694C10.2976 2.62347 10.1871 2.64189 10.0833 2.68115L5.42896 4.44263" />
      <path d="M10.7881 19.6444C11.588 19.6444 12.2364 18.9959 12.2364 18.1961C12.2364 17.3962 11.588 16.7478 10.7881 16.7478C9.98826 16.7478 9.33984 17.3962 9.33984 18.1961C9.33984 18.9959 9.98826 19.6444 10.7881 19.6444Z" />
    </svg>
  );
}

export default LoadHandle;
