/*
IMPORTANT
This list must match exactly the one in /server/utils/carBrands.js
as server validation is in place.
*/

const carBrands = [
  {
    name: 'BYD',
    value: 'byd'
  },
  {
    name: 'Chevrolet',
    value: 'chevrolet'
  },
  {
    name: 'Daf Trucks',
    value: 'daf-trucks'
  },
  {
    name: 'Dina',
    value: 'dina'
  },
  {
    name: 'Ford',
    value: 'ford'
  },
  {
    name: 'Foton',
    value: 'foton'
  },
  {
    name: 'Freightliner',
    value: 'freightliner'
  },
  {
    name: 'Hino',
    value: 'hino'
  },
  {
    name: 'Honda',
    value: 'honda'
  },
  {
    name: 'International',
    value: 'international'
  },
  {
    name: 'Isuzu',
    value: 'isuzu'
  },
  {
    name: 'Kenworth',
    value: 'kenworth'
  },
  {
    name: 'Kia',
    value: 'kia'
  },
  {
    name: 'Mack Trucks',
    value: 'mack-trucks'
  },
  {
    name: 'MAN Truck & Bus',
    value: 'man-truck-bus'
  },
  {
    name: 'Mazda',
    value: 'mazda'
  },
  {
    name: 'Mercedes-Benz',
    value: 'mercedes-benz'
  },
  {
    name: 'Nissan',
    value: 'nissan'
  },
  {
    name: 'Peugeot',
    value: 'peugeot'
  },
  {
    name: 'RAM',
    value: 'ram'
  },
  {
    name: 'Renault',
    value: 'renault'
  },
  {
    name: 'Scania',
    value: 'scania'
  },
  {
    name: 'Shacman',
    value: 'shacman'
  },
  {
    name: 'Suzuki',
    value: 'suzuki'
  },
  {
    name: 'Toyota',
    value: 'toyota'
  },
  {
    name: 'Volkswagen',
    value: 'volkswagen'
  },
  {
    name: 'Volvo',
    value: 'volvo'
  },
  {
    name: 'Bajaj',
    value: 'bajaj'
  },
  {
    name: 'AKT',
    value: 'akt'
  },
  {
    name: 'TVS',
    value: 'tvs'
  },
  {
    name: 'Yamaha',
    value: 'yamaha'
  },
  {
    name: 'Hero',
    value: 'hero'
  },
  {
    name: 'Pulsar',
    value: 'pulsar'
  },
  {
    name: 'Apache',
    value: 'apache'
  },
  {
    name: 'Otro',
    value: 'otro'
  }
];

export default carBrands;
