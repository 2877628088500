import React from 'react';
import Swal from 'sweetalert2';
import M from 'materialize-css';
import Button from '../../../../common/button/Button';
import axiosAuth from '../../../../../utils/axiosAuth';

function DeleteUserGroup(props) {
  const { setShowLoaderFullscreen, showLoaderFullscreen, groupId, history } =
    props;
  function deleteGroup() {
    setShowLoaderFullscreen(true);
    axiosAuth
      .post(`/api/user-groups/delete/${groupId}`)
      .then((res) => {
        setShowLoaderFullscreen(false);
        M.toast({
          html: 'Grupo eliminado exitosamente',
          classes: 'success-toast'
        });
        history.push('/grupos');
      })
      .catch((err) => {
        console.log(err);
        setShowLoaderFullscreen(false);
        M.toast({
          html: 'Tuvimos un error al eliminar el grupo',
          classes: 'error-toast'
        });
      });
  }

  function handleDeleteGroup() {
    Swal.fire({
      icon: 'warning',
      title: '¿Deseas eliminar permanentemente este grupo?',
      text: 'Esta acción no se puede deshacer',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        // setShowLoaderFullscreen(true);
        deleteGroup();
      }
    });
  }

  return (
    <div className="col s12 right-align">
      <Button
        onClick={() => handleDeleteGroup()}
        loading={showLoaderFullscreen}
        disabled={showLoaderFullscreen}
        id="delete-Group"
        variant="red-button"
        type="button"
      >
        Eliminar grupo
      </Button>
    </div>
  );
}
export default DeleteUserGroup;
