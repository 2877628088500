import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import axiosAuth from '../../../../utils/axiosAuth';
import durationToString from '../../../../utils/durationToString';
import roundNumber from '../../../../utils/roundNumber';
import Error from '../../../common/error/Error';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import Filters from './filters/Filters';
import './DriverTrips.scss';

function DriverTrips(props) {
  const { driver } = props;
  const [error, setError] = useState(null);
  const [loadingTrips, setLoadingTrips] = useState(false);
  const [trips, setTrips] = useState(null);
  const [date, setDate] = useState('');
  const lastTripRef = useRef(null);
  const [keepLooking, setKeepLooking] = useState(true);
  const [lastTrip, setLastTrip] = useState('');
  const [riskProfile, setRiskProfile] = useState('');

  useEffect(() => {
    setTrips(null);
    fetchTrips(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, riskProfile]);

  useEffect(() => {
    const onScroll = (e) => {
      // If reference to last driver row exists and its in view and
      // fetchUsers keeps returning result
      if (
        trips &&
        lastTripRef &&
        lastTripRef.current &&
        isLastTripInViewport() &&
        !loadingTrips &&
        keepLooking &&
        !date
      ) {
        fetchTrips();
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trips, lastTripRef, loadingTrips, keepLooking, date, riskProfile]);

  function fetchTrips(cleanTripsArray) {
    setLoadingTrips(true);

    // Get driver trips
    axiosAuth
      .get('/api/trips', {
        params: {
          userId: driver.userId,
          date,
          lastTrip: cleanTripsArray ? '' : lastTrip,
          riskProfile
        }
      })
      .then((res) => {
        const { trips: tripsRes } = res.data;

        if (date || cleanTripsArray) {
          // If date has value, set full results to trips' array
          setTrips(tripsRes);
        } else {
          // Append new trips response to trips array
          const tripsCopy = trips ? [...trips, ...tripsRes] : [...tripsRes];
          // Order by key
          setTrips(tripsCopy);
        }

        // Stop or resume infinite scrolling
        // The < 30 is hardcoded and must be changed (results per page)
        if (!tripsRes.length || tripsRes.length < 30) {
          setKeepLooking(false);
          setLastTrip('');
        } else {
          setKeepLooking(true);
          // Save last trips' id to continue query
          setLastTrip(tripsRes[tripsRes.length - 1].id);
        }

        // Stop loader
        setLoadingTrips(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingTrips(false);
        setError(err);
      });
  }

  function isLastTripInViewport() {
    // Set an offset to trigger action before target element is in view
    const offset = 200;

    const top = lastTripRef.current.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  }

  function handleScoreColor(number) {
    if (number >= 80) {
      return 'good';
    } else if (number >= 60) {
      return 'regular';
    } else if (number > 0 && number < 60) {
      return 'bad';
    }
    return 'na';
  }

  function renderTripsTable() {
    // Loader en lo que cargan los logs
    if (!trips) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No trips means no data
    if (!trips.length) {
      return (
        <div className="airbag-table-container" id="trips-table">
          <table className="airbag-table">
            <thead>
              <tr>
                <th>Viajes realizados</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="center-align">Sin viajes</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="trips-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th className="center-align">
                <div>Calificación</div>
                <div id="trip-scores-exp">
                  <span className="score-item">
                    <FontAwesomeIcon
                      icon={['fal', 'mobile-button']}
                      data-tip="Uso de celular"
                    />
                  </span>
                  <span className="score-item">
                    <FontAwesomeIcon
                      icon={['fal', 'gauge-max']}
                      data-tip="Exceso de velocidad"
                    />
                  </span>
                  <span className="score-item">
                    <FontAwesomeIcon
                      icon={['fal', 'diamond-turn-right']}
                      data-tip="Curvas"
                    />
                  </span>
                  <span className="score-item">
                    <FontAwesomeIcon
                      icon={['fal', 'gauge-simple-max']}
                      data-tip="Aceleración"
                    />
                  </span>
                  <span className="score-item">
                    <FontAwesomeIcon
                      icon={['fal', 'gauge-simple-min']}
                      data-tip="Frenado"
                    />
                  </span>
                </div>
              </th>
              <th>Fecha</th>
              <th>Inicio</th>
              <th>Duración</th>
              <th>Distancia</th>
              <th>Puntos</th>
            </tr>
          </thead>
          <tbody>
            {trips.map((trip, idx) => (
              <tr
                key={trip.id}
                className="trip-row"
                ref={idx === trips.length - 1 ? lastTripRef : null}
              >
                <td className="trip-score">
                  <Link
                    to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                  >
                    <span
                      className={`overall-score score-item ${handleScoreColor(
                        trip.score.overall
                      )}`}
                    >
                      {trip.score.overall}
                    </span>
                  </Link>
                  <span className="scores-icons">
                    <span
                      className={`score-item ${handleScoreColor(
                        trip.score.phoneDistraction
                      )}`}
                    >
                      <FontAwesomeIcon icon={['fal', 'mobile-button']} />
                    </span>
                    <span
                      className={`score-item ${handleScoreColor(
                        trip.score.speeding
                      )}`}
                    >
                      <FontAwesomeIcon icon={['fal', 'gauge-max']} />
                    </span>
                    <span
                      className={`score-item ${handleScoreColor(
                        trip.score.cornering
                      )}`}
                    >
                      <FontAwesomeIcon icon={['fal', 'diamond-turn-right']} />
                    </span>
                    <span
                      className={`score-item ${handleScoreColor(
                        trip.score.acceleration
                      )}`}
                    >
                      <FontAwesomeIcon icon={['fal', 'gauge-simple-max']} />
                    </span>
                    <span
                      className={`score-item ${handleScoreColor(
                        trip.score.braking
                      )}`}
                    >
                      <FontAwesomeIcon icon={['fal', 'gauge-simple-min']} />
                    </span>
                  </span>
                </td>
                <td>
                  <Link
                    to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                  >
                    {dayjs(trip.startDate).format('DD [de] MMMM')}
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                  >
                    {dayjs(trip.startDate).format('HH:mm')}
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                  >
                    {durationToString(
                      dayjs.duration(Math.ceil(trip.duration), 'minutes')
                    )}
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                  >
                    {trip.distance > 10
                      ? `${roundNumber(trip.distance)} km`
                      : `${trip.distance.toFixed(1)} km`}
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                  >
                    {trip.coins ? roundNumber(trip.coins) : 0}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactTooltip />
      </div>
    );
  }

  // API error handler
  if (error) return <Error error={error} hideLink={true} />;

  return (
    <div id="_driver-trips_">
      <div className="filters">
        <Filters
          riskProfile={riskProfile}
          setRiskProfile={setRiskProfile}
          setDate={setDate}
          date={date}
          setTrips={setTrips}
        />
      </div>
      {renderTripsTable()}
    </div>
  );
}

export default DriverTrips;
