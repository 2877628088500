/* eslint max-len: 0 */

import React from 'react';

function Construction(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <path d="M255.12,131.56a4,4,0,0,0,4-4V99.21a4,4,0,0,0-4-4H28.35a4,4,0,0,0-4,4v28.35a4,4,0,0,0,4,4H52.69V151.8H28.35a4,4,0,0,0-4,4v28.34a4,4,0,0,0,4,4H52.69v20.35H28.35a4,4,0,0,0-4,4v28.35a4,4,0,0,0,4,4H255.12a4,4,0,0,0,4-4V212.49a4,4,0,0,0-4-4H230.77V188.14h24.35a4,4,0,0,0,4-4V155.8a4,4,0,0,0-4-4H230.77V131.56Zm-4-8h-48.7V103.21h48.7Zm-56.7,0H145.73V103.21h48.69Zm-24.34,56.58H145.73V159.9h48.69v20.24Zm-4,28.35H117.39V188.14h48.69ZM89,159.9h48.69v20.24H89Zm77-8.1H117.39V131.56h48.69Zm-28.35-28.24H89V103.21h48.69ZM32.35,103.21H81v20.35H32.35Zm28.34,28.35h48.7V151.8H60.69ZM32.35,159.8H55.8a4.41,4.41,0,0,0,.89.1H81v20.24H32.35Zm28.34,28.34h48.7v20.35H60.69ZM32.35,216.49H81v20.35H32.35Zm56.69,0h48.69v20.35H89Zm56.69,0h48.69v20.35H145.73Zm105.39,20.35h-48.7V216.49h48.7Zm-28.35-28.35H174.08V188.14h48.69Zm28.35-28.35h-48.7V159.9h24.35a4.33,4.33,0,0,0,.89-.1h23.46ZM222.77,151.8H174.08V131.56h48.69Z" />
    </svg>
  );
}

export default Construction;
