import React from 'react';

function Close(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <line className="cls-1" x1="42.52" y1="42.52" x2="240.94" y2="240.94" />
        <line className="cls-1" x1="240.94" y1="42.52" x2="42.52" y2="240.94" />
      </g>
    </svg>
  );
}

export default Close;
