import { logsDefinitions } from '../utils/logs';

/*
data: We receive an array with raw logs or grouped logs

RAW:
[
  {
    ...logDocument
  },
  {
    ...logDocument
  },
  ...
]

GROUPED:
[
  {
    slug: activacion_qr,
    total: 10,
    ...
  },
  {
    slug: llamada_entrante,
    total: 3,
    ...
  },
  ...
]
*/

function pieChartLogs(data, mustProcessData) {
  const processedData = [];

  // Group and count logs raw array
  if (mustProcessData) {
    // Build info object
    const info = {};
    for (const log of logsDefinitions) {
      info[log.slug] = {
        ...log,
        total: 0
      };
    }

    // Counter
    for (const log of data) {
      let key = log.type;

      // Check if log has a sub-type in metadata
      if (log.metadata && log.metadata.type) key = log.metadata.type;

      if (info[key]) info[key].total = info[key].total + 1;
    }

    // Convert to array
    for (const key in info) {
      if (key) {
        processedData.push(info[key]);
      }
    }
  } else {
    // Fill data with definition info
    for (const item of data) {
      // Find log information
      const definition = logsDefinitions.find((l) => l.slug === item.slug);

      // Push item to processed data
      if (definition) processedData.push({ ...item, ...definition });
    }
  }

  // Sort processed data
  processedData.sort(function compare(a, b) {
    if (a.total < b.total) {
      return 1;
    }
    if (a.total > b.total) {
      return -1;
    }
    return 0;
  });

  // Check how many array elements have value
  let elementsWithValue = 0;
  processedData.forEach((logDescription) => {
    if (logDescription.total) elementsWithValue += 1;
  });

  // Group 8th to last if there are more than 7 elements
  let ret = [];
  if (elementsWithValue > 8) {
    // Group those from index 4 upwards
    const others = { name: 'Otros', slug: 'otros', color: '#8bc34a', total: 0 };
    for (let i = 7; i < processedData.length; i++) {
      others.total += processedData[i].total;
    }
    // Manualy assign array with 6 elements
    ret = [
      processedData[0],
      processedData[1],
      processedData[2],
      processedData[3],
      processedData[4],
      processedData[5],
      processedData[6],
      others
    ];
  } else {
    // Fill array with as much elementsWithValue dynamically
    for (let j = 0; j < elementsWithValue; j++) {
      ret.push(processedData[j]);
    }
  }

  return ret;
}

export default pieChartLogs;
