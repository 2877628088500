// 'duration' param is a dayjs/momentjs(deprecated) duration instance
function durationToString(duration, fullNames) {
  let time = '';

  // Check if the names must be abbreviated or not
  if (fullNames) {
    // Add years, months, days, hours and minutes value
    if (duration.years()) {
      time = time + `${duration.years()} año${duration.years() > 1 ? 's' : ''}`;
    }
    if (duration.months()) {
      time =
        time + ` ${duration.months()} mes${duration.months() > 1 ? 'es' : ''}`;
    }
    if (duration.days()) {
      time = time + ` ${duration.days()} día${duration.days() > 1 ? 's' : ''}`;
    }
    if (duration.hours()) {
      time =
        time + ` ${duration.hours()} hora${duration.hours() > 1 ? 's' : ''}`;
    }
    if (duration.minutes()) {
      time =
        time +
        ` ${duration.minutes()} minuto${duration.minutes() > 1 ? 's' : ''}`;
    }
    // Handle seconds only
    if (
      !duration.years() &&
      !duration.months() &&
      !duration.days() &&
      !duration.hours() &&
      !duration.minutes()
    ) {
      if (duration.seconds()) time = ' < 1 minuto';
      else time = ' 0 minutos';
    }
  } else {
    // Add years, months, days, hours and minutes value
    if (duration.years()) time = time + `${duration.years()} y`;
    if (duration.months()) time = time + ` ${duration.months()} m`;
    if (duration.days()) time = time + ` ${duration.days()} d`;
    if (duration.hours()) time = time + ` ${duration.hours()} h`;
    if (duration.minutes()) time = time + ` ${duration.minutes()} min`;
    // Handle seconds only
    if (
      !duration.years() &&
      !duration.months() &&
      !duration.days() &&
      !duration.hours() &&
      !duration.minutes()
    ) {
      if (duration.seconds()) time = ' < 1 min';
      else time = ' 0 min';
    }
  }

  return time;
}

export default durationToString;
