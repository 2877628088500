import React from 'react';
import numeral from 'numeral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import Icon from '../../common/airbag-icons/Icon';
import roundNumber from '../../../utils/roundNumber';
import durationToString from '../../../utils/durationToString';

function HardData(props) {
  const { current, past } = props;

  function getDiffPercentage(pValue, diff) {
    // Avoid division with 0
    if (!pValue) {
      return 0;
    }

    return roundNumber((Math.abs(diff) * 100) / pValue);
  }

  function processValueDifference(key, withText, switchColors) {
    if (current && past) {
      const valueCurrent = current[key];
      const valuePast = past[key];
      const diff = valueCurrent - valuePast;

      // No data on prev period
      if (!valuePast) {
        return;
      }

      // Handle percentage difference
      if (diff > 0) {
        return (
          <>
            <FontAwesomeIcon
              icon="arrow-up"
              className={switchColors ? 'decrease' : 'increase'}
            />
            {getDiffPercentage(valuePast, diff)}% {withText && 'más'}
          </>
        );
      } else if (diff < 0) {
        return (
          <>
            <FontAwesomeIcon
              icon="arrow-down"
              className={switchColors ? 'increase' : 'decrease'}
            />
            {getDiffPercentage(valuePast, diff)}% {withText && 'menos'}
          </>
        );
      } else {
        return (
          <>
            <FontAwesomeIcon icon="minus" className="no-change" />
            0%
          </>
        );
      }
    }

    return null;
  }

  function renderDetectedRisks(data) {
    // If no element is present, avoid rendering
    // the empty box
    if (
      !data.TotalSpeedingKm &&
      !data.PhoneUsageDistanceKm &&
      !data.PhoneUsageDurationMin &&
      !data.PhoneUsageOverSpeedDistanceKm &&
      !data.PhoneUsageOverSpeedDurationMin
    ) {
      return null;
    }

    // Array to hold risks labels
    const riskLabels = [];

    if (data.TotalSpeedingKm) {
      riskLabels.push(
        <p className="description" key="risk-1">
          <FontAwesomeIcon icon="check" className="desc-icon" />
          Se recorrieron un total de{' '}
          <span className="desc-value">
            {numeral(roundNumber(data.TotalSpeedingKm)).format('0,0')} km
          </span>{' '}
          en exceso de velocidad
        </p>
      );
    }

    if (data.PhoneUsageDistanceKm) {
      riskLabels.push(
        <p className="description" key="risk-2">
          <FontAwesomeIcon icon="check" className="desc-icon" />
          Se recorrieron un total de{' '}
          <span className="desc-value">
            {numeral(roundNumber(data.PhoneUsageDistanceKm)).format('0,0')} km
          </span>{' '}
          usando el celular
        </p>
      );
    }

    if (data.PhoneUsageDurationMin) {
      riskLabels.push(
        <p className="description" key="risk-3">
          <FontAwesomeIcon icon="check" className="desc-icon" />
          El celular se utilizó durante{' '}
          <span className="desc-value">
            {durationToString(
              dayjs.duration(data.PhoneUsageDurationMin, 'm'),
              true
            )}
          </span>{' '}
          mientras se conducía
        </p>
      );
    }

    if (
      data.PhoneUsageOverSpeedDistanceKm &&
      data.PhoneUsageOverSpeedDurationMin
    ) {
      riskLabels.push(
        <p className="description" key="risk-4">
          <FontAwesomeIcon icon="check" className="desc-icon" />
          Se utilizó el celular por{' '}
          <span className="desc-value">
            {numeral(roundNumber(data.PhoneUsageOverSpeedDistanceKm)).format(
              '0,0'
            )}{' '}
            km
          </span>{' '}
          en{' '}
          <span className="desc-value">
            {durationToString(
              dayjs.duration(data.PhoneUsageOverSpeedDurationMin, 'm'),
              true
            )}
          </span>{' '}
          mientras se conducía a exceso de velocidad
        </p>
      );
    }

    return (
      <div className="row card-custom secondary-card">
        <div className="col s12">
          <p className="b-title">Riesgos detectados</p>
        </div>
        <div className="col s12">{riskLabels}</div>
      </div>
    );
  }

  return (
    <div className="row no-margin">
      <div className="col s12">
        <div className="row card-custom main-card">
          <div className="col s12 m6 l3 kpi-container">
            <p className="kpi">
              <Icon icon="pin" className="kpi-icon" />
              {numeral(roundNumber(current.MileageKm)).format('0,0')} km
              <span className="change">
                {processValueDifference('MileageKm', false, false)}
              </span>
            </p>
            <label>Distancia recorrida</label>
          </div>
          <div className="col s12 m6 l3 kpi-container">
            <p className="kpi">
              <Icon icon="clock" className="kpi-icon" />
              {durationToString(dayjs.duration(current.DrivingTime, 'm'))}
              <span className="change">
                {processValueDifference('DrivingTime', false, false)}
              </span>
            </p>
            <label>Tiempo en conducción</label>
          </div>
          <div className="col s6 m4 l3 kpi-container">
            <p className="kpi">
              <Icon icon="routes" className="kpi-icon" />
              {numeral(roundNumber(current.TripsCount, 0)).format('0,0')}
              <span className="change">
                {processValueDifference('TripsCount', false, false)}
              </span>
            </p>
            <label>Viajes realizados</label>
          </div>
          <div className="col s6 m4 l3 kpi-container">
            <p className="kpi">
              <Icon icon="profile" className="kpi-icon" />
              {roundNumber(current.ActiveUsers, 0)}
              <span className="change">
                {processValueDifference('ActiveUsers', false, false)}
              </span>
            </p>
            <label>Usuarios activos</label>
          </div>
          <div className="col s6 m4 l3 kpi-container">
            <p className="kpi">
              <Icon icon="velocimeter" className="kpi-icon" />
              {roundNumber(current.MaxSpeedKmh, 0)} km/h
            </p>
            <label>Velocidad máxima</label>
          </div>
          <div className="col s6 m4 l3 kpi-container">
            <p className="kpi">
              <Icon icon="velocimeter" className="kpi-icon" />
              {roundNumber(current.AverageSpeedKmh, 0)} km/h
              <span className="change">
                {processValueDifference('AverageSpeedKmh', false, false)}
              </span>
            </p>
            <label>Velocidad promedio</label>
          </div>
          <div className="col s6 m4 l3 kpi-container">
            <p className="kpi">
              <Icon icon="day" className="kpi-icon" />
              {durationToString(dayjs.duration(current.DayDrivingTime, 'm'))}
              <span className="change">
                {processValueDifference('DayDrivingTime', false, false)}
              </span>
            </p>
            <label>Manejo de día</label>
          </div>
          <div className="col s6 m4 l3 kpi-container">
            <p className="kpi">
              <Icon icon="night" className="kpi-icon" />
              {durationToString(dayjs.duration(current.NightDrivingTime, 'm'))}
              <span className="change">
                {processValueDifference('NightDrivingTime', false, false)}
              </span>
            </p>
            <label>Manejo de noche</label>
          </div>
        </div>

        {renderDetectedRisks(current)}
      </div>
    </div>
  );
}

export default HardData;
