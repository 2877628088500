import React from 'react';
import { VehicleInfo } from './VehicleInfo';

export const QuestionItem = ({ item, index }) => {
  return (
    <div className="question-item">
      {item.type !== 'vehicle' && (
        <>
          <p className="question" data-number={index + 1}>
            {index + 1}. {item.question}
          </p>

          {item.description && (
            <p className="description">{item.description}</p>
          )}

          <p className="answer">
            R:{' '}
            {item.answer ? (
              <span>{item.answer}</span>
            ) : (
              <span className="na">N/A</span>
            )}
          </p>
        </>
      )}
      {item.type === 'vehicle' && (
        <>
          <p className="question">{index + 1}. Información del vehículo</p>
          <VehicleInfo vehicle={item.rawVehicle} />
        </>
      )}
    </div>
  );
};
