import React from 'react';
import { render } from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { PostHogProvider } from 'posthog-js/react';
import store from './store';
import App from './components/App';

// Initialize Sentry on production
if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    sampleRate: 1.0, // 100% of error events are sent
    replaysSessionSampleRate: 0.0, // Avoid replay for all sessions
    replaysOnErrorSampleRate: 1.0, // Only save replay for errors
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()]
  });
}

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  autocapture: false
};

render(
  <CookiesProvider>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </PostHogProvider>
  </CookiesProvider>,
  document.getElementById('root')
);
