/* eslint max-len: 0 */

import React from 'react';

function Deacc(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_5" data-name="Capa 5">
        <path d="M187.63,146.73H88.42a5,5,0,1,1,0-10h94.21V113.48a5,5,0,0,1,10,0v28.25A5,5,0,0,1,187.63,146.73Z" />
      </g>
    </svg>
  );
}

export default Deacc;
