/* eslint max-len: 0 */

import React from 'react';

function Report(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 32"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M6.70075 1.42004H19.8146C20.2041 1.42168 20.5772 1.57758 20.852 1.85362C21.1269 2.12966 21.2813 2.50336 21.2813 2.89292V29.7285C21.2813 30.1189 21.1262 30.4933 20.8503 30.7695C20.5743 31.0457 20.2 31.201 19.8096 31.2013H2.75413C2.36349 31.2013 1.98886 31.0462 1.71264 30.7699C1.43643 30.4937 1.28125 30.1191 1.28125 29.7285V6.03267L6.70075 1.42004Z" />
      <path d="M1.28125 6.03267L6.13853 6.20813C6.28404 6.21348 6.42897 6.18705 6.56322 6.13068C6.69748 6.07432 6.81784 5.98937 6.91593 5.88176C7.01401 5.77415 7.08747 5.64645 7.13119 5.50756C7.1749 5.36867 7.18782 5.22192 7.16905 5.07753L6.70075 1.42004" />
      <path d="M5.07837 26.3997H16.6983" />
      <path d="M5.07837 22.3616H16.6983" />
      <path d="M5.07837 18.3236H16.6983" />
      <path d="M5.07837 14.2855H16.6983" />
      <path d="M5.07837 10.2474H16.6983" />
    </svg>
  );
}

export default Report;
