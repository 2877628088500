import axios from 'axios';
import refreshAccessToken from '../utils/refreshAccessToken';

// Custom axios configuration
const axiosAuth = axios.create();

// Request interceptor for API calls
axiosAuth.interceptors.request.use(
  (config) => {
    // Get Access Token from localStorage
    const aat = localStorage.getItem('aat');

    // If Access Token is found, add it as Bearer Token in authorization header
    if (aat) {
      config.headers['Authorization'] = 'Bearer ' + aat;
    }

    // Add timezone information
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (timeZone) {
      config.headers['timezone'] = timeZone;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Get original request info
    const originalRequest = error.config;

    // 403 error means expired access token.
    // Verify that request hasn't been retried to avoid an infinite loop
    if (
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      // Change retry status to avoid infinite loop
      originalRequest._retry = true;

      // Request new access token
      const newAccessToken = await refreshAccessToken();

      if (newAccessToken) {
        // Update auth header with new access token
        originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
        // Update access token in localstorage
        localStorage.setItem('aat', newAccessToken);

        return axios(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosAuth;
