/* eslint max-len: 0 */

import React from 'react';

function ClosedRoad(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_6" data-name="Capa 6">
        <path d="M226.79,236h-24.3L162.05,41.43a5,5,0,0,0-4.9-4H126.31a5,5,0,0,0-4.89,4L81,236H56.61a5,5,0,0,0,.08,10H226.77a5,5,0,0,0,0-10ZM130.38,47.45h22.7L192.28,236H91.19Z" />
      </g>
    </svg>
  );
}

export default ClosedRoad;
