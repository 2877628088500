import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import DoughnutChart from '../../../common/chartjs/DoughnutChart';
import HabitsScorings from '../../../common/chartjs/HabitsScorings';
import SpeedComparison from '../../../common/chartjs/SpeedComparison';
import TripAltimetry from '../../../common/chartjs/TripAltimetry';
import pieChartLogs from '../../../../data-processing/pieChartLogs';
import { scoresSanitizer } from '../../../../utils/sanitizers';
import durationToString from '../../../../utils/durationToString';
import cssVars from '../../../css-variables/css-variables-js';
import './TripAnalytics.scss';

function TripAnalytics(props) {
  const {
    logs,
    trip,
    speedComparison,
    altimetry,
    phoneUsageGraph,
    speedingGraph
  } = props;
  const [logsData, setLogsData] = useState(null);
  const [scoreData, setScoreData] = useState(null);
  const [ecoScoreData, setEcoScoreData] = useState(null);

  useEffect(() => {
    // Handle logs data
    if (logs && logs.length) {
      // Add labels, data and colors
      const logsLabels = [];
      const logsDataArr = [];
      const logsColors = [];

      pieChartLogs(logs, true).forEach((item) => {
        logsLabels.push(`${item.name}: ${item.total}`);
        logsDataArr.push(item.total);
        logsColors.push(item.color);
      });
      setLogsData({
        labels: logsLabels,
        data: logsDataArr,
        colors: logsColors
      });
    }

    // Handle trip score
    if (trip.score && Object.keys(trip.score).length) {
      const scoreLabels = [];
      const scoreValues = [];
      const scoreColors = [];

      Object.keys(trip.score).forEach((key) => {
        if (key !== 'overall') {
          scoreLabels.push(scoresSanitizer(key));
          scoreValues.push(trip.score[key]);

          // Handle color
          let color = cssVars['graph-successful'] + '99';
          if (trip.score[key] < 80 && trip.score[key] >= 60) {
            color = cssVars['graph-attention'] + '99';
          } else if (trip.score[key] < 60) {
            color = cssVars['graph-error'] + '99';
          }
          scoreColors.push(color);
        }
      });
      setScoreData({
        labels: scoreLabels,
        data: scoreValues,
        colors: scoreColors
      });
    }

    if (trip.ecoScore && Object.keys(trip.ecoScore).length) {
      const ecoScoreLabels = [];
      const ecoScoreValues = [];
      const ecoScoreColors = [];

      Object.keys(trip.ecoScore).forEach((key) => {
        ecoScoreLabels.push(scoresSanitizer(key));
        ecoScoreValues.push(trip.ecoScore[key]);

        // Handle color
        let color = cssVars['graph-successful'] + '99';
        if (trip.ecoScore[key] < 80 && trip.ecoScore[key] >= 60) {
          color = cssVars['graph-attention'] + '99';
        } else if (trip.ecoScore[key] < 60) {
          color = cssVars['graph-error'] + '99';
        }
        ecoScoreColors.push(color);
      });
      setEcoScoreData({
        labels: ecoScoreLabels,
        data: ecoScoreValues,
        colors: ecoScoreColors
      });
    }
  }, [logs, trip]);

  function renderTimelineChart(arr, type) {
    return arr.map((block, i) => {
      const durationText = durationToString(
        dayjs.duration(block.duration, 'm')
      );
      let tipText = 'Se usó el celular por ';
      if (type === 'speeding') {
        tipText = 'Se excedió el límite por ';
      }

      return (
        <div
          className={`block ${block.hasInfringement ? 'pointer' : ''}`}
          key={`${i}-${block.duration}`}
          data-tip={block.hasInfringement ? `${tipText} ${durationText}` : null}
          style={{
            width: `${block.percentage}%`,
            background: block.hasInfringement
              ? cssVars['graph-error']
              : '#dadada'
          }}
        ></div>
      );
    });
  }

  return (
    <div className="row" id="_trip-analytics_">
      {phoneUsageGraph && speedingGraph ? (
        <div className="col s12">
          <div className="charts-container">
            <div id="timeline-charts">
              <p className="title">
                Duración de viaje:{' '}
                {durationToString(dayjs.duration(trip.duration, 'm'), true)}
              </p>
              <div id="main-labels">
                <span className="dot-span">
                  <span className="dot grey-dot"></span> Viaje realizado
                </span>
                <span className="dot-span">
                  <span className="dot red-dot"></span> Infracción detectada
                </span>
              </div>
              <div className="t-row">
                <p className="subtitle">Uso de celular</p>
                <div className="t-container">
                  {renderTimelineChart(phoneUsageGraph, 'phone-dist')}
                </div>
                <div className="labels">
                  <span className="start">Inicio de viaje</span>
                  <span className="end">Fin de viaje</span>
                </div>
                <div className="clear"></div>
              </div>
              <div className="t-row">
                <p className="subtitle">Exceso de velocidad</p>
                <div className="t-container">
                  {renderTimelineChart(speedingGraph, 'speeding')}
                </div>
                <div className="labels">
                  <span className="start">Inicio de viaje</span>
                  <span className="end">Fin de viaje</span>
                </div>
                <div className="clear"></div>
              </div>
            </div>
          </div>
          <ReactTooltip />
        </div>
      ) : null}
      {speedComparison && (
        <div className="col s12">
          <div className="row charts-container">
            <div id="speed-comparison-chart">
              <SpeedComparison
                title={`Análisis de velocidad`}
                labels={speedComparison.labels}
                data={[
                  {
                    data: speedComparison.speed,
                    label: 'Velocidad de conductor'
                  },
                  {
                    data: speedComparison.speedLimit,
                    label: 'Límite de velocidad'
                  }
                ]}
                colors={[cssVars['graph-attention'], cssVars['graph-error']]}
                displayLegend={true}
              />
            </div>
          </div>
        </div>
      )}
      {altimetry && altimetry.data.length > 2 ? (
        <div className="col s12">
          <div className="row charts-container">
            <div id="altimetry-chart">
              <TripAltimetry
                title={`Altura de ruta`}
                labels={altimetry.labels}
                data={[{ data: altimetry.data }]}
                colors={['#63c450']}
                displayLegend={false}
              />
            </div>
          </div>
        </div>
      ) : null}
      {logsData && (
        <div className="col s12">
          <div className="row charts-container">
            {/* Pie chart */}
            <div className="col s12">
              <div id="doughnut-chart">
                <DoughnutChart
                  title="Frecuencia de datos"
                  labels={logsData.labels}
                  data={logsData.data}
                  colors={logsData.colors}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="col s12 m6">
        <div className="row charts-container">
          {scoreData && (
            <div className="col s12">
              <div id="scores-polar">
                <HabitsScorings
                  title="Hábitos de conducción"
                  labels={scoreData.labels}
                  data={scoreData.data}
                  colors={scoreData.colors}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col s12 m6">
        <div className="row charts-container">
          {ecoScoreData && (
            <div className="col s12">
              <div id="ecoscores-polar">
                <HabitsScorings
                  title="Conducción ecológica"
                  labels={ecoScoreData.labels}
                  data={ecoScoreData.data}
                  colors={ecoScoreData.colors}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TripAnalytics;
