import React, { useState } from 'react';
import M from 'materialize-css';
import Button from '../../../common/button/Button';
import axiosAuth from '../../../../utils/axiosAuth';
import './AddUserGroup.scss';

function AddGroup(props) {
  const { fetchGroups, setIsOpen } = props;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  function addGroup(e) {
    e.preventDefault();
    setLoading(true);

    // Make request
    axiosAuth
      .post('/api/user-groups', { name: name })
      .then((res) => {
        M.toast({
          html: 'Grupo creado con éxito',
          classes: 'success-toast'
        });
        fetchGroups();
        setLoading(false);
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  return (
    <div id="_add-driver_">
      <h5 id="main-t">Nuevo grupo</h5>
      <form onSubmit={addGroup}>
        {/* INFORMACIÓN GENERAL */}
        <div className="row big-label">
          <div className="col s12">
            <p>Información general</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6 input-field">
            <label className="active">Nombre</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>

        <div className="center-align">
          <Button
            variant="yellow-button"
            loading={loading}
            disabled={loading}
            type="submit"
          >
            Agregar grupo
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddGroup;
