import React from 'react';
import dayjs from 'dayjs';
import ScoresCharts from '../../../analytics/scores-charts/ScoresCharts';
// eslint-disable-next-line max-len
import GamificationCharts from '../../../analytics/gamification-charts/GamificationCharts';
// eslint-disable-next-line max-len
import RiskByLocationCharts from '../../../analytics/risk-by-locations-charts/RiskByLocationCharts';
import './RenderStats.scss';

function RenderStats(props) {
  const { user, group, period, company } = props;
  const DAYS_COUNT = 14;

  function fillNoDataArr(len) {
    const ret = [];
    for (let i = 0; i < len; i++) ret.push(0);
    return ret;
  }

  function fillNoLabelsArr(len) {
    const ret = [];
    for (let i = len; i > 0; i--) {
      ret.push(dayjs().subtract(i, 'd').format('DD MMM'));
    }
    return ret;
  }

  return (
    <>
      <div className="col s12">
        {/* Scores Charts */}
        <ScoresCharts
          user={user}
          groupId={group.id}
          period={period}
          fillNoLabelsArr={fillNoLabelsArr}
          fillNoDataArr={fillNoDataArr}
          DAYS_COUNT={DAYS_COUNT}
        />
        {/* Gamification chart */}
        <p className="indicator-title">Gamificación</p>
        <GamificationCharts
          user={user}
          groupId={group.id}
          period={period}
          fillNoLabelsArr={fillNoLabelsArr}
          fillNoDataArr={fillNoDataArr}
          DAYS_COUNT={DAYS_COUNT}
        />
        {/* Risk by location chart*/}
        <p className="indicator-title">Información histórica por ubicación</p>
        <RiskByLocationCharts
          user={user}
          company={company}
          groupId={group.id}
          period={period}
        />
      </div>
    </>
  );
}

export default RenderStats;
