import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import qs from 'qs';
import { posthog } from 'posthog-js';
import ReactGA from 'react-ga4';
import M from 'materialize-css';
import Swal from 'sweetalert2';
import axiosAuth from '../../../utils/axiosAuth';
import NEW_DEFAULT_SCHEDULE from '../../../utils/newDefaultSchedule';
import DriverProfile from './driver-profile/DriverProfile';
import DriverMetrics from './driver-metrics/DriverMetrics';
import DriverInfo from './driver-info/DriverInfo';
import DriverActivity from './driver-activity/DriverActivity';
import DriverTrips from './driver-trips/DriverTrips';
import DriverReports from './driver-reports/DriverReports';
import DriverGame from './driver-game/DriverGame';
import MessageCourtain from '../../common/message-courtain/MessageCourtain';
import Error from '../../common/error/Error';
import Icon from '../../common/airbag-icons/Icon';
import AirbagLoaderLines from '../../common/airbag-loader/AirbagLoaderLines';
import Button from '../../common/button/Button';
// eslint-disable-next-line max-len
import LoaderFullscreen from '../../common/loader-fullscreen/LoaderFullscreen';
import './CompanyDriver.scss';
import { DriverCoins } from './driver-coins/DriverCoins';

function CompanyDriver(props) {
  // Get all data after main url
  const queryString = window.location.search;
  // Get all params
  const urlParams = new URLSearchParams(queryString);
  const { history, location, company, user } = props;
  const driverId = urlParams.get('driverId');
  const [error, setError] = useState(null);
  const [driver, setDriver] = useState({});
  const [section, setSection] = useState('trips');
  const [mapPosition, setMapPosition] = useState({
    lat: 19.432541,
    lon: -99.133403,
    zoom: 3
  });
  const [showLoaderFullscreen, setShowLoaderFullscreen] = useState(false);

  const SectionToRender = {
    metrics: <DriverMetrics driver={driver} company={company} />,
    info: (
      <DriverInfo
        driverId={driverId}
        driver={driver}
        setDriver={setDriver}
        {...props}
      />
    ),
    activity: (
      <DriverActivity
        driver={driver}
        location={location}
        history={history}
        user={user}
      />
    ),
    trips: <DriverTrips driver={driver} />,
    reports: <DriverReports driver={driver} />,
    game: <DriverGame driver={driver} />,
    coins: <DriverCoins driver={driver} />
  };

  useEffect(() => {
    // Get driver info
    fetchDriver();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle tab location
  useEffect(() => {
    const tab = qs.parse(location.search, { ignoreQueryPrefix: true }).tab;
    // If tab has valid value, set it. Else, fallback to info tab
    if (
      tab === 'metrics' ||
      tab === 'info' ||
      tab === 'trips' ||
      tab === 'reports' ||
      tab === 'activity' ||
      tab === 'game' ||
      tab === 'coins'
    ) {
      setSection(tab);
    } else {
      // Default view is trips
      setSection('trips');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function fetchDriver() {
    // Search for user
    axiosAuth
      .get(`/api/users/info`, {
        params: { driverId, withLocation: true }
      })
      .then((res) => {
        const { user } = res.data;
        if (user) {
          // Check if driver has schedule or we assign it manually
          if (!user.schedule) {
            user.schedule = NEW_DEFAULT_SCHEDULE;
          } else if (typeof user.schedule['0'].start === 'string') {
            // User has old schedule, so we must convert it to new format
            for (const key in user.schedule) {
              // Only analyze those keys that matches weekday (0-6)
              if (parseInt(key) >= 0 && parseInt(key) <= 6) {
                // Get day information
                const dayData = user.schedule[key];
                // Build new object
                user.schedule[key] = {
                  active: dayData.active,
                  start: [],
                  end: []
                };
                // If day is active, push current schedules
                if (dayData.active) {
                  user.schedule[key].start.push(dayData.start);
                  user.schedule[key].end.push(dayData.end);
                }
              }
            }
          }

          // Process user's location
          const mapPos = mapPosition;
          if (user.location && user.location.lat && user.location.lon) {
            mapPos.lat = user.location.lat;
            mapPos.lon = user.location.lon;
            mapPos.zoom = 15;
            setMapPosition(mapPos);
          }
        }

        // Set driver
        setDriver(user);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }

  function renderSection() {
    // Render the corresponding section
    return SectionToRender[section] || <DriverTrips driver={driver} />;
  }

  function deleteDriver() {
    axiosAuth
      .post(`/api/users/delete`, {
        driverId: driver.id
      })
      .then((res) => {
        setShowLoaderFullscreen(false);
        M.toast({
          html: 'Conductor eliminado exitosamente',
          classes: 'success-toast'
        });
        history.push('/conductores');
      })
      .catch((err) => {
        console.log(err);
        setShowLoaderFullscreen(false);
        M.toast({
          html: 'Tuvimos un error al eliminar al conductor',
          classes: 'error-toast'
        });
      });
  }

  function enableDriver() {
    axiosAuth
      .post(`/api/users/change-status`, {
        driverId: driver.id
      })
      .then((res) => {
        setShowLoaderFullscreen(false);
        M.toast({
          html: 'Conductor habilitado exitosamente',
          classes: 'success-toast'
        });
        fetchDriver();
      })
      .catch((err) => {
        console.log(err);
        setShowLoaderFullscreen(false);
        M.toast({
          html: 'Tuvimos un error al habilitar al conductor',
          classes: 'error-toast'
        });
      });
  }

  function handleDeleteDriverClick() {
    Swal.fire({
      icon: 'error',
      title: '¿Deseas eliminar al conductor?',
      text: 'Esta acción no se puede deshacer',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: '<span class="dark-color-text">Si, eliminar</span>',
      confirmButtonColor: '#f6c700'
    }).then((result) => {
      if (result.isConfirmed) {
        setShowLoaderFullscreen(true);
        deleteDriver();
      }
    });
  }

  function handleEnableDriverClick() {
    Swal.fire({
      icon: 'question',
      title: '¿Deseas habilitar al conductor?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: '<span class="dark-color-text">Si, habilitar</span>',
      confirmButtonColor: '#f6c700'
    }).then((result) => {
      if (result.isConfirmed) {
        setShowLoaderFullscreen(true);
        enableDriver();
      }
    });
  }

  // API error handler
  if (error) return <Error error={error} />;

  // Not found
  if (!driver) {
    return (
      <MessageCourtain
        icon="user-slash"
        message="No se encontró al conductor"
        title="¡Oops!"
        link="/conductores"
      />
    );
  }

  // Info loading
  if (!Object.keys(driver).length) {
    return <AirbagLoaderLines />;
  }

  function renderInactiveView() {
    return (
      <div className="row">
        <div className="col s12" id="actions">
          <Button
            variant="red-button"
            loading={showLoaderFullscreen}
            disabled={showLoaderFullscreen}
            type="button"
            onClick={() => handleDeleteDriverClick()}
          >
            Eliminar conductor
          </Button>
          <Button
            variant="green-button"
            loading={showLoaderFullscreen}
            disabled={showLoaderFullscreen}
            type="button"
            onClick={() => handleEnableDriverClick()}
          >
            Habilitar conductor
          </Button>
        </div>
      </div>
    );
  }

  function renderActiveView() {
    return (
      <>
        <div className="row" id="driver-menu-row">
          <div className="col s12">
            <div id="driver-menu">
              <div id="menu-container">
                <span
                  onClick={() => {
                    setSection('trips');
                    history.push(`${location.pathname}?driverId=${driverId}`);

                    // Google Analytics
                    ReactGA.event({
                      category: 'driver_details',
                      action: 'user_trips_tab'
                    });

                    // Posthog
                    posthog.capture('user_trips_tab', {
                      category: 'driver_details',
                      isCustomEvent: true
                    });
                  }}
                  className={classNames('sec', { active: section === 'trips' })}
                >
                  <span className="sec-content">
                    <Icon icon="car" className="sect-icon" />
                    Viajes realizados
                  </span>
                </span>
                <span
                  onClick={() => {
                    setSection('reports');
                    history.push(
                      `${location.pathname}?tab=reports&driverId=${driverId}`
                    );

                    // Google Analytics
                    ReactGA.event({
                      category: 'driver_details',
                      action: 'user_reports_tab'
                    });

                    // Posthog
                    posthog.capture('user_reports_tab', {
                      category: 'driver_details',
                      isCustomEvent: true
                    });
                  }}
                  className={classNames('sec', {
                    active: section === 'reports'
                  })}
                >
                  <span className="sec-content">
                    <Icon icon="eventRegister" className="sect-icon" />
                    Registros y evidencias
                  </span>
                </span>
                <span
                  onClick={() => {
                    setSection('activity');
                    history.push(
                      `${location.pathname}?tab=activity&driverId=${driverId}`
                    );

                    // Google Analytics
                    ReactGA.event({
                      category: 'driver_details',
                      action: 'user_activity_tab'
                    });

                    // Posthog
                    posthog.capture('user_activity_tab', {
                      category: 'driver_details',
                      isCustomEvent: true
                    });
                  }}
                  className={classNames('sec', {
                    active: section === 'activity'
                  })}
                >
                  <span className="sec-content">
                    <Icon icon="mapLocation" className="sect-icon" />
                    Actividad por día
                  </span>
                </span>
                <span
                  onClick={() => {
                    setSection('metrics');
                    history.push(
                      `${location.pathname}?tab=metrics&driverId=${driverId}`
                    );

                    // Google Analytics
                    ReactGA.event({
                      category: 'driver_details',
                      action: 'user_scores_tab'
                    });

                    // Posthog
                    posthog.capture('user_scores_tab', {
                      category: 'driver_details',
                      isCustomEvent: true
                    });
                  }}
                  className={classNames('sec', {
                    active: section === 'metrics'
                  })}
                >
                  <span className="sec-content">
                    <Icon icon="ranking" className="sect-icon" />
                    Métricas y calificaciones
                  </span>
                </span>
                <span
                  onClick={() => {
                    setSection('info');
                    history.push(
                      `${location.pathname}?tab=info&driverId=${driverId}`
                    );

                    // Google Analytics
                    ReactGA.event({
                      category: 'driver_details',
                      action: 'user_profile_tab'
                    });

                    // Posthog
                    posthog.capture('user_profile_tab', {
                      category: 'driver_details',
                      isCustomEvent: true
                    });
                  }}
                  className={classNames('sec', { active: section === 'info' })}
                >
                  <span className="sec-content">
                    <Icon icon="profile" className="sect-icon" />
                    Información general
                  </span>
                </span>
                <span
                  onClick={() => {
                    setSection('game');
                    history.push(
                      `${location.pathname}?tab=game&driverId=${driverId}`
                    );

                    // Google Analytics
                    ReactGA.event({
                      category: 'driver_details',
                      action: 'user_game_tab'
                    });

                    // Posthog
                    posthog.capture('user_game_tab', {
                      category: 'driver_details',
                      isCustomEvent: true
                    });
                  }}
                  className={classNames('sec', {
                    active: section === 'game'
                  })}
                >
                  <span className="sec-content">
                    <Icon icon="profile" className="sect-icon" />
                    Héroes del camino
                  </span>
                </span>
                <span
                  onClick={() => {
                    setSection('coins');
                    history.push(
                      `${location.pathname}?tab=coins&driverId=${driverId}`
                    );

                    // Google Analytics
                    ReactGA.event({
                      category: 'driver_details',
                      action: 'user_coins_tab'
                    });

                    // Posthog
                    posthog.capture('user_coins_tab', {
                      category: 'driver_details',
                      isCustomEvent: true
                    });
                  }}
                  className={classNames('sec', {
                    active: section === 'coins'
                  })}
                >
                  <span className="sec-content">
                    <Icon icon="record" className="sect-icon" />
                    Historial de puntos
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s12">{renderSection()}</div>
        </div>
      </>
    );
  }

  return (
    <div id="company-driver">
      <DriverProfile
        driver={driver}
        company={company}
        setDriver={setDriver}
        mapPosition={mapPosition}
        fetchDriver={fetchDriver}
        user={user}
      />
      {driver.status === 'inactive' ? renderInactiveView() : renderActiveView()}
      {showLoaderFullscreen && <LoaderFullscreen />}
    </div>
  );
}

export default withRouter(CompanyDriver);
