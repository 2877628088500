const axios = require('axios');

async function reverseGeocoder(lat, lon) {
  const ret = {
    state: '',
    stateShort: '',
    country: '',
    district: '',
    city: '',
    success: true,
    error: null
  };

  try {
    // Check for valid coordinates
    if (!lat || !lon) return ret;

    // Make request to HERE api
    const hereResponse = await axios.get(
      'https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json',
      {
        params: {
          apiKey: process.env.REACT_APP_HERE_MAPS_API_KEY,
          mode: 'retrieveAddresses',
          prox: `${lat}, ${lon}`
        }
      }
    );

    // Add address info
    const address =
      hereResponse.data.Response.View[0].Result[0].Location.Address;
    ret.district = address.District;
    ret.city = address.City;
    ret.stateShort = address.State;
    ret.houseNumber = address.HouseNumber;
    ret.postalCode = address.PostalCode;
    ret.street = address.Street;
    ret.label = address.Label;

    // Process result
    const resultArray =
      hereResponse.data.Response.View[0].Result[0].Location.Address
        .AdditionalData;

    // Loop through result array to assign values
    resultArray.forEach((item) => {
      if (item.key === 'CountryName') ret.country = item.value;
      if (item.key === 'StateName') ret.state = item.value;
    });

    // Return result
    return ret;
  } catch (err) {
    console.log('Error in reverseGeocoder:', err);

    ret.success = false;
    ret.error = err;
    return ret;
  }
}
export default reverseGeocoder;
