import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import Icon from '../../../common/airbag-icons/Icon';
import lastSeen from '../../../../utils/lastSeen';
import './ActiveDriversTable.scss';

function ActiveDriversTable(props) {
  const { drivers } = props;
  const [driverStatus, setDriverStatus] = useState('activeNearLost');

  function renderLastSeenColor(hoursDiff) {
    if (hoursDiff < 24) {
      return 'good';
    }
    if (hoursDiff >= 24 && hoursDiff < 120) {
      return 'regular';
    }
    return 'bad';
  }

  function renderDriverLastSeen(driver) {
    return `hace ${lastSeen(driver.lastSeen)}`;
  }

  function renderTableContent() {
    // No drivers in category
    if (!drivers[driverStatus].length) {
      return (
        <table className="airbag-table">
          <tbody>
            <tr>
              <td className="center-align">
                <Icon icon="profile" className="table-icon-indicator" />
                <br />
                Sin conductores
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    return (
      <table className="airbag-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th className="center-align">Última conexión</th>
          </tr>
        </thead>
        <tbody>
          {drivers[driverStatus].map((driver, i) => {
            const hoursDiff = dayjs().diff(dayjs(driver.lastSeen), 'hour');

            return (
              <tr key={driver.id} className="driver-row">
                <td>
                  <span className="pos-index">{i + 1}.</span>
                  <Link to={`/conductor?driverId=${driver.id}`}>
                    {driver.fullName}
                  </Link>
                </td>
                <td
                  className={`last-seen-color ${renderLastSeenColor(
                    hoursDiff
                  )}`}
                >
                  {renderDriverLastSeen(driver)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  function renderDriversTable() {
    // Loader
    if (!drivers) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="drivers-table">
        <div className="airbag-top">
          <select
            className="browser-default"
            id="drivers-select-active"
            onChange={(e) => setDriverStatus(e.target.value)}
            defaultValue={driverStatus}
          >
            <option value="activeOk">Ok</option>
            <option value="activeNearLost">Por perderse</option>
            <option value="activeLost">Perdidos</option>
          </select>
        </div>
        {renderTableContent()}
      </div>
    );
  }

  return <div id="_active-drivers-table_">{renderDriversTable()}</div>;
}

export default ActiveDriversTable;
