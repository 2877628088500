import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { posthog } from 'posthog-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga4';
import Papa from 'papaparse';
import axiosAuth from '../../../../utils/axiosAuth';
import ActivitiesList from '../../../common/activities-list/ActivitiesList';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import Error from '../../../common/error/Error';
import ActivityMap from './activity-map/ActivityMap';
import ActivityInfo from './activity-info/ActivityInfo';
import './DriverActivity.scss';
import Button from '../../../common/button/Button';
import M from 'materialize-css';

const DEFAULT_FOCUS = { lat: 19.432541, lon: -99.133403 };

// Function to ask the user where to save the file
async function saveFile(file, filename) {
  if ('showSaveFilePicker' in window) {
    try {
      // create a file handle
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: filename,
        types: [
          {
            description: 'CSV File',
            accept: { 'text/csv': ['.csv'] }
          }
        ]
      });

      // create a writable stream
      const writableStream = await fileHandle.createWritable();
      // write the csv content
      await writableStream.write(file);
      // close the stream
      await writableStream.close();

      M.toast({
        html: 'Archivo descargado'
      });
    } catch (error) {
      M.toast({
        html: 'Descarga cancelada',
        classes: 'error-toast'
      });
    }
  } else {
    // in case the browser doesn't support 'showSaveFilePicker':
    // create a blob with the csv data
    const blob = new Blob([file], { type: 'text/csv;charset=utf-8;' });

    // create a link element to trigger the download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    // setting corresponding data to the link
    link.setAttribute('href', url);
    link.setAttribute('download', `${filename}`);
    link.style.visibility = 'hidden';

    // append to the body, click the link, and remove from document
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    M.toast({
      html: 'Archivo descargado'
    });
  }
}

function DriverActivity(props) {
  const { history, location, driver, user } = props;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [trips, setTrips] = useState(null);
  const [activities, setActivities] = useState(null);
  // const [gapJoins, setGapJoins] = useState(null);
  const [focus, setFocus] = useState(DEFAULT_FOCUS);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const dayParam = urlParams.get('day');
  const [day, setDay] = useState(
    dayParam ? dayParam : dayjs().format('YYYY-MM-DD')
  );
  const [locationLogs, setLocationLogs] = useState(null);
  const locationLogsRaw = useRef(null);
  const [heartbeats, setHeartbeats] = useState(null);
  const heartbeatsRaw = useRef(null);
  const [debugMode, setDebugMode] = useState(false);
  const datePickerRef = useRef(null);
  const [isHeartbeatsLoading, setIsHeartbeatsLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setHeartbeats(null);
    setLocationLogs(null);

    // Save day in params
    history.push(
      `${location.pathname}?tab=activity&driverId=${driver.id}&day=${dayjs(
        day
      ).format('YYYY-MM-DD')}`
    );

    axiosAuth
      .get(`/api/users/${driver.id}/activity`, {
        params: { day }
      })
      .then((res) => {
        const { trips, activities, locationLogs, heartbeats } = res.data;
        setTrips(trips);
        setActivities(activities);
        setLoading(false);

        // Save location logs and heartbeats in separate array
        locationLogsRaw.current = locationLogs;
        heartbeatsRaw.current = heartbeats;

        // Only show if it's on debug mode
        if (debugMode) {
          setLocationLogs(locationLogsRaw.current);
          setHeartbeats(heartbeatsRaw.current);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day]);

  useEffect(() => {
    if (debugMode) {
      setLocationLogs(locationLogsRaw.current);
      setHeartbeats(heartbeatsRaw.current);
    } else {
      setLocationLogs(null);
      setHeartbeats(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debugMode]);

  const handleHeartbeatHistoryClick = async () => {
    setIsHeartbeatsLoading(true);
    try {
      M.toast({
        html: 'Archivo solicitado',
        classes: ''
      });
      const { data } = await axiosAuth.get(
        `/api/users/${driver.id}/heartbeats-history/${dayjs(day).format(
          'YYYY-MM-DD'
        )}`
      );

      if (data.data && data.data.length > 0) {
        const csvData = data.data || data;

        // convert data to CSV using 'Papa parse'
        const csv = Papa.unparse(csvData);

        // call 'saveFile' function to let the user select file location
        await saveFile(
          csv,
          `${driver.fullName.split(' ').join('')}_${dayjs(day).format(
            'DD-MM-YY'
          )}.csv`
        );
      } else {
        M.toast({
          html: 'No hay datos para la fecha seleccionada',
          classes: 'error-toast'
        });
      }
    } catch (err) {
      console.error(err);
      M.toast({
        html: 'Error al descargar el archivo',
        classes: 'error-toast'
      });
    } finally {
      setIsHeartbeatsLoading(false);
    }
  };

  function renderNextDay(d) {
    const nextDay = dayjs(d).add(1, 'day');

    // Check if tomorrow is in future
    if (nextDay.isAfter(dayjs())) {
      return (
        <span className="day-box option not-valid">
          <span className="hide-on-small-only">
            {dayjs(day).add(1, 'day').format('dddd DD')}
          </span>
          <FontAwesomeIcon icon="caret-right" />
        </span>
      );
    } else {
      return (
        <span
          className="day-box option"
          onClick={() => {
            if (!loading) {
              setDay(new Date(dayjs(day).add(1, 'day').toISOString()));

              // Google Analytics
              ReactGA.event({
                category: 'driver_details',
                action: 'change_user_activity_day'
              });

              // Posthog
              posthog.capture('change_user_activity_day', {
                category: 'driver_details',
                isCustomEvent: true
              });
            }
          }}
        >
          <span className="hide-on-small-only">
            {dayjs(day).add(1, 'day').format('dddd DD')}
          </span>
          <FontAwesomeIcon icon="caret-right" />
        </span>
      );
    }
  }

  // API error handler
  if (error) return <Error error={error} />;

  return (
    <div id="_driver-activity_">
      <div id="filters">
        <div id="day-label">
          <span
            className="day-box option"
            onClick={() => {
              if (!loading) {
                setDay(new Date(dayjs(day).subtract(1, 'day').toISOString()));

                // Google Analytics
                ReactGA.event({
                  category: 'driver_details',
                  action: 'change_user_activity_day'
                });

                // Posthog
                posthog.capture('change_user_activity_day', {
                  category: 'driver_details',
                  isCustomEvent: true
                });
              }
            }}
          >
            <FontAwesomeIcon icon="caret-left" />
            <span className="hide-on-small-only">
              {dayjs(day).subtract(1, 'day').format('dddd DD')}
            </span>
          </span>
          <span className="day-box active">
            {dayjs(day).format('dddd DD [de] MMMM')}
          </span>
          {renderNextDay(day)}
        </div>
        <div className="topContent">
          <div className="input-field">
            <DatePicker
              ref={datePickerRef}
              selected={new Date(dayjs(day).toISOString())}
              placeholderText="Seleccionar.."
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
              maxDate={new Date()}
              onChange={(date) => {
                setDay(new Date(dayjs(date).toISOString()));

                // Google Analytics
                ReactGA.event({
                  category: 'driver_details',
                  action: 'change_user_activity_day'
                });

                // Posthog
                posthog.capture('change_user_activity_day', {
                  category: 'driver_details',
                  isCustomEvent: true
                });
              }}
            />
          </div>
          <div id="debug">
            <label>
              <input
                type="checkbox"
                className="filled-in"
                onChange={() => setDebugMode(!debugMode)}
                checked={debugMode}
              />
              <span>Ver más</span>
            </label>
          </div>
          <div id="heartbeats-history">
            <Button
              variant="yellow-button"
              onClick={handleHeartbeatHistoryClick}
              loading={isHeartbeatsLoading}
              disabled={isHeartbeatsLoading}
            >
              {!isHeartbeatsLoading ? 'Descargar csv' : 'Cargando'}
            </Button>
          </div>
        </div>
      </div>
      {loading ? (
        <AirbagLoader />
      ) : (
        <>
          {trips && trips.length ? <ActivityInfo trips={trips} /> : null}
          <ActivityMap
            driver={driver}
            trips={trips}
            activities={activities}
            focus={focus}
            setFocus={setFocus}
            locationLogs={locationLogs}
            heartbeats={heartbeats}
            day={day}
          />
          <ActivitiesList
            activities={activities}
            setFocus={setFocus}
            applyBoundingBox={null}
            userId={user.id}
          />
        </>
      )}
    </div>
  );
}

export default DriverActivity;
