import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import moment from 'moment';
import dayjs from 'dayjs';
import setFontawesomeIcons from '../utils/fontawesome';
import skins from '../utils/skins';
import getDomainName from '../utils/getDomainName';
import {
  Chart,
  ArcElement,
  DoughnutController,
  RadarController,
  LineController,
  BarController,
  PolarAreaController,
  RadialLinearScale,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';

/* Styles */
import 'materialize-css/dist/css/materialize.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './css-variables/css-variables.scss';
import 'react-input-range/lib/css/index.css';
import './App.scss';

/* Components */
import ErrorBoundary from './error-boundary/ErrorBoundary';
import RoutesDefinitions from './RoutesDefinitions';

/* Redux methods and actions */
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from '../actions/user';
import * as companyActions from '../actions/company';
import * as companyGroupActions from '../actions/companyGroup';
import * as skinActions from '../actions/skin';

/* DayJS plugins */
const isoWeek = require('dayjs/plugin/isoWeek');
const updateLocale = require('dayjs/plugin/updateLocale');
const duration = require('dayjs/plugin/duration');
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(isoWeek);
dayjs.extend(updateLocale);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
// Custom locale
dayjs.locale('es', {
  name: 'es', // name String
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
  months:
    // eslint-disable-next-line max-len
    'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
      '_'
    ),
  monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_')
});

/* Moment JS */
moment.updateLocale('es-us', {
  timeZone: 'America/Mexico_City',
  months:
    // eslint-disable-next-line max-len
    'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
      '_'
    ),
  monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Dom_Lun_Mar_Mie_Jue_Vie_Sab'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
});

/* Register Chart.js imports */
Chart.register(
  ArcElement,
  DoughnutController,
  RadarController,
  LineController,
  BarController,
  PolarAreaController,
  RadialLinearScale,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Legend,
  Title,
  Tooltip
);
// Change global settings
Chart.defaults.font.family = 'PP Object Sans';

/* Init FontAwesome icons */
setFontawesomeIcons();

function App(props) {
  // Save initial url as previous url to work with on route change
  const previousUrl = useRef(window.location.pathname);
  const location = useLocation();
  const domainName = getDomainName();
  const skin = skins[domainName];

  /* Auth handler */
  useEffect(() => {
    // Handle skin
    props.setCompanySkin(skin);

    // Handle auth from admin back office
    const aat = props.cookies.get('aat');

    // If access token is found, save it in local storage
    if (aat) {
      localStorage.setItem('aat', aat);

      // Remove aat cookie
      if (process.env.REACT_APP_ENV === 'development') {
        props.cookies.remove('aat', {
          path: '/',
          domain: 'localhost'
        });
      } else {
        props.cookies.remove('aat', {
          path: '/',
          domain: `app.${skin.domain}`
        });
        props.cookies.remove('aat', {
          path: '/',
          domain: `.${skin.domain}`
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Location change handler */
  useEffect(() => {
    // Scroll to top on route change only if pathname changes
    if (previousUrl.current !== location.pathname) window.scrollTo(0, 0);
    // Save url as previous url for next location visit
    previousUrl.current = location.pathname;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ErrorBoundary>
      <RoutesDefinitions {...props} />
    </ErrorBoundary>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    company: state.company,
    companyGroup: state.companyGroup,
    skin: state.skin
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(
    {
      ...userActions,
      ...companyActions,
      ...companyGroupActions,
      ...skinActions
    },
    dispatch
  );
}

export default withRouter(
  withCookies(connect(mapStateToProps, mapDispachToProps)(App))
);
