import React, { useState } from 'react';
import M from 'materialize-css';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import axiosAuth from '../../../../utils/axiosAuth';
import carBrands from '../../../../utils/carBrands';
import vehicleTypes from '../../../../utils/vehicleTypes';
import fuelTypes from '../../../../utils/fuelTypes';
import insuranceBrands from '../../../../utils/insuranceBrands';
import Button from '../../../common/button/Button';
import SearchUserInput from '../../../common/search-user/SearchUserInput';
import './AddVehicle.scss';

function AddVehicle(props) {
  const { setIsOpen, fetchVehicles, user, company } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    uid: '',
    brand: '',
    model: '',
    plate: '',
    niv: '',
    color: '',
    vehicleType: '',
    description: '',
    fuel: '',
    height: '',
    insuranceBrand: '',
    insuranceNumber: '',
    insuranceExpiration: ''
  });
  const [driver, setDriver] = useState(null);

  function addVehicle(e) {
    e.preventDefault();
    setLoading(true);

    // Make request
    axiosAuth
      .post('/api/vehicles', { ...data, driver })
      .then((res) => {
        M.toast({
          html: 'Vehículo guardado con éxito',
          classes: 'success-toast'
        });
        fetchVehicles(true);
        setLoading(false);
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  // Function to set the vehicle's height based on the selected type
  function setVehicleTypeData(e) {
    let height = 0;
    let width = 0;
    let length = 0;
    vehicleTypes.forEach((type) => {
      if (type.value === e.target.value) {
        height = type.height;
        width = type.width;
        length = type.length;
      }
    });
    setData({
      ...data,
      vehicleType: e.target.value,
      height: height,
      width: width,
      length: length
    });
  }

  function onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  return (
    <div id="_add-vehicle_">
      <h5 id="main-t">Nuevo Vehículo</h5>
      <form onSubmit={addVehicle}>
        {/* INFORMACIÓN GENERAL */}
        <div className="row big-label">
          <div className="col s12">
            <p>Información general</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 input-field">
            <label className="active">Identificador Único</label>
            <input
              type="text"
              value={data.uid}
              placeholder="Identificador único"
              onChange={(e) => setData({ ...data, uid: e.target.value })}
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Marca</label>
            <select
              className="browser-default"
              defaultValue={data.brand}
              onChange={(e) => setData({ ...data, brand: e.target.value })}
            >
              <option value="" disabled>
                Seleccionar..
              </option>
              {carBrands.map((brand, i) => (
                <option key={i} value={brand.value}>
                  {brand.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Modelo</label>
            <input
              type="text"
              value={data.model}
              placeholder="Ingresa el modelo"
              onChange={(e) => setData({ ...data, model: e.target.value })}
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Placas</label>
            <input
              type="text"
              value={data.plate}
              placeholder="Ingresa las placas"
              onChange={(e) => setData({ ...data, plate: e.target.value })}
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Tipo Vehículo</label>
            <select
              className="browser-default"
              defaultValue={data.vehicleType}
              onChange={(e) => setVehicleTypeData(e)}
            >
              <option value="" disabled>
                Seleccionar..
              </option>
              {vehicleTypes.map((type, i) => (
                <option key={i} value={type.value}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Combustible</label>
            <select
              className="browser-default"
              defaultValue={data.fuel}
              onChange={(e) =>
                setData({
                  ...data,
                  fuel: e.target.value
                })
              }
            >
              <option value="" disabled>
                Seleccionar..
              </option>
              {fuelTypes.map((type, i) => (
                <option key={i} value={type.value}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">NIV</label>
            <input
              type="text"
              value={data.niv}
              placeholder="Ingresa el NIV"
              onChange={(e) =>
                setData({
                  ...data,
                  niv: e.target.value
                })
              }
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Color</label>
            <input
              type="text"
              value={data.color}
              placeholder="Ingresa el color"
              onChange={(e) =>
                setData({
                  ...data,
                  color: e.target.value
                })
              }
            />
          </div>
          <div className="col s12 input-field">
            <label className="active">Descripción</label>
            <textarea
              value={data.description}
              placeholder="Ingresa una descripción"
              onChange={(e) =>
                setData({
                  ...data,
                  description: e.target.value
                })
              }
            />
          </div>
        </div>

        {/* PÓLIZA DE SEGURO */}
        <div className="row big-label">
          <div className="col s12">
            <p>Póliza de seguro</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m12 input-field">
            <label className="active">Número de póliza</label>
            <input
              type="text"
              value={data.insuranceNumber}
              placeholder="Número de póliza"
              onChange={(e) =>
                setData({
                  ...data,
                  insuranceNumber: e.target.value
                })
              }
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Aseguradora</label>
            <select
              className="browser-default"
              defaultValue={data.insuranceBrand}
              onChange={(e) =>
                setData({
                  ...data,
                  insuranceBrand: e.target.value
                })
              }
            >
              <option value="" disabled>
                Seleccionar..
              </option>
              {insuranceBrands.map((brand, i) => (
                <option key={i} value={brand.value}>
                  {brand.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Vencimiento</label>
            <DatePicker
              ref={(el) => onDatepickerRef(el)}
              selected={
                data.insuranceExpiration
                  ? new Date(dayjs(data.insuranceExpiration).format())
                  : ''
              }
              placeholderText="Seleccionar.."
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
              minDate={new Date()}
              onChange={(date) =>
                setData({
                  ...data,
                  insuranceExpiration: dayjs(date).toISOString()
                })
              }
            />
          </div>
        </div>

        {/* Conductor */}
        <div className="row big-label">
          <div className="col s12">
            <p>Conductor asignado</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6 input-field">
            <SearchUserInput
              setResult={setDriver}
              user={user}
              company={company}
              mandatory={user.authRole === 'admin' ? false : true}
            />
          </div>
        </div>

        <div className="center-align">
          <Button
            variant="yellow-button"
            loading={loading}
            disabled={loading}
            type="submit"
          >
            Agregar Vehículo
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddVehicle;
