import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import axiosAuth from '../../../../utils/axiosAuth';
import roundNumber from '../../../../utils/roundNumber';
import Error from '../../../common/error/Error';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import Icon from '../../../common/airbag-icons/Icon';
import './DriverGame.scss';
import { StreakWeek } from './StreakWeek';
import { Level } from './Level';

function DriverGame(props) {
  const { driver } = props;
  const [error, setError] = useState(null);
  const [streak, setStreak] = useState(null);

  useEffect(() => {
    // Get streak info
    axiosAuth
      .get('/api/game/streak', { params: { driverId: driver.id } })
      .then((res) => {
        const { streak } = res.data;
        setStreak(streak);
      })
      .catch((err) => {
        console.error(err);
        setError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // API error handler
  if (error) return <Error error={error} />;

  function renderCurrentLevel() {
    if (driver.currentLevel) {
      const { name, currentDistance, percentageCompleted, to, from } =
        driver.currentLevel;

      return (
        <div className="airbag-table-container">
          <Level {...driver.currentLevel} />
        </div>
      );
    } else {
      return (
        <div className="airbag-table-container no-level">
          <p>Sin nivel</p>
        </div>
      );
    }
  }

  function renderStreaks() {
    // Loader
    if (!streak) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No streak means no data
    if (!Object.keys(streak).length) {
      return (
        <div id="no-data">
          <Icon icon="eventRegister" />
          <p>Sin information de racha</p>
        </div>
      );
    }

    const { isInStreak, lastWeekDaysStreak } = streak;

    return (
      <div className="airbag-table-container" id="trips-table">
        <StreakWeek
          streak={lastWeekDaysStreak}
          totalDaysOfStreak={streak.totalDaysOfStreak}
          maxHistoricalStreak={streak.maxHistoricalStreak}
        />
      </div>
    );
  }

  return (
    <div id="_driver-game_">
      <h5>Rachas</h5>
      {renderStreaks()}
      <h5>Nivel actual</h5>
      {renderCurrentLevel()}
    </div>
  );
}

export default DriverGame;
