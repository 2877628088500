import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import './Filters.scss';

function Filters(props) {
  const { filters, setFilters } = props;
  // const { showLocationLogs, setShowLocationLogs } = props;
  const [hideFilters, setHideFilters] = useState(true);

  return (
    <div id="_trip-log-filters_">
      <div className="right-align">
        <FontAwesomeIcon
          icon={hideFilters ? 'sort-amount-down-alt' : 'times'}
          id="filter-btn"
          onClick={() => {
            setHideFilters(!hideFilters);

            // Google Analytics
            ReactGA.event({
              category: 'trip_details',
              action: 'click_trip_filters'
            });
            // Posthog
            posthog.capture('click_trip_filters', {
              category: 'trip_details',
              isCustomEvent: true
            });
          }}
        />
      </div>
      {!hideFilters && (
        <div id="trip-log-filters-container">
          <span
            className={`${
              filters.includes('speeding') ? 'active-btn' : 'inactive-btn'
            }`}
            style={{ background: '#e6f7ff', color: '#015073' }}
            onClick={() => {
              // Update filter array
              const newFilters = filters.includes('speeding')
                ? [...filters.filter((f) => f !== 'speeding')]
                : [...filters, 'speeding'];
              setFilters(newFilters);
            }}
          >
            <FontAwesomeIcon icon="tachometer-alt" />
            Exceso de velocidad
          </span>
          <span
            className={`${
              filters.includes('acc') ? 'active-btn' : 'inactive-btn'
            }`}
            style={{ background: '#e8f7fd', color: '#0f7dac' }}
            onClick={() => {
              // Update filter array
              const newFilters = filters.includes('acc')
                ? [...filters.filter((f) => f !== 'acc')]
                : [...filters, 'acc'];
              setFilters(newFilters);
            }}
          >
            <FontAwesomeIcon icon="car-alt" />
            Aceleración brusca
          </span>
          <span
            className={`${
              filters.includes('deacc') ? 'active-btn' : 'inactive-btn'
            }`}
            style={{ background: '#e8f6fc', color: '#1a9ad3' }}
            onClick={() => {
              // Update filter array
              const newFilters = filters.includes('deacc')
                ? [...filters.filter((f) => f !== 'deacc')]
                : [...filters, 'deacc'];
              setFilters(newFilters);
            }}
          >
            <FontAwesomeIcon icon="car-alt" />
            Frenado brusco
          </span>
          <span
            className={`${
              filters.includes('cornering') ? 'active-btn' : 'inactive-btn'
            }`}
            style={{ background: '#e8f9fc', color: '#1cbbd7' }}
            onClick={() => {
              // Update filter array
              const newFilters = filters.includes('cornering')
                ? [...filters.filter((f) => f !== 'cornering')]
                : [...filters, 'cornering'];
              setFilters(newFilters);
            }}
          >
            <FontAwesomeIcon icon="car-crash" />
            Curvas pronunciadas
          </span>
          <span
            className={`${
              filters.includes('datos_activados')
                ? 'active-btn'
                : 'inactive-btn'
            }`}
            style={{ background: '#f5dcd7', color: '#f4563a' }}
            onClick={() => {
              // Update filter array
              const newFilters = filters.includes('datos_activados')
                ? [
                    ...filters.filter((f) => {
                      return (
                        f !== 'datos_activados' && f !== 'datos_desactivados'
                      );
                    })
                  ]
                : [...filters, 'datos_activados', 'datos_desactivados'];
              setFilters(newFilters);
            }}
          >
            <FontAwesomeIcon icon="signal" />
            Datos móviles
          </span>
          <span
            className={`${
              filters.includes('llamada_entrante')
                ? 'active-btn'
                : 'inactive-btn'
            }`}
            style={{ background: '#ebe5ff', color: '#7e65d8' }}
            onClick={() => {
              // Update filter array
              const newFilters = filters.includes('llamada_entrante')
                ? [
                    ...filters.filter((f) => {
                      return (
                        f !== 'llamada_entrante' && f !== 'llamada_saliente'
                      );
                    })
                  ]
                : [...filters, 'llamada_entrante', 'llamada_saliente'];
              setFilters(newFilters);
            }}
          >
            <FontAwesomeIcon icon="phone-volume" />
            Llamadas
          </span>
          {/* <span
            className={`${
              filters.includes('aplicacion_bloqueada')
                ? 'active-btn'
                : 'inactive-btn'
            }`}
            style={{ background: '#daebff', color: '#65a1e6' }}
            onClick={() => {
              // Update filter array
              const newFilters = filters.includes('aplicacion_bloqueada')
                ? [
                    ...filters.filter((f) => {
                      return (
                        f !== 'aplicacion_bloqueada' &&
                        f !== 'aplicacion_desbloqueada'
                      );
                    })
                  ]
                : [
                    ...filters,
                    'aplicacion_bloqueada',
                    'aplicacion_desbloqueada'
                  ];
              setFilters(newFilters);
            }}
          >
            <FontAwesomeIcon icon="mobile-alt" />
            Bloqueo del dispositivo
          </span>*/}
          <span
            className={`${
              filters.includes('entrega') ? 'active-btn' : 'inactive-btn'
            }`}
            style={{ background: '#ffeed5', color: '#ff9800' }}
            onClick={() => {
              // Update filter array
              const newFilters = filters.includes('entrega')
                ? [...filters.filter((f) => f !== 'entrega')]
                : [...filters, 'entrega'];
              setFilters(newFilters);
            }}
          >
            <FontAwesomeIcon icon="box-open" />
            Entregas
          </span>
          <span
            className={`${
              filters.includes('parada') ? 'active-btn' : 'inactive-btn'
            }`}
            style={{ background: '#ddf2d9', color: '#63c450' }}
            onClick={() => {
              // Update filter array
              const newFilters = filters.includes('parada')
                ? [...filters.filter((f) => f !== 'parada')]
                : [...filters, 'parada'];
              setFilters(newFilters);
            }}
          >
            <FontAwesomeIcon icon="bus" />
            Paradas
          </span>
          <span
            className={`${
              filters.includes('alerta') ? 'active-btn' : 'inactive-btn'
            }`}
            style={{ background: '#ffe6f2', color: '#ff0080' }}
            onClick={() => {
              // Update filter array
              const newFilters = filters.includes('alerta')
                ? [...filters.filter((f) => f !== 'alerta')]
                : [...filters, 'alerta'];
              setFilters(newFilters);
            }}
          >
            <FontAwesomeIcon icon={['fal', 'exclamation']} />
            Alertas
          </span>
          <span
            className={`${
              filters.includes('gas') ? 'active-btn' : 'inactive-btn'
            }`}
            style={{ background: '#fde8ec', color: 'crimson' }}
            onClick={() => {
              // Update filter array
              const newFilters = filters.includes('gas')
                ? [...filters.filter((f) => f !== 'gas')]
                : [...filters, 'gas'];
              setFilters(newFilters);
            }}
          >
            <FontAwesomeIcon icon="gas-pump" />
            Gasolina
          </span>
          {/* <span
            className={`${showLocationLogs ? 'active-btn' : 'inactive-btn'}`}
            style={{ background: '#b3ffd9', color: '#00b359' }}
            onClick={() => setShowLocationLogs(!showLocationLogs)}
          >
            <FontAwesomeIcon icon="map-marker-alt" />
            Ubicación
          </span> */}
        </div>
      )}
    </div>
  );
}

export default Filters;
