/* eslint max-len: 0 */

import React from 'react';

function Pin(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path
          className="cls-1"
          d="M195.24,172c12.41-23.92,22.06-48.72,22.06-68a75.57,75.57,0,1,0-151.14,0c0,53.39,75.57,151.14,75.57,151.14S172.94,215,195.24,172"
        />
        <circle className="cls-1" cx="141.73" cy="111.53" r="30.23" />
        <path
          className="cls-1"
          d="M195.24,172c12.41-23.92,22.06-48.72,22.06-68a75.57,75.57,0,1,0-151.14,0c0,53.39,75.57,151.14,75.57,151.14S172.94,215,195.24,172"
        />
        <circle className="cls-1" cx="141.73" cy="111.53" r="30.23" />
      </g>
    </svg>
  );
}

export default Pin;
