/*
IMPORTANT
This list must match exactly the one in /server/utils/fuelTypes.js
as server validation is in place.
*/

const fuelTypes = [
  {
    name: 'Diesel',
    value: 'f-diesel'
  },
  {
    name: 'Gasolina',
    value: 'f-gas'
  },
  {
    name: 'Eléctrico',
    value: 'f-electric'
  }
];

export default fuelTypes;
