import React from 'react';

// Render activation/deactivation notification source
function renderNotifSource(notif) {
  if (notif.source === 'admin') {
    return (
      <span className="notif-source">
        {notif.manager ? notif.manager : 'administrador'}
      </span>
    );
  } else if (notif.source === 'self') {
    return <span className="notif-source">él mismo</span>;
  } else if (notif.source === 'sam') {
    return <span className="notif-source">Sam</span>;
  } else if (notif.source === 'sync') {
    return <span className="notif-source">Airbag Sync</span>;
  } else if (notif.source === 'retry') {
    return (
      <span className="notif-source">
        reintento de activación {notif.retryCount ? '#' + notif.retryCount : ''}
      </span>
    );
  } else if (notif.source === 'schedule') {
    return <span className="notif-source">horario laboral</span>;
  } else {
    return <span className="notif-source">otro</span>;
  }
}

export default renderNotifSource;
