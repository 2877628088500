function company(state = {}, action) {
  switch (action.type) {
    case 'SET_COMPANY': {
      state = { ...state, ...action.payload };
      break;
    }
    case 'UNSET_COMPANY': {
      state = {};
      break;
    }
    default:
      break;
  }
  return state;
}

export default company;
