import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import dayjs from 'dayjs';
import durationToString from '../../../utils/durationToString';

function DrivingTimes(props) {
  const { title, labels, data, colors } = props;
  const chartContainer = useRef(null);

  const chartConfig = {
    type: 'doughnut',
    data: {
      labels,
      datasets: [
        {
          data: data,
          backgroundColor: colors,
          borderWidth: 1
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      resizeDelay: 800,
      plugins: {
        // https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
        tooltip: {
          callbacks: {
            title: (tooltipItems, data) => {
              return null;
            },
            label: (tooltipItem, data) => {
              return durationToString(
                dayjs.duration(tooltipItem.raw, 'm'),
                true
              );
            },
            afterLabel: (tooltipItems, data) => {
              return null;
            }
          },
          backgroundColor: '#464646',
          padding: 12,
          displayColors: false
        },
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 10,
            boxHeight: 10,
            padding: 18,
            usePointStyle: true,
            pointStyle: 'circle',
            color: '#464646'
          }
        },
        title: {
          text: title,
          display: true,
          color: '#000',
          padding: 18,
          font: {
            size: 14
          }
        }
      }
    }
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      new Chart(chartContainer.current, chartConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContainer]);

  return <canvas ref={chartContainer} />;
}

export default DrivingTimes;
