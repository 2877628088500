import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import M from 'materialize-css';
import Button from '../../common/button/Button';
import './ForgotPassword.scss';

function ForgotPassword(props) {
  const { skin } = props;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    // If access token is found, redirect to dashboard
    if (localStorage.getItem('aat')) {
      props.history.push('/');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    axios
      .post('/api/auth/forgot-password', {
        email: email.toLowerCase()
      })
      .then(() => {
        setEmail('');
        Swal.fire({
          icon: 'success',
          title: 'Revisa tu correo',
          // eslint-disable-next-line max-len
          text: 'En caso de existir una cuenta asociada, recibirás instrucciones para establecer una nueva contraseña',
          confirmButtonText: '<span class="dark-color-text">Aceptar</span>',
          confirmButtonColor: '#f6c700',
          didClose: () => {
            props.history.push('/login');
          }
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        let message = 'Tuvimos un error, por favor intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          message = err.response.data;
        }
        M.toast({
          html: message,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  return (
    <div id="_forgot-pass_">
      <div className="outer">
        <div className="middle">
          <div className="inner">
            <form id="formContainer" onSubmit={handleSubmit}>
              <div className="row">
                <div id="logo-container">
                  <Link to="/login">
                    <img
                      id="logoBlack"
                      src={skin.logoBlack}
                      alt="Company logo"
                    />
                  </Link>
                </div>
                <h2 id="formTitle">Solicita un nuevo enlace</h2>
                <p id="formSubtitle">
                  Ingresa tu correo electrónico y te enviaremos instrucciones
                  para crear una nueva contraseña.
                </p>
                <div className="input-field col">
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label htmlFor="email" className="active">
                    Correo electrónico:
                  </label>
                </div>
                <div className="col s12 center-align">
                  <Button
                    variant="yellow-button"
                    className="big-button"
                    loading={loading}
                    disabled={loading}
                    type="submit"
                  >
                    Enviar
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
