import React from 'react';

function OnOff(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path className="cls-1" d="M180.51,64.61a99.22,99.22,0,1,1-78.9.73" />
        <line className="cls-1" x1="141.73" y1="27.6" x2="141.73" y2="127.56" />
      </g>
    </svg>
  );
}

export default OnOff;
