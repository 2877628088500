import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import axiosAuth from '../../../../utils/axiosAuth';
import roundNumber from '../../../../utils/roundNumber';
import { getColors } from '../../../../utils/colors';
import cssVars from '../../../css-variables/css-variables-js';
import ScoresChart from '../../../common/chartjs/ScoresChart';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import DrivingTimesChart from '../driving-times-chart/DrivingTimesChart';
import './ScoresCharts.scss';

function ScoresCharts(props) {
  const { user, groupId, fillNoLabelsArr, fillNoDataArr, DAYS_COUNT } = props;
  const [scoresLabels, setScoresLabels] = useState(null);
  const [scores, setScores] = useState(null);
  const [tripsByRisk, setTripsByRisk] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [activeDrivers, setActiveDrivers] = useState(null);
  const [tripsCount, setTripsCount] = useState(null);

  useEffect(() => {
    // Reset values to show loaders
    setScores(null);
    setTripsByRisk(null);
    setTripsCount(null);
    setDistance(null);
    setDuration(null);
    setActiveDrivers(null);

    // Build query params
    const queryParams = {
      days: 30 // Default last month of data
    };
    // Add groupId if manager is group-limited
    if (user.authRole === 'group-limited' || groupId) {
      queryParams.groupId = groupId;
    }

    // Get charts info
    axiosAuth
      .get(`/api/charts/company/scores`, { params: queryParams })
      .then((res) => {
        const { scores: scoresRes, labels } = res.data;

        // Set labels
        if (labels && labels.length) {
          setScoresLabels(labels.map((day) => dayjs(day).format('DD MMM')));
        } else {
          setScoresLabels(fillNoLabelsArr(DAYS_COUNT));
        }

        // Set scores
        const { overall } = scoresRes;
        if (overall && overall.data && overall.data.length) {
          setScores(overall.data.map((score) => roundNumber(score)));
        } else {
          setScores(fillNoDataArr(DAYS_COUNT));
        }

        // Distance and duration
        const { distance, duration } = scoresRes;
        if (distance && distance.data && distance.data.length) {
          setDistance(distance.data.map((num) => roundNumber(num)));
        } else {
          setDistance(fillNoDataArr(DAYS_COUNT));
        }
        if (duration && duration.data && duration.data.length) {
          setDuration(duration.data.map((num) => roundNumber(num)));
        } else {
          setDuration(fillNoDataArr(DAYS_COUNT));
        }

        // Active drivers
        const { drivers } = scoresRes;
        if (drivers && drivers.data && drivers.data.length) {
          setActiveDrivers(scoresRes.drivers.data);
        } else {
          setActiveDrivers(fillNoDataArr(DAYS_COUNT));
        }

        // Trips by risk label
        const { riskProfileLow, riskProfileMid, riskProfileHigh } = scoresRes;
        if (riskProfileLow && riskProfileMid && riskProfileHigh) {
          setTripsByRisk({
            low: riskProfileLow.data,
            mid: riskProfileMid.data,
            high: riskProfileHigh.data
          });
        } else {
          setTripsByRisk({
            low: fillNoDataArr(DAYS_COUNT),
            mid: fillNoDataArr(DAYS_COUNT),
            high: fillNoDataArr(DAYS_COUNT)
          });
        }

        // Trips count
        const { trips } = scoresRes;
        if (trips && trips.data && trips.data.length) {
          setTripsCount(trips.data);
        } else {
          setTripsCount(fillNoDataArr(DAYS_COUNT));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  function handleScoreColor(number, returnHex) {
    if (number >= 80) {
      if (returnHex) return cssVars['graph-successful'];
      return 'good';
    } else if (number >= 60) {
      if (returnHex) return cssVars['graph-attention'];
      return 'regular';
    } else if (number > 0 && number < 60) {
      if (returnHex) return cssVars['graph-error'];
      return 'bad';
    } else {
      if (returnHex) return '#b5b5b5';
      return '';
    }
  }

  function getAverage(arr, rounded) {
    // Handle no valid array
    if (!arr || !arr.length) return 0;

    // Remove 0 from array
    const newArr = [];
    arr.forEach((item) => {
      if (item) newArr.push(item);
    });

    // Nothing added means no data
    if (!newArr.length) return 0;

    // Get average
    let average = newArr.reduce((a, b) => a + b, 0) / newArr.length;

    // Round Number
    if (rounded) {
      average = roundNumber(average);
    }

    return average;
  }

  function getMaxValueTripRisks(obj) {
    const { low, mid, high } = obj;
    const maxAll = [];

    for (let i = 0; i < low.length; i++) {
      // Sum value by day and push to array
      const daySum = low[i] + mid[i] + high[i];
      maxAll.push(daySum);
    }

    // Get max value of array
    const maxValue = Math.max(...maxAll);

    return maxValue;
  }

  return (
    <div className="row no-margin">
      <div className="col s12">
        <div className="graph-box scores-data-label">
          {tripsByRisk && scoresLabels ? (
            <ScoresChart
              title={`Número de viajes por nivel de riesgo`}
              labels={scoresLabels}
              data={[
                { data: tripsByRisk.high, label: 'Riesgo Alto' },
                { data: tripsByRisk.mid, label: 'Riesgo Medio' },
                { data: tripsByRisk.low, label: 'Riesgo Bajo' }
              ]}
              colors={[
                cssVars['graph-error'],
                cssVars['graph-attention'],
                cssVars['graph-successful']
              ]}
              displayLegend={true}
              max={getMaxValueTripRisks(tripsByRisk) + 2}
              showSingleValueInTooltip={false}
            />
          ) : (
            <AirbagLoader />
          )}
        </div>
      </div>
      <div className="col s12 m6 l6">
        <div className="graph-box scores-data">
          {scores && scoresLabels ? (
            <ScoresChart
              title={`Calificación promedio de la flotilla`}
              labels={scoresLabels}
              data={[{ data: scores, label: '' }]}
              colors={[handleScoreColor(getAverage(scores, true), true)]}
              displayLegend={false}
              max={100}
              showSingleValueInTooltip={true}
            />
          ) : (
            <AirbagLoader />
          )}
        </div>
      </div>
      <div className="col s12 m6 l6">
        <div className="graph-box scores-data">
          {tripsCount && scoresLabels ? (
            <ScoresChart
              title={`Número de viajes realizados`}
              labels={scoresLabels}
              data={[{ data: tripsCount, label: '' }]}
              colors={[getColors(5)[4]]}
              displayLegend={false}
              max={Math.max(...tripsCount) + 1}
              showSingleValueInTooltip={true}
            />
          ) : (
            <AirbagLoader />
          )}
        </div>
      </div>
      <div className="col s12 m6 l6">
        <div className="graph-box scores-data">
          {distance && scoresLabels ? (
            <ScoresChart
              title={`Kilómetros recorridos`}
              labels={scoresLabels}
              data={[{ data: distance, label: '' }]}
              colors={[getColors(2)[1]]}
              displayLegend={false}
              showSingleValueInTooltip={true}
              max={Math.max(...distance) + 10}
            />
          ) : (
            <AirbagLoader />
          )}
        </div>
      </div>
      <div className="col s12 m6 l6">
        <div className="graph-box scores-data">
          {activeDrivers && scoresLabels ? (
            <ScoresChart
              title={`Número de conductores activos`}
              labels={scoresLabels}
              data={[{ data: activeDrivers, label: '' }]}
              colors={[getColors(4)[3]]}
              displayLegend={false}
              max={Math.max(...activeDrivers) + 1}
              showSingleValueInTooltip={true}
            />
          ) : (
            <AirbagLoader />
          )}
        </div>
      </div>
      <div className="col s12 m6 l6">
        <div className="graph-box scores-data">
          {duration && scoresLabels ? (
            <ScoresChart
              title={`Minutos en viaje`}
              labels={scoresLabels}
              data={[{ data: duration, label: '' }]}
              colors={[getColors(3)[2]]}
              displayLegend={false}
              showSingleValueInTooltip={true}
              max={Math.max(...duration) + 1}
            />
          ) : (
            <AirbagLoader />
          )}
        </div>
      </div>
      <div className="col s12 m6 l6">
        <DrivingTimesChart user={user} />
      </div>
    </div>
  );
}

export default ScoresCharts;
