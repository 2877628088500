/* eslint max-len: 0 */

import React from 'react';

function EndWorkday(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 12"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M5.85782 10.0112C5.86702 10.8629 5.16571 11.5615 4.29122 11.5718C3.41674 11.5821 2.70027 10.8986 2.69053 10.0463C2.68079 9.19405 3.38265 8.49544 4.25713 8.4857C5.13161 8.47596 5.84808 9.15888 5.85782 10.0112Z" />
      <path d="M18.9774 9.86451C18.9871 10.7168 18.2858 11.4154 17.4113 11.4252C16.5368 11.4349 15.8198 10.752 15.8106 9.90023C15.8014 9.04847 16.5022 8.34932 17.3767 8.33958C18.2512 8.32984 18.9682 9.01276 18.9774 9.86451Z" />
      <path d="M0.55127 0.811281L13.2194 0.670044L13.2783 5.96023L0.610255 6.10147L0.55127 0.811281Z" />
      <path d="M15.0532 2.85405L16.4109 2.8389C16.8179 2.83457 17.1826 3.09486 17.2767 3.46879L17.7465 4.98398C17.7465 5.00617 17.7692 5.00563 17.7692 5.02781L15.0776 5.05757L15.0532 2.85405ZM16.4006 1.95738L14.1387 1.98282L14.1733 5.06731L14.1879 6.38986L14.2274 9.91647L15.1322 9.90673C15.1187 8.69404 16.1252 7.68805 17.3698 7.67723C18.6144 7.66641 19.6426 8.64371 19.6561 9.85586C19.8929 9.85558 20.1199 9.76163 20.2876 9.59454C20.4553 9.42745 20.55 9.20079 20.5512 8.96407L20.5268 6.76C20.5246 6.62193 20.49 6.48631 20.4257 6.36411C20.3614 6.2419 20.2692 6.13654 20.1567 6.05652L18.9483 5.19069C18.7908 5.07972 18.6769 4.91723 18.6263 4.73127L18.1572 3.21607C17.9223 2.4693 17.1923 1.94872 16.4006 1.95738Z" />
      <path d="M13.3228 9.92788L6.53635 10.0036C6.52282 8.79095 5.49357 7.8104 4.24949 7.82447C3.00541 7.83854 1.99835 8.84182 2.01188 10.054L0.654698 10.0691L0.620605 6.98462L13.2882 6.84338L13.3228 9.92788Z" />
    </svg>
  );
}

export default EndWorkday;
