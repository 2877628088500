/* eslint max-len: 0 */

import React from 'react';

function Car(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2">
        <path
          className="st0"
          d="M210.8,199.5l-0.1,23.4c0,6.2-5,11.2-11.2,11.2c0,0,0,0,0,0l-12.2,0c-6.2,0-11.2-5-11.2-11.2c0,0,0,0,0,0l0.1-23.4"
        />
        <path
          className="st0"
          d="M107.1,199.3l-0.1,23.4c0,6.2-5,11.2-11.2,11.2l-12.2,0c-6.2,0-11.2-5-11.2-11.2c0,0,0,0,0,0l0.1-23.4"
        />
        <circle className="st0" cx="193.6" cy="160.6" r="11.5" />
        <circle className="st0" cx="89.9" cy="158.9" r="11.5" />
        <path
          className="st0"
          d="M211,118.8l-7.7-46.7c-2.1-13-13.4-22.6-26.6-22.6l-69.6-0.2c-25.6,0-25.9,59.3-32.8,69.8"
        />
        <path
          className="st0"
          d="M88,118.8l123.1,0c12.7,0,23,10.3,23,23c0,0,0,0,0,0l-0.2,57.7l-184.5-0.5l0.2-57.7C50.6,116.9,76.8,118.8,88,118.8"
        />
      </g>
    </svg>
  );
}

export default Car;
