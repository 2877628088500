import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobileOnly } from 'react-device-detect';
import M from 'materialize-css';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import roundNumber from '../../../../utils/roundNumber';
import durationToString from '../../../../utils/durationToString';
import Stat from '../../../common/stat/Stat';
import Icon from '../../../common/airbag-icons/Icon';
import './TripInfo.scss';

function TripInfo(props) {
  const { trip, driver, latestHeartbeat, company } = props;
  const [collapsibleOpen, setCollapsibleOpen] = useState(false);
  const [extraTripCopllapsibleOpen, setExtraTripCopllapsibleOpen] =
    useState(false);
  const [extraTripCopllapsibleOpen2, setExtraTripCopllapsibleOpen2] =
    useState(false);

  useEffect(() => {
    const elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function detailedFeedbackContent() {
    const titles = {
      'phone-distraction': 'Uso de celular', // old trips
      phoneDistraction: 'Uso de celular',
      speeding: 'Exceso de velocidad',
      acc: 'Aceleraciones bruscas',
      deacc: 'Frenados bruscos',
      cornering: 'Curvas pronunciadas'
    };

    return Object.keys(trip.detailedFeedback).map((key) => {
      const incidents = trip.detailedFeedback[key];

      return (
        <div className="detailed-feedback-row" key={key}>
          <p className="df-title">{titles[key]}</p>
          {incidents.map((fb, idx) => {
            // Get correct date in feedback text
            const hourStartText = fb.startDate.slice(11, 19);
            const hourStartFixed = dayjs(fb.startDate).format('HH:mm:ss');
            const hourEndText = fb.endDate.slice(11, 19);
            const hourEndFixed = dayjs(fb.endDate).format('HH:mm:ss');
            let feedback = '';

            if (fb.feedback) {
              feedback = fb.feedback.replace(hourStartText, hourStartFixed);
              feedback = feedback.replace(hourEndText, hourEndFixed);
            }

            // Build feedback description
            let description;
            if (key === 'speeding') {
              const maxLog = fb.max.log ? fb.max.log : fb.max.locationLog;
              const speed = roundNumber(maxLog.position.spd);
              const speedLimit = maxLog.metadata
                ? maxLog.metadata.speedLimit
                : maxLog.position.speedLimit; // old logs
              // Check if there is no duration to send a value < 1
              // to get the < 1 min string
              const duration = durationToString(
                dayjs.duration(fb.duration ? fb.duration : 0.5, 'm'),
                true
              );

              description = (
                <div className="incident-desc">
                  <p>
                    Velocidad: <span>{speed} km/h</span>. Límite:{' '}
                    <span>{speedLimit} km/h</span>. Duración:{' '}
                    <span>{duration}</span>.
                  </p>
                </div>
              );
            }
            if (key === 'phone-distraction') {
              const maxSpeed = roundNumber(fb.max.value);
              // Check if there is no duration to send a value < 1
              // to get the < 1 min string
              const duration = durationToString(
                dayjs.duration(fb.duration ? fb.duration : 0.5, 'm'),
                true
              );

              description = (
                <div className="incident-desc">
                  <p>
                    Velocidad máxima registrada: <span>{maxSpeed} km/h</span>.
                    Duración: <span>{duration}</span>.
                  </p>
                </div>
              );
            }
            if (key === 'deacc' || key === 'acc' || key === 'cornering') {
              // Get infirgment value
              const value = roundNumber(fb.max.value, 2);
              description = (
                <div className="incident-desc">
                  <p>
                    Se excedió la fuerza por <span>{value} m/s2</span>.
                  </p>
                </div>
              );
            }

            return (
              <div key={`${key}-${idx}`} className="incident">
                <p>{feedback}</p>
                {description}
              </div>
            );
          })}
        </div>
      );
    });
  }

  function renderDetailedFeedback() {
    // Handle trips without tips.
    if (!trip.detailedFeedback) {
      return null;
    }

    return (
      <div className="col s12">
        <ul className="collapsible" id="driving-tips">
          <li>
            <div
              className="collapsible-header"
              onClick={() => setCollapsibleOpen(!collapsibleOpen)}
            >
              <p className="tips-title">
                <FontAwesomeIcon
                  icon={['fal', 'shield-exclamation']}
                  className="header-icon"
                />
                ¿Por qué se obtuvo esta calificación?
                <FontAwesomeIcon
                  icon={['fal', collapsibleOpen ? 'caret-up' : 'caret-down']}
                  className="caret-icon"
                />
              </p>
            </div>
            <div className="collapsible-body">{detailedFeedbackContent()}</div>
          </li>
        </ul>
      </div>
    );
  }

  function renderExtraInfo() {
    return (
      <div className="col s12">
        <ul
          className="collapsible"
          id="driving-tips"
          onClick={() =>
            setExtraTripCopllapsibleOpen(!extraTripCopllapsibleOpen)
          }
        >
          <li>
            <div className="collapsible-header">
              <p className="tips-title">
                <FontAwesomeIcon
                  icon={['fal', 'light-emergency-on']}
                  className="header-icon"
                />
                Riesgos detectados
                <FontAwesomeIcon
                  icon={[
                    'fal',
                    extraTripCopllapsibleOpen ? 'caret-up' : 'caret-down'
                  ]}
                  className="caret-icon"
                />
              </p>
            </div>
            <div className="collapsible-body">
              <div className="row" id="general-info">
                <div className="col s6 l3">
                  <p className="title">
                    <Icon icon="phone" className="title-icon" />
                    {trip.phoneUsageDistance < 1 && trip.phoneUsageDistance > 0
                      ? trip.phoneUsageDistance.toFixed(1)
                      : Math.round(trip.phoneUsageDistance)}{' '}
                    km
                  </p>
                  <p className="subtitle">Distancia en uso de celular</p>
                </div>
                <div className="col s6 l3">
                  <p className="title">
                    <Icon icon="velocimeter" className="title-icon" />
                    {trip.highSpeedingDistance + trip.midSpeedingDistance < 1 &&
                    trip.highSpeedingDistance + trip.midSpeedingDistance > 0
                      ? (
                          trip.highSpeedingDistance + trip.midSpeedingDistance
                        ).toFixed(1)
                      : Math.round(
                          trip.highSpeedingDistance + trip.midSpeedingDistance
                        )}{' '}
                    km
                  </p>
                  <p className="subtitle">Distancia en exceso velocidad</p>
                </div>
                <div className="col s6 l3">
                  <p className="title">
                    <Icon icon="velocimeter" className="title-icon" />
                    {trip.phoneUsageWithSpeedingDistance < 1 &&
                    trip.phoneUsageWithSpeedingDistance > 0
                      ? trip.phoneUsageWithSpeedingDistance.toFixed(1)
                      : Math.round(trip.phoneUsageWithSpeedingDistance)}{' '}
                    km
                  </p>
                  <p className="subtitle">
                    Distancia en celular en exceso de velocidad
                  </p>
                </div>
                <div className="col s6 l3">
                  <p className="title">
                    <Icon icon="phone" className="title-icon" />
                    {durationToString(
                      dayjs.duration(
                        Math.ceil(trip.phoneUsageWithSpeedingDurationMinutes),
                        'minutes'
                      )
                    )}
                  </p>
                  <p className="subtitle">
                    Tiempo en celular en exceso de velocidad
                  </p>
                </div>
                <div className="col s6 l3">
                  <p className="title">
                    <Icon icon="acc" className="title-icon" />
                    {(trip.logTypes && trip.logTypes.acc) || 0}
                  </p>
                  <p className="subtitle">Aceleraciones bruscas</p>
                </div>
                <div className="col s6 l3">
                  <p className="title">
                    <Icon icon="deacc" className="title-icon" />
                    {(trip.logTypes && trip.logTypes.deacc) || 0}
                  </p>
                  <p className="subtitle">Frenados bruscos</p>
                </div>
                <div className="col s6 l3">
                  <p className="title">
                    <Icon icon="cornering" className="title-icon" />
                    {(trip.logTypes && trip.logTypes.cornering) || 0}
                  </p>
                  <p className="subtitle">Curvas pronunciadas</p>
                </div>
                {dayjs().format('YYYY-MM-DD') >= '2024-08-12' && // Release date
                company.hasCustomTelematicsValues ? (
                  <>
                    <div className="col s6 l3">
                      <p className="title">
                        <Icon icon="velocimeter" className="title-icon" />
                        {trip.customSpeedLimitDistance > 0 &&
                        trip.customSpeedLimitDistance < 1
                          ? roundNumber(trip.customSpeedLimitDistance / 100, 1)
                          : roundNumber(
                              trip.customSpeedLimitDistance / 100
                            )}{' '}
                        km
                      </p>
                      <p className="subtitle">
                        Distancia en exceso de velocidad personalizado
                      </p>
                    </div>
                    <div className="col s6 l3">
                      <p className="title">
                        <Icon icon="velocimeter" className="title-icon" />
                        {durationToString(
                          dayjs.duration(
                            Math.ceil(trip.customSpeedLimitDuration),
                            'minutes'
                          )
                        )}
                      </p>
                      <p className="subtitle">
                        Tiempo en exceso de velocidad personalizado
                      </p>
                    </div>
                    {/* <div className="col s6 l3">
                      <p className="title">
                        0 km
                      </p>
                      <p className="subtitle">
                        Distancia en exceso velocidad personalizado usando el
                        celular
                      </p>
                    </div> */}
                  </>
                ) : null}
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  function renderPills(hb) {
    const {
      created,
      locationPermissionGranted,
      motionActivityPermissionGranted,
      lowPowerModePermissionGranted,
      notificationsPermissionGranted,
      gpsEnabled,
      lowPowerModeActive
    } = hb;
    // Handle hb array
    const main = [];

    if (!locationPermissionGranted) {
      main.push(
        <span className="not-ok" key="locationPermissionGranted">
          Permiso de ubicación
        </span>
      );
    }
    if (!gpsEnabled) {
      main.push(
        <span className="not-ok" key="gpsEnabled">
          Ubicación desactivada
        </span>
      );
    }
    if (!motionActivityPermissionGranted) {
      main.push(
        <span className="not-ok" key="motionActivityPermissionGranted">
          Permiso de actividad física
        </span>
      );
    }
    if (!lowPowerModePermissionGranted) {
      main.push(
        <span className="not-ok" key="lowPowerModePermissionGranted">
          Permiso de uso de batería
        </span>
      );
    }
    if (lowPowerModeActive) {
      main.push(
        <span className="not-ok" key="lowPowerModePermissionGranted">
          Ahorro de batería prendido
        </span>
      );
    }
    if (!notificationsPermissionGranted) {
      main.push(
        <span className="not-ok" key="lowPowerModePermissionGranted">
          Permiso de notificaciones
        </span>
      );
    }

    // Handle no errors
    if (!main.length) {
      main.push(
        <span className="ok" key="all-ok">
          Configuración correcta
        </span>
      );
    }

    return main;
  }

  function renderLatestHeartbeat() {
    return (
      <div className="col s12">
        <ul
          className="collapsible"
          id="heartbeats"
          onClick={() =>
            setExtraTripCopllapsibleOpen2(!extraTripCopllapsibleOpen2)
          }
        >
          <li>
            <div className="collapsible-header">
              <p className="tips-title">
                <FontAwesomeIcon
                  icon={['fal', 'mobile-button']}
                  className="header-icon"
                />
                Permisos y sensores
                <FontAwesomeIcon
                  icon={[
                    'fal',
                    extraTripCopllapsibleOpen2 ? 'caret-up' : 'caret-down'
                  ]}
                  className="caret-icon"
                />
              </p>
            </div>
            <div className="collapsible-body">
              {latestHeartbeat ? (
                <>
                  <div className="hb-container">
                    {renderPills(latestHeartbeat)}
                  </div>
                  <p id="hb-subtitle">
                    Hora de registro{' '}
                    {dayjs(latestHeartbeat.created).format('HH:ss')}
                  </p>
                </>
              ) : (
                <p id="hb-title">Sin información</p>
              )}
            </div>
          </li>
        </ul>
      </div>
    );
  }

  function handleScoreColor(number) {
    if (number >= 80) {
      return 'green';
    } else if (number >= 60) {
      return 'yellow';
    } else if (number < 60) {
      return 'red';
    }
  }

  const dataTipText =
    'Este viaje se volvió a procesar después de analizar nueva información';

  const { score, ecoScore } = trip;

  return (
    <div className="row no-margin" id="_trip-info_">
      <div className="col s12">
        <h5 id="driver-name">
          Viaje de{' '}
          <Link to={`/conductor?driverId=${driver.id}`}>
            {driver.name} {driver.lastName}
          </Link>
        </h5>
        {trip.wasUpdatedByProvider ? (
          <div id="extra-info">
            <FontAwesomeIcon icon="sync" data-tip={dataTipText} />
          </div>
        ) : null}
      </div>
      {/* ---- general ---- */}
      <div className="col s12">
        <div className="row" id="general-info">
          <div className="col s6 m6 l2">
            <p className="title">
              <Icon icon="clock" className="title-icon" />
              {durationToString(
                dayjs.duration(Math.ceil(trip.duration), 'minutes')
              )}
            </p>
            <p className="subtitle">Duración del viaje</p>
          </div>
          <div className="col s6 m6 l3">
            <p className="title">
              <Icon icon="routes" className="title-icon" />
              {trip.distance > 10
                ? `${roundNumber(trip.distance)} km`
                : `${trip.distance.toFixed(1)} km`}
            </p>
            <p className="subtitle">Distancia recorrida</p>
          </div>
          <div className="col s6 m6 l2">
            <p className="title">
              <Icon icon="phone" className="title-icon" />
              {durationToString(
                dayjs.duration(Math.ceil(trip.phoneUsageTime), 'minutes')
              )}
            </p>
            <p className="subtitle">Tiempo en celular</p>
          </div>
          <div className="col s6 m6 l3">
            <p className="title">
              <Icon icon="velocimeter" className="title-icon" />
              {Math.round(trip.maxSpeed)} km/h
            </p>
            <p className="subtitle">Velocidad máxima</p>
          </div>
          <div className="col s12 l2">
            <p className="title">
              <FontAwesomeIcon
                icon={['fal', 'coins']}
                className="title-fa-icon"
              />
              {trip.coins ? roundNumber(trip.coins) : 0}
            </p>
            <p className="subtitle">Puntos</p>
          </div>
        </div>
      </div>
      {renderExtraInfo()}
      {renderLatestHeartbeat()}
      <div className="col s12">
        <ul id="start-end-points">
          <li>
            <FontAwesomeIcon icon="circle" className="small-icon" />
            <div className="point-content">
              <p className="point-label">{trip.startAddress}</p>
              <p>
                {dayjs(trip.startDate).format(
                  isMobileOnly ? 'DD MMMM - HH:mm' : 'dddd D [de] MMMM HH:mm A'
                )}
              </p>
            </div>
          </li>
          <li>
            <Icon icon="pin" className="big-icon" />
            <div className="point-content">
              <p className="point-label">{trip.endAddress}</p>
              <p>
                {dayjs(trip.endDate).format(
                  isMobileOnly ? 'DD MMMM - HH:mm' : 'dddd D [de] MMMM HH:mm A'
                )}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div className="col s12">
        {/* SCORING */}
        <div className="row metrics-table">
          <div className="col s12">
            <p className="table-title">Hábitos de conducción</p>
          </div>
          <div className="col s12 l2 wrapp">
            <Stat
              value={score.overall}
              color={handleScoreColor(score.overall)}
            />
          </div>
          <div className="col s6 m4 l2 wrapp">
            <p className="stat-title">Aceleración</p>
            <p
              className={`stat-value st-${handleScoreColor(
                score.acceleration
              )}`}
            >
              {score.acceleration} <span>/ 100</span>
            </p>
          </div>
          <div className="col s6 m4 l2 wrapp">
            <p className="stat-title">Frenado</p>
            <p className={`stat-value st-${handleScoreColor(score.braking)}`}>
              {score.braking} <span>/ 100</span>
            </p>
          </div>
          <div className="col s6 m4 l2 wrapp">
            <p className="stat-title">Curvas</p>
            <p className={`stat-value st-${handleScoreColor(score.cornering)}`}>
              {score.cornering} <span>/ 100</span>
            </p>
          </div>
          <div className="col s6 m6 l2 wrapp">
            <p className="stat-title">Uso de celular</p>
            <p
              className={`stat-value st-${handleScoreColor(
                score.phoneDistraction
              )}`}
            >
              {score.phoneDistraction} <span>/ 100</span>
            </p>
          </div>
          <div className="col s12 m6 l2 wrapp">
            <p className="stat-title">Exceso de velocidad</p>
            <p className={`stat-value st-${handleScoreColor(score.speeding)}`}>
              {score.speeding} <span>/ 100</span>
            </p>
          </div>
        </div>
      </div>
      {ecoScore && (
        <div className="col s12">
          {/* ECO SCORING */}
          <div className="row metrics-table">
            <div className="col s12">
              <p className="table-title">Conducción ecológica</p>
            </div>
            <div className="col s12 l2 wrapp">
              <Stat
                value={ecoScore.overall}
                color={handleScoreColor(ecoScore.overall)}
              />
            </div>
            <div className="col s6 l2 wrapp">
              <p className="stat-title">Combustible</p>
              <p className={`stat-value st-${handleScoreColor(ecoScore.fuel)}`}>
                {ecoScore.fuel} <span>/ 100</span>
              </p>
            </div>
            <div className="col s6 l2 wrapp">
              <p className="stat-title">Llantas</p>
              <p
                className={`stat-value st-${handleScoreColor(ecoScore.tyres)}`}
              >
                {ecoScore.tyres} <span>/ 100</span>
              </p>
            </div>
            <div className="col s6 l2 wrapp">
              <p className="stat-title">Frenos</p>
              <p
                className={`stat-value st-${handleScoreColor(ecoScore.brakes)}`}
              >
                {ecoScore.brakes} <span>/ 100</span>
              </p>
            </div>
          </div>
        </div>
      )}
      {renderDetailedFeedback()}
      {/* Tooltips */}
      <ReactTooltip />
    </div>
  );
}

export default TripInfo;
