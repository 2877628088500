import React from 'react';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import './Filters.scss';

function Filters(props) {
  const { riskProfile, setRiskProfile } = props;

  return (
    <div className="row" id="_filters-trips_">
      <div className="col s12" id="filters-box">
        <span className="input-field item-container">
          <label htmlFor="status-select" className="active">
            Nivel de riesgo
          </label>
          <select
            className="browser-default"
            id="status-select"
            value={riskProfile}
            onChange={(e) => {
              // Set filter
              setRiskProfile(e.target.value);
            }}
            onFocus={() => {
              // Google Analytics
              ReactGA.event({
                category: 'trips_view',
                action: 'risk_filter_focus'
              });
              // Posthog
              posthog.capture('risk_filter_focus', {
                category: 'trips_view',
                isCustomEvent: true
              });
            }}
          >
            <option value="">Todos</option>
            <option value="Alto">Riesgo alto</option>
            <option value="Medio">Riesgo medio</option>
            <option value="Bajo">Riesgo bajo</option>
          </select>
        </span>
      </div>
    </div>
  );
}

export default Filters;
