import React from 'react';

function Search(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <circle className="cls-1" cx="116.93" cy="116.93" r="88.58" />
        <line
          className="cls-1"
          x1="255.12"
          y1="255.12"
          x2="184.54"
          y2="184.54"
        />
      </g>
    </svg>
  );
}

export default Search;
