export function setCompany(company) {
  return {
    type: 'SET_COMPANY',
    payload: company
  };
}

export function unsetCompany() {
  return {
    type: 'UNSET_COMPANY',
    payload: {}
  };
}
