import lastSeen from '../lastSeen';
import getMarker from './getMarker';

// Function to add the drivers markers on the home map
function addDriversMarkers(markers, H, mapUI, mapObj) {
  // Declare group
  const group = new H.map.Group();

  for (const marker of markers) {
    // Check for valid position
    if (!marker.lat || !marker.lng) continue;

    // Create a marker icon from an image URL:
    let icon = null;
    if (marker.markerType === 'good') {
      icon = new H.map.Icon(getMarker('location-ok'), {
        anchor: new H.math.Point(29, 85)
      });
    } else if (marker.markerType === 'regular') {
      icon = new H.map.Icon(getMarker('location-warn'), {
        anchor: new H.math.Point(29, 85)
      });
    } else if (marker.markerType === 'bad') {
      icon = new H.map.Icon(getMarker('location-lost'), {
        anchor: new H.math.Point(29, 85)
      });
    }

    // Create a marker using the previously instantiated ico
    const mapMarker = new H.map.Marker(
      { lat: marker.lat, lng: marker.lng },
      { icon }
    );
    // Set the text that the marker will show when clicked on
    const markerData =
      `<b>${marker.driver.name} ${marker.driver.lastName}</b>` +
      `<p class="last-seen"><span>últ. vez hace</span> ${lastSeen(
        marker.driver.position.created
      )}</p>` +
      // eslint-disable-next-line
      `<a id="cat-btn" href="/conductor?driverId=${marker.driver.id}">Ver perfil</a>`;
    mapMarker.setData(markerData);

    // Add the event listener to listen clicks on marker
    mapMarker.addEventListener('tap', (event) => {
      const bubble = new H.ui.InfoBubble(
        { lat: marker.lat, lng: marker.lng },
        { content: event.target.getData() }
      );
      // Add the bubble to the UI
      mapUI.current.addBubble(bubble);
    });

    // retrieve all map objects
    // Add the marker to the map
    group.addObject(mapMarker);
  }

  // Add the group to the map
  mapObj.current.addObject(group);

  // Set minimum map zoom to fit all objects
  // Docs: https://developer.here.com/documentation/examples/maps-js/markers/zoom-to-set-of-markers
  if (markers.length > 1) {
    mapObj.current.getViewModel().setLookAtData({
      bounds: group.getBoundingBox()
    });
  }
}

export default addDriversMarkers;
