import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import M from 'materialize-css';
import Button from '../../common/button/Button';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import Icon from '../../common/airbag-icons/Icon';
import TwoFAWizard from '../two-FA-wizard/TwoFAWizard';
import './RestorePassword.scss';

const specCharsStr = `$&+,:;=¿?@#|'<>.^*()%¡!-`;

function RestorePassword(props) {
  const { skin } = props;
  const [loading, setLoading] = useState(false);
  const [restoreToken, setRestoreToken] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [pass1Type, setPass1Type] = useState('password');
  const [pass2Type, setPass2Type] = useState('password');
  const [requestTwoFa, setRequestTwoFa] = useState(false);

  useEffect(() => {
    // If access token is found, redirect to dashboard
    if (localStorage.getItem('aat')) {
      props.history.push('/');
      return;
    }

    // Check for restore password token in params
    if (!props.match.params.token) {
      props.history.push('/login');
      return;
    }

    axios
      .post('/api/auth/verify-restore-password-token', {
        token: props.match.params.token
      })
      .then(() => {
        setRestoreToken(props.match.params.token);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Enlace no válido',
          // eslint-disable-next-line max-len
          text: 'El enlace para establecer tu contraseña ya no es válido, pero puedes solicitar uno nuevo dando click al siguiente botón',
          confirmButtonColor: '#f6c700',
          confirmButtonText:
            '<span class="dark-color-text">Solicitar nuevo enlace</span>',
          didClose: () => {
            props.history.push('/forgot-password');
          }
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function restorePassword(e) {
    e.preventDefault();
    setLoading(true);

    axios
      .post('/api/auth/restore-password', {
        password1,
        password2,
        token: restoreToken
      })
      .then((res) => {
        const { user, company, accessToken, refreshToken } = res.data;

        // Save user and company information in store
        props.setCompany(company);
        props.setUser(user);

        // Check if user has 2FA
        if (user.has2FAEnabled) {
          setLoading(false);
          // This will show 2FA section
          setRequestTwoFa(true);

          // Show success message
          Swal.fire({
            icon: 'success',
            title: 'Contraseña restablecida',
            // eslint-disable-next-line max-len
            text: 'Tu contraseña fue restablecida con éxito. Ahora necesitamos validar tu identidad enviando un código único a tu correo o teléfono.',
            confirmButtonColor: '#f6c700',
            confirmButtonText: '<span class="dark-color-text">Continuar</span>'
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Contraseña restablecida',
            text: 'Tu contraseña fue restablecida con éxito',
            confirmButtonColor: '#f6c700',
            confirmButtonText: '<span class="dark-color-text">Aceptar</span>',
            didClose: () => {
              // Save both tokens in localStorage
              localStorage.setItem('aat', accessToken);
              localStorage.setItem('art', refreshToken);

              // Redirect to dashboard
              props.history.push('/');
            }
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;

          if (err.response.data === 'Unauthenticated') {
            msg = 'El enlace no es válido';
          }
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  // Validate password length
  function passValidLength(pass) {
    let ret = '';

    if (pass.length < 8) {
      ret = 'not-valid';
    } else if (pass.length >= 8 && pass.length <= 30) {
      ret = 'valid';
    } else if (pass.length > 30) {
      ret = 'not-valid';
    }

    return ret;
  }

  // Validate that password has min one uppercase and lowercase letter
  function passValidLowUpp(pass) {
    let ret = '';

    if (/[A-Z]/.test(pass) && /[a-z]/.test(pass)) {
      ret = 'valid';
    } else {
      ret = 'not-valid';
    }

    return ret;
  }

  // Validate that password has a number
  function passValidNum(pass) {
    let ret = '';

    if (/\d/.test(pass)) {
      ret = 'valid';
    } else {
      ret = 'not-valid';
    }

    return ret;
  }

  // Validate that password has special character
  function passValidSpecChar(pass) {
    let ret = '';

    if (/[$&+,:;=¿?@#|'<>.^*()%¡!-]/.test(pass)) {
      ret = 'valid';
    } else {
      ret = 'not-valid';
    }

    return ret;
  }

  if (!restoreToken) {
    return (
      <div id="_restore-password_">
        <div className="outer">
          <div className="middle">
            <div className="inner">
              <AirbagLoader />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="_restore-password_">
      <div className="outer">
        <div className="middle">
          <div className="inner">
            {requestTwoFa ? (
              <TwoFAWizard {...props} password={password1} />
            ) : (
              <form id="formContainer" onSubmit={restorePassword}>
                <div className="row">
                  <div id="logo-container">
                    <Link to="/login">
                      <img
                        id="logoBlack"
                        src={skin.logoBlack}
                        alt="Company logo"
                      />
                    </Link>
                  </div>
                  <h2 id="formTitle">Crea tu nueva contraseña</h2>
                  <div className="input-field col">
                    <input
                      id="pass1"
                      type={pass1Type}
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                    />
                    <Icon
                      icon="eye"
                      className={`eye-icon ${pass1Type}`}
                      onClick={() => {
                        if (pass1Type === 'password') {
                          setPass1Type('text');
                        } else {
                          setPass1Type('password');
                        }
                      }}
                    />
                    <label htmlFor="pass1" className="active">
                      Contraseña:
                    </label>
                  </div>
                  <div className="input-field col">
                    <input
                      id="pass2"
                      type={pass2Type}
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                    />
                    <Icon
                      icon="eye"
                      className={`eye-icon ${pass2Type}`}
                      onClick={() => {
                        if (pass2Type === 'password') {
                          setPass2Type('text');
                        } else {
                          setPass2Type('password');
                        }
                      }}
                    />
                    <label htmlFor="pass2" className="active">
                      Confirma tu contraseña:
                    </label>
                  </div>
                  <div className="col s12">
                    <ul>
                      <li>
                        <span
                          className={`spot ${passValidLength(password1)}`}
                        ></span>
                        Entre 8 y 30 caracteres
                      </li>
                      <li>
                        <span
                          className={`spot ${passValidLowUpp(password1)}`}
                        ></span>
                        Mínimo una letra minúscula y una mayúscula
                      </li>
                      <li>
                        <span
                          className={`spot ${passValidNum(password1)}`}
                        ></span>
                        Debe de incluir un número
                      </li>
                      <li>
                        <span
                          className={`spot ${passValidSpecChar(password1)}`}
                        ></span>
                        Debe de incluir uno de los siguientes:
                      </li>
                      <li className={passValidSpecChar(password1)}>
                        <p id="spec-char">{specCharsStr}</p>
                      </li>
                    </ul>
                  </div>
                  <div className="col s12 center-align">
                    <Button
                      variant="yellow-button"
                      loading={loading}
                      disabled={loading}
                      type="submit"
                    >
                      Aceptar
                    </Button>
                    <div id="terms-text">
                      Al iniciar sesión aceptas los
                      <br />
                      <a
                        href={`https://${skin.domain}/terms`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        términos y condiciones
                      </a>{' '}
                      y el{' '}
                      <a
                        href={`https://${skin.domain}/terms`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        aviso de privacidad
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(RestorePassword);
