import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosAuth from '../../../utils/axiosAuth';
import HeaderSection from '../../common/header-section/HeaderSection';
import SideModal from '../../common/side-modal/SideModal';
import Error from '../../common/error/Error';
import Icon from '../../common/airbag-icons/Icon';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import AddVehicle from './add-vehicle/AddVehicle';
import Filters from './filters/Filters';
import './CompanyVehicles.scss';

// Get first group in array or nothing if you are admin
function getGroupFromArray(authRole, arr) {
  let ret = '';

  // No default group selected to admins
  if (authRole === 'admin') {
    return ret;
  }

  if (arr && typeof arr === 'object' && arr.length) {
    // Assign first group in array
    ret = arr[0].id;
  }

  return ret;
}

function CompanyVehicles(props) {
  const { user } = props;
  const { groups } = user;
  const lastVehicleRef = useRef(null);
  const last = useRef('');
  const keepLooking = useRef(true);
  const loading = useRef(false);
  const [error, setError] = useState(null);
  const [vehicles, setVehicles] = useState(null);
  const [newVehicleOpen, setNewVehicleOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState({ key: 'plate', order: 'desc' });
  const [group, setGroup] = useState(getGroupFromArray(user.authRole, groups));

  // Search for vehicle with Algolia or group. Also makes first query on load;
  useEffect(() => {
    fetchVehicles(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, group]);

  useEffect(() => {
    const onScroll = (e) => {
      // If reference to last vehicle row exists and its in view and
      // fetchVehicles keeps returning result
      if (
        vehicles &&
        lastVehicleRef &&
        lastVehicleRef.current &&
        isLastVehicleInViewport() &&
        !loading.current &&
        keepLooking.current &&
        !query
      ) {
        fetchVehicles(false);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles, lastVehicleRef, loading.current, keepLooking, query]);

  function fetchVehicles(cleanVehiclesArray) {
    // Loader inside table
    if (cleanVehiclesArray) setVehicles(null);
    // Loader inside loop list
    loading.current = true;

    /* Build query parameters */
    const queryParameters = {};
    // Algolia Search
    if (query) queryParameters.query = query;
    // Pagination
    if (last.current && !cleanVehiclesArray) {
      queryParameters.last = last.current;
    }
    // Group
    if (group) queryParameters.group = group;

    // Get vehicles from server
    axiosAuth
      .get(`/api/vehicles`, {
        params: queryParameters
      })
      .then((res) => {
        const { vehicles: vehiclesRes } = res.data;

        // Save last vehicle id for pagination
        let lastVehicleId = null;
        if (vehiclesRes.length) {
          lastVehicleId = vehiclesRes[vehiclesRes.length - 1].id;
        }

        // Here you can process array's information
        const activeVehicles = vehiclesRes;

        if (query || cleanVehiclesArray) {
          // If there is a query, set full results to vehicles's array
          setVehicles(sortArrayByKey(activeVehicles, sort.key, sort.order));
        } else {
          // Append new vehicles response to vehicles's array
          const vehiclesCopy = vehicles
            ? [...vehicles, ...activeVehicles]
            : [...activeVehicles];
          // Order by key and apply current filter
          setVehicles(sortArrayByKey(vehiclesCopy, sort.key, sort.order));
        }

        // Stop or resume infinite scrolling | The < 20 is hardcoded
        // and must be changed (results per page)
        if (!vehiclesRes.length || vehiclesRes.length < 20) {
          keepLooking.current = false;
          last.current = '';
        } else {
          keepLooking.current = true;
          // Save last driver's id to continue query
          last.current = lastVehicleId;
        }

        // Stop loader
        loading.current = false;
      })
      .catch((err) => {
        console.log(err);
        loading.current = false;
        setError(err);
      });
  }

  function isLastVehicleInViewport() {
    // Set an offset to trigger action before target element is in view
    const offset = 100;

    const top = lastVehicleRef.current.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  }

  function sortArrayByKey(array, key, order) {
    if (typeof array === 'object') {
      const arrayCopy = [...array];

      if (order === 'desc') {
        arrayCopy.sort(function compare(a, b) {
          if (a[key] < b[key]) return -1;
          if (a[key] > b[key]) return 1;
          return 0;
        });
      } else if (order === 'asc') {
        arrayCopy.sort(function compare(a, b) {
          if (b[key] < a[key]) return -1;
          if (b[key] > a[key]) return 1;
          return 0;
        });
      }

      return arrayCopy;
    }

    return array;
  }

  function renderVehiclesTable() {
    // Show loader
    if (!vehicles) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No vehicles
    if (!vehicles.length) {
      return (
        <div id="no-data">
          <Icon icon="crash" id="error-icon" />
          <p>Sin vehículos</p>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="vehicles-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>
                <span
                  className="pointer"
                  onClick={() => {
                    setVehicles(
                      sortArrayByKey(
                        vehicles,
                        'plate',
                        sort.order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setSort({
                      key: 'plate',
                      order: sort.order === 'asc' ? 'desc' : 'asc'
                    });
                  }}
                >
                  Placas
                  {sort.key === 'plate' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-down' : 'chevron-up'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              <th>ID / Nombre</th>
              <th>Marca / Modelo</th>
              <th>Conductor</th>
              <th>Tipo</th>
            </tr>
          </thead>
          <tbody>
            {vehicles.map((vehicle, i) => (
              <tr
                key={vehicle.id}
                className="vehicle-row"
                ref={i === vehicles.length - 1 ? lastVehicleRef : null}
              >
                <td>
                  <span className="pos-index">{i + 1}.</span>
                  <Link to={`/vehiculo?vehicleId=${vehicle.id}`}>
                    {vehicle.plate ? vehicle.plate : ''}
                  </Link>
                </td>
                <td>
                  <Link to={`/vehiculo?vehicleId=${vehicle.id}`}>
                    {vehicle.uid ? vehicle.uid : ''}
                  </Link>
                </td>
                <td>
                  {vehicle.brand ? vehicle.brand.toUpperCase() : '-'} /{' '}
                  {vehicle.model ? vehicle.model : '-'}
                </td>
                <td>{vehicle.driver ? vehicle.driver.fullName : ''}</td>
                <td>
                  {vehicle.vehicleTypeName ? vehicle.vehicleTypeName : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  // API error handler
  if (error) return <Error error={error} />;

  return (
    <div id="_company-vehicles_">
      <HeaderSection
        title="Vehículos"
        // eslint-disable-next-line max-len
        body="Da de alta tus vehículos y asígnalos a tus operadores."
      />
      <Filters
        query={query}
        setQuery={setQuery}
        groups={groups}
        group={group}
        setGroup={setGroup}
        setNewVehicleOpen={setNewVehicleOpen}
        user={user}
      />
      <div className="row no-margin">
        <div className="col s12">{renderVehiclesTable()}</div>
      </div>

      {/* ADD NEW VEHICLE */}
      <SideModal isOpen={newVehicleOpen} setIsOpen={setNewVehicleOpen}>
        {newVehicleOpen && (
          <AddVehicle
            isOpen={newVehicleOpen}
            setIsOpen={setNewVehicleOpen}
            fetchVehicles={fetchVehicles}
            user={props.user}
            company={props.company}
          />
        )}
      </SideModal>
    </div>
  );
}

export default CompanyVehicles;
