import React from 'react';
import dayjs from 'dayjs';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import numeral from 'numeral';
import ReactTooltip from 'react-tooltip';
import M from 'materialize-css';
import { saveAs } from 'file-saver';
import activitiesDefinitions from '../../../utils/activitiesDefinitions';
import roundNumber from '../../../utils/roundNumber';
import axiosAuth from '../../../utils/axiosAuth';
import Icon from '../airbag-icons/Icon';
import AirbagLoader from '../airbag-loader/AirbagLoader';
import './ActivitiesList.scss';

function ActivitiesList(props) {
  const {
    activities,
    setFocus,
    applyBoundingBox,
    lastActivityRef,
    setType,
    mediaLoading,
    userId
  } = props;

  function renderDescription(act) {
    const ret = [];

    // Delivery data
    if (act.type === 'entrega') {
      if (act.wasDeliverySuccess) {
        ret.push(
          <p key={`${act.id}-entrega-success`}>
            Se registró una entrega <span className="ok">exitosa</span>.
          </p>
        );
      } else {
        ret.push(
          <p key={`${act.id}-entrega-unsuccess`}>
            Entrega <span className="not-ok">no exitosa</span>.
            <br />
            {!act.message ? act.deliveryFailureReason : ''}
          </p>
        );
      }
    }

    // Handle aux activities
    if (act.type === 'aux-activity') {
      // Get subtype
      const slug = act.metadata ? act.metadata.type : '';

      // Handle gas
      if (slug === 'gas') {
        const { type, cost, liters, mileageKm } = act.gas_details;

        // Handle gas type
        let gasType = 'Diésel';
        if (type === 'green') {
          gasType = 'Magna';
        } else if (type === 'red') {
          gasType = 'Premium';
        } else if (type === 'gas') {
          gasType = 'Gas';
        }

        // Round values
        const roundedCost = numeral(roundNumber(cost)).format('0,0');
        const roundedLt = numeral(roundNumber(liters)).format('0,0');
        const roundedMileage = numeral(roundNumber(mileageKm)).format('0,0');

        ret.push(
          <p key={`${act.id}-gas`}>
            ${roundedCost} por {roundedLt} litros de {gasType}{' '}
            {mileageKm ? `(${roundedMileage} km)` : ''}
          </p>
        );
      }

      if (slug === 'load-handle') {
        ret.push(
          <p key={`${act.id}-lh`}>
            {act.handleArriving ? 'Iniciando carga' : 'Terminando carga'}
          </p>
        );
      }

      if (slug === 'UNLOAD_HANDLE') {
        ret.push(
          <p key={`${act.id}-ulh`}>
            {act.handleArriving ? 'Iniciando descarga' : 'Terminando descarga'}
          </p>
        );
      }
    }

    // Other
    if (act.type === 'other') {
      if (act.otherType) {
        let otherMessage = '';
        if (act.otherType === 'MAINTENANCE') {
          otherMessage = 'Limpieza y mantenimiento';
        }

        ret.push(<p key={`${act.id}-other`}>{otherMessage}</p>);
      }
    }

    // Custom message
    if (act.message) {
      ret.push(<p key={`${act.id}-message`}>{act.message}</p>);
    }

    return <div className="desc">{ret}</div>;
  }

  function scrollToMap() {
    if (document.getElementById('here-map-anchor')) {
      const element = document.getElementById('here-map-anchor');
      const headerOffset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }

  function downloadPhoto(activity) {
    if (activity && activity.photos && activity.photos.length) {
      const photo = activity.photos[0];
      if (photo) {
        const fileName = `${activity.id}-1.jpg`;
        const linkSource = `data:image/jpeg;base64,${photo.base64.base64Data}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    }
  }

  // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
  function base64toBlob(base64String, sliceSize = 512) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
      type: 'application/zip'
    });
    return blob;
  }

  function downloadAll(activity) {
    axiosAuth
      .get(`/api/activities/activity/download-zip`, {
        params: {
          userId,
          activityId: activity.id
        }
      })
      .then((res) => {
        const { zipFileBase64 } = res.data;
        // Convert base64 to blob for file download
        const blobUrl = URL.createObjectURL(base64toBlob(zipFileBase64));
        saveAs(blobUrl, `${activity.id}.zip`);
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: 'Tuvimos un error al descargar las imágenes',
          classes: 'error-toast'
        });
      });
  }

  function renderCarousel(act) {
    const { photos } = act;

    if (photos && photos.length) {
      if (!mediaLoading || photos[0].base64) {
        return (
          <div className="activity-photos">
            <Carousel
              showThumbs={false}
              showIndicators={photos.length > 1}
              renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && (
                  <span
                    onClick={onClickHandler}
                    style={{
                      position: 'absolute',
                      zIndex: 2,
                      top: 'calc(50% - 15px)',
                      width: 30,
                      height: 30,
                      cursor: 'pointer',
                      left: 15
                    }}
                  >
                    <FontAwesomeIcon className="arrow" icon="arrow-left" />
                  </span>
                )
              }
              renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                  <span
                    onClick={onClickHandler}
                    style={{
                      position: 'absolute',
                      zIndex: 2,
                      top: 'calc(50% - 15px)',
                      width: 30,
                      height: 30,
                      cursor: 'pointer',
                      right: 15
                    }}
                  >
                    <FontAwesomeIcon className="arrow" icon="arrow-right" />
                  </span>
                )
              }
              renderIndicator={(onClickHandler, isSelected, index) => {
                if (isSelected) {
                  return (
                    <li
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: 20,
                        display: 'inline-block',
                        margin: '0 8px',
                        border: '1px solid #000',
                        background: '#f6c700'
                      }}
                    />
                  );
                }
                return (
                  <li
                    style={{
                      background: '#fff',
                      width: 10,
                      height: 10,
                      borderRadius: 20,
                      display: 'inline-block',
                      margin: '0 8px',
                      border: '1px solid #000'
                    }}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                  />
                );
              }}
            >
              {photos.map((photo, i) => (
                <div key={`${act.id}-${i}`}>
                  {photo.base64 ? (
                    <img
                      key={photo.fileName}
                      className="act-photo"
                      alt=""
                      src={`data:image/png;base64,${photo.base64.base64Data}`}
                    />
                  ) : null}
                </div>
              ))}
            </Carousel>
          </div>
        );
      } else {
        return <AirbagLoader />;
      }
    }

    return null;
  }

  function renderVoicenotes(act) {
    if (act.voicenotes && act.voicenotes.length) {
      if (!mediaLoading || act.voicenotes[0].base64) {
        const vn = act.voicenotes[0];

        return (
          <div className="audio-wrapper">
            <audio controls>
              <source
                src={`data:audio/mp3;base64,${vn.base64String}`}
                type="audio/mp3"
              />
              Tu navegador no permite reproducir audios.
            </audio>
          </div>
        );
      } else {
        return <AirbagLoader />;
      }
    }

    return null;
  }

  function renderActivities() {
    if (!activities || !activities.length) {
      return <p id="no-data">Sin registros</p>;
    }

    return activities.map((act, idx) => {
      // Get log type
      let key = act.type;
      // Check for log sub-type in metadata
      if (act.metadata && act.metadata.type) key = act.metadata.type;
      // Get log info
      const actInfo = activitiesDefinitions.find((l) => l.slug === key);

      if (!actInfo) {
        return null;
      }

      return (
        <div
          className="activity-row"
          key={`${act.id}-container`}
          ref={lastActivityRef}
        >
          <div className="activity-info">
            <p>
              <span className="pos">{idx + 1}.</span>
              <span
                className="act-tag"
                style={{ background: actInfo.colorLight, color: actInfo.color }}
              >
                <Icon
                  className="act-icon"
                  icon={actInfo.airbagIcon}
                  stroke={actInfo.color}
                />
                {actInfo.name}
              </span>
              <span>{dayjs(act.created).format('DD [de] MMMM HH:mm')}</span>
            </p>
            {renderDescription(act)}
          </div>
          <div className="cta-filters">
            {setFocus && (
              <FontAwesomeIcon
                icon={['fal', 'map-location-dot']}
                aria-hidden="false"
                data-tip="Ver en mapa"
                className="cta-icon"
                onClick={() => {
                  setFocus({ lat: act.position.lat, lon: act.position.lon });
                  if (setType) {
                    setType(key);
                  }
                  // Disable bounding box in map to focus on selected point
                  if (applyBoundingBox) {
                    applyBoundingBox.current = false;
                  }
                  // Scroll to map
                  scrollToMap();
                }}
              />
            )}
            {act.photos && act.photos.length === 1 ? (
              <FontAwesomeIcon
                icon={['fal', 'download']}
                aria-hidden="false"
                data-tip="Descargar imagen"
                className="cta-icon"
                onClick={() => {
                  downloadPhoto(act);
                }}
              />
            ) : null}
            {act.photos && act.photos.length > 1 ? (
              <FontAwesomeIcon
                icon={['fal', 'file-export']}
                aria-hidden="false"
                data-tip="Descargar todas las imágenes"
                className="cta-icon"
                onClick={() => {
                  downloadAll(act);
                }}
              />
            ) : null}
            <ReactTooltip />
          </div>
          {renderCarousel(act)}
          {renderVoicenotes(act)}
        </div>
      );
    });
  }

  return <div id="_activities-list_">{renderActivities()}</div>;
}

export default ActivitiesList;
