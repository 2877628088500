/* eslint max-len: 0 */

import React from 'react';

function Break(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 15"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M1.37109 9.65797L5.27881 8.65505C5.38003 8.62763 5.48726 8.63223 5.58577 8.66821C5.68428 8.70418 5.76924 8.76978 5.82896 8.85598C5.88869 8.94219 5.92026 9.04477 5.91934 9.14964C5.91841 9.25451 5.88504 9.35652 5.82381 9.44166L3.31652 12.9708C3.25625 13.0561 3.22368 13.1578 3.22322 13.2622C3.22277 13.3666 3.25446 13.4686 3.31398 13.5543C3.37351 13.6401 3.45798 13.7054 3.55594 13.7415C3.6539 13.7776 3.76059 13.7827 3.86152 13.756L7.6056 12.7945" />
      <path d="M14.0326 0.900024H18.5232C18.64 0.900256 18.754 0.936258 18.8497 1.00319C18.9454 1.07012 19.0183 1.16476 19.0587 1.27439C19.099 1.38401 19.1047 1.50336 19.0752 1.61636C19.0456 1.72936 18.9822 1.8306 18.8934 1.90645L15.2132 5.04302C15.1242 5.11875 15.0607 5.21995 15.031 5.33295C15.0014 5.44595 15.0072 5.56532 15.0475 5.67496C15.0879 5.78459 15.1609 5.87921 15.2567 5.94605C15.3525 6.01289 15.4665 6.04873 15.5833 6.04874H21.3711" />
      <path d="M6.88086 6.43644L11.269 4.41165C11.3832 4.35889 11.5108 4.34244 11.6346 4.36453C11.7585 4.38662 11.8725 4.44617 11.9614 4.53516C12.0504 4.62415 12.1098 4.73827 12.1318 4.86213C12.1538 4.98598 12.1372 5.1136 12.0844 5.22775L9.90225 9.95226C9.84939 10.0664 9.83283 10.194 9.85481 10.3179C9.87679 10.4417 9.93625 10.5559 10.0252 10.6448C10.1141 10.7338 10.2281 10.7934 10.352 10.8155C10.4758 10.8376 10.6034 10.8211 10.7176 10.7684L16.3748 8.15853" />
    </svg>
  );
}

export default Break;
