import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import qs from 'qs';
import M from 'materialize-css';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import axiosAuth from '../../../utils/axiosAuth';
import vehicleTypes from '../../../utils/vehicleTypes';
import fuelTypes from '../../../utils/fuelTypes';
import SideModal from '../../common/side-modal/SideModal';
import MessageCourtain from '../../common/message-courtain/MessageCourtain';
import Error from '../../common/error/Error';
import Button from '../../common/button/Button';
import Icon from '../../common/airbag-icons/Icon';
import AirbagLoaderLines from '../../common/airbag-loader/AirbagLoaderLines';
import HereMap from '../../common/here-map/HereMap';
import EditVehicle from './edit-vehicle/EditVehicle';
import VehicleTrips from './vehicle-trips/VehicleTrips';
import VehicleGeneralScores from './general-scores/VehicleGeneralScores';
import IndividualScores from './individual-scores/IndividualScores';
import VehicleStatistics from './vehicle-statistics/VehicleStatistics';
import './CompanyVehicle.scss';

function CompanyVehicle(props) {
  const { user } = props;
  // Get all data after mian url
  const queryString = window.location.search;
  // Get all params
  const urlParams = new URLSearchParams(queryString);
  const { history, location } = props;
  const vehicleId = urlParams.get('vehicleId');
  const [error, setError] = useState(null);
  const [driver, setDriver] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [editVehicleOpen, setEditVehicleOpen] = useState(false);
  const [section, setSection] = useState('trips');
  const [lastLocation, setLastLocation] = useState(null);

  useEffect(() => {
    // Get vehicle info
    fetchVehicle();
    fetchLastLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle tab location
  useEffect(() => {
    const tab = qs.parse(location.search, { ignoreQueryPrefix: true }).tab;
    // If tab has valid value, set it. Else, fallback to info tab
    if (
      tab === 'trips' ||
      tab === 'general-scores' ||
      tab === 'drivers-scores' ||
      tab === 'statistics'
    ) {
      setSection(tab);
    } else setSection('trips');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  function renderSection() {
    if (section === 'trips') {
      return <VehicleTrips vehicleId={vehicleId} />;
    } else if (section === 'general-scores') {
      return <VehicleGeneralScores vehicleId={vehicleId} />;
    } else if (section === 'drivers-scores') {
      return <IndividualScores vehicleId={vehicleId} />;
    } else if (section === 'statistics') {
      return <VehicleStatistics vehicleId={vehicleId} />;
    }
  }

  function fetchLastLocation() {
    // Search for last location
    axiosAuth
      .get(`/api/vehicles/${vehicleId}/lastLocation`)
      .then((res) => {
        const { location } = res.data;
        setLastLocation(location);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }

  function disableVehicle(e) {
    setLoading(true);

    // Make request
    axiosAuth
      .post('/api/vehicles/disable', {
        vehicleId: vehicle.id
      })
      .then((res) => {
        M.toast({
          html: 'Vehículo eliminado con éxito',
          classes: 'success-toast'
        });
        history.push(`/vehiculos`);
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  function fetchVehicle() {
    // Search for vehicle
    axiosAuth
      .get(`/api/vehicles/${vehicleId}`)
      .then((res) => {
        const { vehicle, driver } = res.data;
        setVehicle(vehicle);
        setDriver(driver);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }

  // Function to determine the type of vehicle
  // based on the code stored in firestore
  function translateVehicleType(typeCode) {
    let vehicleType = '';
    vehicleTypes.forEach((type) => {
      if (type.value === typeCode) vehicleType = type.name;
    });
    return vehicleType;
  }

  // Function to determine the type of fuel based
  // on the code stored in firestore
  function translateFuelType(fuelType) {
    let fuel = '';
    fuelTypes.forEach((type) => {
      if (type.value === fuelType) fuel = type.name;
    });
    return fuel;
  }

  function formatLastLocation(lastLocation) {
    if (lastLocation) {
      return {
        lat: lastLocation.lat,
        lng: lastLocation.lon
      };
    }
    return null;
  }

  function formatInsuranceExp(expiration) {
    if (expiration) {
      return moment(expiration).format('DD [de] MMMM, YYYY');
    }
    return '-';
  }

  // API error handler
  if (error) return <Error error={error} />;

  // Not found
  if (!vehicle) {
    return (
      <MessageCourtain
        icon="car-crash"
        message="No se encontró el vehículo"
        title="¡Oops!"
        link="/vehiculos"
      />
    );
  }

  if (vehicle.status === 'inactive') {
    return (
      <MessageCourtain
        icon="car-crash"
        // eslint-disable-next-line max-len
        message="Vehículo desahiblitado. Pónte en contacto con soporte para reestablecerlo"
        title="¡Oops!"
        link="/vehiculos"
      />
    );
  }

  // Info loading
  if (!Object.keys(vehicle).length) {
    return <AirbagLoaderLines />;
  }

  return (
    <React.Fragment>
      <div id="_vehicle_">
        <div className="right-align">
          <Icon
            icon="settings"
            className="action-icon"
            onClick={() => {
              setEditVehicleOpen(true);
              // Google Analytics
              ReactGA.event({
                category: 'vehicles',
                action: 'open_edit_vehicle'
              });
              // Posthog
              posthog.capture('open_edit_vehicle', {
                category: 'vehicles',
                isCustomEvent: true
              });
            }}
          />
        </div>
        <h5 id="main-t">Información de Vehículo</h5>
        <div className="row no-margin">
          <div className="col s12">
            <div className="row info-table">
              <div className="col s12 tableHeader">
                <p className="table-title">Información general</p>
              </div>
              <div className="col s6 m3">
                <p className="subtitle">Nombre</p>
                <p className="tableValue">{vehicle.uid ? vehicle.uid : '-'}</p>
              </div>
              <div className="col s6 m6">
                <p className="subtitle">Marca / Modelo</p>
                <p className="tableValue">
                  {vehicle.brand ? vehicle.brand.toUpperCase() : '-'} /{' '}
                  {vehicle.model ? vehicle.model : '-'}
                </p>
              </div>
              <div className="col s6 m3">
                <p className="subtitle">Tipo de vehículo</p>
                <p className="tableValue">
                  {vehicle.vehicleType
                    ? translateVehicleType(vehicle.vehicleType)
                    : '-'}
                </p>
              </div>
              <div className="col s6 m3 l3">
                <p className="subtitle">Placas</p>
                <p className="tableValue">
                  {vehicle.plate ? vehicle.plate : '-'}
                </p>
              </div>
              <div className="col s4 m3 l3">
                <p className="subtitle">Combustible</p>
                <p className="tableValue">
                  {vehicle.fuel ? translateFuelType(vehicle.fuel) : '-'}
                </p>
              </div>
              <div className="col s4 m3 l3">
                <p className="subtitle">Altura</p>
                <p className="tableValue">
                  {vehicle.height ? vehicle.height + ' m' : '-'}
                </p>
              </div>
              <div className="col s4 m3 l3">
                <p className="subtitle">Color</p>
                <p className="tableValue">
                  {vehicle.color ? vehicle.color : '-'}
                </p>
              </div>
              <div className="col s12 m6 l6">
                <p className="subtitle">NIV</p>
                <p className="tableValue">{vehicle.niv ? vehicle.niv : '-'}</p>
              </div>
              <div className="col s12 m6 l6">
                <p className="subtitle">Conductor</p>
                <p className="tableValue">
                  {driver ? (
                    <Link to={`/conductor?driverId=${driver.id}`}>
                      <span id="driver-link">
                        {driver.name} {driver.lastName}
                      </span>
                    </Link>
                  ) : (
                    '-'
                  )}
                </p>
              </div>
              <div className="col s12">
                <p className="subtitle">Descripción</p>
                <p className="tableValue">
                  {vehicle.description ? vehicle.description : '-'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row no-margin">
          <div className="col s12 l5">
            <div id="map">
              <HereMap
                center={{
                  lat: lastLocation ? lastLocation.lat : 19.432541,
                  lng: lastLocation ? lastLocation.lon : -99.133403
                }}
                zoom={13}
                height={341}
                lastLocationMarker={formatLastLocation(lastLocation)}
              />
            </div>
          </div>
          <div className="col s12 l7">
            <div className="row info-table">
              <div className="col s12 tableHeader">
                <p className="table-title">Póliza de Seguro</p>
              </div>
              {vehicle.insurance ? (
                <>
                  <div className="col s12">
                    <p className="subtitle">Número</p>
                    <p className="tableValue insurance">
                      {vehicle.insurance.number
                        ? vehicle.insurance.number
                        : '-'}
                    </p>
                  </div>
                  <div className="col s6">
                    <p className="subtitle">Aseguradora</p>
                    <p className="tableValue insurance">
                      {vehicle.insurance.brand
                        ? vehicle.insurance.brand.toUpperCase()
                        : '-'}
                    </p>
                  </div>
                  <div className="col s6">
                    <p className="subtitle">Fecha de expiración</p>
                    <p className="tableValue insurance">
                      {formatInsuranceExp(vehicle.insurance.expiration)}
                    </p>
                  </div>
                </>
              ) : (
                <div className="col s12">
                  <p className="tableValue">Sin información</p>
                </div>
              )}
            </div>
          </div>
          <div className="col s12">
            <div id="vehicle-menu">
              <div id="menu-container">
                <span
                  onClick={() => {
                    setSection('trips');
                    history.push(
                      // eslint-disable-next-line max-len
                      `${location.pathname}?vehicleId=${vehicleId}&tab=trips`
                    );

                    // Google Analytics
                    ReactGA.event({
                      category: 'vehicles',
                      action: 'vehicle_trips_tab'
                    });
                    // Posthog
                    posthog.capture('vehicle_trips_tab', {
                      category: 'vehicles',
                      isCustomEvent: true
                    });
                  }}
                  className={classNames('sec', {
                    active: section === 'trips'
                  })}
                >
                  <span className="sec-content">
                    <Icon icon="routes" className="sect-icon" />
                    Viajes realizados
                  </span>
                </span>
                <span
                  onClick={() => {
                    setSection('statistics');
                    history.push(
                      // eslint-disable-next-line max-len
                      `${location.pathname}?vehicleId=${vehicleId}&tab=statistics`
                    );

                    // Google Analytics
                    ReactGA.event({
                      category: 'vehicles',
                      action: 'vehicle_metrics_tab'
                    });
                    // Posthog
                    posthog.capture('vehicle_metrics_tab', {
                      category: 'vehicles',
                      isCustomEvent: true
                    });
                  }}
                  className={classNames('sec', {
                    active: section === 'statistics'
                  })}
                >
                  <span className="sec-content">
                    <Icon icon="graph" className="sect-icon" />
                    Estadísticas
                  </span>
                </span>
                <span
                  onClick={() => {
                    setSection('general-scores');
                    history.push(
                      // eslint-disable-next-line max-len
                      `${location.pathname}?vehicleId=${vehicleId}&tab=general-scores`
                    );

                    // Google Analytics
                    ReactGA.event({
                      category: 'vehicles',
                      action: 'vehicle_scores_tab'
                    });
                    // Posthog
                    posthog.capture('vehicle_scores_tab', {
                      category: 'vehicles',
                      isCustomEvent: true
                    });
                  }}
                  className={classNames('sec', {
                    active: section === 'general-scores'
                  })}
                >
                  <span className="sec-content">
                    <Icon icon="ranking" className="sect-icon" />
                    Calificación
                  </span>
                </span>
                <span
                  onClick={() => {
                    setSection('drivers-scores');
                    history.push(
                      // eslint-disable-next-line max-len
                      `${location.pathname}?vehicleId=${vehicleId}&tab=drivers-scores`
                    );

                    // Google Analytics
                    ReactGA.event({
                      category: 'vehicles',
                      action: 'vehicle_scores_by_user_tab'
                    });
                    // Posthog
                    posthog.capture('vehicle_scores_by_user_tab', {
                      category: 'vehicles',
                      isCustomEvent: true
                    });
                  }}
                  className={classNames('sec', {
                    active: section === 'drivers-scores'
                  })}
                >
                  <span className="sec-content">
                    <Icon icon="profile" className="sect-icon" />
                    Análisis por conductor
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderSection()}

      {user.authRole === 'admin' && vehicle.status === 'active' ? (
        <div>
          <Button
            className="right"
            variant="red-button"
            loading={loading}
            disabled={loading}
            onClick={() => {
              disableVehicle();
            }}
          >
            Eliminar Vehículo
          </Button>
        </div>
      ) : null}

      {/* EDIT VEHICLE */}
      <SideModal isOpen={editVehicleOpen} setIsOpen={setEditVehicleOpen}>
        {editVehicleOpen && (
          <EditVehicle
            vehicle={vehicle}
            user={props.user}
            company={props.company}
            vehicleDriver={driver}
            fetchVehicle={fetchVehicle}
            setEditVehicleOpen={setEditVehicleOpen}
          />
        )}
      </SideModal>
    </React.Fragment>
  );
}

export default withRouter(CompanyVehicle);
