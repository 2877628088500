import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosAuth from '../../../utils/axiosAuth';
import LoaderInner from '../loader-inner/LoaderInner';
import Error from '../error/Error';
import DriverHeartbeats from '../driver-heartbeats/DriverHeartbeats';
import DEFAULT_SCHEDULE from '../../../utils/defaultSchedule';
import lastSeen from '../../../utils/lastSeen';
import {
  sanitizeAppStatus,
  phoneNumberFormatter
} from '../../../utils/sanitizers';
import DriverNotifications from '../driver-notifications/DriverNotifications';
import './DriverQuickView.scss';

function Spinner() {
  return (
    <div className="valign-wrapper" id="_hearbeats-loading_">
      <div className="preloader-wrapper small active">
        <div className="spinner-layer">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div>
          <div className="gap-patch">
            <div className="circle"></div>
          </div>
          <div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DriverQuickView(props) {
  const { driverId } = props;
  const [error, setError] = useState(null);
  const [driver, setDriver] = useState({});
  const [heartbeats, setHeartbeats] = useState(null);
  const [loadingHeartbeats, setLoadingHeartbeats] = useState(true);

  useEffect(() => {
    // Fetch Raxel heartbeats info
    axiosAuth
      .get(`/api/users/heartbeats`, {
        params: { driverId }
      })
      .then((res) => {
        const { heartbeats } = res.data;
        setHeartbeats(heartbeats);
        setLoadingHeartbeats(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingHeartbeats(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Get driver's info
    axiosAuth
      .get(`/api/users/info`, {
        params: { driverId, withLocation: true }
      })
      .then((res) => {
        const { user } = res.data;

        if (user) {
          // Check if driver has default schedule
          if (Object.keys(user).length && !user.schedule) {
            user.schedule = DEFAULT_SCHEDULE;
          }

          // Set driver
          setDriver(user);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderECType(ec) {
    if (ec) {
      if (ec.type === 'cellphone') return 'Celular';
      if (ec.type === 'landphone') return 'Línea fija';
    }
    return 'Sin especificar';
  }

  // Does driver works today?
  const schedule =
    driver.schedule && driver.schedule[dayjs().isoWeekday() - 1].active
      ? driver.schedule[dayjs().isoWeekday() - 1]
      : null;
  // Does driver works tomorrow?
  const scheduleTomorrow =
    driver.schedule &&
    driver.schedule[dayjs().add(1, 'd').isoWeekday() - 1].active
      ? driver.schedule[dayjs().add(1, 'd').isoWeekday() - 1]
      : null;

  function renderDriverStatus(driver) {
    const { firstLogin, isResting, appStatus, isSDKEnabled, status, location } =
      driver;

    // Discard disabled drivers
    if (status === 'inactive') {
      return <span className="status grey-s">Deshabilitado</span>;
    }

    // First validation, check if user has logged in
    if (firstLogin) {
      return <span className="status red-s">Sin configurar</span>;
    }

    // Handle users that are resting only for TMS
    if (isResting) {
      return <span className="status grey-s">En descanso</span>;
    }

    // Handle if user is lost
    const lastSeenSince =
      location && location.dateTime
        ? dayjs().diff(dayjs(location.dateTime), 'm')
        : null;
    if (driver.appStatus === 'active' && lastSeenSince >= 7200) {
      return <span className="status red-s">Perdido</span>;
    }

    // Default is active or inactive
    return (
      <span
        className={classNames('status', {
          'green-s': appStatus === 'active',
          'grey-s': appStatus === 'inactive'
        })}
      >
        {sanitizeAppStatus(appStatus, isSDKEnabled)}
      </span>
    );
  }

  // API error handler
  if (error) return <Error error={error} />;

  if (!driver || !Object.keys(driver).length) return <LoaderInner />;

  return (
    <div id="_driver-quick-view_">
      <div className="row">
        <div className="col s12" id="name">
          <h5>
            <Link to={`/conductor?driverId=${driver.id}`}>
              <span>
                {driver.fullName} <FontAwesomeIcon icon="external-link-alt" />
              </span>
            </Link>
          </h5>
          <p id="last-seen">
            <span>Conexión más reciente hace</span>{' '}
            {driver.location.dateTime
              ? lastSeen(driver.location.dateTime)
              : '-'}
          </p>
          <div id="driver-status">{renderDriverStatus(driver)}</div>
        </div>
      </div>
      <div className="row big-label">
        <div className="col s12">
          <p>Perfil</p>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m6">
          <label>Email</label>
          <p className="fake-input">{driver.email ? driver.email : '-'}</p>
        </div>
        <div className="col s12 m6">
          <label>Teléfono</label>
          <p className="fake-input">{driver.phone}</p>
        </div>
        {driver.phoneModel && (
          <div className="col s12 m6">
            <label>Modelo</label>
            <p className="fake-input">{driver.phoneModel}</p>
          </div>
        )}
        {driver.appInfo && driver.appInfo.versionApp && (
          <div className="col s12 m6">
            <label>Versión de app</label>
            <p className="fake-input">
              {`${driver.appInfo.versionApp} (${driver.appInfo.versionCode})`}
            </p>
          </div>
        )}
        <div className="col s12 m6">
          <label>ID</label>
          <p className="fake-input">
            {driver.internalCompanyId ? driver.internalCompanyId : '-'}
          </p>
        </div>
        <div className="col s12 m6">
          <label>Puntos</label>
          <p className="fake-input">{driver.coins}</p>
        </div>
      </div>
      <div className="row big-label">
        <div className="col s12">
          <p>Contacto de emergencia</p>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m6">
          <label>Nombre</label>
          <p className="fake-input">
            {driver.emergencyContact && driver.emergencyContact.name
              ? driver.emergencyContact.name
              : '-'}
          </p>
        </div>
        <div className="col s12 m6">
          <label>Teléfono</label>
          <p className="fake-input">
            {driver.emergencyContact && driver.emergencyContact.phone
              ? phoneNumberFormatter(driver.emergencyContact.phone)
              : '-'}
          </p>
        </div>
        <div className="col s12 m6">
          <label>Tipo de número</label>
          <p className="fake-input">{renderECType(driver.emergencyContact)}</p>
        </div>
      </div>
      <div className="row big-label">
        <div className="col s12">
          <p>
            Horario laboral{' '}
            <span className="big-label-indicator">
              {driver.schedule ? (
                driver.schedule.timezone ? (
                  <span id="tz">{driver.schedule.timezone}</span>
                ) : null
              ) : null}
            </span>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m6">
          <label>Hoy {dayjs().format('dddd')}</label>
          <p className="fake-input">
            {schedule ? `${schedule.start} - ${schedule.end}` : 'N/A'}
          </p>
        </div>
        <div className="col s12 m6">
          <label>Mañana {dayjs().add(1, 'd').format('dddd')}</label>
          <p className="fake-input">
            {scheduleTomorrow
              ? `${scheduleTomorrow.start} - ${scheduleTomorrow.end}`
              : 'N/A'}
          </p>
        </div>
      </div>
      <div className="row big-label">
        <div className="col s12">
          <p>Sistema</p>
        </div>
      </div>
      <div className="row">
        <div className="col s12 m6">
          <label>Primer ingreso</label>
          <p className="fake-input">
            {driver.firstLoginData
              ? dayjs(driver.firstLoginDate).format('D [de] MMMM, YYYY HH:mm')
              : '-'}
          </p>
        </div>
        <div className="col s12 m6">
          <label>Ingreso más reciente</label>
          <p className="fake-input">
            {driver.lastLogin
              ? dayjs(driver.lastLogin).format('D [de] MMMM, YYYY HH:mm')
              : '-'}
          </p>
        </div>
        <div className="col s12 m6">
          <label>Registro</label>
          <p className="fake-input">
            {dayjs(driver.created).format('D [de] MMMM, YYYY HH:mm')}
          </p>
        </div>
        <div className="col s12" id="heartbeats-wrapper">
          {loadingHeartbeats ? (
            Spinner()
          ) : (
            <DriverHeartbeats
              heartbeats={heartbeats}
              appStatus={driver.appStatus}
              isResting={driver.isResting}
              lastSeen={
                driver.location && driver.location.dateTime
                  ? driver.location.dateTime
                  : null
              }
              firstLogin={driver.firstLogin}
              isDriving={driver.isDriving}
            />
          )}
        </div>
      </div>
      <div className="row big-label center-mobile">
        <div className="col s12">
          <p>Historial de activaciones</p>
        </div>
      </div>
      <div className="row">
        <div className="col s12">
          <DriverNotifications driverId={driverId} />
        </div>
      </div>
    </div>
  );
}

export default DriverQuickView;
