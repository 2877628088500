import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import AirbagLoader from '../airbag-loader/AirbagLoader';
import axiosAuth from '../../../utils/axiosAuth';
import './DriverNotifications.scss';

function DriverNotifications(props) {
  const { driverId } = props;
  const lastNotifRef = useRef(null);
  const loading = useRef(false);
  const keepLooking = useRef(true);
  const last = useRef('');
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onScroll = (e) => {
      // If reference to last vehicle row exists and its in
      // view and fetchUsers keeps returning result
      if (
        notifications &&
        lastNotifRef &&
        lastNotifRef.current &&
        isLastNotifInViewport() &&
        !loading.current &&
        keepLooking.current
      ) {
        fetchNotifications();
      }
    };

    // Get div element
    const elem = document.getElementById('_driver-notifications_');

    // When notifications box is closed, drowdownElem is null so we validate
    if (elem) elem.addEventListener('scroll', onScroll);

    return () => {
      if (elem) elem.removeEventListener('scroll', onScroll);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, lastNotifRef, loading.current, keepLooking]);

  function isLastNotifInViewport() {
    const top = lastNotifRef.current.getBoundingClientRect().top;
    return top >= 0 && top <= window.innerHeight;
  }

  function fetchNotifications() {
    loading.current = true;

    /* Build query parameters */
    const queryParameters = {};
    if (last.current) queryParameters.last = last.current; // Pagination

    axiosAuth
      .get(`/api/users/${driverId}/notifications`, {
        params: queryParameters
      })
      .then((res) => {
        const { notifications: notificationsRes } = res.data;

        // Save last notification id for pagination
        let lastNotifId = null;
        if (notificationsRes.length) {
          lastNotifId = notificationsRes[notificationsRes.length - 1].id;
        }

        // Append new notifications response to notifications's array
        const notificationsCopy = notifications
          ? [...notifications, ...notificationsRes]
          : [...notificationsRes];
        // Order by key and apply current filter
        setNotifications(notificationsCopy);

        // Stop or resume infinite scrolling
        // The < 20 is hardcoded and must be changed (results per page)
        if (!notificationsRes.length || notificationsRes.length < 20) {
          keepLooking.current = false;
          last.current = '';
        } else {
          keepLooking.current = true;
          // Save last notification's id to continue query
          last.current = lastNotifId;
        }

        // Stop loader
        loading.current = false;
      })
      .catch((err) => {
        console.log(err);
        loading.current = false;
      });
  }

  function renderNotification(notif, idx) {
    return (
      <div
        key={notif.id}
        className="notification-row"
        ref={idx === notifications.length - 20 ? lastNotifRef : null}
      >
        <div className="date-col custom-col">
          <p className="notif-date">
            {dayjs(notif.created).format('DD/MM/YY hh:mm a')}
          </p>
        </div>
        <div className="content-col custom-col">
          <div className="notif-title">
            <span className="status grey-s">{notif.title}</span>
          </div>
          <span className="notif-body">{notif.body}</span>
        </div>
      </div>
    );
  }

  function renderNotifications() {
    if (!notifications) {
      return <AirbagLoader />;
    }

    if (!notifications.length) {
      return <p>Sin notificaciones</p>;
    }

    return notifications.map((notif, idx) => renderNotification(notif, idx));
  }

  return <div id="_driver-notifications_">{renderNotifications()}</div>;
}

export default DriverNotifications;
