import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import {
  sanitizeGender,
  sanitizeCivilStatus,
  sanitizeNationality
} from '../../../utils/sanitizers';
export const FormDriverInfo = ({
  fullName,
  phone,
  email,
  gender,
  hired,
  created,
  nationality,
  birthdate,
  jobTitle,
  civilStatus,
  coins
}) => {
  return (
    <div className="driver-info-container">
      <div className="driver-info">
        <div className="basic-info">
          <div className="driver-entry">
            <span>Nombre:</span>
            <span>{fullName || '-'}</span>
          </div>
          <div className="driver-entry">
            <span>Teléfono:</span>
            <span>{phone || '-'}</span>
          </div>
          <div className="driver-entry">
            <span>Correo electrónico:</span>
            <span>{email || '-'}</span>
          </div>
        </div>
      </div>
      <div className="other-info">
        <div className="driver-entry">
          <span>Género:</span>
          <span>{gender ? sanitizeGender(gender) : '-'}</span>
        </div>
        <div className="driver-entry">
          <span>Fecha de nacimiento:</span>
          <span>{birthdate ? dayjs(birthdate).format('DD/MMMM/YY') : '-'}</span>
        </div>
        <div className="driver-entry">
          <span>Estado civil:</span>
          {civilStatus ? sanitizeCivilStatus(civilStatus) : '-'}
        </div>
        <div className="driver-entry">
          <span>Nacionalidad:</span>

          {nationality ? sanitizeNationality(nationality) : '-'}
        </div>
      </div>
      <div className="job-info">
        <div>
          <span>Puntos:</span>
          <span>{coins || '-'}</span>
        </div>
        <div>
          <span>Puesto:</span>
          <span>{jobTitle || '-'}</span>
        </div>
        <div>
          <span>Contratado:</span>
          <span>{hired ? dayjs(hired).format('DD/MMMM/YY') : '-'}</span>
        </div>
        <div>
          <span>Registro:</span>
          <span>{created ? dayjs(created).format('DD/MMMM/YY') : '-'}</span>
        </div>
      </div>
    </div>
  );
};
