import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import Swal from 'sweetalert2';
import Icon from '../../../common/airbag-icons/Icon';
import Button from '../../../common/button/Button';
import { isManagerInChildCompany } from '../../../../utils/companyGroupStates';
import './Filters.scss';

function Filters(props) {
  const {
    setQuery,
    user,
    filters,
    setFilters,
    initialFilters,
    setNewDriverOpen,
    groups,
    company,
    companyGroup,
    skin
  } = props;
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  // Handle screen resize
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize(window.innerWidth);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle timeout on driver search
  useEffect(() => {
    // Wait before firing request for optimization
    const delayDebounceFn = setTimeout(() => {
      setQuery(searchTerm);
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  function renderAddDriverButton() {
    // Hide button when group-limited manager has no groups assigned
    if (user.authRole === 'group-limited' && !groups.length) return null;

    // Check if children can create drivers
    if (
      Object.keys(companyGroup).length &&
      !companyGroup.canChildrenCreateDrivers &&
      isManagerInChildCompany(company, user, companyGroup) &&
      !user.isSam
    ) {
      return (
        <Button
          variant="yellow-button"
          id="add-driver-btn"
          type="button"
          onClick={() => {
            // Fire modal
            Swal.fire({
              icon: 'info',
              title: 'Para agregar conductores contacta a tu supervisor',
              // eslint-disable-next-line max-len
              text: `O envía un correo a ventas@${skin.domain} para que te podamos apoyar`,
              confirmButtonColor: '#f6c700',
              confirmButtonText:
                // eslint-disable-next-line max-len
                '<span class="dark-color-text">Aceptar</span>',
              cancelButtonText: 'Cancelar'
            });
          }}
        >
          Agregar conductor
          <Icon icon="more" id="add-driver-icon" />
        </Button>
      );
    }

    return (
      <Button
        variant="yellow-button"
        id="add-driver-btn"
        onClick={() => {
          setNewDriverOpen(true);

          // Google Analytics
          ReactGA.event({
            category: 'drivers_view',
            action: 'open_new_driver'
          });

          // Posthog
          posthog.capture('open_new_driver', {
            category: 'drivers_view',
            isCustomEvent: true
          });
        }}
        type="button"
      >
        Agregar conductor
        <Icon icon="more" id="add-driver-icon" />
      </Button>
    );
  }

  function renderFirstGroupOption() {
    if (user.authRole === 'admin') {
      if (groups.length) {
        return <option value="">Todos</option>;
      } else {
        return (
          <option value="" disabled>
            Sin grupos
          </option>
        );
      }
    } else if (user.authRole === 'group-limited') {
      if (!groups.length) {
        return (
          <option value="" disabled>
            Sin grupos
          </option>
        );
      }
    }
  }

  function shouldShowFilters() {
    if (windowSize > 992) {
      // Show filters by default on large windows
      return true;
    } else {
      // Only show filters on mobile if flag is on
      if (filtersOpen) {
        return true;
      }
    }

    return false;
  }

  return (
    <div className="row" id="_filters-drivers_">
      <div className="col s12">
        <div className="hide-on-large-only">
          <span>
            <Button
              variant="yellow-button"
              type="button"
              id="filters-btn"
              className={filtersOpen ? 'filters-opened' : ''}
              onClick={() => {
                setFiltersOpen(!filtersOpen);

                // Google Analytics
                if (filtersOpen) {
                  ReactGA.event({
                    category: 'drivers_view',
                    action: 'filter_close'
                  });

                  // Posthog
                  posthog.capture('filter_close', {
                    category: 'drivers_view',
                    isCustomEvent: true
                  });
                } else {
                  ReactGA.event({
                    category: 'drivers_view',
                    action: 'filter_open'
                  });

                  // Posthog
                  posthog.capture('filter_open', {
                    category: 'drivers_view',
                    isCustomEvent: true
                  });
                }
              }}
            >
              {filtersOpen ? 'Esconder' : 'Ver'} filtros
              <Icon
                icon="acc"
                id="filters-btn-icon"
                className={filtersOpen ? 'filters-opened' : ''}
              />
            </Button>
          </span>
          {renderAddDriverButton()}
        </div>
      </div>
      {shouldShowFilters() ? (
        <div className="col s12" id="filters-box">
          <span className="input-field item-container">
            <label htmlFor="query-input" className="active">
              Buscar conductor
            </label>
            <input
              type="text"
              placeholder="Nombre, teléfono"
              id="query-input"
              value={searchTerm}
              onChange={(e) => {
                // Limit query to 25 chars
                if (e.target.value.length < 26) {
                  setSearchTerm(e.target.value);
                }

                // Reset filters
                setFilters({
                  ...initialFilters(user.authRole, groups)
                });
              }}
              onFocus={() => {
                // Google Analytics
                ReactGA.event({
                  category: 'drivers_view',
                  action: 'search_driver_focus'
                });

                // Posthog
                posthog.capture('search_driver_focus', {
                  category: 'drivers_view',
                  isCustomEvent: true
                });
              }}
            />
            {searchTerm && (
              <FontAwesomeIcon
                icon="times"
                id="query-clear"
                onClick={() => {
                  setQuery('');
                  setSearchTerm('');
                }}
              />
            )}
          </span>
          <span className="input-field item-container">
            <label htmlFor="groups-select" className="active">
              Grupo
            </label>
            <select
              className="browser-default"
              id="groups-select"
              value={filters.group}
              disabled={searchTerm}
              onChange={(e) => {
                // Reset search
                setQuery('');
                setSearchTerm('');

                // Set filter
                setFilters({
                  ...filters,
                  applied: true,
                  group: e.target.value
                });
              }}
              onFocus={() => {
                // Google Analytics
                ReactGA.event({
                  category: 'drivers_view',
                  action: 'group_filter_focus'
                });

                // Posthog
                posthog.capture('group_filter_focus', {
                  category: 'drivers_view',
                  isCustomEvent: true
                });
              }}
            >
              {renderFirstGroupOption()}
              {groups.map((gp) => {
                return (
                  <option key={gp.id} value={gp.id}>
                    {gp.name}
                  </option>
                );
              })}
            </select>
          </span>
          <span className="input-field item-container">
            <label htmlFor="status-select" className="active">
              Estado
            </label>
            <select
              className="browser-default"
              id="status-select"
              value={filters.status}
              disabled={searchTerm}
              onChange={(e) => {
                // Reset search
                setQuery('');
                setSearchTerm('');

                // Set filter
                setFilters({
                  ...filters,
                  applied: true,
                  status: e.target.value
                });
              }}
              onFocus={() => {
                // Google Analytics
                ReactGA.event({
                  category: 'drivers_view',
                  action: 'status_filter_focus'
                });

                // Posthog
                posthog.capture('status_filter_focus', {
                  category: 'drivers_view',
                  isCustomEvent: true
                });
              }}
            >
              <option value="">Todos</option>
              <option value="active">Activados</option>
              <option value="inactive">Desactivados</option>
              <option value="isResting">En descanso</option>
              <option value="no-login">Sin configurar</option>
              <option value="disabled">Deshabilitados</option>
            </select>
          </span>
          <span className="hide-on-med-and-down">
            {renderAddDriverButton()}
          </span>
        </div>
      ) : null}
    </div>
  );
}

export default Filters;
