import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderSection from '../../common/header-section/HeaderSection';
import Error from '../../common/error/Error';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import Icon from '../../common/airbag-icons/Icon';
import axiosAuth from '../../../utils/axiosAuth';
import activitiesDefinitions from '../../../utils/activitiesDefinitions';
import './Activities.scss';

function Activities(props) {
  const lastActivityRef = useRef(null);
  const lastActivityId = useRef('');
  const lastDriverId = useRef('');
  const keepLooking = useRef(true);
  const loading = useRef(false);
  const [error, setError] = useState(null);
  const [activities, setActivities] = useState(null);

  // Fetch activities on first load
  useEffect(() => {
    fetchActivities(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onScroll = (e) => {
      // If reference to last activity row exists and its in view and
      // fetchActivities keeps returning result
      if (
        activities &&
        lastActivityRef &&
        lastActivityRef.current &&
        isLastActivityInViewport() &&
        !loading.current &&
        keepLooking.current
      ) {
        fetchActivities();
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities, lastActivityRef, loading.current, keepLooking]);

  function fetchActivities(cleanActivitiesArray) {
    // Turn on loading flag
    loading.current = true;

    /* Build query parameters */
    const queryParameters = {};
    // Pagination
    if (lastActivityId.current && lastDriverId.current) {
      queryParameters.lastActivityId = lastActivityId.current;
      queryParameters.lastDriverId = lastDriverId.current;
    }

    // Get activities from server
    axiosAuth
      .get(`/api/activities/all`, {
        params: queryParameters
      })
      .then((res) => {
        const { activities: activitiesRes } = res.data;

        // Save last activity id for pagination
        let lastActivityIdCopy = null;
        let lastDriverIdCopy = null;
        if (activitiesRes.length) {
          lastActivityIdCopy = activitiesRes[activitiesRes.length - 1].id;
          lastDriverIdCopy = activitiesRes[activitiesRes.length - 1].user;
        }

        // Here you can process array's information
        const processedActivities = activitiesRes;

        if (cleanActivitiesArray) {
          // Reset information
          setActivities(processedActivities);
        } else {
          // Append new activities response to activities' array
          const activitiesCopy = activities
            ? [...activities, ...processedActivities]
            : [...processedActivities];
          // Set new activities array
          setActivities(activitiesCopy);
        }

        // Stop or resume infinite scrolling | The < 30 is hardcoded
        // and must be changed (results per page)
        if (!activitiesRes.length || activitiesRes.length < 30) {
          keepLooking.current = false;
          lastActivityId.current = '';
          lastDriverId.current = '';
        } else {
          keepLooking.current = true;
          // Save last driver's id and activity id to continue query
          lastActivityId.current = lastActivityIdCopy;
          lastDriverId.current = lastDriverIdCopy;
        }

        // Stop loader
        loading.current = false;
      })
      .catch((err) => {
        console.log(err);
        loading.current = false;
        setError(err);
      });
  }

  function isLastActivityInViewport() {
    // Set an offset to trigger action before target element is in view
    const offset = 100;

    const top = lastActivityRef.current.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  }

  function renderDateRow(date) {
    const d = dayjs(date);
    const ret = d.format('dddd DD [de] MMMM');
    return ret;
  }

  function renderActivityType(activity) {
    const { slug, user, created } = activity;

    // Get activity definition
    let actInfo = activitiesDefinitions.find((l) => l.slug === slug);

    // Activity not found, default to other
    if (!actInfo) {
      actInfo = activitiesDefinitions.find((l) => l.slug === 'other');
    }

    // Build URL
    const targetDate = dayjs(created).format('YYYY-MM-DD');
    let reportsUrl = `/conductor?tab=reports&driverId=${user}`;
    reportsUrl += `&startDate=${targetDate}`;
    reportsUrl += `&endDate=${targetDate}`;

    return (
      <Link to={reportsUrl} className="no-underline-link">
        <span
          className="act-tag"
          style={{ background: actInfo.colorLight, color: actInfo.color }}
        >
          <Icon
            className="act-icon"
            icon={actInfo.airbagIcon}
            stroke={actInfo.color}
          />
          {actInfo.name}
        </span>
      </Link>
    );
  }

  function renderActivitiesTable() {
    // Loader
    if (!activities) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No activities means no data
    if (!activities.length) {
      return (
        <div id="no-data">
          <Icon icon="crash" id="error-icon" />
          <p>Sin registros</p>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="activities-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Fecha y hora</th>
              <th>Conductor</th>
            </tr>
          </thead>
          <tbody>
            {activities.map((activity, i) => {
              // This is our return object.
              const activityRow = [];

              // Always render date before first activity
              if (!i) {
                activityRow.push(
                  <tr key="first-row" className="date-row">
                    <td>{renderDateRow(activity.created)}</td>
                  </tr>
                );
              } else {
                // Get previous activity
                const prevActivity = activities[i - 1];

                // Get dates for validation
                const currentDate = dayjs(activity.created).format(
                  'YYYY-MM-DD'
                );
                const prevDate = dayjs(prevActivity.created).format(
                  'YYYY-MM-DD'
                );

                // Add row for change in day
                if (currentDate !== prevDate) {
                  activityRow.push(
                    <tr key={i} className="date-row">
                      <td>{renderDateRow(currentDate)}</td>
                    </tr>
                  );
                }
              }

              // Add activity data
              activityRow.push(
                <tr
                  key={activity.id}
                  className="activity-row"
                  ref={i === activities.length - 1 ? lastActivityRef : null}
                >
                  <td>
                    {renderActivityType(activity)}
                    {activity.photos ? (
                      <span className="media-indicator">
                        <FontAwesomeIcon icon={['fal', 'camera']} />
                        {activity.photos.length}
                      </span>
                    ) : null}
                    {activity.voicenotes ? (
                      <span className="media-indicator">
                        <FontAwesomeIcon icon={['fal', 'microphone']} />
                        {activity.voicenotes.length}
                      </span>
                    ) : null}
                  </td>
                  <td>{dayjs(activity.created).format('DD/MM HH:mm')}</td>
                  <td>
                    <Link to={`/conductor?driverId=${activity.user}`}>
                      {activity.driverName}
                    </Link>
                  </td>
                </tr>
              );

              // Return array
              return activityRow;
            })}
          </tbody>
        </table>
      </div>
    );
  }

  // API error handler
  if (error) return <Error error={error} />;

  return (
    <div id="_activities_">
      <HeaderSection
        title="Registros y evidencias"
        // eslint-disable-next-line max-len
        body="Consulta los registros realizados por tus conductores."
      />
      <div className="row no-margin">
        <div className="col s12">{renderActivitiesTable()}</div>
      </div>
    </div>
  );
}

export default Activities;
