import React from 'react';

export const ProgressBar = ({ value, max, min, unit = 'km' }) => {
  const percentage = (value / (max - min)) * 100;
  min = Math.max(min, 0);
  return (
    <div className="progress-bar-container">
      <div className="progress-bar-values">
        <div>
          {min} {unit}
        </div>
        <div>
          {max} {unit}
        </div>
      </div>
      <div className="progress-bar" role="progressbar">
        <div
          className="progress-bar-fill"
          style={{
            width: `${percentage}%`
          }}
        >
          {Math.round(Math.floor(percentage))}%
        </div>
      </div>
    </div>
  );
};
