import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressBar } from './ProgressBar';

export const Level = ({
  currentDistance = 0,
  from = 0,
  to = 0,
  name = '',
  remainingDistance = 0,
  totalDistanceTravelled = 0
}) => {
  if (!name) {
    return (
      <div className="airbag-table-container no-level">
        <p>Sin nivel</p>
      </div>
    );
  }

  const [, levelNumber] = name.split('Nivel ');

  return (
    <div className="level-container">
      <div className="level-icon-container">
        <FontAwesomeIcon icon={['fal', 'hexagon']} className="level-icon" />
        <div className="level-number">{levelNumber}</div>
      </div>
      <div>
        <h5>{name}</h5>
        <ProgressBar value={currentDistance} min={from - 1} max={to} />
      </div>
      <div>
        <div>
          Distancia recorrida este nivel:
          <span> {Math.round(currentDistance.toFixed(2))}</span> km
        </div>
        <div>
          Distancia para el siguiente nivel:
          <span> {Math.round(remainingDistance.toFixed(2))}</span> km
        </div>
        <div>
          Distancia total recorrida:
          <span> {Math.round(totalDistanceTravelled.toFixed(2))}</span> km
        </div>
      </div>
    </div>
  );
};
