import dayjs from 'dayjs';
import getMarker from './getMarker';

// Display heartbeats in map
function displayHeartbeats(heartbeats, H, mapUI, mapObj) {
  // Declare group
  const group = new H.map.Group();

  for (const hb of heartbeats) {
    // Check for valid position
    const {
      latitude: lat,
      longitude: lng,
      created,
      locationPermissionGranted,
      motionActivityPermissionGranted,
      lowPowerModePermissionGranted,
      notificationsPermissionGranted,
      gpsEnabled,
      lowPowerModeActive,
      networkConnection,
      isDriving,
      isSDKEnabled,
      chargeLevel,
      wifiConnection
    } = hb;
    if (!lat || !lng) {
      continue;
    }

    // Create a marker icon from an image URL
    let icon = new H.map.Icon(getMarker('heartbeat-ok'));
    let hasError = false;
    if (
      !locationPermissionGranted ||
      !motionActivityPermissionGranted ||
      !lowPowerModePermissionGranted ||
      !notificationsPermissionGranted ||
      !gpsEnabled ||
      lowPowerModeActive
    ) {
      // Update color to show error
      icon = new H.map.Icon(getMarker('heartbeat-not-ok'));
      hasError = true;
    }

    if (!hasError && !networkConnection) {
      // Update color to show warning
      icon = new H.map.Icon(getMarker('heartbeat-warn'));
      hasError = true;
    }

    // Create a marker using the previously instantiated icon
    const mapMarker = new H.map.Marker({ lat, lng }, { icon });

    // Set the text that the marker will show when clicked on
    let markerData = '<ul class="heartbeat-data">';
    markerData += `<li><span>Fecha:</span> ${dayjs(created).format(
      'DD/MM HH:mm'
    )}</li>`;
    if (isDriving) {
      markerData += `<li><span>Está manejando</span></li>`;
    } else {
      markerData += `<li><span>No está manejando</span></li>`;
    }
    if (isSDKEnabled) {
      markerData += `<li><span>Rastreo activado</span></li>`;
    } else {
      markerData += `<li><span>Rastreo desactivado</span></li>`;
    }
    markerData += `<li><span>Batería:</span> ${chargeLevel}%</li>`;
    if (networkConnection) {
      markerData += `<li><span>Conexión por:</span> ${
        wifiConnection ? 'WiFi' : 'Datos móviles'
      }</li>`;
    }

    if (hasError) {
      if (!locationPermissionGranted) {
        markerData += `<li><span>ERROR: Permiso de ubicación</span></li>`;
      }
      if (!motionActivityPermissionGranted) {
        // eslint-disable-next-line max-len
        markerData += `<li>ERROR: <span>Permiso de actividad física</span></li>`;
      }
      if (!lowPowerModePermissionGranted) {
        markerData += `<li>ERROR: <span>Permiso de batería</span></li>`;
      }
      if (!notificationsPermissionGranted) {
        markerData += `<li>ERROR: <span>Permiso de notificaciones</span></li>`;
      }
      if (!gpsEnabled) {
        markerData += `<li>ERROR: <span>Ubicación desactivada</span></li>`;
      }
      if (lowPowerModeActive) {
        // eslint-disable-next-line max-len
        markerData += `<li>ERROR: <span>Ahorro de batería encendido</span></li>`;
      }
      if (!networkConnection) {
        markerData += `<li>ERROR: <span>Sin conexión a internet</span></li>`;
      }
    }

    markerData += '</ul>';

    mapMarker.setData(markerData);

    // Add the event listener to listen clicks on marker
    mapMarker.addEventListener('tap', (event) => {
      const bubble = new H.ui.InfoBubble(
        { lat, lng },
        { content: event.target.getData() }
      );
      // Add the bubble to the UI
      mapUI.current.addBubble(bubble);
    });

    // retrieve all map objects
    // Add the marker to the map
    group.addObject(mapMarker);
  }

  // Add the group to the map
  mapObj.current.addObject(group);
}

export default displayHeartbeats;
