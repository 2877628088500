import React from 'react';

function Acc(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_5" data-name="Capa 5">
        <path d="M163.09,118.48H63.87a5,5,0,1,1,0-10h99.22a5,5,0,0,1,0,10Z" />
        <path d="M191.23,146.62H92a5,5,0,1,1,0-10h99.21a5,5,0,1,1,0,10Z" />
        <path d="M219.59,175H120.38a5,5,0,0,1,0-10h99.21a5,5,0,0,1,0,10Z" />
      </g>
    </svg>
  );
}

export default Acc;
