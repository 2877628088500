/* eslint max-len: 0 */

import React from 'react';

function Signal(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <path d="M141.73,58.64c-8.28,0-14.17,5.87-14.17,14.17,0,6.38,3.62,11.45,9.17,13.35v169a5,5,0,0,0,10,0v-169a13.63,13.63,0,0,0,9.18-13.35C155.91,64.51,150,58.64,141.73,58.64Z" />
      <path d="M113.62,44.39a5,5,0,0,0-7.07,0,41.45,41.45,0,0,0,0,58.55,5,5,0,1,0,7.07-7.07,31.45,31.45,0,0,1,0-44.41A5,5,0,0,0,113.62,44.39Z" />
      <path d="M92.7,25.67a5,5,0,0,0-7.07,0,68,68,0,0,0,0,96,5,5,0,0,0,7.07,0,5,5,0,0,0,0-7.07,58,58,0,0,1,0-81.86A5,5,0,0,0,92.7,25.67Z" />
      <path d="M170.07,43.53a5,5,0,0,0,0,7.08,31.43,31.43,0,0,1,0,44.4,5,5,0,1,0,7.07,7.07,41.45,41.45,0,0,0,0-58.55A5,5,0,0,0,170.07,43.53Z" />
      <path d="M197.94,24.81a5,5,0,1,0-7.07,7.07,58,58,0,0,1,0,81.87,5,5,0,0,0,7.07,7.07A68,68,0,0,0,197.94,24.81Z" />
    </svg>
  );
}

export default Signal;
