import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Icon from '../../../../common/airbag-icons/Icon';
import './WeatherTable.scss';

// Component that represents a table with the weather info
function WeatherTable(props) {
  const [visibility, setVisibility] = useState(props.weather.visibility);
  const [weatherDesc, setWeatherDesc] = useState(
    props.weather.weather[0].description
  );

  // Function to determine the visibility during the trip
  function translateVisibility() {
    if (visibility < 50) setVisibility('Neblina densa');
    else if (visibility >= 50 && visibility < 200) {
      setVisibility('Neblina espesa');
    } else if (visibility >= 200 && visibility < 500) {
      setVisibility('Neblina moderada');
    } else if (visibility >= 500 && visibility < 1000) {
      setVisibility('Neblina ligera');
    } else if (visibility >= 1000 && visibility < 2000) {
      setVisibility('Neblina fina');
    } else if (visibility >= 2000 && visibility < 4000) {
      setVisibility('Calina');
    } else if (visibility >= 4000 && visibility < 10000) {
      setVisibility('Niebla ligera');
    } else if (visibility >= 10000 && visibility < 20000) {
      setVisibility('Claro');
    } else if (visibility >= 20000 && visibility < 50000) {
      setVisibility('Muy Claro');
    } else if (visibility > 50000) setVisibility('Bastante claro');
  }

  // Function to make the first letter of the
  // weather description a capital letter
  function capitalizeDescription() {
    setWeatherDesc(weatherDesc.charAt(0).toUpperCase() + weatherDesc.slice(1));
  }

  // Call the translate and capitalize function
  // as soon as the component gets rendered
  useEffect(() => {
    translateVisibility();
    capitalizeDescription();
    // eslint-disable-next-line
  }, []);

  // Display the stats passed through props
  return (
    <div className="col s12 m12 l6 xl6">
      <div className="row weather-table">
        <div className="col s12 tableHeader">
          <Icon icon="day" id="cloudIcon" />
          <p className="table-title">{props.title}</p>
        </div>
        <div className="col s12">
          <p className="address">{props.address}</p>
        </div>
        <div className="col s6">
          <p className="subtitle">
            <FontAwesomeIcon className="titleIcons" icon="thermometer-half" />
            Temperatura
          </p>
          <p className="weatherStat">{props.weather.temp} ºC</p>
          <p className="subtitle">
            <FontAwesomeIcon className="titleIcons" icon="wind" />
            Viento
          </p>
          <p className="weatherStat">{props.weather.wind_speed} km/h</p>
        </div>
        <div className="col s6">
          <p className="subtitle">
            <FontAwesomeIcon className="titleIcons" icon="eye" />
            Visibilidad
          </p>
          <p className="weatherStat">{visibility}</p>
          <p className="subtitle">
            <FontAwesomeIcon className="titleIcons" icon="tint" />
            Humedad
          </p>
          <p className="weatherStat">{props.weather.humidity} %</p>
        </div>
      </div>
    </div>
  );
}

export default WeatherTable;
