import React from 'react';

function PerformanceReport(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <line
          className="cls-1"
          x1="165.35"
          y1="198.43"
          x2="165.35"
          y2="141.73"
        />
        <line
          className="cls-1"
          x1="118.11"
          y1="198.43"
          x2="118.11"
          y2="70.87"
        />
        <line className="cls-1" x1="212.6" y1="198.43" x2="212.6" y2="113.39" />
        <line className="cls-1" x1="70.87" y1="198.43" x2="70.87" y2="113.39" />
        <rect
          className="cls-1"
          x="28.35"
          y="28.35"
          width="226.77"
          height="226.77"
        />
      </g>
    </svg>
  );
}

export default PerformanceReport;
