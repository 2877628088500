/* eslint max-len: 0 */

import React from 'react';

function Delivery(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_5" data-name="Capa 5">
        <path d="M254.74,75.94a.88.88,0,0,0,0-.17,2.63,2.63,0,0,0,0-.49,2.38,2.38,0,0,0-.05-.26,2.88,2.88,0,0,0-.09-.4l-.09-.26c0-.13-.09-.25-.14-.37l-.12-.26c-.06-.11-.12-.23-.19-.34l-.15-.23a4.08,4.08,0,0,0-.25-.33l-.16-.2-.34-.33-.15-.14a4.26,4.26,0,0,0-.53-.39h0l-.12-.06c-.16-.09-.32-.18-.49-.26l-.05,0-108-47.65a5,5,0,0,0-4,0l-108,47.65a.05.05,0,0,0,0,0l-.5.26-.11.06h0a6.55,6.55,0,0,0-.53.39,1.85,1.85,0,0,1-.14.14c-.12.11-.24.22-.34.33a1.56,1.56,0,0,0-.16.2,4.19,4.19,0,0,0-.26.33c-.05.07-.09.15-.14.23l-.2.34c0,.09-.08.17-.11.26s-.1.24-.15.37a2,2,0,0,0-.08.26c0,.13-.07.27-.1.4a2.38,2.38,0,0,0,0,.26c0,.16,0,.32,0,.49s0,.11,0,.17V204.65a5,5,0,0,0,2.88,4.53l108,50.47h0l.14.06.47.17.2.06.45.09a1.08,1.08,0,0,0,.19,0,4.46,4.46,0,0,0,.63.05,4.6,4.6,0,0,0,.64-.05l.19,0A3.12,3.12,0,0,0,143,260l.21-.06.47-.17.13-.06h0l108-50.47a5,5,0,0,0,2.89-4.53V75.94Zm-113-42.13,95.92,42.31L222.88,83l-81.15,37.92-41.95-19.6L148,80.07a5,5,0,1,0-4-9.15L87.75,95.71,60.58,83l-14.76-6.9Zm-103,50L60,93.8l22.66,10.58v67a5,5,0,0,0,10,0V109.06l44,20.58V247.26l-98-45.79Zm206,117.62-98,45.79V129.64L223.44,93.8l21.3-10Z" />
      </g>
    </svg>
  );
}

export default Delivery;
