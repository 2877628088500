import React from 'react';

function EventRegister(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <line
          className="cls-1"
          x1="127.56"
          y1="184.25"
          x2="212.6"
          y2="184.25"
        />
        <line
          className="cls-1"
          x1="127.56"
          y1="127.56"
          x2="212.6"
          y2="127.56"
        />
        <line className="cls-1" x1="127.56" y1="70.87" x2="212.6" y2="70.87" />
        <polyline
          className="cls-1"
          points="56.69 184.74 70.87 198.91 99.21 170.56"
        />
        <polyline
          className="cls-1"
          points="56.69 128.3 70.87 142.47 99.21 114.13"
        />
        <polyline
          className="cls-1"
          points="56.69 70.87 70.87 85.04 99.21 56.69"
        />
        <rect
          className="cls-1"
          x="28.35"
          y="28.35"
          width="226.77"
          height="226.77"
        />
      </g>
    </svg>
  );
}

export default EventRegister;
