import React, { useState } from 'react';
import axiosAuth from '../../../utils/axiosAuth';
import LoaderInner from '../loader-inner/LoaderInner';
import './SearchMultipleUserInput.scss';

function SearchMultipleUserInput(props) {
  const { addUser } = props;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState(null);

  function searchUser(name) {
    if (name) {
      setLoading(true);
      // Make request
      axiosAuth
        .get('/api/users', {
          params: { query: value }
        })
        .then((res) => {
          const suggestions = [];
          res.data.drivers.forEach((driver, idx) => {
            suggestions.push(
              <p
                className="suggestion"
                onClick={() => {
                  addUser(driver);
                  // Reset suggestions
                  setSuggestions(null);
                  // Reset input value
                  setValue('');
                }}
                key={`${driver.id}-${idx}`}
              >
                {driver.name} {driver.lastName}{' '}
              </p>
            );
          });
          setSuggestions(suggestions);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setSuggestions(null);
    }
  }

  function renderSuggestions() {
    // Loading
    if (loading || !suggestions) return <LoaderInner />;

    // No suggestions
    if (!suggestions.length) {
      return <p className="center-align">Sin resultados</p>;
    }

    return <div>{suggestions}</div>;
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col s12 m6 input-field">
          <label className="active">Agregar Conductor</label>
          <input
            id="multiple-input"
            type="text"
            placeholder="Nombre del conductor"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyUp={(e) => searchUser(e.target.value)}
          />
          {value && (
            <div className="suggestions-box">{renderSuggestions()}</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default SearchMultipleUserInput;
