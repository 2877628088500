import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import Icon from '../../../common/airbag-icons/Icon';
import './LastTripsTable.scss';

function LastTripsTable(props) {
  const { trips } = props;

  function handleScoreColor(number) {
    if (number >= 80) {
      return 'good';
    } else if (number >= 60) {
      return 'regular';
    } else if (number > 0 && number < 60) {
      return 'bad';
    }
    return 'na';
  }

  function renderTripsTable() {
    // Loader
    if (!trips) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No trips means no data
    if (!trips.length) {
      return (
        <div className="airbag-table-container" id="trips-table">
          <div className="airbag-top">
            <span>Últimos viajes realizados</span>
          </div>
          <div className="graph-message">
            <Icon icon="routes" className="graph-icon-indicator" />
            <p>Sin viajes</p>
          </div>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="trips-table">
        <div className="airbag-top">
          <span>Últimos viajes realizados</span>
        </div>
        <table className="airbag-table">
          <thead>
            <tr>
              <th className="center-align">Calificación</th>
              <th>Fecha y hora</th>
              <th>Conductor</th>
            </tr>
          </thead>
          <tbody>
            {trips.map((trip, i) => (
              <tr key={trip.id} className="trip-row">
                <td
                  className={`trip-score ${handleScoreColor(
                    trip.score.overall
                  )}`}
                >
                  <Link
                    to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                  >
                    {trip.score.overall}
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                  >
                    {dayjs(trip.startDate).format('DD/MM HH:mm')}
                  </Link>
                </td>
                <td>
                  <Link to={`/conductor?driverId=${trip.user}`}>
                    {trip.driverName}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return <div id="_last-trips-table_">{renderTripsTable()}</div>;
}

export default LastTripsTable;
