// Function to display a polyline
function displayPolyline(
  H,
  mapObj,
  mapUI,
  group,
  waypoints,
  strokeColor,
  lineWidth,
  zIndex,
  content
) {
  // Declare a lineString (used to draw a polyline)
  const lineString = new H.geo.LineString();

  // Ignore empty waypoints
  if (!waypoints.length) {
    return;
  }

  // Push each waypoint to the lineString
  waypoints.forEach((waypoint) => {
    lineString.pushPoint({
      lat: waypoint.lat,
      lng: waypoint.lng
    });
  });

  const polyline = new H.map.Polyline(lineString, {
    style: { lineWidth, strokeColor, lineJoin: 'round' },
    arrows: true,
    zIndex
  });

  // Assign content to polyline for info box on hover
  if (content) {
    polyline.setData(content);

    // Handle marker info bubble
    const bubble = new H.ui.InfoBubble(
      { lat: waypoints[0].lat, lng: waypoints[0].lng },
      { content: polyline.getData() }
    );
    // Hide info box
    bubble.close();
    // Add the bubble to the UI
    mapUI.current.addBubble(bubble);

    // Simulate on hover: when mouse enters polyline
    polyline.addEventListener('pointerenter', (evt) => {
      const coords = mapObj.current.screenToGeo(
        evt.currentPointer.viewportX,
        evt.currentPointer.viewportY
      );
      const lat = coords.lat.toFixed(4);
      const lng = coords.lng.toFixed(4);

      bubble.setPosition({ lat, lng });
      bubble.open();
    });
    // Simulate on hover: when mouse leaves polyline
    polyline.addEventListener('pointerleave', (evt) => {
      bubble.close();
    });
    // Simulate on hover: when mouse moves inside polyLine
    polyline.addEventListener('pointermove', (evt) => {
      const coords = mapObj.current.screenToGeo(
        evt.currentPointer.viewportX,
        evt.currentPointer.viewportY
      );
      const lat = coords.lat.toFixed(4);
      const lng = coords.lng.toFixed(4);

      bubble.setPosition({ lat, lng });
    });
  }

  // Paint a new polyline using the lineString(outer and inner)
  group.addObject(polyline);
}

export default displayPolyline;
