/*
IMPORTANT
This  list must match exactly the one in /server/utils/insuranceBrands.js
as server validation is in place.
*/

const insuranceBrands = [
  {
    name: 'Aba Seguros',
    value: 'aba-seguros'
  },
  {
    name: 'ANA Seguros',
    value: 'ana-seguros'
  },
  {
    name: 'Axa Seguros',
    value: 'axa-seguros'
  },
  {
    name: 'BBVA',
    value: 'bbva'
  },
  {
    name: 'Chubb',
    value: 'chubb'
  },
  {
    name: 'El Potosi',
    value: 'el-potosi'
  },
  {
    name: 'El Águila',
    value: 'el-aguila'
  },
  {
    name: 'General de Seguros',
    value: 'general-de-seguros'
  },
  {
    name: 'GNP',
    value: 'gnp'
  },
  {
    name: 'HDI',
    value: 'hdi'
  },
  {
    name: 'HSBC',
    value: 'hsbc'
  },
  {
    name: 'Inbursa',
    value: 'inbursa'
  },
  {
    name: 'La Latino Seguros',
    value: 'la-latino-seguros'
  },
  {
    name: 'La Previsora Compañía de Seguros',
    value: 'la-previsora'
  },
  {
    name: 'Mapfre',
    value: 'mapfre'
  },
  {
    name: 'MetLife',
    value: 'metlife'
  },
  {
    name: 'Primero Seguros',
    value: 'primero-seguros'
  },
  {
    name: 'Qualitas',
    value: 'qualitas'
  },
  {
    name: 'Seguros Afirme',
    value: 'seguros-afirme'
  },
  {
    name: 'Seguros AIG',
    value: 'seguros-aig'
  },
  {
    name: 'Seguros Atlas',
    value: 'seguros-atlas'
  },
  {
    name: 'Seguros Banorte',
    value: 'seguros-banorte'
  },
  {
    name: 'Seguros Bolívar',
    value: 'seguros-bolivar'
  },
  {
    name: 'Seguros del Estado',
    value: 'seguros-estado'
  },
  {
    name: 'Seguros Monterrey',
    value: 'seguros-monterrey'
  },
  {
    name: 'Seguros Multiva',
    value: 'seguros-multiva'
  },
  {
    name: 'Sura',
    value: 'sura'
  },
  {
    name: 'Zurich',
    value: 'zurich'
  },
  {
    name: 'Otro',
    value: 'otro'
  }
];

export default insuranceBrands;
