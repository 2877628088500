export function setCompanyGroup(companyGroup) {
  return {
    type: 'SET_COMPANY_GROUP',
    payload: companyGroup
  };
}

export function unsetCompanyGroup() {
  return {
    type: 'UNSET_COMPANY_GROUP',
    payload: {}
  };
}
