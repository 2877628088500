import React from 'react';
import Favorite from './Favorite';
import Alert from './Alert';
import Car from './Car';
import Chat from './Chat';
import Clock from './Clock';
import Close from './Close';
import Crash from './Crash';
import Day from './Day';
import Download from './Download';
import Error from './Error';
import Gas from './Gas';
import Graph from './Graph';
import Home from './Home';
import MapLocation from './MapLocation';
import More from './More';
import Night from './Night';
import OnOff from './OnOff';
import Pin from './Pin';
import Profile from './Profile';
import Ranking from './Ranking';
import Ranking2 from './Ranking2';
import Velocimeter from './Velocimeter';
import Settings from './Settings';
import Search from './Search';
import Routes from './Routes';
import Record from './Record';
import RealtimeLocation from './RealtimeLocation';
import EventRegister from './EventRegister';
import PerformanceReport from './PerformanceReport';
import Eye from './Eye';
import CallOut from './CallOut';
import Signal from './Signal';
import CallIn from './CallIn';
import Stop from './Stop';
import Delivery from './Delivery';
import Police from './Police';
import Construction from './Construction';
import ClosedRoad from './ClosedRoad';
import Traffic from './Traffic';
import TechProblem from './TechProblem';
import Acc from './Acc';
import Deacc from './Deacc';
import Cornering from './Cornering';
import Phone from './Phone';
import Report from './Report';
import AuxActivity from './AuxActivity';
import Break from './Break';
import LoadHandle from './LoadHandle';
import Maintenance from './Maintenance';
import Truck from './Truck';
import Manuals from './Manuals';

function Icon(props) {
  const Icons = {
    favorite: <Favorite {...props} />,
    alert: <Alert {...props} />,
    car: <Car {...props} />,
    callOut: <CallOut {...props} />,
    chat: <Chat {...props} />,
    clock: <Clock {...props} />,
    close: <Close {...props} />,
    crash: <Crash {...props} />,
    day: <Day {...props} />,
    download: <Download {...props} />,
    error: <Error {...props} />,
    gas: <Gas {...props} />,
    graph: <Graph {...props} />,
    home: <Home {...props} />,
    mapLocation: <MapLocation {...props} />,
    more: <More {...props} />,
    night: <Night {...props} />,
    onOff: <OnOff {...props} />,
    pin: <Pin {...props} />,
    profile: <Profile {...props} />,
    ranking: <Ranking {...props} />,
    ranking2: <Ranking2 {...props} />,
    velocimeter: <Velocimeter {...props} />,
    settings: <Settings {...props} />,
    search: <Search {...props} />,
    routes: <Routes {...props} />,
    record: <Record {...props} />,
    realtimeLocation: <RealtimeLocation {...props} />,
    eventRegister: <EventRegister {...props} />,
    performanceReport: <PerformanceReport {...props} />,
    eye: <Eye {...props} />,
    signal: <Signal {...props} />,
    callIn: <CallIn {...props} />,
    stop: <Stop {...props} />,
    delivery: <Delivery {...props} />,
    police: <Police {...props} />,
    construction: <Construction {...props} />,
    closedRoad: <ClosedRoad {...props} />,
    traffic: <Traffic {...props} />,
    techProblem: <TechProblem {...props} />,
    acc: <Acc {...props} />,
    deacc: <Deacc {...props} />,
    cornering: <Cornering {...props} />,
    phone: <Phone {...props} />,
    report: <Report {...props} />,
    auxActivity: <AuxActivity {...props} />,
    break: <Break {...props} />,
    loadHandle: <LoadHandle {...props} />,
    maintenance: <Maintenance {...props} />,
    truck: <Truck {...props} />,
    manuals: <Manuals {...props} />
  };

  return Icons[props.icon] || null;
}

export default Icon;
