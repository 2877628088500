import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import './Stat.scss';

function Stat(props) {
  let { value, color } = props;

  // Handle no value (null, undefined, NaN)
  if (!value) value = 0;

  // Fix number font size if too big
  let size = '';
  if (value.toString().length === 4) {
    // 1,000 - 9,999
    size = 'len-4';
  } else if (value.toString().length === 5) {
    // 10,000 - 99,000
    size = 'len-5';
  } else if (value.toString().length === 6) {
    // 100,000 - 999,999
    size = 'len-6';
  }

  return (
    <div className="stat">
      <div className={`circle-wrapper ${color}-border-light`}>
        <div className={`circle valign-wrapper ${color}-border`}>
          <h5 className={`${size}`}>{numeral(value).format('0,0')}</h5>
        </div>
      </div>
    </div>
  );
}

Stat.propTypes = {
  color: PropTypes.string.isRequired
};

export default Stat;
