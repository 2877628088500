import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosAuth from '../../../utils/axiosAuth';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import AllUsersStatus from '../../common/chartjs/AllUsersStatus';
import ActiveUsers from '../../common/chartjs/ActiveUsers';
import Icon from '../../common/airbag-icons/Icon';
import cssVars from '../../css-variables/css-variables-js';
import Indicators from './indicators/Indicators';
import RankingTable from './ranking-table/RankingTable';
import LastTripsTable from './last-trips-table/LastTripsTable';
import TripsInRisk from './trips-in-risk/TripsInRisk';
import AllDriversTable from './all-drivers-table/AllDriversTable';
import ActiveDriversTable from './active-drivers-table/ActiveDriversTable';
import './CompanyHome.scss';

function getGroupFromArray(arr) {
  let ret = null;

  if (arr && typeof arr === 'object' && arr.length) {
    // Assign first group in array
    ret = arr[0].id;
  }

  return ret;
}

function CompanyHome(props) {
  const { user, company } = props;
  const { groups } = user;
  const [score, setScore] = useState(null);
  const [tripsByRisk, setTripsByRisk] = useState(null);
  const [distance, setDistance] = useState(null);
  const [activeDrivers, setActiveDrivers] = useState(null);
  const [group, setGroup] = useState(getGroupFromArray(groups));
  const [givenCoins, setGivenCoins] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [trips, setTrips] = useState(null);
  const [tripsInRisk, setTripsInRisk] = useState(null);

  useEffect(() => {
    // Reset values to show loaders
    setScore(null);
    setTripsByRisk(null);
    setDistance(null);
    setActiveDrivers(null);
    setGivenCoins(null);
    setDrivers(null);
    setTrips(null);

    // Build query params to add groupId if manager is group-limited
    const queryParams = {};
    if (user.authRole === 'group-limited') {
      queryParams.groupId = group;
    }

    // Get charts info
    axiosAuth
      .get(`/api/charts/company/scores`, { params: queryParams })
      .then((res) => {
        const { scores: scoresRes } = res.data;

        // Set score
        const { overall } = scoresRes;
        if (overall && overall.data && overall.data.length) {
          setScore(overall.data[overall.data.length - 1]);
        } else {
          setScore(0);
        }

        // Distance
        const { distance } = scoresRes;
        if (distance && distance.data && distance.data.length) {
          setDistance(distance.data[distance.data.length - 1]);
        } else {
          setDistance(0);
        }

        // Active drivers
        const { drivers } = scoresRes;
        if (drivers && drivers.data && drivers.data.length) {
          setActiveDrivers(drivers.data[drivers.data.length - 1]);
        } else {
          setActiveDrivers(0);
        }

        // Trips by risk label
        const { riskProfileLow, riskProfileMid, riskProfileHigh } = scoresRes;
        // Assign values before validation
        let low = 0;
        let mid = 0;
        let high = 0;
        if (
          riskProfileLow &&
          riskProfileLow.data &&
          riskProfileLow.data.length
        ) {
          low = riskProfileLow.data[riskProfileLow.data.length - 1];
        }
        if (
          riskProfileMid &&
          riskProfileMid.data &&
          riskProfileMid.data.length
        ) {
          mid = riskProfileMid.data[riskProfileMid.data.length - 1];
        }
        if (
          riskProfileHigh &&
          riskProfileHigh.data &&
          riskProfileHigh.data.length
        ) {
          high = riskProfileHigh.data[riskProfileHigh.data.length - 1];
        }
        setTripsByRisk({ low, mid, high });
      })
      .catch((err) => {
        console.log(err);
      });

    // Get coins info
    axiosAuth
      .get(`/api/charts/company/coins`, { params: { ...queryParams, days: 2 } })
      .then((res) => {
        const { coins: coinsRes } = res.data;

        // Handle cumulative coins
        let gCoins = 0;
        const { cumulative } = coinsRes;
        if (cumulative && cumulative.length === 2) {
          gCoins = cumulative[1] - cumulative[0];
        }
        setGivenCoins(gCoins);
      })
      .catch((err) => {
        console.log(err);
      });

    // Get drivers status
    axiosAuth
      .get(`/api/users/status`, { params: queryParams })
      .then((res) => {
        const { drivers: driversRes } = res.data;
        setDrivers(driversRes);
      })
      .catch((err) => {
        console.log(err);
      });

    if (user.authRole === 'admin') {
      // Get last trips
      axiosAuth
        .get(`/api/trips/latest`)
        .then((res) => {
          const { trips: tripsRes, highRiskTrips: highRiskTripsRes } = res.data;
          setTrips(tripsRes);
          setTripsInRisk(highRiskTripsRes);
        })
        .catch((err) => {
          console.log(err);
          setTrips([]);
          setTripsInRisk([]);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  function buildActiveDriversLabels(drivers) {
    return [
      `Ok: ${drivers.activeOk.length}`,
      `Por perderse: ${drivers.activeNearLost.length}`,
      `Perdidos: ${drivers.activeLost.length}`
    ];
  }

  function buildAllDriversLabels(drivers) {
    return [
      `Activados: ${drivers.active.length}`,
      `Desactivados: ${drivers.inactive.length}`,
      `En descanso: ${drivers.resting.length}`,
      `Sin configurar: ${drivers['no-login'].length}`
    ];
  }

  function buildAllDriversData(drivers) {
    return [
      drivers.active.length,
      drivers.inactive.length,
      drivers.resting.length,
      drivers['no-login'].length
    ];
  }

  function buildAllDriverscolors() {
    return [
      cssVars['graph-successful'],
      '#eeeeee',
      '#eeeeee',
      cssVars['graph-error']
    ];
  }

  return (
    <div id="company-home">
      <div className="row main-title">
        <div className="col s12 m6">
          <h5>
            Hola, <span>{props.user.name}</span>
          </h5>
        </div>
        {user.authRole === 'group-limited' ? (
          <div className="col s12 m6" id="filters">
            <span className="input-field item-container">
              <label htmlFor="groups-select" className="active">
                Grupo
              </label>
              {group ? (
                <select
                  className="browser-default"
                  id="groups-select"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                >
                  {groups.map((gp) => {
                    return (
                      <option key={gp.id} value={gp.id}>
                        {gp.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select className="browser-default" id="groups-select">
                  <option value="" disabled>
                    Sin grupos
                  </option>
                </select>
              )}
            </span>
          </div>
        ) : null}
      </div>
      <p className="day-indicator-title">Resumen de ayer</p>
      <Indicators
        company={company}
        score={score}
        activeDrivers={activeDrivers}
        distance={distance}
        givenCoins={givenCoins}
        tripsByRisk={tripsByRisk}
      />
      <p className="day-indicator-title">
        Hoy {dayjs().format('DD [de] MMMM')}
      </p>
      <div className="row no-margin">
        {/* Active drivers pie chart and table */}
        <div className="col s12 m6 l7 xl6">
          <div className="graph-box active-drivers-chart">
            {drivers ? (
              <>
                <div className="graph-top">
                  <span>
                    Conductores activados{' '}
                    <FontAwesomeIcon
                      icon="question-circle"
                      // eslint-disable-next-line max-len
                      data-tip="Conductores cuyos viajes son rastreados y calificados"
                    />
                  </span>
                  {/* Tooltips */}
                  <ReactTooltip />
                </div>
                <div className="active-drivers-container">
                  {drivers.activeOk.length ||
                  drivers.activeNearLost.length ||
                  drivers.activeLost.length ? (
                    <ActiveUsers
                      labels={buildActiveDriversLabels(drivers)}
                      data={[
                        drivers.activeOk.length,
                        drivers.activeNearLost.length,
                        drivers.activeLost.length
                      ]}
                      colors={[
                        cssVars['graph-successful'],
                        cssVars['graph-attention'],
                        cssVars['graph-error']
                      ]}
                    />
                  ) : (
                    <div className="no-home-data">
                      <Icon icon="profile" className="icon-indicator" />
                      <p>Sin conductores activados</p>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="airbag-loader">
                <AirbagLoader />
              </div>
            )}
          </div>
        </div>
        <div className="col s12 m6 l5 xl6">
          <ActiveDriversTable drivers={drivers} />
        </div>

        {/* All drivers pie chart and table */}
        <div className="col s12 m6 l7 xl6">
          <div className="graph-box active-drivers-chart">
            {drivers ? (
              <>
                <div className="graph-top">
                  <span>Todos tus conductores</span>
                </div>
                <div className="active-drivers-container">
                  <AllUsersStatus
                    labels={buildAllDriversLabels(drivers)}
                    data={buildAllDriversData(drivers)}
                    colors={buildAllDriverscolors()}
                  />
                </div>
              </>
            ) : (
              <div className="airbag-loader">
                <AirbagLoader />
              </div>
            )}
          </div>
        </div>
        <div className="col s12 m6 l5 xl6">
          <AllDriversTable drivers={drivers} user={user} company={company} />
        </div>

        {/* Admin charts */}
        {user.authRole === 'admin' ? (
          <>
            <div className="col s12 m6 l7 xl6">
              <TripsInRisk tripsInRisk={tripsInRisk} />
            </div>
            <div className="col s12 m6 l5 xl6">
              <LastTripsTable trips={trips} />
            </div>
            <div className="col s12">
              <RankingTable />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default CompanyHome;
