import dayjs from 'dayjs';
import getMarker from './getMarker';

// Function to add last location markers on the map
function addLastLocationMarker(lastLocationMarker, H, mapObj, mapUI) {
  if (lastLocationMarker) {
    // Default icon
    let iconType = 'location-ok';

    // Check if last location has a timestamp
    if (lastLocationMarker.dateTime) {
      const diff = dayjs().diff(dayjs(lastLocationMarker.dateTime), 'h');

      if (diff >= 120) {
        iconType = 'location-lost';
      } else if (diff >= 24 && diff < 120) {
        iconType = 'location-warn';
      }
    }

    const icon = new H.map.Icon(getMarker(iconType), {
      anchor: new H.math.Point(29, 85)
    });

    // Create a marker using the previously instantiated icon:
    const mapMarker = new H.map.Marker(
      { lat: lastLocationMarker.lat, lng: lastLocationMarker.lng },
      { icon }
    );

    // Add the marker to the map
    mapObj.current.addObject(mapMarker);
  }
}

export default addLastLocationMarker;
