import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { getColors } from '../../../../../utils/colors';
import durationToString from '../../../../../utils/durationToString';
import roundNumber from '../../../../../utils/roundNumber';
import HereMap from '../../../../common/here-map/HereMap';
import './ActivityMap.scss';

function ActivityMap(props) {
  const {
    driver,
    trips,
    activities,
    focus,
    setFocus,
    locationLogs,
    heartbeats,
    day
  } = props;
  const colors = getColors(trips.length);
  const [visible, setVisible] = useState([]);
  const handleHideAndShowRef = useRef(null);
  const [tripsSum, setTripsSum] = useState({
    duration: 0,
    distance: 0,
    coins: 0
  });

  useEffect(() => {
    // Set focus on trip start or first activity
    if (trips && trips[0]) {
      setFocus({
        lat: trips[0].startPosition.lat,
        lon: trips[0].startPosition.lon
      });

      // Sum values displayed in trips table
      sumTripValues();

      const visible = [];
      for (let i = 0; i < trips.length; i++) {
        visible.push(true);
      }
      setVisible([...visible]);
    } else if (activities && activities[0]) {
      setFocus({
        lat: activities[0].position.lat,
        lon: activities[0].position.lon
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function sumTripValues() {
    let duration = 0;
    let distance = 0;
    let coins = 0;
    // Loop through trips
    for (const trip of trips) {
      duration += trip.duration ? roundNumber(trip.duration) : 0;
      distance += trip.distance ? roundNumber(trip.distance) : 0;
      coins += trip.coins ? roundNumber(trip.coins) : 0;
    }
    setTripsSum({ duration, distance, coins });
  }

  function handleScoreColor(number) {
    if (number >= 80) {
      return 'good';
    } else if (number >= 60) {
      return 'regular';
    } else if (number < 60) {
      return 'bad';
    }
  }

  function renderTripsTable() {
    // No trips means no dats
    if (!trips.length) {
      return (
        <div className="airbag-table-container" id="trips-table">
          <table className="airbag-table">
            <thead>
              <tr>
                <th>Viajes realizados</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="center-align">Sin viajes</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="trips-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>Viajes realizados</th>
              <th>Calificación</th>
              <th>Hora</th>
              <th>Puntos</th>
              <th>Duración</th>
              <th>Distancia</th>
              <th>
                Mostrar y <br /> esconder
              </th>
            </tr>
          </thead>
          <tbody>
            {trips.map((trip, idx) => (
              <tr key={trip.id} className="trip-row">
                <td>
                  <FontAwesomeIcon
                    icon="map-marker-alt"
                    className="map-marker"
                    style={{ color: colors[idx] }}
                  />
                  <Link
                    to={`/viajes/viaje?userId=${driver.id}&tripId=${trip.id}`}
                  >
                    Viaje #{idx + 1}
                  </Link>
                </td>
                <td
                  className={`trip-score ${handleScoreColor(
                    trip.score.overall
                  )}`}
                >
                  {trip.score.overall}
                </td>
                <td>
                  {dayjs(trip.startDate).isBefore(dayjs(day).startOf(day))
                    ? '(día anterior)'
                    : ''}{' '}
                  {dayjs(trip.startDate).format('HH:mm')} -{' '}
                  {dayjs(trip.endDate).format('HH:mm')}
                </td>
                <td>{trip.coins ? roundNumber(trip.coins) : 0}</td>
                <td>
                  {durationToString(
                    dayjs.duration(roundNumber(trip.duration), 'minutes')
                  )}
                </td>
                <td>
                  {trip.distance > 10
                    ? `${roundNumber(trip.distance)} km`
                    : `${trip.distance.toFixed(1)} km`}
                </td>
                <td>
                  {visible[idx] ? (
                    <FontAwesomeIcon
                      onClick={() => {
                        handleHideAndShowRef.current(idx);
                        const visibleCopy = [...visible];
                        visibleCopy[idx] = false;
                        setVisible([...visibleCopy]);
                      }}
                      icon="eye"
                      className="eye-icon"
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={() => {
                        handleHideAndShowRef.current(idx);
                        const visibleCopy = [...visible];
                        visibleCopy[idx] = true;
                        setVisible([...visibleCopy]);
                      }}
                      className="eye-icon"
                      icon="eye-slash"
                    />
                  )}
                </td>
              </tr>
            ))}
            <tr className="trip-sum">
              <td></td>
              <td></td>
              <td></td>
              <td>{tripsSum.coins}</td>
              <td>
                {durationToString(
                  dayjs.duration(roundNumber(tripsSum.duration), 'minutes')
                )}
              </td>
              <td>
                {numeral(roundNumber(tripsSum.distance)).format('0,0')} km
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div id="_activity-map_">
      <div className="row">
        <div className="col s12">
          <div id="map">
            <HereMap
              center={{
                lat: focus.lat,
                lng: focus.lon
              }}
              zoom={13}
              height={500}
              activities={activities}
              trips={trips}
              // gapJoins={gapJoins && gapJoins.length ? gapJoins : null}
              handleHideAndShowRef={handleHideAndShowRef}
              locationLogs={locationLogs}
              heartbeats={heartbeats}
              id="here-map-anchor"
            />
          </div>
        </div>
        <div className="col s12">{renderTripsTable()}</div>
      </div>
    </div>
  );
}

export default ActivityMap;
