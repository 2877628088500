import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosAuth from '../../../../utils/axiosAuth';
import durationToString from '../../../../utils/durationToString';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import './VehicleTrips.scss';

function VehicleTrips(props) {
  const vehicleId = props.vehicleId;
  const [trips, setTrips] = useState([]);
  const [loadingTrips, setLoadingTrips] = useState(true);

  useEffect(() => {
    // Get vehicle info
    fetchTrips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchTrips() {
    axiosAuth
      .get(`/api/vehicles/${vehicleId}/trips`)
      .then((res) => {
        const { trips } = res.data;
        setTrips(trips);
        setLoadingTrips(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleScoreColor(number) {
    if (number >= 80) {
      return 'good';
    } else if (number >= 60) {
      return 'regular';
    } else if (number > 0 && number < 60) {
      return 'bad';
    }
    return 'na';
  }

  function renderTripsTable() {
    // Loader en lo que cargan los logs
    if (!trips) {
      return (
        <div className="row box-data">
          <AirbagLoader />
        </div>
      );
    }

    // Si no hay logs
    if (!trips.length) {
      return (
        <div className="row box-data dark">
          <div className="col s12">
            <p className="center-align">Sin viajes</p>
          </div>
        </div>
      );
    }

    return (
      <div id="trips-content">
        {trips.map((trip, idx) => (
          <div className="row box-data" key={idx}>
            {/* eslint-disable */}
            <Link
              to={`/viajes/viaje?userId=${trip.driverData.userId}&tripId=${trip.tripId}`}
            >
              {/* eslint-enable */}
              <div className="row no-margin">
                <div className="col s2 l1 trip-score">
                  <h5 className={handleScoreColor(trip.score.overall)}>
                    {trip.score.overall}
                  </h5>
                </div>
                <div className="col s10 l9">
                  <ul className="trip-points">
                    <li>
                      <FontAwesomeIcon
                        icon="map-marker-alt"
                        className="big-icon"
                      />
                      {moment(trip.endDate).format('DD/MM')},{' '}
                      {moment(trip.endDate).format('HH:mm')}
                      <span>{trip.endAddress}</span>
                    </li>
                    <li>
                      <FontAwesomeIcon icon="circle" className="small-icon" />
                      {moment(trip.startDate).format('DD/MM')},{' '}
                      {moment(trip.startDate).format('HH:mm')}
                      <span>{trip.startAddress}</span>
                    </li>
                  </ul>
                </div>
                <div className="col l2 hide-on-med-and-down">
                  <p>
                    <FontAwesomeIcon icon="clock" className="icon" />
                    {durationToString(
                      moment.duration(Math.ceil(trip.duration), 'minutes')
                    )}
                  </p>
                </div>
              </div>
              <div className="row no-margin">
                <div className="col s12">
                  <p className="driverRow">
                    <FontAwesomeIcon
                      id="driverIcon"
                      icon={['fal', 'user']}
                      className="big-icon"
                    />
                    <span className="driverName">
                      {trip.driverData.fullName}
                    </span>
                  </p>
                </div>
              </div>
            </Link>
          </div>
        ))}
        {loadingTrips ? (
          <div className="row box-data">
            <AirbagLoader />
          </div>
        ) : null}
      </div>
    );
  }

  // Loader
  if (loadingTrips) return <AirbagLoader />;

  return (
    <div className="row">
      <div className="col s12">
        <div className="box-container" id="_vehicle-trips_">
          <div className="row box-top-title">
            <div className="col s12">
              <p>
                <FontAwesomeIcon icon="road" className="box-icon" /> Viajes
                realizados
              </p>
            </div>
          </div>
          {renderTripsTable()}
        </div>
      </div>
    </div>
  );
}

export default VehicleTrips;
