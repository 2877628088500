import React from 'react';
import WeatherTable from './trip-weather-table/WeatherTable';
import './TripWeather.scss';

// Component in charge of displaying the trip's weather info
function TripWeather({ startWeather, endWeather, trip }) {
  // If there is no info, display nothing
  if (!startWeather || !endWeather) {
    return null;
  }

  // Display 2 weather tables , each with its respective info
  return (
    <div className="row no-margin">
      <WeatherTable
        title={'Clima al salir'}
        weather={startWeather}
        address={trip.startAddress}
      />
      <WeatherTable
        title={'Clima al llegar'}
        weather={endWeather}
        address={trip.endAddress}
      />
    </div>
  );
}

export default TripWeather;
