import skins from '../utils/skins';

function skin(state = {}, action) {
  switch (action.type) {
    case 'SET_COMPANY_SKIN': {
      state = { ...state, ...action.payload };
      break;
    }
    case 'UNSET_COMPANY_SKIN': {
      state = skins['airbagtech.io'];
      break;
    }
    default:
      break;
  }
  return state;
}

export default skin;
