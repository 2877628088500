const DEFAULT_SCHEDULE = {
  0: {
    active: false,
    start: '09:00',
    end: '18:00'
  },
  1: {
    active: false,
    start: '09:00',
    end: '18:00'
  },
  2: {
    active: false,
    start: '09:00',
    end: '18:00'
  },
  3: {
    active: false,
    start: '09:00',
    end: '18:00'
  },
  4: {
    active: false,
    start: '09:00',
    end: '18:00'
  },
  5: {
    active: false,
    start: '09:00',
    end: '18:00'
  },
  6: {
    active: false,
    start: '09:00',
    end: '18:00'
  },
  timezone: 'GMT-06'
};

export default DEFAULT_SCHEDULE;
