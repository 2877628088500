import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import AirbagLoader from '../airbag-loader/AirbagLoader';
import renderNotifSource from '../../../utils/renderNotifSource';
import axiosAuth from '../../../utils/axiosAuth';
import './DriverActivations.scss';

function DriverActivations(props) {
  const { driverId } = props;
  const [activations, setActivations] = useState([]);

  useEffect(() => {
    fetchActivations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchActivations() {
    axiosAuth
      .get(`/api/users/${driverId}/activation-history`)
      .then((res) => {
        const { activations } = res.data;
        setActivations(activations);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function renderDateRow(date) {
    const d = dayjs(date);
    const ret = d.format('dddd DD [de] MMMM');
    return ret;
  }

  function renderActivationsRow(notif, idx) {
    const actRow = [];

    if (!idx) {
      actRow.push(
        <div key="first-row" className="date-row">
          {renderDateRow(notif.created)}
        </div>
      );
    } else {
      // Get previous notif
      const prevNotif = activations[idx - 1];

      // Get dates for validation
      const currentDate = dayjs(notif.created).format('YYYY-MM-DD');
      const prevDate = dayjs(prevNotif.created).format('YYYY-MM-DD');

      // Add row for change in day
      if (currentDate !== prevDate) {
        actRow.push(
          <div key={idx} className="date-row">
            {renderDateRow(currentDate)}
          </div>
        );
      }
    }

    actRow.push(
      <div key={notif.id} className="activation-row">
        <div className="date-col custom-col">
          <p className="act-date">{dayjs(notif.created).format('HH:mm')}</p>
        </div>
        <div className="content-col custom-col">
          <div className="act-title">
            <span
              className={classNames('status', {
                'green-s': notif.appStatus === 'active',
                'red-s': notif.appStatus === 'inactive'
              })}
            >
              {notif.appStatus === 'active' ? 'Activación' : 'Desactivación'}
            </span>
          </div>
          <div className="act-body">Por {renderNotifSource(notif)}</div>
        </div>
      </div>
    );

    return actRow;
  }

  function renderActivations() {
    if (!activations) {
      return <AirbagLoader />;
    }

    if (!activations.length) {
      return <p>Sin activaciones</p>;
    }

    return activations.map((notif, idx) => renderActivationsRow(notif, idx));
  }

  return <div id="_driver-activations_">{renderActivations()}</div>;
}

export default DriverActivations;
