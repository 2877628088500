import React, { useState, useEffect } from 'react';
import axiosAuth from '../../../../utils/axiosAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Stat from '../../../common/stat/Stat';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import './IndividualScores.scss';

function IndividualScores(props) {
  const vehicleId = props.vehicleId;
  const [scores, setScores] = useState([]);
  const [loadingScores, setLoadingScores] = useState(true);

  useEffect(() => {
    // Get vehicle info
    fetchScores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchScores() {
    axiosAuth
      .get(`/api/vehicles/${vehicleId}/individualScores`)
      .then((res) => {
        const { scores } = res.data;
        setScores(scores);

        setLoadingScores(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleScoreColor(number) {
    if (number >= 80) {
      return 'green';
    } else if (number >= 60) {
      return 'yellow';
    } else if (number > 0 && number < 60) {
      return 'red';
    }
    return 'na';
  }

  function renderScoresTable() {
    // Si no hay logs
    if (scores.length === 0) {
      return (
        <div className="row box-data dark">
          <div className="col s12">
            <p className="center-align">Sin datos</p>
          </div>
        </div>
      );
    }

    return (
      <div id="scores-content">
        {scores.map((score, idx) => (
          <div className="row box-data" key={idx}>
            <div className="row">
              <div className="col s12">
                <p className="driverRow">
                  <FontAwesomeIcon
                    icon={['fal', 'user']}
                    className="driverIcon"
                  />
                  {score.driver}
                </p>
              </div>
            </div>
            <div className="row metrics-table">
              <div className="col s12">
                <p className="table-title">Hábitos de conducción</p>
              </div>
              <div className="col s12 l2 wrapp">
                <Stat
                  value={score.score.overall ? score.score.overall : 0}
                  color={handleScoreColor(score.score.overall)}
                />
              </div>
              <div className="col s6 m4 l2 wrapp">
                <p className="stat-title">Aceleración</p>
                <p
                  className={`stat-value st-${handleScoreColor(
                    score.score.acceleration
                  )}`}
                >
                  {score.score.acceleration ? score.score.acceleration : '-'}
                  <span> / 100</span>
                </p>
              </div>
              <div className="col s6 m4 l2 wrapp">
                <p className="stat-title">Frenado</p>
                <p
                  className={`stat-value st-${handleScoreColor(
                    score.score.braking
                  )}`}
                >
                  {score.score.braking ? score.score.braking : '-'}{' '}
                  <span>/ 100</span>
                </p>
              </div>
              <div className="col s6 m4 l2 wrapp">
                <p className="stat-title">Curvas</p>
                <p
                  className={`stat-value st-${handleScoreColor(
                    score.score.cornering
                  )}`}
                >
                  {score.score.cornering ? score.score.cornering : '-'}{' '}
                  <span>/ 100</span>
                </p>
              </div>
              <div className="col s6 m6 l2 wrapp">
                <p className="stat-title">Uso de celular</p>
                <p
                  className={`stat-value st-${handleScoreColor(
                    score.score.phoneDistraction
                  )}`}
                >
                  {score.score.phoneDistraction
                    ? score.score.phoneDistraction
                    : '-'}{' '}
                  <span>/ 100</span>
                </p>
              </div>
              <div className="col s12 m6 l2 wrapp">
                <p className="stat-title">Exceso velocidad</p>
                <p
                  className={`stat-value st-${handleScoreColor(
                    score.score.speeding
                  )}`}
                >
                  {score.score.speeding ? score.score.speeding : '-'}{' '}
                  <span>/ 100</span>
                </p>
              </div>
            </div>
            <div className="row metrics-table">
              <div className="col s12">
                <p className="table-title">Conducción ecológica</p>
              </div>
              <div className="col s12 l2 wrapp">
                <Stat
                  value={score.ecoScore.overall ? score.ecoScore.overall : 0}
                  color={handleScoreColor(score.ecoScore.overall)}
                />
              </div>
              <div className="col s6 l2 wrapp">
                <p className="stat-title">Combustible</p>
                <p
                  className={`stat-value st-${handleScoreColor(
                    score.ecoScore.fuel
                  )}`}
                >
                  {score.ecoScore.fuel ? score.ecoScore.fuel : '-'}{' '}
                  <span>/ 100</span>
                </p>
              </div>
              <div className="col s6 l2 wrapp">
                <p className="stat-title">Llantas</p>
                <p
                  className={`stat-value st-${handleScoreColor(
                    score.ecoScore.tires
                  )}`}
                >
                  {score.ecoScore.tires ? score.ecoScore.tires : '-'}{' '}
                  <span>/ 100</span>
                </p>
              </div>
              <div className="col s6 l2 wrapp">
                <p className="stat-title">Frenos</p>
                <p
                  className={`stat-value st-${handleScoreColor(
                    score.ecoScore.brakes
                  )}`}
                >
                  {score.ecoScore.brakes ? score.ecoScore.brakes : '-'}{' '}
                  <span>/ 100</span>
                </p>
              </div>
            </div>
          </div>
        ))}
        {loadingScores ? (
          <div className="row box-data">
            <AirbagLoader />
          </div>
        ) : null}
      </div>
    );
  }

  // Loader
  if (loadingScores) return <AirbagLoader />;

  return (
    <div className="row">
      <div className="col s12">
        <div className="box-container" id="_vehicle-individual-scores_">
          <div className="row box-top-title">
            <div className="col s12">
              <p>
                <FontAwesomeIcon icon={['fal', 'user']} className="box-icon" />
                Calificación por conductor
              </p>
            </div>
          </div>
          {renderScoresTable()}
        </div>
      </div>
    </div>
  );
}

export default IndividualScores;
