import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

function HabitsScorings(props) {
  const { title, labels, data, colors } = props;
  const chartContainer = useRef(null);

  const chartConfig = {
    type: 'polarArea',
    data: {
      labels,
      datasets: [
        {
          data,
          fill: true,
          borderColor: '#ffffff',
          backgroundColor: colors,
          borderWidth: 2
        }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      resizeDelay: 800,
      scales: {
        r: {
          pointLabels: {
            display: true,
            centerPointLabels: true,
            font: {
              size: 12
            }
          },
          beginAtZero: true,
          max: 100,
          angleLines: {
            color: '#e6e6e6'
          },
          grid: {
            color: '#e6e6e6'
          },
          ticks: {
            color: '#464646'
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        // https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
        tooltip: {
          backgroundColor: '#464646',
          padding: 12,
          displayColors: false
        },
        title: {
          text: title,
          display: true,
          color: '#000',
          padding: 18,
          font: {
            size: 14
          }
        }
      }
    }
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      new Chart(chartContainer.current, chartConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContainer]);

  return <canvas ref={chartContainer} />;
}

export default HabitsScorings;
