/*
IMPORTANT
This list must match exactly the one in /server/utils/vehicleTypes.js
as server validation is in place.
*/

const vehicleTypes = [
  {
    name: 'Automóvil',
    value: 's-vehicle',
    height: '1.8',
    width: '1.8',
    length: '4.5'
  },
  {
    name: 'Furgoneta',
    value: 'van',
    height: '2.5',
    width: '2.1',
    length: '5.5'
  },
  {
    name: 'Autobus 2 ejes',
    value: 'B2',
    height: '3',
    width: '2.6',
    length: '14'
  },
  {
    name: 'Autobus escolar',
    value: 'ae',
    height: '3.85',
    width: '2.1',
    length: '14'
  },
  {
    name: 'Autobus turístico 1 piso',
    value: 'B3',
    height: '3.85',
    width: '2.6',
    length: '14'
  },
  {
    name: 'Autobus turístico de 2 pisos',
    value: 'B4',
    height: '4.50',
    width: '2.6',
    length: '14'
  },
  {
    name: 'Camión unitario/rabón 2 ejes',
    value: 'C2',
    height: '4.25',
    width: '2.6',
    length: '14'
  },
  {
    name: 'Camión unitario/torton 3 ejes',
    value: 'C3',
    height: '4.25',
    width: '2.6',
    length: '14'
  },
  {
    name: 'Camión remolque 4 ejes',
    value: 'C2-R2',
    height: '4.25',
    width: '2.6',
    length: '31'
  },
  {
    name: 'Camión remolque 5 ejes',
    value: 'C3-R2',
    height: '4.25',
    width: '2.6',
    length: '31'
  },
  {
    name: 'Camión remolque 6 ejes',
    value: 'C3-R3',
    height: '4.25',
    width: '2.6',
    length: '31'
  },
  {
    name: 'Tractocamión articulado 3 ejes',
    value: 'T2-S1',
    height: '4.25',
    width: '2.6',
    length: '23'
  },
  {
    name: 'Tractocamión articulado 4 ejes',
    value: 'T2-S2',
    height: '4.25',
    width: '2.6',
    length: '23'
  },
  {
    name: 'Tractocamión articulado 5 ejes',
    value: 'T2-S3',
    height: '4.25',
    width: '2.6',
    length: '23'
  },
  {
    name: 'Tractocamión articulado/caja cerrada 5 ejes',
    value: 'T3-S2',
    height: '4.25',
    width: '2.6',
    length: '23'
  },
  {
    name: 'Tractocamión articulado 6 ejes',
    value: 'T3-S3',
    height: '4.25',
    width: '2.6',
    length: '23'
  },
  {
    name: 'Tractocamión semiremolque remolque 5 ejes',
    value: 'T2-S1-R2',
    height: '4.25',
    width: '2.6',
    length: '31'
  },
  {
    name: 'Tractocamión semiremolque remolque 6 ejes',
    value: 'T2-S2-R2',
    height: '4.25',
    width: '2.6',
    length: '31'
  },
  {
    name: 'Tractocamión semiremolque remolque 7 ejes',
    value: 'T3-S1-R3',
    height: '4.25',
    width: '2.6',
    length: '31'
  },
  {
    name: 'Tractocamión semiremolque remolque 8 ejes',
    value: 'T3-S2-R3',
    height: '4.25',
    width: '2.6',
    length: '31'
  },
  {
    name: 'Tractocamión semiremolque remolque 9 ejes',
    value: 'T3-S2-R4',
    height: '4.25',
    width: '2.6',
    length: '31'
  },
  {
    name: 'Turibus 2 ejes',
    value: 't2-e',
    height: '4.5',
    width: '2.6',
    length: '12'
  },
  {
    name: 'Cargo Van 2 ejes',
    value: 'c-v',
    height: '1.7',
    width: '1.95',
    length: '5.9'
  },
  {
    name: 'Motocicleta',
    value: 'motorcycle',
    height: '1.4',
    width: '0.9',
    length: '2.2'
  },
  {
    name: 'Otro',
    value: 'other',
    height: '',
    width: '',
    length: ''
  }
];

export default vehicleTypes;
