export const logsDefinitions = [
  // Telemetry
  {
    name: 'Conducción',
    slug: 'telemetry',
    icon: 'tachometer-alt',
    airbagIcon: 'velocimeter',
    color: '#81d8fe',
    colorLight: '#e6f7ff',
    markerImg: 'speeding'
  },
  {
    name: 'Exceso de velocidad',
    slug: 'speeding',
    icon: 'tachometer-alt',
    airbagIcon: 'velocimeter',
    color: '#9ac4e5',
    colorLight: '#e6f7ff',
    markerImg: 'speeding'
  },
  {
    name: 'Aceleración brusca',
    slug: 'acc',
    icon: 'car-alt',
    airbagIcon: 'acc',
    color: '#aadf9f',
    colorLight: '#e8f7fd',
    markerImg: 'acc'
  },
  {
    name: 'Frenado brusco',
    slug: 'deacc',
    icon: 'car-alt',
    airbagIcon: 'deacc',
    color: '#aa99e6',
    colorLight: '#e8f6fc',
    markerImg: 'deacc'
  },
  {
    name: 'Curva peligrosa',
    slug: 'cornering',
    icon: 'car-crash',
    airbagIcon: 'cornering',
    color: '#8ee2f0',
    colorLight: '#ff80bf',
    markerImg: 'cornering'
  },
  {
    name: 'Uso de celular',
    slug: 'phone-distraction',
    icon: 'phone',
    airbagIcon: 'phone',
    color: '#ffc880',
    colorLight: '#ffe6f2',
    markerImg: 'phone_distraction'
  },
  /* Calls */
  {
    name: 'Llamada entrante',
    slug: 'llamada_entrante',
    icon: 'phone-volume',
    airbagIcon: 'callIn',
    color: '#ff8080',
    colorLight: '#ebe5ff',
    markerImg: 'llamada_entrante'
  },
  {
    name: 'Llamada saliente',
    slug: 'llamada_saliente',
    icon: 'phone-volume',
    airbagIcon: 'callOut',
    color: '#ff8080',
    colorLight: '#ebe5ff',
    markerImg: 'llamada_saliente'
  },
  /* NOM-087 */
  {
    name: 'Parada',
    slug: 'parada', // old
    icon: 'bus',
    airbagIcon: 'stop',
    color: '#ffa480',
    colorLight: '#ddf2d9',
    markerImg: 'parada'
  },
  {
    name: 'Pausa / Parada',
    slug: 'stop', // new
    icon: 'bus',
    airbagIcon: 'stop',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'parada'
  },
  {
    name: 'Descanso',
    slug: 'break',
    icon: 'bed',
    airbagIcon: 'break',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'break'
  },
  /* Aux activities */
  {
    name: 'Gasolina',
    slug: 'gas',
    icon: 'gas-pump',
    airbagIcon: 'gas',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'gas'
  },
  {
    name: 'Limpieza o mantenimiento',
    slug: 'maintenance',
    icon: 'cogs',
    airbagIcon: 'maintenance',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'maintenance'
  },
  {
    name: 'Obligaciones administrativas',
    slug: 'administrative',
    icon: 'cogs',
    airbagIcon: 'report',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'administrative'
  },
  {
    name: 'Carga / Descarga',
    slug: 'load-handle',
    icon: 'dolly',
    airbagIcon: 'loadHandle',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'load_handle'
  },
  {
    name: 'Actividad auxiliar',
    slug: 'other-aux',
    icon: 'cogs',
    airbagIcon: 'auxActivity',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'aux_activity'
  },
  /* Delivery */
  {
    name: 'Entrega',
    slug: 'entrega',
    icon: 'box-open',
    airbagIcon: 'delivery',
    color: '#ff9800',
    colorLight: '#ffeed5',
    markerImg: 'entrega'
  },
  /* Reports */
  {
    name: 'Construcción',
    slug: 'construction',
    icon: 'tools',
    airbagIcon: 'construction',
    color: '#a39be2',
    colorLight: '#f3f2ff',
    markerImg: 'construction'
  },
  {
    name: 'Vialidad cerrada',
    slug: 'closed_road',
    icon: 'road',
    airbagIcon: 'closedRoad',
    color: '#a39be2',
    colorLight: '#f3f2ff',
    markerImg: 'closed_road'
  },
  {
    name: 'Tráfico',
    slug: 'traffic',
    icon: 'car-alt',
    airbagIcon: 'traffic',
    color: '#a39be2',
    colorLight: '#f3f2ff',
    markerImg: 'traffic'
  },
  {
    name: 'Problema técnico',
    slug: 'tech_problem',
    icon: 'cogs',
    airbagIcon: 'techProblem',
    color: '#a39be2',
    colorLight: '#f3f2ff',
    markerImg: 'tech_problem'
  },
  {
    name: 'Accidente Vial',
    slug: 'road_accident',
    icon: 'crash',
    airbagIcon: 'crash',
    color: '#a39be2',
    colorLight: '#f3f2ff',
    markerImg: 'road_accident'
  },
  {
    name: 'Reporte',
    slug: 'other-report',
    icon: 'warning',
    airbagIcon: 'auxActivity',
    color: '#a39be2',
    colorLight: '#f3f2ff',
    markerImg: 'report'
  },
  /* ??? */
  {
    name: 'Alerta', // Will be deprecated
    slug: 'alerta',
    icon: 'exclamation',
    airbagIcon: 'alert',
    color: '#ff0080',
    colorLight: '#ffe6f2',
    markerImg: 'warning'
  },
  {
    name: 'Patio vehicular',
    slug: 'vehicle-yard',
    icon: 'warehouse',
    airbagIcon: 'stop',
    color: '#63c450',
    colorLight: '#ddf2d9',
    markerImg: 'parada'
  },
  {
    name: 'Datos desactivados',
    slug: 'datos_desactivados',
    icon: 'signal',
    airbagIcon: 'signal',
    color: '#f7836e',
    colorLight: '#f5dcd7',
    markerImg: 'datos_desactivados'
  },
  {
    name: 'Datos activados',
    slug: 'datos_activados',
    icon: 'signal',
    airbagIcon: 'signal',
    color: '#f4563a',
    colorLight: '#f5dcd7',
    markerImg: 'datos_activados'
  },
  /* Start / end workday */
  {
    name: 'Inicio de jornada laboral',
    slug: 'start_workday',
    icon: 'signal',
    airbagIcon: 'truck',
    color: '#008e6c',
    colorLight: '#c3eddc',
    markerImg: 'start_workday'
  },
  {
    name: 'Fin de jornada laboral',
    slug: 'end_workday',
    icon: 'signal',
    airbagIcon: 'truck',
    color: '#c91316',
    colorLight: '#ffdad6',
    markerImg: 'end_workday'
  },
  /* Manual activations / deactivations */
  {
    name: 'Activación manual',
    slug: 'manual_activation',
    icon: 'signal',
    airbagIcon: 'truck',
    color: '#008e6c',
    colorLight: '#c3eddc',
    markerImg: 'start_workday'
  },
  {
    name: 'Desactivación manual',
    slug: 'manual_deactivation',
    icon: 'signal',
    airbagIcon: 'truck',
    color: '#c91316',
    colorLight: '#ffdad6',
    markerImg: 'end_workday'
  },
  /* Other */
  {
    name: 'Reporte',
    slug: 'other',
    icon: 'cogs',
    airbagIcon: 'techProblem',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'tech_problem'
  },
  /* Deprecated */
  {
    name: 'Bloqueo desactivado', // Deprecated
    slug: 'aplicacion_desbloqueada',
    icon: 'mobile-alt',
    airbagIcon: 'alert',
    color: '#a7caf1',
    colorLight: '#dfe8f2',
    markerImg: 'danger'
  },
  {
    name: 'Bloqueo activado', // Deprecated
    slug: 'aplicacion_bloqueada',
    icon: 'mobile-alt',
    airbagIcon: 'alert',
    color: '#3a87df',
    colorLight: '#dfe8f2',
    markerImg: 'danger'
  },
  {
    name: 'Tienda', // Deprecated
    slug: 'shop',
    icon: 'store-alt',
    airbagIcon: 'stop',
    color: '#63c450',
    colorLight: '#ddf2d9',
    markerImg: 'parada'
  },
  {
    name: 'Baño', // Deprecated
    slug: 'restroom',
    icon: 'restroom',
    airbagIcon: 'stop',
    color: '#63c450',
    colorLight: '#ddf2d9',
    markerImg: 'parada'
  },
  {
    name: 'Comida', // Deprecated
    slug: 'meal',
    icon: 'utensils',
    airbagIcon: 'stop',
    color: '#63c450',
    colorLight: '#ddf2d9',
    markerImg: 'parada'
  },
  {
    name: 'Policía', // Deprecated
    slug: 'police',
    icon: 'shield-alt',
    airbagIcon: 'police',
    color: '#ff0080',
    colorLight: '#ffe6f2',
    markerImg: 'police'
  },
  {
    name: 'Robo', // Deprecated
    slug: 'robbery',
    icon: 'exclamation',
    airbagIcon: 'alert',
    color: '#ff0080',
    colorLight: '#ffe6f2',
    markerImg: 'robbery'
  }
];
