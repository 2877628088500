import React, { useEffect, useLayoutEffect, useRef } from 'react';
import H from '@here/maps-api-for-javascript';
import { getColors } from '../../../utils/colors';
import addDriversMarkers from '../../../utils/hereMap/addDriversMarkers';
// eslint-disable-next-line max-len
import addReportLocationMarkers from '../../../utils/hereMap/addReportLocationMarkers';
// eslint-disable-next-line max-len
import addLastLocationMarker from '../../../utils/hereMap/addLastLocationMarker';
import displayTrip from '../../../utils/hereMap/displayTrip';
// import fillGaps from '../../../utils/hereMap/fillGaps';
import displayActivities from '../../../utils/hereMap/displayActivities';
import dragableMarker from '../../../utils/hereMap/dragableMarker';
import displayLocationLogs from '../../../utils/hereMap/displayLocationLogs';
import displayHeartbeats from '../../../utils/hereMap/displayHeartbeats';
import './HereMap.scss';

// Component that represents an instance of a HERE Map,
// functionality of the map will change depending of the
// props that ared passed through, in order to add a new functionality,
// a new prop would be required with its corresponding useEffect hook.
// Docs: https://developer.here.com/tutorials/react/
function HereMap(props) {
  const {
    width,
    height,
    center,
    zoom,
    showTraffic,
    showIncidents,
    driversMarkers,
    lastLocationMarker,
    reportMarker,
    trip,
    filters,
    trips,
    gapJoins,
    handleHideAndShowRef,
    activities,
    id,
    applyBoundingBox,
    markersDragable,
    setMarkersDragable,
    setWaypoints,
    waypoints,
    setLoading,
    setCenter,
    locationLogs,
    heartbeats
  } = props;

  // References for the map, ui, etc.
  const mapRef = useRef(null);
  const mapObj = useRef(null);
  const mapUI = useRef(null);
  const platformRef = useRef(null);
  const tripsGroups = useRef([]);
  const tripGroup = useRef(null);
  const behavior = useRef(null);

  // Create a map object at component initialization
  useLayoutEffect(() => {
    // Attach function to parent for hiding and showing a group
    if (handleHideAndShowRef) {
      handleHideAndShowRef.current = handleHideAndShow;
    }

    // mapRef.current will be undefined when
    // this hook first runs; edge case that
    if (!mapRef.current) {
      return;
    }

    // Initialize main service
    const platform = new H.service.Platform({
      apikey: process.env.REACT_APP_HERE_MAPS_API_KEY
    });
    // Save service in ref variable
    platformRef.current = platform;

    const layers = platform.createDefaultLayers();

    // Create an instance of the map
    const map = new H.Map(mapRef.current, layers.vector.normal.map, {
      pixelRatio: window.devicePixelRatio || 1,
      center,
      zoom
    });
    mapObj.current = map;

    // Show the layers based on props
    if (showTraffic) {
      mapObj.current.addLayer(layers.vector.normal.traffic);
    }
    if (showIncidents) {
      mapObj.current.addLayer(layers.vector.normal.trafficincidents);
    }

    // Add the interactive behaviour to the map
    behavior.current = new H.mapevents.Behavior(
      new H.mapevents.MapEvents(mapObj.current)
    );

    // Create the basic controls for the map
    const ui = H.ui.UI.createDefault(mapObj.current, layers, 'es-ES');
    mapUI.current = ui;

    // Handle on map resize
    const onResize = () => {
      mapObj.current.getViewPort().resize();
    };
    window.addEventListener('resize', onResize);

    return () => {
      // Unsubscribe event listeners
      window.removeEventListener('resize', onResize);

      // Destroy map
      mapObj.current.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef]);

  // Whenever the center prop changes, update the center of the map
  useEffect(() => {
    if (mapObj && mapObj.current) {
      mapObj.current.setCenter(center);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);

  // Add driver markers on the map
  useEffect(() => {
    if (driversMarkers && driversMarkers.length) {
      addDriversMarkers(driversMarkers, H, mapUI, mapObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driversMarkers]);

  // Add last location markers
  useEffect(() => {
    if (reportMarker) {
      addReportLocationMarkers(reportMarker, H, mapObj, mapUI);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportMarker]);

  useEffect(() => {
    if (lastLocationMarker) {
      addLastLocationMarker(lastLocationMarker, H, mapObj, mapUI);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLocationMarker]);

  // Display a trip on the map
  useEffect(() => {
    if (trip && trip.locationLogs && trip.locationLogs.length) {
      // This will run on second render. As filters change, we clean up the map
      if (tripGroup && tripGroup.current) {
        // Remove trip group
        mapObj.current.removeObject(tripGroup.current);
      }

      displayTrip(
        H,
        mapObj,
        mapUI,
        tripGroup,
        applyBoundingBox,
        trip,
        '#1eb1fc',
        filters
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip, filters]);

  // Handle daily activity of a driver
  useEffect(() => {
    if (trips && trips.length) {
      // Get colors to paint trips
      const colors = getColors(trips.length);

      // Loop through each trip
      for (let i = 0; i < trips.length; i++) {
        const trip = trips[i];
        const group = new H.map.Group();

        // Build group object
        const groupObj = {
          // We add 'current' because displayTrip expects a useRef
          group: { current: group }
        };

        // Add group object to array
        tripsGroups.current.push(groupObj);

        // Display trip
        displayTrip(H, mapObj, mapUI, groupObj.group, false, trip, colors[i]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trips]);

  // UseEffect to place activities markers
  useEffect(() => {
    if (activities && activities.length) {
      displayActivities(H, mapObj, mapUI, activities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activities]);

  // Fill gaps between trips (TODO)
  useEffect(() => {
    if (gapJoins && gapJoins.length) {
      // fillGaps(gapJoins, H, mapObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gapJoins]);

  // add dragable markers
  useEffect(() => {
    if (markersDragable && markersDragable.length) {
      dragableMarker(
        mapObj,
        behavior,
        H,
        markersDragable,
        setMarkersDragable,
        setWaypoints,
        waypoints,
        setLoading,
        setCenter
      );
    }
  }, [markersDragable]);

  // Add location logs on the map
  useEffect(() => {
    if (locationLogs && locationLogs.length) {
      displayLocationLogs(locationLogs, H, mapUI, mapObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationLogs]);

  // Add heartbeats on the map
  useEffect(() => {
    if (heartbeats && heartbeats.length) {
      displayHeartbeats(heartbeats, H, mapUI, mapObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heartbeats]);

  // Function to hide and show trips
  function handleHideAndShow(index) {
    // Check current viibility status
    const isVisible = tripsGroups.current[index].group.current.getVisibility();
    // Change visibility
    tripsGroups.current[index].group.current.setVisibility(!isVisible);
  }

  return (
    <div
      style={{
        width: width ? width : '100%',
        height: height ? height : '300px'
      }}
      ref={mapRef}
      id={id}
    ></div>
  );
}

export default HereMap;
