import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import M from 'materialize-css';
import { posthog } from 'posthog-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import ReactGA from 'react-ga4';
import ReactTooltip from 'react-tooltip';
import SideModal from '../../common/side-modal/SideModal';
import Error from '../../common/error/Error';
import DriverQuickView from '../../common/driver-quick-view/DriverQuickView';
import Icon from '../../common/airbag-icons/Icon';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import HeaderSection from '../../common/header-section/HeaderSection';
import { sanitizeAppStatus } from '../../../utils/sanitizers';
import axiosAuth from '../../../utils/axiosAuth';
import lastSeen from '../../../utils/lastSeen';
import AddDriver from './add-driver/AddDriver';
import Filters from './filters/Filters';
import './CompanyDrivers.scss';
import roundNumber from '../../../utils/roundNumber';

// Get first group in array or nothing if you are admin
function getGroupFromArray(authRole, arr) {
  let ret = '';

  // No default group selected to admins
  if (authRole === 'admin') {
    return ret;
  }

  if (arr && typeof arr === 'object' && arr.length) {
    // Assign first group in array
    ret = arr[0].id;
  }

  return ret;
}

// Initial filters, not applied by default
function initialFilters(authRole, groups) {
  return {
    applied: false,
    status: '',
    group: getGroupFromArray(authRole, groups)
  };
}

function CompanyDrivers(props) {
  const { company, user, companyGroup } = props;
  const { groups } = user;
  const videoUrl =
    'https://www.youtube.com/embed/8gMDBAPBpj4?list=PLPbFMY-3HtQvwCS0QjdMy56MiOY3Q9wJQ';
  const triggerPaginationRef = useRef(null);
  const last = useRef('');
  const keepLooking = useRef(true);
  const loading = useRef(false);
  const [error, setError] = useState(null);
  const [drivers, setDrivers] = useState(null);
  const [query, setQuery] = useState('');
  const [newDriverOpen, setNewDriverOpen] = useState(false);
  const [loadingCAS, setLoadingCAS] = useState(false);
  const [sort, setSort] = useState({ key: 'fullName', order: 'desc' });
  const [filters, setFilters] = useState(
    initialFilters(props.user.authRole, groups)
  );
  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const [quickViewOpen, setQuickViewOpen] = useState(false);

  // Handle filters
  useEffect(() => {
    // We send true so driver's array is emptied after filter being applied
    if (filters.applied) {
      fetchUsers(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // Search for user with Algolia
  useEffect(() => {
    // We send true so driver's array is emptied
    // after being searched for something
    fetchUsers(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const onScroll = (e) => {
      // If reference to last driver row exists and its in
      // view and fetchUsers keeps returning result
      if (
        drivers &&
        triggerPaginationRef &&
        triggerPaginationRef.current &&
        isLastDriverInViewport() &&
        !loading.current &&
        keepLooking.current &&
        !query
      ) {
        fetchUsers(false);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drivers, triggerPaginationRef, loading.current, keepLooking, query]);

  function fetchUsers(cleanDriversArray) {
    // Loader inside table
    if (cleanDriversArray) setDrivers(null);
    // Loader inside loop list
    loading.current = true;

    /* Build query parameters */
    // Get location for last seen
    const queryParameters = { getLocation: true };
    // Algolia Search
    if (query) queryParameters.query = query;
    // Pagination
    if (last.current && !cleanDriversArray) queryParameters.last = last.current;
    // Status filter
    if (filters.status) queryParameters.status = filters.status;
    // Group
    if (filters.group) queryParameters.group = filters.group;

    // Get company drivers
    axiosAuth
      .get(`/api/users`, {
        params: queryParameters
      })
      .then((res) => {
        const { drivers: driversRes } = res.data;

        // Save last driver id for pagination
        // before messing with driversRes array
        let lastDriverId = null;
        if (driversRes.length) {
          lastDriverId = driversRes[driversRes.length - 1].id;
        }

        // Process lastSeenSince info for filtering
        const activeDrivers = insertLastSeen(driversRes);

        if (query || cleanDriversArray) {
          // If there is a query, set full results to driver's array
          setDrivers(sortArrayByKey(activeDrivers, sort.key, sort.order));
        } else {
          // Append new drivers response to driver's array
          const driversCopy = drivers
            ? [...drivers, ...activeDrivers]
            : [...activeDrivers];
          // Order by key and apply current filter
          setDrivers(sortArrayByKey(driversCopy, sort.key, sort.order));
        }

        // Stop or resume infinite scrolling
        // The < 50 is hardcoded and must be changed (results per page)
        if (!driversRes.length || driversRes.length < 50 || filters.group) {
          keepLooking.current = false;
          last.current = '';
        } else {
          keepLooking.current = true;
          // Save last driver's id to continue query
          last.current = lastDriverId;
        }

        // Stop loader
        loading.current = false;
      })
      .catch((err) => {
        console.log(err);
        loading.current = false;
        setError(err);
      });
  }

  function handleAppStatusClick(driver) {
    Swal.fire({
      icon: 'question',
      title: `¿Deseas ${
        driver.appStatus === 'active' ? 'desactivar' : 'activar'
      } a ${driver.fullName}?`,
      showCancelButton: true,
      confirmButtonText: `<span class="dark-color-text">Si, ${
        driver.appStatus === 'active' ? 'desactivar' : 'activar'
      }</span>`,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f6c700'
    }).then((result) => {
      if (result.isConfirmed) {
        changeAppStatus(driver.userId, driver.pushToken);

        // Google analytics
        if (driver.appStatus === 'active') {
          ReactGA.event({
            category: 'drivers_view',
            action: 'deactivate_driver_from_quickview'
          });
          posthog.capture(`deactivate_driver_from_quickview`, {
            category: 'drivers_view',
            isCustomEvent: true
          });
        } else {
          ReactGA.event({
            category: 'drivers_view',
            action: 'activate_driver_from_quickview'
          });
          posthog.capture(`activate_driver_from_quickview`, {
            category: 'drivers_view',
            isCustomEvent: true
          });
        }
      }
    });
  }

  function changeAppStatus(userId, pushToken) {
    setLoadingCAS(true);

    axiosAuth
      .post(`/api/users/change-app-status`, {
        driverId: userId,
        pushToken
      })
      .then((res) => {
        const { newAppStatus } = res.data;
        M.toast({
          html: `Usuario ${
            newAppStatus === 'active' ? 'activado' : 'desactivado'
          } correctamente`,
          classes: 'success-toast'
        });

        // Search affected user in array and update
        const driversCopy = [...drivers];
        driversCopy.forEach((driver) => {
          if (driver.id === userId) {
            driver.appStatus = newAppStatus;
          }
        });
        setDrivers(driversCopy);

        setLoadingCAS(false);
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: 'Tuvimos un error, intenta de nuevo más tarde',
          classes: 'error-toast'
        });
        setLoadingCAS(false);
      });
  }

  function isLastDriverInViewport() {
    // Set an offset to trigger action before target element is in view
    const offset = 100;

    const top = triggerPaginationRef.current.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  }

  function sortArrayByKey(array, key, order) {
    if (typeof array === 'object') {
      const arrayCopy = [...array];

      if (order === 'desc') {
        arrayCopy.sort(function compare(a, b) {
          if (a[key] < b[key]) return -1;
          if (a[key] > b[key]) return 1;
          return 0;
        });
      } else if (order === 'asc') {
        arrayCopy.sort(function compare(a, b) {
          if (b[key] < a[key]) return -1;
          if (b[key] > a[key]) return 1;
          return 0;
        });
      }

      return arrayCopy;
    }

    return array;
  }

  function insertLastSeen(rawDrivers) {
    const ret = [];

    rawDrivers.forEach((d) => {
      // Default a negative number for filtering
      d.lastSeenSince = -1;

      // Validate driver's position
      if (d.position && d.position.dateTime) {
        // Make diff negative to always have drivers with no locations last
        d.lastSeenSince = dayjs().diff(dayjs(d.position.dateTime), 'm');
      }

      // Add to array
      ret.push(d);
    });

    return ret;
  }

  function renderLastSeenColor(driver) {
    const { isResting, lastSeenSince } = driver;

    // Skip resting drivers
    if (isResting) {
      return 'none';
    }

    // Handle color based on minutes
    if (lastSeenSince < 1440) {
      return 'good';
    }
    if (lastSeenSince >= 1440 && lastSeenSince < 7200) {
      return 'regular';
    }
    if (lastSeenSince > 7200) {
      return 'bad';
    }

    return 'none';
  }

  function renderDriverStatus(driver) {
    const { firstLogin, isResting, appStatus, isSDKEnabled, status } = driver;

    // Discard disabled drivers
    if (status === 'inactive') {
      return <span className="pill red-s">Deshabilitado</span>;
    }

    // First validation, check if user has logged in
    if (firstLogin) {
      return <span className="pill red-s">Sin configurar</span>;
    }

    // Handle users that are resting only for TMS
    if (isResting) {
      return <span className="pill grey-s">En descanso</span>;
    }

    // Default is active or inactive
    return (
      <span
        className={classNames('pill', {
          'green-s': appStatus === 'active',
          'grey-s': appStatus === 'inactive'
        })}
      >
        {sanitizeAppStatus(appStatus, isSDKEnabled)}
      </span>
    );
  }

  function renderLastSeenText(driver) {
    const { position } = driver;
    if (!position.dateTime) {
      return '';
    }

    return (
      <span className={`last-seen ${renderLastSeenColor(driver)}`}>
        {lastSeen(position.dateTime)}
      </span>
    );
  }

  function renderDriverConnection(driver) {
    const { firstLogin, isResting, appStatus, lastSeenSince, status } = driver;

    // Discard inactive, not configured and resting drivers
    if (status === 'inactive' || firstLogin || isResting) {
      return null;
    }

    // For inactive drivers
    if (appStatus === 'inactive') {
      return null;
    }

    // Handle if user is lost
    if (lastSeenSince >= 7200) {
      return <span className="pill red-s">Perdido</span>;
    }

    // Handle if user is missing
    if (lastSeenSince < 7200 && lastSeenSince >= 1440) {
      return <span className="pill yellow-s">Por perderse</span>;
    }

    // Handle if user is ok
    if (lastSeenSince < 1440) {
      return <span className="pill green-s">Ok</span>;
    }

    return null;
  }

  function renderScore(score) {
    let scoreColor = 'none';

    if (score >= 80) {
      scoreColor = 'good';
    } else if (score >= 60) {
      scoreColor = 'regular';
    } else if (score > 0) {
      scoreColor = 'bad';
    }

    return (
      <span className={`score-span ${scoreColor}`}>
        {score ? roundNumber(score) : ''}
      </span>
    );
  }

  function renderDriversTable() {
    // Loader en lo que cargan los conductores
    if (!drivers) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // Si no hay conductores
    if (!drivers.length) {
      return (
        <div id="no-data">
          <Icon icon="error" id="error-icon" />
          <p>Sin conductores</p>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="drivers-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>
                <span
                  className="pointer"
                  onClick={() => {
                    setDrivers(
                      sortArrayByKey(
                        drivers,
                        'fullName',
                        sort.order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setSort({
                      key: 'fullName',
                      order: sort.order === 'asc' ? 'desc' : 'asc'
                    });
                  }}
                >
                  Nombre
                  {sort.key === 'fullName' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-down' : 'chevron-up'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              <th className="center-align">
                <span
                  className="pointer"
                  onClick={() => {
                    setDrivers(
                      sortArrayByKey(
                        drivers,
                        'rankingPosition',
                        sort.order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setSort({
                      key: 'rankingPosition',
                      order: sort.order === 'asc' ? 'desc' : 'asc'
                    });
                  }}
                >
                  Calificación
                  {sort.key === 'rankingPosition' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-down' : 'chevron-up'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              <th className="center-align">
                <span
                  className="pointer"
                  onClick={() => {
                    setDrivers(
                      sortArrayByKey(
                        drivers,
                        'rankingScore',
                        sort.order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setSort({
                      key: 'rankingScore',
                      order: sort.order === 'asc' ? 'desc' : 'asc'
                    });
                  }}
                >
                  Pos. Ranking
                  {sort.key === 'rankingScore' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-down' : 'chevron-up'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              <th>Contacto</th>
              <th>
                <span
                  className="pointer"
                  onClick={() => {
                    setDrivers(
                      sortArrayByKey(
                        drivers,
                        'groupName',
                        sort.order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setSort({
                      key: 'groupName',
                      order: sort.order === 'asc' ? 'desc' : 'asc'
                    });
                  }}
                >
                  Grupo
                  {sort.key === 'groupName' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-down' : 'chevron-up'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              <th>
                <span
                  className="pointer"
                  onClick={() => {
                    setDrivers(
                      sortArrayByKey(
                        drivers,
                        'coins',
                        sort.order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setSort({
                      key: 'coins',
                      order: sort.order === 'asc' ? 'desc' : 'asc'
                    });
                  }}
                >
                  Puntos
                  {sort.key === 'coins' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-down' : 'chevron-up'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              <th className="center-align">
                <span
                  className="pointer"
                  onClick={() => {
                    setDrivers(
                      sortArrayByKey(
                        drivers,
                        'lastSeenSince',
                        sort.order === 'asc' ? 'desc' : 'asc'
                      )
                    );
                    setSort({
                      key: 'lastSeenSince',
                      order: sort.order === 'asc' ? 'desc' : 'asc'
                    });
                  }}
                >
                  Conexión más
                  <br />
                  reciente hace
                  {sort.key === 'lastSeenSince' && (
                    <FontAwesomeIcon
                      icon={
                        sort.order === 'desc' ? 'chevron-down' : 'chevron-up'
                      }
                      className="sort-arrow"
                    />
                  )}
                </span>
              </th>
              <th className="center-align">Estado app</th>
            </tr>
          </thead>
          <tbody>
            {drivers.map((driver, i) => {
              const {
                id,
                fullName,
                phone,
                group,
                coins,
                email,
                rankingScore,
                rankingPosition
              } = driver;

              return (
                <tr
                  key={id}
                  ref={i === drivers.length - 25 ? triggerPaginationRef : null}
                  className="driver-row"
                >
                  <td>
                    <Icon
                      icon="eye"
                      className="eye-cta"
                      onClick={() => {
                        setSelectedDriverId(id);
                        setQuickViewOpen(true);

                        // Google Analytics
                        ReactGA.event({
                          category: 'drivers_view',
                          action: 'open_driver_quickview'
                        });
                        // Posthog
                        posthog.capture(`open_driver_quickview`, {
                          category: 'drivers_view',
                          isCustomEvent: true
                        });
                      }}
                    />
                    <span className="pos-index">{i + 1}.</span>
                    <Link to={`/conductor?driverId=${id}`}>{fullName}</Link>
                  </td>
                  <td className="center-align">{renderScore(rankingScore)}</td>
                  <td className="ranking-pos">
                    {rankingPosition && rankingScore
                      ? `${rankingPosition}`
                      : ''}
                  </td>
                  <td>{phone ? phone : email}</td>
                  <td>{group ? group.name : ''}</td>
                  <td>{coins ? Math.floor(coins) : '0'}</td>
                  <td className="pills-container">
                    {renderLastSeenText(driver)}
                    {renderDriverConnection(driver)}
                  </td>
                  <td className="pills-container">
                    {renderDriverStatus(driver)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function renderDriverSwitch() {
    // Hide if there is no drivers information
    if (!drivers || !drivers.length) {
      return null;
    }

    return (
      <div className="airbag-table-container" id="switch-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th className="center-align">
                Cambiar
                <br />
                estado
              </th>
            </tr>
          </thead>
          <tbody>
            {drivers.map((driver) => {
              const {
                id,
                firstLogin,
                isDriving,
                appStatus,
                lastSeenSince,
                status
              } = driver;

              // Remove switch to inactive drivers
              if (status === 'inactive') {
                return (
                  <tr key={'cta-' + id} className="switch-row">
                    <td className="switch-container"></td>
                  </tr>
                );
              }

              return (
                <tr key={'cta-' + id} className="switch-row">
                  <td className="switch-container">
                    <div
                      className={classNames({
                        'hide-switch': firstLogin,
                        'disabled-switch':
                          (isDriving &&
                            appStatus === 'active' &&
                            lastSeenSince < 1440) ||
                          loadingCAS
                      })}
                    >
                      <input
                        type="checkbox"
                        className="custom-switch"
                        id={'switch-' + id}
                        checked={appStatus === 'active'}
                        disabled={
                          loadingCAS ||
                          firstLogin ||
                          (appStatus === 'active' && isDriving)
                        }
                        onChange={() => {
                          handleAppStatusClick(driver);
                        }}
                      />
                      <label
                        htmlFor={'switch-' + id}
                        className={`${appStatus === 'active' ? 'on' : 'off'}`}
                        data-tip={
                          isDriving &&
                          appStatus === 'active' &&
                          lastSeenSince < 1440
                            ? // eslint-disable-next-line max-len
                              'El conductor no se puede desactivar ya que tiene un viaje en curso'
                            : ''
                        }
                      ></label>
                      {/* Tooltips */}
                      <ReactTooltip />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  // API error handler
  if (error) return <Error error={error} />;

  return (
    <div id="_company-drivers_">
      <HeaderSection
        title="Conductores"
        // eslint-disable-next-line max-len
        body="Listado de tus conductores con opciones de filtros y búsqueda. Puedes agregar nuevos usuarios y activar o desactivar la aplicación de los ya configurados."
        videoUrl={videoUrl}
      />
      <Filters
        {...props}
        query={query}
        setQuery={setQuery}
        user={props.user}
        filters={filters}
        setFilters={setFilters}
        initialFilters={initialFilters}
        setNewDriverOpen={setNewDriverOpen}
        groups={groups}
        group={filters.group}
        keepLooking={keepLooking}
        company={company}
        companyGroup={companyGroup}
      />
      <div className="row no-margin">
        <div className="col s9 m10 l11 xl11">{renderDriversTable()}</div>
        <div className="col s3 m2 l1 xl1 no-padding-left">
          {renderDriverSwitch()}
        </div>
      </div>

      {/* ADD NEW DRIVER */}
      <SideModal isOpen={newDriverOpen} setIsOpen={setNewDriverOpen}>
        {newDriverOpen && (
          <AddDriver
            fetchUsers={fetchUsers}
            setIsOpen={setNewDriverOpen}
            groups={groups}
            group={filters.group}
            user={props.user}
          />
        )}
      </SideModal>

      {/* DRIVER QUICK VIEW */}
      <SideModal isOpen={quickViewOpen} setIsOpen={setQuickViewOpen}>
        {quickViewOpen && (
          <DriverQuickView
            driverId={selectedDriverId}
            company={company}
            user={user}
          />
        )}
      </SideModal>

      {/* Tooltips */}
      <ReactTooltip />
    </div>
  );
}

export default CompanyDrivers;
