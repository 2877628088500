import React, { useState } from 'react';
import M from 'materialize-css';
import axiosAuth from '../../../../../utils/axiosAuth';
import Button from '../../../../common/button/Button';
import './EditFlags.scss';

function EditFlags(props) {
  const { driver, setDriver, setEditFlagsOpen } = props;
  const [loading, setLoading] = useState(false);
  const [showMobileScores, setShowMobileScores] = useState(
    driver.showMobileScores || false
  );
  const [showMobileTrips, setShowMobileTrips] = useState(
    driver.showMobileTrips || false
  );
  const [showMobileWorkDay, setShowMobileWorkDay] = useState(
    driver.showMobileWorkDay || false
  );
  const [activationMode, setActivationMode] = useState(
    driver.activationMode || 'ADMIN_MODE'
  );

  function editFlags(e) {
    e.preventDefault();
    setLoading(true);

    // Make request
    axiosAuth
      .post('/api/users/edit-flags', {
        driverId: driver.id,
        showMobileScores,
        showMobileTrips,
        showMobileWorkDay,
        activationMode
      })
      .then(() => {
        M.toast({
          html: 'Banderas editadas exitosamente',
          classes: 'success-toast'
        });
        setDriver({
          ...driver,
          showMobileScores,
          showMobileTrips,
          showMobileWorkDay,
          activationMode
        });
        setLoading(false);
        setEditFlagsOpen(false);
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  return (
    <div id="_edit-flags_">
      <div className="row">
        <div className="col s12">
          <h5 id="main-t">Editar banderas</h5>
        </div>
      </div>
      <form onSubmit={editFlags}>
        <div className="row">
          <div className="col s12 m6 input-field">
            <label className="active">Activación y desactivación manual</label>
            <select
              className="browser-default"
              value={activationMode}
              onChange={(e) => setActivationMode(e.target.value)}
            >
              <option value="SINGLE_MODE">Prendida</option>
              <option value="ADMIN_MODE">Apagada</option>
            </select>
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Calificaciones</label>
            <select
              className="browser-default"
              value={showMobileScores}
              onChange={(e) => setShowMobileScores(e.target.value === 'true')}
            >
              <option value="true">Prendida</option>
              <option value="false">Apagada</option>
            </select>
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Viajes realizados</label>
            <select
              className="browser-default"
              value={showMobileTrips}
              onChange={(e) => setShowMobileTrips(e.target.value === 'true')}
            >
              <option value="true">Prendida</option>
              <option value="false">Apagada</option>
            </select>
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Jornada laboral</label>
            <select
              className="browser-default"
              value={showMobileWorkDay}
              onChange={(e) => setShowMobileWorkDay(e.target.value === 'true')}
            >
              <option value="true">Prendida</option>
              <option value="false">Apagada</option>
            </select>
          </div>
        </div>
        <div className="center-align">
          <Button
            variant="yellow-button"
            type="submit"
            loading={loading}
            disabled={loading}
          >
            Guardar información
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditFlags;
