/* eslint max-len: 0 */

import React from 'react';

function MapLocation(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path
          className="cls-1"
          d="M182.19,136.06c9.3-17.94,16.54-36.54,16.54-51a56.67,56.67,0,1,0-113.34,0c0,40,56.67,113.35,56.67,113.35s23.4-30.09,40.13-62.34"
        />
        <circle className="cls-1" cx="142.06" cy="90.72" r="22.67" />
        <polyline
          className="cls-1"
          points="186.42 127.53 227.1 127.53 255.45 255.09 28.68 255.09 57.02 127.53 97.79 127.53"
        />
      </g>
    </svg>
  );
}

export default MapLocation;
