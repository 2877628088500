function companyGroup(state = {}, action) {
  switch (action.type) {
    case 'SET_COMPANY_GROUP': {
      state = { ...state, ...action.payload };
      break;
    }
    case 'UNSET_COMPANY_GROUP': {
      state = {};
      break;
    }
    default:
      break;
  }
  return state;
}

export default companyGroup;
