import React, { useState, useEffect, useMemo, useCallback } from 'react';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import axiosAuth from '../../../utils/axiosAuth';
import './Billing.scss';
import dayjs from 'dayjs';
import { BillingItem } from './BillingItem';
import HeaderSection from '../../common/header-section/HeaderSection';
import { GeneralBillingInfo } from './GeneralBillingInfo';

const today = dayjs();

export const Billing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [drivers, setDrivers] = useState(null);
  const [billable, setBillable] = useState(0);
  const [billableTotal, setBillableTotal] = useState(0);
  const [deletedBillable, setDeletedBillable] = useState(0);
  const [deletedBillableTotal, setDeletedBillableTotal] = useState(0);
  const [proratedTotal, setProratedTotal] = useState(0);
  const [prorated, setProrated] = useState(0);
  const [total, setTotal] = useState(0);
  const [nonBillable, setNonBillable] = useState(0);
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState(false);
  const [month, setMonth] = useState(today.subtract(1, 'M').format('YYYY-MM'));
  const [prizes, setPrizes] = useState(null);

  const fetchDrivers = async () => {
    try {
      const { data } = await axiosAuth.get('/api/billing');

      setDrivers(data.users);
      setProratedTotal(data.prorated);
      setBillableTotal(data.billable);
      setDeletedBillableTotal(data.deletedTotal);
      setTotal(data.total);
      setPrizes(data.prizes);
      setProrated(data.numberOfProrated);
      setNonBillable(data.numberOfNonBillable);
      setBillable(data.numberOfBillable);
      setDeletedBillable(data.numberOfDeletedBillable);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to sort drivers by key clicked
  // used 'useCallback' since it is a complex function
  const sortDrivers = useCallback((drivers, key) => {
    if (!drivers) return [];
    switch (key) {
      case 'name':
        return [...drivers]?.sort((a, b) => {
          return a.user.fullName.localeCompare(b.user.fullName);
        });
      case 'firstLoginDate':
        return [...drivers]?.sort((a, b) => {
          const aFirstLogin =
            a.loginHistory[0]?.created || a.user.firstLoginDate;
          const bFirstLogin =
            b.loginHistory[0]?.created || b.user.firstLoginDate;

          // case 1: both have first login dates, compare them
          if (aFirstLogin && bFirstLogin) {
            return dayjs(aFirstLogin).isBefore(dayjs(bFirstLogin)) ? -1 : 1;
          }

          // case 2: only one has a first login date,
          // prioritize the one with a date
          if (aFirstLogin && !bFirstLogin) {
            return -1;
          }
          if (!aFirstLogin && bFirstLogin) {
            return 1;
          }

          // case 3: neither has a first login date
          return 0;
        });

      case 'hoursSinceFirstLogin':
        return [...drivers]?.sort((a, b) => {
          if (
            a.loginHistory[0] &&
            b.loginHistory[0] &&
            today.diff(dayjs(a.loginHistory[0].created), 'hours') <
              today.diff(dayjs(b.loginHistory[0].created), 'hours')
          ) {
            return -1;
          }
          if (
            a.loginHistory[0] &&
            b.loginHistory[0] &&
            today.diff(dayjs(a.loginHistory[0].created), 'hours') >
              today.diff(dayjs(b.loginHistory[0].created), 'hours')
          ) {
            return 1;
          }
          if (!a.loginHistory[0] && !b.loginHistory[0]) {
            return 1;
          }
          return 0;
        });
      case 'distance':
        return [...drivers]?.sort((a, b) => {
          return !!a.user.currentLevel?.currentDistance ===
            !!b.user.currentLevel?.currentDistance
            ? 0
            : a.user.currentLevel?.currentDistance
            ? -1
            : 1;
        });
      case 'billable':
        return [...drivers]?.sort((a, b) => {
          const aBillable = a.billable ?? a.user?.billable ?? false;
          const bBillable = b.billable ?? b.user?.billable ?? false;

          if (aBillable === bBillable) return 0;
          return aBillable ? -1 : 1;
        });
      case 'billingType':
        return [...drivers]?.sort((a, b) => {
          const aType =
            a.prorated || a.user.prorated
              ? 1
              : a.billable || a.user.billable
              ? 2
              : 3;
          const bType =
            b.prorated || b.user.prorated
              ? 1
              : b.billable || b.user.billable
              ? 2
              : 3;
          return aType - bType;
        });
      case 'deleted':
        return [...drivers]?.sort((a, b) =>
          a.user.deleted === b.user.deleted ? 0 : a.user.deleted ? -1 : 1
        );

      case 'isResting':
        return [...drivers]?.sort((a, b) => {
          const aResting = a.user.isResting;
          const bResting = b.user.isResting;

          if (aResting === bResting) return 0;
          return aResting ? -1 : 1;
        });

      case 'deletedDate':
        return [...drivers]?.sort((a, b) => {
          const aDeleted = a.user.deleted;
          const bDeleted = b.user.deleted;

          // case 1: Both are deleted, compare deleted dates
          if (aDeleted && bDeleted) {
            return dayjs(a.user.deletedDate).isBefore(dayjs(b.user.deletedDate))
              ? -1
              : dayjs(a.user.deletedDate).isAfter(dayjs(b.user.deletedDate))
              ? 1
              : 0;
          }

          // case 2: Only one is deleted, prioritize the deleted one
          if (aDeleted && !bDeleted) {
            return -1;
          }
          if (!aDeleted && bDeleted) {
            return 1;
          }

          // case 3: Neither is deleted
          return 0;
        });
      default:
        return drivers;
    }
  }, []);

  useEffect(() => {
    // call fetchDrivers when component mounts
    fetchDrivers();
  }, []);

  const handleHeaderClick = async (event) => {
    const { target } = event;

    if (target.getAttribute('data-sort') !== sortKey) {
      setSortKey(target.getAttribute('data-sort'));
      setSortOrder(false);
    } else {
      // reverse the direction of the array
      setSortOrder(!sortOrder);
    }
  };

  // use memoization to prevent unwanted re-renders
  const sortedDrivers = useMemo(() => {
    const sorted = sortDrivers(drivers, sortKey)?.map((driver, idx) => (
      <BillingItem {...driver} key={idx} idx={idx} />
    ));
    return sortOrder ? sorted.reverse() : sorted;
  }, [drivers, sortKey, sortOrder, sortDrivers]);

  if (isLoading) {
    return <AirbagLoader />;
  }

  if (!drivers || drivers.length === 0) {
    return (
      <div className="airbag-table-container no-drivers">
        <p>Sin conductores</p>
      </div>
    );
  }

  return (
    <div id="_company-billing_">
      <HeaderSection
        title={`Facturación del mes: ${dayjs(month).format('MMMM YYYY')}`}
      />
      {/* <div>
      // this will be used for later filter implementation
        <label htmlFor="month">Seleccionar mes:</label>
        <input
          type="month"
          id="month"
          name="start"
          // max={today.format('YYYY-MM')}
          min={dayjs('2025-01-01').format('MMMM-YYYY')}
          value={month}
          className="ranking-month-filter"
          onChange={(e) => setMonth(e.target.value)}
        />
      </div> */}
      <div className="row card-custom main-card">
        <GeneralBillingInfo
          total={total}
          billingTotal={billableTotal}
          deletedBillingTotal={deletedBillableTotal}
          billing={billable}
          deletedBilling={deletedBillable}
          prorated={prorated}
          proratedTotal={proratedTotal}
          nonBillable={nonBillable}
          prizes={prizes}
        />
      </div>
      <div className="airbag-table-container" id="companies-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>
                <span onClick={handleHeaderClick} data-sort="name">
                  Nombre
                </span>
              </th>
              <th className="center-align">
                <span onClick={handleHeaderClick} data-sort="firstLoginDate">
                  Primer inicio de sesión
                </span>
              </th>
              <th className="center-align">
                <span onClick={handleHeaderClick} data-sort="billable">
                  Cobrable
                </span>
              </th>
              <th className="center-align">
                <span onClick={handleHeaderClick} data-sort="billingType">
                  Tipo de cobro
                </span>
              </th>
              <th className="center-align">
                <span onClick={handleHeaderClick} data-sort="deleted">
                  Eliminado
                </span>
              </th>
              <th className="center-align">
                <span onClick={handleHeaderClick} data-sort="isResting">
                  En descanso
                </span>
              </th>
              <th className="center-align">
                <span onClick={handleHeaderClick} data-sort="deletedDate">
                  Fecha de eliminación
                </span>
              </th>
            </tr>
          </thead>
          <tbody>{sortedDrivers}</tbody>
        </table>
      </div>
    </div>
  );
};
