import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faLock,
  faClock,
  faCircle,
  faEnvelope,
  faCaretRight,
  faCaretLeft,
  faExternalLinkAlt,
  faCalendar,
  faMapMarkerAlt,
  faCarCrash,
  faPlus,
  faTrashAlt,
  faBomb,
  faChartLine,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faMinus,
  faMedal,
  faTimes,
  faCog,
  faRoad,
  faBoxOpen,
  faBus,
  faMobileAlt,
  faSignal,
  faStop,
  faPlay,
  faChevronDown,
  faChevronUp,
  faSortAmountDownAlt,
  faPhoneVolume,
  faPowerOff,
  faQuestionCircle,
  faStar,
  faHistory,
  faFlagCheckered,
  faMapPin,
  faGasPump,
  faUserSlash,
  faTachometerAlt,
  faChartPie,
  faBook,
  faCarAlt,
  faFile,
  faAddressCard,
  faBirthdayCake,
  faHeart,
  faFlag,
  faListOl,
  faVenusMars,
  faEye,
  faFileContract,
  faCheck,
  faSun,
  faMoon,
  faThermometerHalf,
  faCloudSun,
  faWind,
  faTint,
  faLocationArrow,
  faShieldAlt,
  faTools,
  faCogs,
  faStoreAlt,
  faRestroom,
  faUtensils,
  faBed,
  faDolly,
  faWarehouse,
  faFileExcel,
  faChevronLeft,
  faChevronRight,
  faChevronCircleLeft,
  faChevronCircleRight,
  faExclamationTriangle,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faRedoAlt,
  faCar,
  faEyeSlash,
  faSync,
  faFileVideo
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
  faExclamation,
  faGaugeMax,
  faMobileButton,
  faDiamondTurnRight,
  faGaugeSimpleMin,
  faGaugeSimpleMax,
  faCamera,
  faMicrophone,
  faClipboard,
  faHouse,
  faMap,
  faUser,
  faUsers,
  faRoute,
  faTruck,
  faTrophy,
  faChartMixed,
  faFileLines,
  faChartScatter,
  faCircleInfo,
  faPhone,
  faMobile,
  faCoins,
  faCaretUp,
  faCaretDown,
  faShieldExclamation,
  faLightEmergencyOn,
  faPenToSquare,
  faArrowRightFromBracket,
  faAngleDown,
  faCoinBlank,
  faUserPlus,
  faUsersSlash,
  faHundredPoints,
  faChartUser,
  faDownload,
  faFileExport,
  faXmark,
  faMapLocationDot,
  faBell,
  faTrash,
  faCircleCheck,
  faEnvelopeCircleCheck,
  faFire,
  faFireFlameCurved,
  faHexagon,
  faClockRotateLeft,
  faSquareCaretDown,
  faMoneyCheckDollar,
  faMoneyBillWave,
  faUserTag,
  faClipboardList,
  faAt,
  faSignalBarsSlash,
  faUserXmark,
  faUserLargeSlash
} from '@fortawesome/pro-light-svg-icons';

// Docs: https://github.com/FortAwesome/react-fontawesome

const setFontawesomeIcons = () => {
  library.add(
    faLinkedin,
    faTrophy,
    faUser,
    faLock,
    faHouse,
    faUsers,
    faTruck,
    faClock,
    faCircle,
    faPhone,
    faEnvelope,
    faCaretUp,
    faCaretDown,
    faCaretRight,
    faCaretLeft,
    faExternalLinkAlt,
    faCalendar,
    faMapMarkerAlt,
    faCarCrash,
    faPlus,
    faTrashAlt,
    faBomb,
    faRoute,
    faChartLine,
    faArrowUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faMinus,
    faMedal,
    faTimes,
    faCog,
    faRoad,
    faBoxOpen,
    faBus,
    faMobileAlt,
    faSignal,
    faStop,
    faPlay,
    faChevronDown,
    faChevronUp,
    faSortAmountDownAlt,
    faPhoneVolume,
    faPowerOff,
    faQuestionCircle,
    faStar,
    faHistory,
    faExclamation,
    faFlagCheckered,
    faMapPin,
    faGasPump,
    faUserSlash,
    faTachometerAlt,
    faChartPie,
    faBook,
    faCarAlt,
    faFile,
    faAddressCard,
    faBirthdayCake,
    faHeart,
    faFlag,
    faListOl,
    faVenusMars,
    faEye,
    faFileContract,
    faDownload,
    faCheck,
    faMobile,
    faSun,
    faMoon,
    faThermometerHalf,
    faCloudSun,
    faWind,
    faTint,
    faLocationArrow,
    faShieldAlt,
    faTools,
    faCogs,
    faStoreAlt,
    faRestroom,
    faUtensils,
    faBed,
    faDolly,
    faWarehouse,
    faFileExcel,
    faChevronLeft,
    faChevronRight,
    faChevronCircleLeft,
    faChevronCircleRight,
    faExclamationTriangle,
    faTrash,
    faBatteryFull,
    faBatteryHalf,
    faBatteryQuarter,
    faRedoAlt,
    faCar,
    faEyeSlash,
    faCoins,
    faSync,
    faFileVideo,
    faGaugeMax,
    faMobileButton,
    faDiamondTurnRight,
    faGaugeSimpleMin,
    faGaugeSimpleMax,
    faCamera,
    faMicrophone,
    faClipboard,
    faMap,
    faChartMixed,
    faFileLines,
    faChartScatter,
    faCircleInfo,
    faShieldExclamation,
    faLightEmergencyOn,
    faPenToSquare,
    faArrowRightFromBracket,
    faAngleDown,
    faCoinBlank,
    faUserPlus,
    faUsersSlash,
    faHundredPoints,
    faChartUser,
    faFileExport,
    faXmark,
    faMapLocationDot,
    faBell,
    faCircleCheck,
    faEnvelopeCircleCheck,
    faFire,
    faFireFlameCurved,
    faHexagon,
    faClockRotateLeft,
    faSquareCaretDown,
    faMoneyCheckDollar,
    faMoneyBillWave,
    faUserTag,
    faClipboardList,
    faAt,
    faSignalBarsSlash,
    faUserXmark,
    faUserLargeSlash
  );
};

export default setFontawesomeIcons;
