import React, { useState } from 'react';
import M from 'materialize-css';
import axiosAuth from '../../../../../utils/axiosAuth';
import Button from '../../../../common/button/Button';
import './EditEmergencyContact.scss';

function EditEmergencyContact(props) {
  const { driver, setDriver, setEditContactOpen } = props;
  const [loading, setLoading] = useState(false);
  const [contactName, setContactName] = useState(
    driver.emergencyContact ? driver.emergencyContact.name : ''
  );
  const [contactPhone, setContactPhone] = useState(
    driver.emergencyContact ? driver.emergencyContact.phone : ''
  );
  const [contactType, setContactType] = useState(
    driver.emergencyContact ? driver.emergencyContact.type : ''
  );

  function editEmergencyContact(e) {
    e.preventDefault();
    setLoading(true);

    // Make request
    axiosAuth
      .post('/api/users/edit-contact', {
        driverId: driver.id,
        contactName,
        contactPhone,
        contactType
      })
      .then((res) => {
        M.toast({
          html: 'Contacto de emergencia editado con éxito',
          classes: 'success-toast'
        });
        setDriver({
          ...driver,
          emergencyContact: {
            name: contactName,
            phone: contactPhone,
            type: contactType
          }
        });
        setLoading(false);
        setEditContactOpen(false);
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  return (
    <div id="_edit-general_">
      <div className="row">
        <div className="col s12">
          <h5 id="main-t">Editar contacto de emergencia</h5>
        </div>
      </div>
      <form onSubmit={editEmergencyContact}>
        <div className="row big-label">
          <div className="col s12">
            <p>Contacto</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 input-field">
            <label className="active">Nombre</label>
            <input
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              type="text"
              placeholder="Nombre de contacto"
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Teléfono</label>
            <input
              value={contactPhone}
              onChange={(e) => setContactPhone(e.target.value)}
              type="text"
              placeholder="Teléfono de contacto"
            />
          </div>
          <div className="col s12 m6 input-field">
            <label className="active">Tipo de teléfono</label>
            <select
              className="browser-default"
              value={contactType}
              onChange={(e) => setContactType(e.target.value)}
            >
              <option value="">Selecciona..</option>
              <option value="cellphone">Celular</option>
              <option value="landphone">Línea fija</option>
            </select>
          </div>
        </div>

        <div className="center-align">
          <Button
            variant="yellow-button"
            type="submit"
            loading={loading}
            disabled={loading}
          >
            Guardar información
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditEmergencyContact;
