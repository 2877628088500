import React, { useRef } from 'react';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Filters.scss';

function Filters(props) {
  const { riskProfile, setRiskProfile, setDate, date, setTrips } = props;
  const dateRef = useRef(null);

  return (
    <div className="" id="_filters-trips_user">
      <div className="col s12" id="filters-box">
        <div className="dp-wrap">
          <span className="input-field item-container">
            <label htmlFor="status-select" className="active">
              Nivel de riesgo
            </label>
            <select
              className="browser-default"
              id="status-select"
              value={riskProfile}
              onChange={(e) => {
                // Set filter
                setRiskProfile(e.target.value);
              }}
              onFocus={() => {
                // Google Analytics
                ReactGA.event({
                  category: 'driver_trips_view',
                  action: 'risk_filter_focus'
                });

                // Posthog
                posthog.capture('risk_filter_focus', {
                  category: 'driver_details',
                  isCustomEvent: true
                });
              }}
            >
              <option value="">Todos</option>
              <option value="Alto">Riesgo alto</option>
              <option value="Medio">Riesgo medio</option>
              <option value="Bajo">Riesgo bajo</option>
            </select>
          </span>
          <div className="date-picker-container">
            <DatePicker
              selected={date}
              placeholderText="Buscar por fecha"
              dateFormat="dd/MM/yyyy"
              minDate={
                new Date(dayjs().subtract(6, 'months').format('YYYY/MM/DD'))
              }
              maxDate={new Date()}
              onChange={(date) => setDate(date)}
              ref={dateRef}
            />
            {date && (
              <FontAwesomeIcon
                icon="times"
                id="date-clear"
                onClick={() => {
                  setTrips(null);
                  setDate('');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filters;
