import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import classNames from 'classnames';
import M from 'materialize-css';
import ReactTooltip from 'react-tooltip';
import axiosAuth from '../../../utils/axiosAuth';
import Error from '../../common/error/Error';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import './CompanyNotifications.scss';

function CompanyNotifications(props) {
  const { setNotifOpen, history } = props;
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('unread');
  const [notifications, setNotifications] = useState(null);
  const last = useRef('');
  const lastNotifRef = useRef(null);
  const keepLooking = useRef(true);
  const loading = useRef(true);

  useEffect(() => {
    fetchNotifications(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    const onScroll = (e) => {
      // If reference to last notification row exists and its in view and
      // fetchNotifications keeps returning result
      if (
        notifications &&
        lastNotifRef &&
        lastNotifRef.current &&
        isLastNotifInViewport() &&
        !loading.current &&
        keepLooking.current
      ) {
        fetchNotifications();
      }
    };
    const notifContainer = document.getElementById('notif-container');
    if (notifContainer) {
      notifContainer.addEventListener('scroll', onScroll);
    }

    return () => {
      notifContainer.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, lastNotifRef, loading.current, keepLooking.current]);

  function fetchNotifications(cleanNotifsArray) {
    // Loader inside table
    if (cleanNotifsArray) {
      setNotifications(null);
    }
    // Loader inside loop list
    loading.current = true;
    // lastNotifRef.current = null; ?

    /* Build query parameters */
    const queryParameters = {};
    queryParameters.filter = filter;
    // Pagination
    if (last.current && !cleanNotifsArray) {
      queryParameters.last = last.current;
    }

    // Get notifications
    axiosAuth
      .get(`/api/managers/notifications`, {
        params: queryParameters
      })
      .then((res) => {
        const { notifications: notifRes } = res.data;
        // Save last notif id for pagination
        let lastNotifId = null;
        if (notifRes.length) {
          lastNotifId = notifRes[notifRes.length - 1].id;
        }

        // Here you can process array's information
        const processedNotifs = notifRes;

        if (cleanNotifsArray) {
          setNotifications(processedNotifs);
        } else {
          // Append new notifications response to notifications's array
          const notifsCopy = notifications
            ? [...notifications, ...processedNotifs]
            : [...processedNotifs];
          // Order by key and apply current filter
          setNotifications(notifsCopy);
        }

        // Stop or resume infinite scrolling | The < 25 is hardcoded
        // and must be changed (results per page)
        if (!notifRes.length || notifRes.length < 25) {
          keepLooking.current = false;
          last.current = '';
        } else {
          keepLooking.current = true;
          // Save last notif's id to continue query
          last.current = lastNotifId;
        }

        // Stop loader
        loading.current = false;
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }

  function deleteNotification(index, id) {
    axiosAuth
      .post(`/api/managers/notifications/delete`, { id })
      .then((res) => {
        // Update array
        M.toast({
          html: 'Se eliminó la notificación',
          classes: 'success-toast'
        });
        const notifsCopy = [...notifications];
        notifsCopy.splice(index, 1);
        setNotifications(notifsCopy);
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: 'Tuvimos un error, intenta de nuevo más tarde',
          classes: 'error-toast'
        });
      });
  }

  function markAsRead(index, id) {
    axiosAuth
      .post(`/api/managers/notifications/read`, { id })
      .then((res) => {
        // Update array
        const notifsCopy = [...notifications];
        notifsCopy.splice(index, 1);
        setNotifications(notifsCopy);
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: 'Tuvimos un error, intenta de nuevo más tarde',
          classes: 'error-toast'
        });
      });
  }

  function renderNotifIcon(type) {
    if (type === 'activity') {
      return 'file-lines';
    } else if (type === 'trip') {
      return 'route';
    }

    // Fallback generic icon
    return 'bell';
  }

  function isLastNotifInViewport() {
    // Set an offset to trigger action before target element is in view
    const offset = 100;

    const top = lastNotifRef.current.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  }

  function handleRouteChange(type, notif) {
    if (type === 'trip') {
      history.push(
        `/viajes/viaje?userId=${notif.userId}&tripId=${notif.tripId}`
      );
    } else if (type === 'activity') {
      history.push(
        `/registro?userId=${notif.userId}&activityId=${notif.activityId}`
      );
    } else if (type === 'no_download') {
      history.push('/conductores');
    }
  }

  function renderNotifications() {
    // Show loader
    if (!notifications) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No notifications
    if (!notifications.length) {
      return (
        <div id="no-notifications">
          <FontAwesomeIcon
            id="no-notif-icon"
            icon={['fal', 'envelope-circle-check']}
          />
          <p>Sin notificaciones</p>
        </div>
      );
    }

    // Process notifications
    const ret = [];
    for (let i = 0; i < notifications.length; i++) {
      const notif = notifications[i];

      let notificationRow = (
        <div
          className="notif-row"
          key={notif.id}
          ref={i === notifications.length - 1 ? lastNotifRef : null}
        >
          <div className={classNames('row', { unread: !notif.read })}>
            <div className="col s2 center-align">
              <FontAwesomeIcon
                className="n-icon"
                icon={['fal', renderNotifIcon(notif.notificationType)]}
              />
              {!notif.read ? (
                <FontAwesomeIcon className="unread-dot" icon="circle" />
              ) : null}
            </div>
            <div className="col s10">
              <p className="n-title">{notif.title}</p>
              <p className="n-description">{notif.body}</p>
              <div className="n-bottom">
                <span className="n-date">
                  {dayjs(notif.created).format('DD MMMM YYYY')}
                </span>
                <FontAwesomeIcon
                  aria-hidden="false"
                  className="cta-icon delete-i"
                  icon={['fal', 'trash']}
                  onClick={(e) => {
                    // Avoid main CTA
                    e.stopPropagation();

                    deleteNotification(i, notif.id);
                  }}
                  data-tip="Eliminar notificación"
                />
                <FontAwesomeIcon
                  aria-hidden="false"
                  className={classNames('cta-icon read-i', {
                    unread: !notif.read
                  })}
                  icon={['fal', 'circle-check']}
                  onClick={(e) => {
                    // Avoid main CTA
                    e.stopPropagation();

                    if (!notif.read) {
                      markAsRead(i, notif.id);
                    }
                    return;
                  }}
                  data-tip={notif.read ? 'Leído' : 'Marca como leído'}
                />
              </div>
            </div>
          </div>
          <ReactTooltip />
        </div>
      );

      // Handle CTA
      if (
        notif.notificationType === 'trip' ||
        notif.notificationType === 'activity' ||
        notif.notificationType === 'no_download'
      ) {
        notificationRow = (
          <div
            className="notif-link"
            key={`with-link-${notif.id}`}
            onClick={(e) => {
              if (!notif.read) {
                markAsRead(i, notif.id);
              }
              handleRouteChange(notif.notificationType, notif);
              setNotifOpen(false);
            }}
          >
            {notificationRow}
          </div>
        );
      }

      ret.push(notificationRow);
    }

    return ret;
  }

  // API error handler
  if (error) return <Error error={error} />;

  return (
    <div id="_company-notifications_">
      <div className="row">
        <div className="col s12">
          <h5 id="main-t">Notificaciones</h5>
        </div>
      </div>
      <div id="top-notif-options">
        <span
          className={classNames('right-cta', { active: filter === 'all' })}
          onClick={() => setFilter('all')}
        >
          Todas
        </span>
        <span
          className={classNames('right-cta', {
            active: filter === 'unread'
          })}
          onClick={() => setFilter('unread')}
        >
          No leídas
        </span>
      </div>
      <div id="notif-container">{renderNotifications()}</div>
    </div>
  );
}

export default CompanyNotifications;
