/* eslint max-len: 0 */

import React from 'react';

function Cornering(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_5" data-name="Capa 5">
        <path d="M150.08,175.08H42.52a5,5,0,0,1,0-10H150.08a9.18,9.18,0,0,0,0-18.35H133.39a19.17,19.17,0,1,1,0-38.34H240.94a5,5,0,0,1,0,10H133.39a9.17,9.17,0,1,0,0,18.34h16.69a19.18,19.18,0,0,1,0,38.35Z" />
      </g>
    </svg>
  );
}

export default Cornering;
