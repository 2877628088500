/* eslint max-len: 0 */

import React from 'react';

function Profile(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <circle cx="167.54" cy="148.82" r="6.38" />
        <circle cx="141.73" cy="49.91" r="6.38" />
        <circle cx="115.93" cy="148.82" r="6.38" />
        <rect
          className="cls-1"
          x="28.35"
          y="77.95"
          width="226.77"
          height="177.17"
          rx="88.58"
        />
        <path
          className="cls-1"
          d="M171.84,179.64c-4.3,10.86-16.09,18.66-30,18.66s-25.84-7.92-30.06-18.92"
        />
      </g>
    </svg>
  );
}

export default Profile;
