import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import M from 'materialize-css';
import { saveAs } from 'file-saver';
import Icon from '../../common/airbag-icons/Icon';
import Error from '../../common/error/Error';
import MessageCourtain from '../../common/message-courtain/MessageCourtain';
import AirbagLoaderLines from '../../common/airbag-loader/AirbagLoaderLines';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import axiosAuth from '../../../utils/axiosAuth';
import activitiesDefinitions from '../../../utils/activitiesDefinitions';
import './Activity.scss';

function Activity() {
  // Get all data after main url
  const queryString = window.location.search;
  // Get all params
  const urlParams = new URLSearchParams(queryString);
  const [error, setError] = useState(null);
  const userId = urlParams.get('userId');
  const activityId = urlParams.get('activityId');
  const [activity, setActivity] = useState({});
  const selectedPhoto = useRef(0);

  // Fetch activity on first load
  useEffect(() => {
    setActivity({});

    fetchActivity(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, activityId]);

  function fetchActivity() {
    // Get activity from server
    axiosAuth
      .get(`/api/activities/activity`, {
        params: {
          userId,
          activityId
        }
      })
      .then((res) => {
        const { activity: activityRes } = res.data;
        setActivity(activityRes);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }

  function downloadPhoto() {
    if (activity && activity.photos && activity.photos.length) {
      const photo = activity.photos[selectedPhoto.current];
      if (photo) {
        const fileName = `${activity.id}-${selectedPhoto.current + 1}.jpg`;
        // eslint-disable-next-line max-len
        const linkSource = `data:image/jpeg;base64,${photo.base64.base64Data}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    }
  }

  // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
  function base64toBlob(base64String, sliceSize = 512) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
      type: 'application/zip'
    });
    return blob;
  }

  function downloadAll() {
    axiosAuth
      .get(`/api/activities/activity/download-zip`, {
        params: {
          userId,
          activityId
        }
      })
      .then((res) => {
        const { zipFileBase64 } = res.data;
        // Convert base64 to blob for file download
        const blobUrl = URL.createObjectURL(base64toBlob(zipFileBase64));
        saveAs(blobUrl, `${activity.id}.zip`);
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: 'Tuvimos un error al descargar las imágenes',
          classes: 'error-toast'
        });
      });
  }

  function renderDescription(act) {
    const ret = [];

    // Delivery data
    if (act.type === 'entrega') {
      if (act.wasDeliverySuccess) {
        ret.push(
          <p key={`${act.id}-entrega-success`}>
            Se registró una entrega <span className="ok">exitosa</span>.
          </p>
        );
      } else {
        ret.push(
          <p key={`${act.id}-entrega-unsuccess`}>
            Entrega <span className="not-ok">no exitosa</span>.
            <br />
            {!act.message ? act.deliveryFailureReason : ''}
          </p>
        );
      }
    }
  }

  function renderCarousel(act) {
    const { photos } = act;

    if (photos && photos.length) {
      if (photos[0].base64) {
        return (
          <div className="activity-photos">
            <Carousel
              showThumbs={false}
              showIndicators={photos.length > 1}
              onChange={(selectedIndex) => {
                selectedPhoto.current = selectedIndex;
              }}
              renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && (
                  <span
                    onClick={onClickHandler}
                    style={{
                      position: 'absolute',
                      zIndex: 2,
                      top: 'calc(50% - 15px)',
                      width: 30,
                      height: 30,
                      cursor: 'pointer',
                      left: 15
                    }}
                  >
                    <FontAwesomeIcon className="arrow" icon="arrow-left" />
                  </span>
                )
              }
              renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                  <span
                    onClick={onClickHandler}
                    style={{
                      position: 'absolute',
                      zIndex: 2,
                      top: 'calc(50% - 15px)',
                      width: 30,
                      height: 30,
                      cursor: 'pointer',
                      right: 15
                    }}
                  >
                    <FontAwesomeIcon className="arrow" icon="arrow-right" />
                  </span>
                )
              }
              renderIndicator={(onClickHandler, isSelected, index) => {
                if (isSelected) {
                  return (
                    <li
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: 20,
                        display: 'inline-block',
                        margin: '0 8px',
                        border: '1px solid #000',
                        background: '#f6c700'
                      }}
                    />
                  );
                }
                return (
                  <li
                    style={{
                      background: '#fff',
                      width: 10,
                      height: 10,
                      borderRadius: 20,
                      display: 'inline-block',
                      margin: '0 8px',
                      border: '1px solid #000'
                    }}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                  />
                );
              }}
            >
              {photos.map((photo, i) => (
                <div key={`${act.id}-${i}`}>
                  {photo.base64 ? (
                    <img
                      key={photo.fileName}
                      className="act-photo"
                      alt=""
                      src={`data:image/png;base64,${photo.base64.base64Data}`}
                    />
                  ) : null}
                </div>
              ))}
            </Carousel>
          </div>
        );
      } else {
        return <AirbagLoader />;
      }
    }

    return null;
  }

  function renderVoicenotes(act) {
    if (act.voicenotes && act.voicenotes.length) {
      if (act.voicenotes[0].base64) {
        const vn = act.voicenotes[0];

        return (
          <div className="audio-wrapper">
            <audio controls>
              <source
                src={`data:audio/mp3;base64,${vn.base64String}`}
                type="audio/mp3"
              />
              Tu navegador no permite reproducir audios.
            </audio>
          </div>
        );
      } else {
        return <AirbagLoader />;
      }
    }

    return null;
  }

  function renderActivity() {
    // Get log type
    let key = activity.type;
    // Check for log sub-type in metadata
    if (activity.metadata && activity.metadata.type) {
      key = activity.metadata.type;
    }
    // Get log info
    const actInfo = activitiesDefinitions.find((l) => l.slug === key);

    return (
      <div id="activity-detail" key={`${activity.id}-container`}>
        <div id="activity-info">
          <p>
            <span
              className="act-tag"
              style={{ background: actInfo.colorLight, color: actInfo.color }}
            >
              <Icon
                className="act-icon"
                icon={actInfo.airbagIcon}
                stroke={actInfo.color}
              />
              {actInfo.name}
            </span>
            <span>{dayjs(activity.created).format('DD [de] MMMM HH:mm')}</span>
          </p>
          {renderDescription(activity)}
        </div>
        <div id="filters">
          {activity.photos && activity.photos.length ? (
            <FontAwesomeIcon
              icon={['fal', 'download']}
              data-tip="Descargar imagen"
              className="cta-icon"
              onClick={() => {
                downloadPhoto();
              }}
            />
          ) : null}
          {activity.photos && activity.photos.length > 1 ? (
            <FontAwesomeIcon
              icon={['fal', 'file-export']}
              data-tip="Descargar todas las imágenes"
              className="cta-icon"
              onClick={() => {
                downloadAll();
              }}
            />
          ) : null}
          <ReactTooltip />
        </div>
        {renderCarousel(activity)}
        {renderVoicenotes(activity)}
      </div>
    );
  }

  // API error handler
  if (error) return <Error error={error} />;

  // Info loading
  if (!Object.keys(activity).length) {
    return <AirbagLoaderLines />;
  }

  // Not found
  if (!activity) {
    return (
      <MessageCourtain
        icon="car-crash"
        message="No se encontró el registro"
        title="¡Oops!"
        link="/registros"
      />
    );
  }

  const { driver } = activity;

  return (
    <div id="_activity_">
      <div className="row no-margin">
        <div className="col s12">
          <h5 id="driver-name">
            Registro de{' '}
            <Link to={`/conductor?driverId=${driver.id}`}>
              {driver.name} {driver.lastName}
            </Link>
          </h5>
        </div>
        <div className="col s12">{renderActivity()}</div>
      </div>
    </div>
  );
}

export default Activity;
