import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import Icon from '../../../common/airbag-icons/Icon';
import Button from '../../../common/button/Button';
import './Filters.scss';

function Filters(props) {
  const { query, setQuery, setNewVehicleOpen, groups, group, setGroup, user } =
    props;

  function renderFirstGroupOption() {
    if (!groups.length) {
      return (
        <option value="" disabled>
          Sin grupos
        </option>
      );
    }
  }

  return (
    <div className="row" id="_filters-vehicles_">
      <div className="col s12">
        <span className="input-field item-container">
          <label htmlFor="query-input" className="active">
            Buscar
          </label>
          <input
            type="text"
            placeholder="Placas, ID / Nombre"
            id="query-input"
            value={query}
            onChange={(e) => {
              // Limit query to 25 chars
              if (e.target.value.length < 26) {
                setQuery(e.target.value);
              }
            }}
            onFocus={() => {
              // Google Analytics
              ReactGA.event({
                category: 'vehicles',
                action: 'search_vehicle_focus'
              });
              // Posthog
              posthog.capture('search_vehicle_focus', {
                category: 'vehicles',
                isCustomEvent: true
              });
            }}
          />
          {query && (
            <FontAwesomeIcon
              icon="times"
              id="query-clear"
              onClick={() => {
                setQuery('');
              }}
            />
          )}
        </span>
        {user.authRole === 'group-limited' && (
          <span className="input-field item-container">
            <label htmlFor="groups-select" className="active">
              Grupo
            </label>
            <select
              className="browser-default"
              id="groups-select"
              value={group}
              onChange={(e) => {
                setGroup(e.target.value);
              }}
            >
              {renderFirstGroupOption()}
              {groups.map((gp) => {
                return (
                  <option key={gp.id} value={gp.id}>
                    {gp.name}
                  </option>
                );
              })}
            </select>
          </span>
        )}
        <Button
          variant="yellow-button"
          id="add-vehicle-btn"
          onClick={() => {
            setNewVehicleOpen(true);

            // Google Analytics
            ReactGA.event({
              category: 'vehicles',
              action: 'open_new_vehicle'
            });
            // Posthog
            posthog.capture('open_new_vehicle', {
              category: 'vehicles',
              isCustomEvent: true
            });
          }}
          type="button"
        >
          Agregar vehículo
          <Icon icon="more" id="add-vehicle-icon" />
        </Button>
      </div>
    </div>
  );
}

export default Filters;
