import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import SentryIntegration from './sentry/SentryIntegration';
import Login from './auth/login/Login';
import ForgotPassword from './auth/forgot-password/ForgotPassword';
import RestorePassword from './auth/restore-password/RestorePassword';
import CompanyMain from './company/CompanyMain';
import NotFound from './not-found/NotFound';

function RoutesDefinitions(props) {
  return (
    <Switch>
      {/* Sentry testing route */}
      {/*
        <Route path="/sentry" exact render={() => <SentryIntegration />} />
      */}
      {/* Auth Routes */}
      <Route
        path="/forgot-password"
        render={() => <ForgotPassword {...props} />}
      />
      <Route
        path="/restore-password/:token"
        render={() => <RestorePassword {...props} />}
      />
      <Route
        path="/restore-password"
        render={() => <RestorePassword {...props} />}
      />
      <Route path="/login" render={() => <Login {...props} />} />
      {/* App Routes */}
      <Route
        path="/"
        exact
        render={() => <CompanyMain {...props} section="home" />}
      />
      <Route
        path="/conductores"
        render={() => <CompanyMain {...props} section="drivers" />}
      />
      <Route
        path="/conductor"
        render={() => <CompanyMain {...props} section="driver" />}
      />
      <Route
        path="/viajes/viaje"
        render={() => <CompanyMain {...props} section="trip" />}
      />
      <Route
        path="/vehiculo"
        render={() => <CompanyMain {...props} section="vehicle" />}
      />
      <Route
        path="/vehiculos"
        render={() => <CompanyMain {...props} section="vehicles" />}
      />
      <Route
        path="/ranking"
        render={() => <CompanyMain {...props} section="ranking" />}
      />
      <Route
        path="/estadisticas"
        render={() => <CompanyMain {...props} section="analytics" />}
      />
      <Route
        path="/reportes"
        render={() => <CompanyMain {...props} section="reports" />}
      />
      <Route
        path="/perfil"
        render={() => <CompanyMain {...props} section="profile" />}
      />
      <Route
        path="/grupos"
        render={() => <CompanyMain {...props} section="groups" />}
      />
      <Route
        path="/grupo"
        render={() => <CompanyMain {...props} section="group" />}
      />
      <Route
        path="/mapa"
        render={() => <CompanyMain {...props} section="map" />}
      />
      <Route
        path="/manuales"
        render={() => <CompanyMain {...props} section="manuals" />}
      />
      <Route
        path="/viajes"
        render={() => <CompanyMain {...props} section="trips" />}
      />
      <Route
        path="/registros"
        render={() => <CompanyMain {...props} section="activities" />}
      />
      <Route
        path="/registro"
        render={() => <CompanyMain {...props} section="activity" />}
      />
      <Route
        path="/game"
        render={() => <CompanyMain {...props} section="game" />}
      />
      <Route
        path="/facturacion"
        render={() => <CompanyMain {...props} section="billing" />}
      />
      <Route
        path="/formularios"
        render={() => <CompanyMain {...props} section="forms" />}
      />
      <Route
        path="/formulario"
        render={() => <CompanyMain {...props} section="form" />}
      />
      {/* Not Found */}
      <Route render={() => <NotFound />} />
    </Switch>
  );
}

export default RoutesDefinitions;
