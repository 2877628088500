import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import Error from '../../common/error/Error';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import Icon from '../../common/airbag-icons/Icon';
import ManualVideo from '../../common/manual-video/ManualVideo';
import axiosAuth from '../../../utils/axiosAuth';
import pieChartLogs from '../../../data-processing/pieChartLogs';
import HardData from './HardData';
import Graphs from './Graphs';
import ScoresCharts from './scores-charts/ScoresCharts';
import GamificationCharts from './gamification-charts/GamificationCharts';
// eslint-disable-next-line max-len
import RiskByLocationCharts from './risk-by-locations-charts/RiskByLocationCharts';
import './CompanyAnalytics.scss';

// When no data is present, fill array with no values
function fillNoDataArr(len) {
  const ret = [];
  for (let i = 0; i < len; i++) ret.push(0);
  return ret;
}
// Fill labels too
function fillNoLabelsArr(len) {
  const ret = [];
  for (let i = len; i > 0; i--) {
    ret.push(dayjs().subtract(i, 'd').format('DD MMM'));
  }
  return ret;
}

const DAYS_COUNT = 14;

function getGroupFromArray(arr, user) {
  let ret = null;

  // Admin users ignore groupId
  if (user.authRole === 'admin') {
    return ret;
  }

  if (arr && typeof arr === 'object' && arr.length) {
    // Assign first group in array
    ret = arr[0].id;
  }

  return ret;
}

function CompanyAnalytics(props) {
  const { user, company } = props;
  const { groups } = user;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [period, setPeriod] = useState('biweekly');
  const [currentLogsData, setCurrentLogsData] = useState(null);
  const [pastLogsData, setPastLogsData] = useState(null);
  // const [ranking, setRanking] = useState(null);
  // const [advanced, setAdvanced] = useState(null);
  const [current, setCurrent] = useState(null);
  const [past, setPast] = useState(null);
  const [group, setGroup] = useState(getGroupFromArray(groups, user));
  const [playVideo, setPlayVideo] = useState(false);
  const [month, setMonth] = useState(dayjs().format('YYYY-MM'));
  const videoUrl =
    'https://www.youtube.com/embed/ne6ohgiERuU?list=PLPbFMY-3HtQvwCS0QjdMy56MiOY3Q9wJQ';

  useEffect(() => {
    if (user.authRole === 'admin') {
      setLoading(true);
      // Reset values
      setCurrentLogsData(null);
      setPastLogsData(null);

      // Get company analytics
      axiosAuth
        .post(`/api/analytics`, { period, month })
        .then((res) => {
          const { current, past } = res.data;

          // setRanking(ranking);
          setCurrent(current);
          setPast(past);

          // Check that all info is in place first
          if (current && past) {
            // Handle log types count for charts current period
            const currentLogsArr = [];
            if (current.log_types && Object.keys(current.log_types).length) {
              Object.keys(current.log_types).forEach((key) => {
                currentLogsArr.push({
                  slug: key,
                  total: current.log_types[key]
                });
              });

              // Add labels, data and colors
              const currentLogsLabels = [];
              const currentLogsDataArr = [];
              const currentLogsColors = [];
              pieChartLogs(currentLogsArr, false).forEach((item) => {
                currentLogsLabels.push(`${item.name}: ${item.total}`);
                currentLogsDataArr.push(item.total);
                currentLogsColors.push(item.color);
              });
              setCurrentLogsData({
                labels: currentLogsLabels,
                data: currentLogsDataArr,
                colors: currentLogsColors
              });
            }

            // Handle log types count for charts past period
            const pastLogsArr = [];
            if (past.log_types && Object.keys(past.log_types).length) {
              Object.keys(past.log_types).forEach((key) => {
                pastLogsArr.push({ slug: key, total: past.log_types[key] });
              });

              // Add labels, data and colors
              const pastLogsLabels = [];
              const pastLogsDataArr = [];
              const pastLogsColors = [];
              pieChartLogs(pastLogsArr, false).forEach((item) => {
                pastLogsLabels.push(`${item.name}: ${item.total}`);
                pastLogsDataArr.push(item.total);
                pastLogsColors.push(item.color);
              });
              setPastLogsData({
                labels: pastLogsLabels,
                data: pastLogsDataArr,
                colors: pastLogsColors
              });
            }
          }

          // Set ranking table
          // if (ranking) {
          //   setRanking(ranking);
          // }

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, month]);

  function periodText(current) {
    if (!current) {
      return '-';
    }

    // Get only month and day
    const startRaw = current.start.slice(5, 10).split('-');
    // Invert them to get DD/MM format
    const start = startRaw[1] + '/' + startRaw[0];

    // Get only month and day
    const endRaw = current.end.slice(5, 10).split('-');
    // Invert them to get DD/MM format
    const end = endRaw[1] + '/' + endRaw[0];

    return `${start} - ${end}`;
  }

  function checkMinDate() {
    const companyCreated = dayjs(company.created);
    const minDate = dayjs().subtract(12, 'month');

    // return company creation date if it happened within the last 12 months
    if (companyCreated.isAfter(minDate)) {
      return companyCreated.format('YYYY-MM');
    }

    // Limit is 12 months ago
    return minDate.format('YYYY-MM');
  }

  function renderFilters() {
    return (
      <>
        <div className="filter-input">
          <label htmlFor="month" className="active">
            Mes
          </label>
          <input
            type="month"
            id="month"
            name="start"
            max={dayjs().format('YYYY-MM')}
            min={checkMinDate()}
            value={month}
            className="month-filter"
            onChange={(e) => setMonth(e.target.value)}
          />
        </div>
        <div className="filter-input">
          <label htmlFor="period-select">
            Periodo {`${periodText(current)}`}
          </label>
          <select
            id="period-select"
            className="browser-default"
            defaultValue={period}
            onChange={(e) => {
              const newValue = e.target.value;
              setPeriod(newValue);

              // Google Analytics
              if (newValue === 'weekly') {
                ReactGA.event({
                  category: 'analytics',
                  action: 'filter_analytics_period_weekly'
                });
                posthog.capture(`filter_analytics_period_weekly`, {
                  category: 'analytics',
                  isCustomEvent: true
                });
              } else if (newValue === 'biweekly') {
                ReactGA.event({
                  category: 'analytics',
                  action: 'filter_analytics_period_biweekly'
                });
                posthog.capture(`filter_analytics_period_biweekly`, {
                  category: 'analytics',
                  isCustomEvent: true
                });
              } else if (newValue === 'monthly') {
                ReactGA.event({
                  category: 'analytics',
                  action: 'filter_analytics_period_monthly'
                });
                posthog.capture(`filter_analytics_period_monthly`, {
                  category: 'analytics',
                  isCustomEvent: true
                });
              }
            }}
          >
            <option value="weekly">Semanal</option>
            <option value="biweekly">Quincenal</option>
            <option value="monthly">Mensual</option>
          </select>
        </div>
        {user.authRole === 'group-limited' ? (
          <div className="filter-input">
            <label htmlFor="groups-select" className="active">
              Grupo
            </label>
            {group ? (
              <select
                className="browser-default"
                id="groups-select"
                value={group}
                onChange={(e) => setGroup(e.target.value)}
              >
                {groups.map((gp) => {
                  return (
                    <option key={gp.id} value={gp.id}>
                      {gp.name}
                    </option>
                  );
                })}
              </select>
            ) : (
              <select className="browser-default" id="groups-select">
                <option value="" disabled>
                  Sin grupos
                </option>
              </select>
            )}
          </div>
        ) : null}
      </>
    );
  }

  // API error handler
  if (error) return <Error error={error} />;

  // No analytics
  if (!loading && !current && !past && user.authRole === 'admin') {
    return (
      <div id="_company-analytics_">
        <div className="row" id="main-title">
          <div className="col s12">
            <h5>
              Estadísticas
              <span>
                <FontAwesomeIcon
                  icon="question-circle"
                  id="more-info"
                  onClick={() => setPlayVideo(true)}
                />
              </span>
            </h5>
            <p>
              Información más importante registrada de tu flota. Se muestran los
              datos generados en el periodo seleccionado y la variación
              porcentual contra el mismo periodo anterior.
            </p>
          </div>
        </div>
        <div className="row no-margin" id="filters">
          <div className="col s12">{renderFilters()}</div>
        </div>
        <div id="no-data">
          <Icon icon="graph" id="error-icon" />
          <p>Sin información</p>
        </div>
      </div>
    );
  }

  return (
    <div id="_company-analytics_">
      <div className="row" id="main-title">
        <div className="col s12">
          <h5>
            Estadísticas
            <span>
              <FontAwesomeIcon
                icon="question-circle"
                id="more-info"
                onClick={() => setPlayVideo(true)}
              />
            </span>
          </h5>
          <p>
            Información más importante registrada de tu flota. Se muestran los
            datos generados en el periodo seleccionado y la variación porcentual
            contra el mismo periodo anterior.
          </p>
        </div>
      </div>
      {playVideo && (
        <ManualVideo
          videoUrl={videoUrl}
          playVideo={playVideo}
          setPlayVideo={setPlayVideo}
        />
      )}
      <div className="row no-margin" id="filters">
        <div className="col s12">{renderFilters()}</div>
      </div>
      {user.authRole === 'admin' ? (
        loading ? (
          <AirbagLoader />
        ) : (
          <>
            {/* Hard data tables */}
            <HardData current={current} past={past} />
            {/* Ranking table */}
            {/* <RankingTable ranking={ranking} /> */}
            {/* Graphs */}
            <p className="indicator-title">Información general</p>
            <Graphs
              currentLogsData={currentLogsData}
              pastLogsData={pastLogsData}
              period={period}
              current={current}
              past={past}
            />
          </>
        )
      ) : null}
      {/* Charts */}
      {dayjs(month).isSame(dayjs(), 'month') ? (
        <>
          {/* Scores Charts */}
          <ScoresCharts
            user={user}
            groupId={group}
            fillNoLabelsArr={fillNoLabelsArr}
            fillNoDataArr={fillNoDataArr}
            DAYS_COUNT={DAYS_COUNT}
          />
          {/* Gamification chart */}
          <p className="indicator-title">Gamificación</p>
          <GamificationCharts
            user={user}
            groupId={group}
            fillNoLabelsArr={fillNoLabelsArr}
            fillNoDataArr={fillNoDataArr}
            DAYS_COUNT={DAYS_COUNT}
          />
          {/* Risk by location chart*/}
          <p className="indicator-title">Información histórica por ubicación</p>
          <RiskByLocationCharts user={user} groupId={group} company={company} />
        </>
      ) : (
        <div className="row" id="charts-not-available">
          <div className="col s12">
            <div className="card-custom">
              <FontAwesomeIcon
                icon={['fal', 'signal-bars-slash']}
                id="no-data-icon"
              />
              <p>
                Las gráficas únicamente se encuentran disponibles para el mes en
                curso
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompanyAnalytics;
