import React from 'react';

function Record(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <g>
          <polyline
            className="cls-1"
            points="142.23 85.04 142.23 141.73 184.25 170.08"
          />
          <polyline
            className="cls-1"
            points="-1.22 123.7 28.85 153.76 58.91 123.7"
          />
        </g>
        <path className="cls-1" d="M29,153.75a113.34,113.34,0,1,1,14,43.77" />
      </g>
    </svg>
  );
}

export default Record;
