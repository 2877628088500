import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export const FormsItem = ({ idx, id, name, created, userInfo }) => {
  return (
    <tr key={id} className="form-row">
      <td>
        <div>
          <span className="pos-index">{idx + 1}.</span>
          <Link to={`/formulario?formId=${id}`}>{name}</Link>
        </div>
      </td>
      <td>
        <div>
          <div>{dayjs(created).format('DD [de] MMMM HH:mm')}</div>
        </div>
      </td>
      <td>
        <div>
          <div>{userInfo.fullName}</div>
        </div>
      </td>
    </tr>
  );
};
