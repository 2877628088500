import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import axiosAuth from '../../../../utils/axiosAuth';
import durationToString from '../../../../utils/durationToString';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import './VehicleStatistics.scss';

function VehicleStatistics(props) {
  const vehicleId = props.vehicleId;
  const [statistics, setStatistics] = useState({});
  const [loadingStatistics, setLoadingStatistics] = useState(true);

  useEffect(() => {
    // Get vehicle info
    fetchStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchStatistics() {
    axiosAuth
      .get(`/api/vehicles/${vehicleId}/statistics`)
      .then((res) => {
        const { statistics } = res.data;
        setStatistics(statistics);
        setLoadingStatistics(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function timeConvert(n) {
    if (n > 60) {
      const num = n;
      const hours = num / 60;
      const rhours = Math.floor(hours);
      const minutes = (hours - rhours) * 60;
      const rminutes = Math.round(minutes);
      return rhours + ' horas y ' + rminutes + ' minutos';
    } else {
      return n + ' minutos';
    }
  }

  // Loader
  if (loadingStatistics) return <AirbagLoader />;

  return (
    <div className="row">
      <div className="col s12">
        <div id="_vehicle-statistics_">
          {/* SCORING */}
          <div className="row metrics-table">
            <div className="col s12">
              <p className="table-title">Datos acumulados</p>
            </div>
            <div className="col s6 m4 l3 wrapp">
              <p className="stat-title">Viajes Realizados</p>
              <p className="stat-value">
                {statistics.totalTrips ? statistics.totalTrips : '-'}
              </p>
            </div>
            <div className="col s6 m4 l3 wrapp">
              <p className="stat-title">Distancia Recorrida</p>
              <p className="stat-value">
                {statistics.distance ? statistics.distance + ' km' : '-'}{' '}
              </p>
            </div>
            <div className="col s6 m4 l3 wrapp">
              <p className="stat-title">Tiempo de manejo</p>
              <p className="stat-value">
                {statistics.drivingTime
                  ? durationToString(
                      dayjs.duration(statistics.drivingTime, 'm')
                    )
                  : '-'}
              </p>
            </div>
            <div className="col s6 m6 l3 wrapp">
              <p className="stat-title">Velocidad promedio</p>
              <p className="stat-value">
                {statistics.averageSpeed
                  ? statistics.averageSpeed + ' km/h'
                  : '-'}{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleStatistics;
