import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

function TripAltimetry(props) {
  const { title, labels, data, colors, displayLegend } = props;
  const chartContainer = useRef(null);

  const chartConfig = {
    type: 'line',
    data: {
      labels,
      datasets: data.map((set, i) => {
        return {
          data: set.data,
          label: set.label,
          backgroundColor: colors[i],
          borderColor: colors[i],
          borderWidth: 2,
          tension: 0.4,
          radius: 0
        };
      })
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      resizeDelay: 800,
      animations: {
        radius: {
          duration: 200,
          easing: 'linear',
          loop: false
        }
      },
      plugins: {
        // https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
        tooltip: {
          callbacks: {
            title: () => {
              return null;
            },
            label: (tooltipItem, data) => {
              return tooltipItem.formattedValue;
            },
            afterLabel: () => {
              return null;
            }
          },
          backgroundColor: '#464646',
          padding: 12,
          displayColors: false
        },
        legend: {
          display: displayLegend,
          labels: {
            boxWidth: 10,
            boxHeight: 10,
            usePointStyle: true,
            pointStyle: 'circle',
            color: '#464646'
          }
        },
        title: {
          text: title,
          display: true,
          color: '#000',
          font: {
            size: 14
          }
        }
      }
    }
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      new Chart(chartContainer.current, chartConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContainer]);

  return <canvas ref={chartContainer} />;
}

export default TripAltimetry;
