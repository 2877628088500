import React, { useState, useEffect } from 'react';
import axiosAuth from '../../../../utils/axiosAuth';
import roundNumber from '../../../../utils/roundNumber';
import { getColors } from '../../../../utils/colors';
import cssVars from '../../../css-variables/css-variables-js';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import RiskByLocation from '../../../common/chartjs/RiskByLocation';
import ScoresByLocation from '../../../common/chartjs/ScoresByLocation';
import './RiskByLocationCharts.scss';

function RiskByLocationCharts(props) {
  const { user, groupId, company } = props;
  const [riskByState, setRiskByState] = useState(null);
  const [scoresByState, setScoresByState] = useState(null);
  const [statesLocationLabels, setLocationLabels] = useState(null);
  const [zoneFilter, setZoneFilter] = useState(
    company.country === 'col' ? 'county' : 'state'
  );

  useEffect(() => {
    setRiskByState(null);
    setScoresByState(null);
    setLocationLabels(null);

    // Build query params to add groupId if manager is group-limited
    const queryParams = {};
    if (user.authRole === 'group-limited' || groupId) {
      queryParams.groupId = groupId;
    }
    // Set zone filter
    queryParams.zoneFilter = zoneFilter;

    // Get company locations info
    axiosAuth
      .get(`/api/charts/company/locations`, { params: queryParams })
      .then((res) => {
        const { locations: locationsRes, labels } = res.data;
        const statesArr = locationsRes.slice(0, 10); // Only 10 states for now

        // Set risk by state
        setRiskByState({
          riskHigh: locationsRes.map((loc) => loc.riskProfileHigh),
          riskMid: locationsRes.map((loc) => loc.riskProfileMid),
          riskLow: locationsRes.map((loc) => loc.riskProfileLow)
        });
        // Set scores by state
        setScoresByState({
          overall: statesArr.map((loc) => roundNumber(loc.overall)),
          speeding: statesArr.map((loc) => roundNumber(loc.speeding)),
          acc: statesArr.map((loc) => roundNumber(loc.acceleration)),
          braking: statesArr.map((loc) => roundNumber(loc.braking)),
          cornering: statesArr.map((loc) => roundNumber(loc.cornering)),
          phoneDistraction: statesArr.map((loc) =>
            roundNumber(
              loc.phoneDistraction ? loc.phoneDistraction : loc.phonedistraction
            )
          )
        });
        // Set states labels
        setLocationLabels(labels.slice(0, 10));
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, zoneFilter]);

  function renderZoneFilters() {
    if (company.country === 'mex') {
      return (
        <select
          id="zoneFilter"
          className="browser-default"
          value={zoneFilter}
          onChange={(e) => setZoneFilter(e.target.value)}
        >
          <option value="street">Calle</option>
          <option value="neighborhood">Municipio</option>
          <option value="city">Ciudad</option>
          <option value="state">Estado</option>
          <option value="country">País</option>
        </select>
      );
    } else if (company.country === 'col') {
      return (
        <select
          id="zoneFilter"
          className="browser-default"
          value={zoneFilter}
          onChange={(e) => setZoneFilter(e.target.value)}
        >
          <option value="street">Calle</option>
          <option value="neighborhood">Zona</option>
          <option value="city">Ciudad</option>
          <option value="county">Departamento</option>
          <option value="country">País</option>
        </select>
      );
    }
  }

  return (
    <div id="_risk-by-location-charts_">
      <div id="filters">
        <label className="active" htmlFor="zoneFilter">
          Ubicación
        </label>
        {renderZoneFilters()}
      </div>
      <div className="graph-box risk-by-location">
        {riskByState && statesLocationLabels ? (
          <RiskByLocation
            title={`Viajes realizados por ubicación por nivel de riesgo`}
            labels={statesLocationLabels}
            data={[
              { data: riskByState.riskLow, label: 'Riesgo bajo' },
              { data: riskByState.riskMid, label: 'Riesgo medio' },
              { data: riskByState.riskHigh, label: 'Riesgo alto' }
            ]}
            colors={[
              cssVars['graph-successful'],
              cssVars['graph-attention'],
              cssVars['graph-error']
            ]}
            displayLegend={true}
          />
        ) : (
          <AirbagLoader />
        )}
      </div>
      <div className="graph-box risk-by-location">
        {scoresByState && statesLocationLabels ? (
          <ScoresByLocation
            title={`Calificación por hábito por ubicación`}
            labels={statesLocationLabels}
            data={[
              { data: scoresByState.overall, label: 'General' },
              {
                data: scoresByState.speeding,
                label: 'Exceso de velocidad'
              },
              { data: scoresByState.acc, label: 'Aceleración' },
              { data: scoresByState.braking, label: 'Frenado' },
              { data: scoresByState.cornering, label: 'Curvas' },
              {
                data: scoresByState.phoneDistraction,
                label: 'Uso de celular'
              }
            ]}
            colors={getColors(6)}
            displayLegend={true}
          />
        ) : (
          <AirbagLoader />
        )}
      </div>
    </div>
  );
}

export default RiskByLocationCharts;
