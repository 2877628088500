import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import Icon from '../../../common/airbag-icons/Icon';
import './AllDriversTable.scss';

function AllDriversTable(props) {
  const { drivers } = props;
  const [driverStatus, setDriverStatus] = useState('active');

  function renderOptions() {
    return (
      <>
        <option value="active">Activados</option>
        <option value="inactive">Desactivados</option>
        <option value="resting">En descanso</option>
        <option value="no-login">Sin configurar</option>
      </>
    );
  }

  function renderTableContent() {
    // No drivers in category
    if (!drivers[driverStatus].length) {
      return (
        <table className="airbag-table">
          <tbody>
            <tr>
              <td className="center-align">
                <Icon icon="profile" className="table-icon-indicator" />
                <br />
                Sin conductores
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    return (
      <table className="airbag-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Contacto</th>
          </tr>
        </thead>
        <tbody>
          {drivers[driverStatus].map((driver, i) => {
            return (
              <tr key={driver.id} className="driver-row">
                <td>
                  <span className="pos-index">{i + 1}.</span>
                  <Link to={`/conductor?driverId=${driver.id}`}>
                    {driver.fullName}
                  </Link>
                </td>
                <td>{driver.phone ? driver.phone : driver.email}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  function renderDriversTable() {
    // Loader
    if (!drivers) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="drivers-table">
        <div className="airbag-top">
          <select
            className="browser-default"
            id="drivers-select-all"
            onChange={(e) => setDriverStatus(e.target.value)}
            defaultValue={driverStatus}
          >
            {renderOptions()}
          </select>
        </div>
        {renderTableContent()}
      </div>
    );
  }

  return <div id="_all-drivers-table_">{renderDriversTable()}</div>;
}

export default AllDriversTable;
