import React, { useState } from 'react';
import M from 'materialize-css';
import axiosAuth from '../../../../../utils/axiosAuth';
import { buildScheduleforFrontEnd } from '../../../../../utils/schedule';
import ScheduleForm from '../../../../common/schedule-form/ScheduleForm';
import Button from '../../../../common/button/Button';
import './EditSchedule.scss';

function processTimezone(timezone) {
  if (!timezone) return 'GMT-06';
  return timezone;
}

function EditSchedule(props) {
  const { driverId, driver, setDriver, setEditScheduleOpen } = props;
  const [schedule, setSchedule] = useState(
    buildScheduleforFrontEnd(driver.schedule)
  );
  const [timezone, setTimezone] = useState(
    processTimezone(driver.schedule.timezone)
  );
  // Index 7 is for global errors
  const [errors, setErrors] = useState(['', '', '', '', '', '', '', '']);
  const [loading, setLoading] = useState(false);

  function handleFormSubmit() {
    setLoading(true);

    axiosAuth
      .post(`/api/users/edit-schedule`, {
        driverId,
        schedule,
        timezone
      })
      .then((res) => {
        setDriver({
          ...driver,
          schedule: { ...res.data.schedule, timezone: res.data.timezone }
        });
        M.toast({
          html: 'Horario guardado exitosamente',
          classes: 'success-toast'
        });
        setLoading(false);
        setEditScheduleOpen(false);
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  function checkForErrors() {
    let hasError = false;
    errors.forEach((error) => {
      if (error) hasError = true;
    });
    return hasError;
  }

  return (
    <div id="_edit-schedule_">
      <h5 id="main-t">Editar horario laboral</h5>
      <ScheduleForm
        schedule={schedule}
        setSchedule={setSchedule}
        timezone={timezone}
        setTimezone={setTimezone}
        errors={errors}
        setErrors={setErrors}
      />
      <div className="right-align">
        <Button
          variant="yellow-button"
          loading={loading}
          disabled={loading || checkForErrors()}
          type="submit"
          onClick={() => handleFormSubmit()}
        >
          Guardar horario
        </Button>
      </div>
    </div>
  );
}

export default EditSchedule;
