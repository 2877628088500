import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../lotties/loading_circulo.json';

function AirbagLoader(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return <Lottie options={defaultOptions} height={80} width={80} />;
}

export default AirbagLoader;
