// Get domain name to apply skin
function getDomainName() {
  const domainName = window.location.hostname;

  // Handle localhost
  if (domainName.includes('localhost') || domainName.includes('127.0.0.1')) {
    return 'localhost';
  }

  // In production, we need to remove subdomain if any
  if (domainName.split('.').length > 1) {
    return domainName.substring(
      domainName.split('.')[0].length + 1, // Start after the first '.'
      domainName.length // End of string
    );
  }

  return domainName;
}

export default getDomainName;
