import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React from 'react';
import ReactTooltip from 'react-tooltip';

export const BillableTooltip = ({
  billable,
  user,
  loginHistory,
  today,
  deletable
}) => {
  // isolated this into a separate function because it involves
  // several conditions and cases
  const tooltipMap = {
    billable: `Este conductor tiene ${today.diff(
      dayjs(loginHistory[0]?.created),
      'days'
    )} días desde su primer inicio de sesión.`,
    deletable: `Este conductor no estuvo activo al menos 72 hrs`,
    deleted: 'Este conductor fue eliminado y nunca inició sesión',
    noSession: 'Este conductor no ha iniciado sesión',
    // proratable: `${today.daysInMonth() - proratable} días`,
    deletedAndBillable: 'El usuario inició sesión por al menos 72 horas'
  };

  let label = 'NO';
  let tooltip = null;

  if (billable || user.billable) {
    label = 'SI';
  } else if (deletable) {
    tooltip = tooltipMap.deletable;
  } else if (user.deleted) {
    tooltip = tooltipMap.deleted;
  } else if (!loginHistory || !loginHistory.length) {
    tooltip = tooltipMap.noSession;
  }

  return (
    <>
      {label}{' '}
      {tooltip && (
        <>
          <FontAwesomeIcon icon="question-circle" data-tip={tooltip} />
          <ReactTooltip place="bottom" type="dark" effect="solid" clickable />
        </>
      )}
    </>
  );
};
