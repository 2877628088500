import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import axiosAuth from '../../../utils/axiosAuth';
import MessageCourtain from '../../common/message-courtain/MessageCourtain';
import './Form.scss';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import { FormDriverInfo } from './FormDriverInfo';
import { QuestionItem } from './QuestionItem';
import Icon from '../../common/airbag-icons/Icon';

function Form() {
  // Get all data after main url
  const queryString = window.location.search;
  // Get all params
  const urlParams = new URLSearchParams(queryString);
  const formId = urlParams.get('formId');
  const [form, setForm] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchForm = async () => {
    try {
      const { data } = await axiosAuth.get(`/api/forms/detail`, {
        params: { formId }
      });

      setForm(data.form);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchForm();
  }, []);

  const questions = useMemo(() => {
    if (!form || !form.content) return null;

    return form.content.map((item, idx) => (
      <QuestionItem item={item} key={`question-${idx}`} index={idx} />
    ));
  }, [form]);

  // Info loading
  if (isLoading) {
    return <AirbagLoader />;
  }

  // Not found
  if (!form) {
    return (
      <MessageCourtain
        icon="car-crash"
        message="No se encontró el formulario"
        title="¡Oops!"
        link="/formularios"
      />
    );
  }

  if (error) {
    return (
      <div id="no-data">
        <Icon icon="crash" id="error-icon" />
        <p>Algo salió mal</p>
      </div>
    );
  }

  return (
    <>
      <div id="_form_">
        <h5>{form.name}</h5>
        <p className="form-description">{form.description}</p>
        <div>
          Fecha de creación:{' '}
          {dayjs(form.created).format('DD [de] MMMM [a las] HH:mm')}
        </div>
        <h5>Información del conductor</h5>
        <FormDriverInfo {...form.userInfo} />
        <h5>Respuestas</h5>
        {questions}
      </div>
    </>
  );
}

export default withRouter(Form);
