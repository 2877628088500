import React from 'react';
import * as Sentry from '@sentry/react';
import Error from '../common/error/Error';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error in Sentry
    Sentry.captureException(error);
  }

  render() {
    // Render error interface
    if (this.state.hasError) return <Error />;

    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
