import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { posthog } from 'posthog-js';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import M from 'materialize-css';
import ReactTooltip from 'react-tooltip';
import ReactGA from 'react-ga4';
import { phoneNumberFormatter } from '../../../../utils/sanitizers';
import lastSeen from '../../../../utils/lastSeen';
import {
  sanitizeCivilStatus,
  sanitizeNationality,
  sanitizeGender
} from '../../../../utils/sanitizers';
import axiosAuth from '../../../../utils/axiosAuth';
import { buildScheduleforFrontEnd } from '../../../../utils/schedule';
import { getColors } from '../../../../utils/colors';
import SideModal from '../../../common/side-modal/SideModal';
import Button from '../../../common/button/Button';
import Icon from '../../../common/airbag-icons/Icon';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
// eslint-disable-next-line max-len
import LoaderFullscreen from '../../../common/loader-fullscreen/LoaderFullscreen';
import BatteryUsageChart from '../../../common/chartjs/BatteryUsageChart';
// eslint-disable-next-line max-len
import DriverNotifications from '../../../common/driver-notifications/DriverNotifications';
// eslint-disable-next-line max-len
import DriverActivations from '../../../common/driver-activations/DriverActivations';
import EditSchedule from './edit-schedule/EditSchedule';
import EditGeneral from './edit-general/EditGeneral';
// eslint-disable-next-line max-len
import EditEmergencyContact from './edit-emergency-contact/EditEmergencyContact';
import EditFlags from './edit-flags/EditFlags';
import './DriverInfo.scss';

function DriverInfo(props) {
  const { history, driver, driverId, setDriver, user } = props;
  const [editScheduleOpen, setEditScheduleOpen] = useState(false);
  const [editGeneralOpen, setEditGeneralOpen] = useState(false);
  const [editFlagsOpen, setEditFlagsOpen] = useState(false);
  const [showLoaderFullscreen, setShowLoaderFullscreen] = useState(false);
  const [editContactOpen, setEditContactOpen] = useState(false);
  const [batteryData, setBatterydata] = useState(null);
  const [altitudeData, setAltitudeData] = useState(null);

  useEffect(() => {
    // Get battery usage chart
    axiosAuth
      .get(`/api/users/${driverId}/battery-usage`)
      .then((res) => {
        const { batteryUsage, altitude } = res.data;

        // Set battery data
        setBatterydata({
          data: batteryUsage.data,
          labels: batteryUsage.labels.map((d) => dayjs(d).format('DD/MM HH:mm'))
        });

        // Set altitude data
        setAltitudeData({
          data: altitude.data,
          labels: altitude.labels.map((d) => dayjs(d).format('DD/MM HH:mm'))
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function deleteDriver() {
    axiosAuth
      .post(`/api/users/delete`, {
        driverId: driver.id
      })
      .then((res) => {
        setShowLoaderFullscreen(false);
        M.toast({
          html: 'Conductor eliminado exitosamente',
          classes: 'success-toast'
        });
        history.push('/conductores');
      })
      .catch((err) => {
        console.log(err);
        setShowLoaderFullscreen(false);
        M.toast({
          html: 'Tuvimos un error al eliminar al conductor',
          classes: 'error-toast'
        });
      });
  }

  function handleIsRestingChange() {
    axiosAuth
      .post(`/api/users/change-is-resting`, {
        driverId: driver.id
      })
      .then((res) => {
        setShowLoaderFullscreen(false);
        M.toast({
          html: 'Estado modificado exitosamente',
          classes: 'success-toast'
        });

        // Update user
        let newIsResting = true;
        if (driver.isResting) {
          newIsResting = false;
        }
        setDriver({ ...driver, isResting: newIsResting });
      })
      .catch((err) => {
        console.log(err);
        setShowLoaderFullscreen(false);
        M.toast({
          html: 'Tuvimos un error al cambiar el estado del conductor',
          classes: 'error-toast'
        });
      });
  }

  function handleDisableChange() {
    axiosAuth
      .post(`/api/users/change-status`, {
        driverId: driver.id
      })
      .then((res) => {
        const { newStatus } = res.data;
        setShowLoaderFullscreen(false);
        M.toast({
          html: 'Usuario deshabilitado exitosamente',
          classes: 'success-toast'
        });

        setDriver({ ...driver, status: newStatus });
      })
      .catch((err) => {
        console.log(err);
        setShowLoaderFullscreen(false);
        M.toast({
          html: 'Tuvimos un error al cambiar el estado del conductor',
          classes: 'error-toast'
        });
      });
  }

  function handleDeleteDriverClick() {
    Swal.fire({
      icon: 'error',
      title: '¿Deseas eliminar al conductor?',
      text: 'Esta acción no se puede deshacer',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: '<span class="dark-color-text">Si, eliminar</span>',
      confirmButtonColor: '#f6c700'
    }).then((result) => {
      if (result.isConfirmed) {
        setShowLoaderFullscreen(true);
        deleteDriver();
      }
    });
  }

  function handleIsRestingDriverClick() {
    Swal.fire({
      icon: 'question',
      title: '¿Deseas cambiar el estado del conductor?',
      text: 'Puedes revertir esta acción en cualquier momento',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: '<span class="dark-color-text">Si, cambiar</span>',
      confirmButtonColor: '#f6c700'
    }).then((result) => {
      if (result.isConfirmed) {
        setShowLoaderFullscreen(true);
        handleIsRestingChange();
      }
    });
  }

  function handleDisableDriverClick() {
    Swal.fire({
      icon: 'question',
      title: '¿Deseas deshabilitar al conductor?',
      text: 'Puedes revertir esta acción en cualquier momento',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText:
        '<span class="dark-color-text">Si, deshabilitar</span>',
      confirmButtonColor: '#f6c700'
    }).then((result) => {
      if (result.isConfirmed) {
        setShowLoaderFullscreen(true);
        handleDisableChange();
      }
    });
  }

  function renderScheduleActions(dayIndex) {
    const processedSchedule = buildScheduleforFrontEnd(driver.schedule);
    const daySchedule = processedSchedule[dayIndex];

    // Empty array: no notifications
    if (!daySchedule.actions.length || !daySchedule.active) {
      return <span className="no-action">Sin establecer</span>;
    }

    // Build JSX array
    const actions = [];
    daySchedule.actions.forEach((action, actionIndex) => {
      // Tooltip text
      let msg = 'Inicio de jornada';
      if (action.type === 'deactivation') msg = 'Fin de jornada';

      // Span element JSX
      const el = (
        <span
          className={`${action.type}`}
          key={`${dayIndex}-${actionIndex}`}
          data-tip={msg}
        >
          {action.hour}
        </span>
      );
      actions.push(el);
    });

    return actions;
  }

  function renderVehicleLink() {
    // No link yet to group limited users
    if (user.authRole === 'group-limited') {
      return null;
    }

    let link = '/vehiculos';
    // Go to vehicle info if it's assigned
    if (driver.vehicle) link = `/vehiculo?vehicleId=${driver.vehicle.id}`;

    // Default to vehicles
    return (
      <Link to={link}>
        <Icon icon="settings" className="edit" />
      </Link>
    );
  }

  function renderECType(ec) {
    if (ec) {
      if (ec.type === 'cellphone') return '(celular)';
      if (ec.type === 'landphone') return '(línea fija)';
    }
    return '(sin tipo)';
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function renderGroups(groups) {
    if (groups && groups.length) {
      return groups.map(
        (group, i) => `${group.name}${i < groups.length - 1 ? ', ' : ''}`
      );
    }
    return '-';
  }

  function renderFirstLogin(firstLogin) {
    if (firstLogin) {
      return dayjs(firstLogin).format('D [de] MMMM, YYYY HH:mm');
    }
    return '-';
  }

  function renderLastLogin(lastLogin) {
    if (lastLogin) {
      return dayjs(lastLogin).format('D [de] MMMM, YYYY HH:mm');
    }
    return '-';
  }

  // Driver info loading
  if (!driver) return <AirbagLoader />;

  return (
    <div id="_driver-info_">
      <div className="row" id="info">
        <div className="col s12 m6 xl4 section-wrapper">
          <div className="section">
            <h6 className="section-label">
              Horario laboral{' '}
              {driver.schedule ? (
                driver.schedule.timezone ? (
                  <span id="tz">{driver.schedule.timezone}</span>
                ) : null
              ) : null}
              <Icon
                icon="settings"
                className="edit"
                onClick={() => {
                  setEditScheduleOpen(true);

                  // Google Analytics
                  ReactGA.event({
                    category: 'driver_details',
                    action: 'open_edit_schedule'
                  });

                  // Posthog
                  posthog.capture('open_edit_schedule', {
                    category: 'driver_details',
                    isCustomEvent: true
                  });
                }}
              />
            </h6>
            <div className="section-content schedule-tags">
              <label>Hoy {dayjs().format('dddd')}</label>
              <div>{renderScheduleActions(dayjs().isoWeekday() - 1)}</div>
            </div>
            <div className="section-content schedule-tags">
              <label>Mañana {dayjs().add(1, 'd').format('dddd')}</label>
              <div>
                {renderScheduleActions(dayjs().add(1, 'd').isoWeekday() - 1)}
              </div>
            </div>
          </div>
        </div>
        <div className="col s12 m6 xl4 section-wrapper">
          <div className="section">
            <h6 className="section-label">
              Vehículo
              {renderVehicleLink()}
            </h6>
            <div className="section-content">
              {driver.vehicle ? (
                <React.Fragment>
                  <label>Nombre</label>
                  <p>
                    <FontAwesomeIcon
                      className="indicator"
                      icon={['fal', 'truck']}
                    />
                    {driver.vehicle.uid}
                  </p>
                </React.Fragment>
              ) : (
                <label>Sin asignar</label>
              )}
            </div>
            {driver.vehicle && (
              <div className="section-content">
                <label>Marca / Modelo / Placas</label>
                <p>
                  <FontAwesomeIcon className="indicator" icon="file" />
                  {driver.vehicle.brand
                    ? capitalizeFirstLetter(driver.vehicle.brand)
                    : '-'}{' '}
                  / {driver.vehicle.model || '-'} / {driver.vehicle.plate}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="col s12 m12 xl4 section-wrapper">
          <div className="section">
            <h6 className="section-label">
              Contacto de emergencia
              <Icon
                icon="settings"
                className="edit"
                onClick={() => {
                  setEditContactOpen(true);

                  // Google Analytics
                  ReactGA.event({
                    category: 'driver_details',
                    action: 'open_edit_emergency_contact'
                  });

                  // Posthog
                  posthog.capture('open_edit_emergency_contact', {
                    category: 'driver_details',
                    isCustomEvent: true
                  });
                }}
              />
            </h6>
            <div className="section-content">
              <label>Nombre</label>
              <p>
                <FontAwesomeIcon className="indicator" icon={['fal', 'user']} />
                {driver.emergencyContact && driver.emergencyContact.name
                  ? driver.emergencyContact.name
                  : '-'}
              </p>
            </div>
            <div className="section-content">
              <label>Teléfono {renderECType(driver.emergencyContact)}</label>
              <p>
                <FontAwesomeIcon
                  className="indicator"
                  icon={['fal', 'phone']}
                />
                {driver.emergencyContact && driver.emergencyContact.phone ? (
                  <a href={`tel:${driver.emergencyContact.phone}`}>
                    {phoneNumberFormatter(driver.emergencyContact.phone)}
                  </a>
                ) : (
                  '-'
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col s12 section-wrapper">
          <div className="section">
            <div className="row">
              <div className="col s12 l6">
                <div className="ba-graphs-wrapper">
                  {batteryData ? (
                    <BatteryUsageChart
                      title="Uso de batería"
                      labels={batteryData.labels}
                      data={[{ data: batteryData.data, label: '' }]}
                      colors={[getColors(1)]}
                      displayLegend={false}
                    />
                  ) : (
                    <AirbagLoader />
                  )}
                </div>
              </div>
              <div className="col s12 l6">
                <div className="ba-graphs-wrapper">
                  {altitudeData ? (
                    <BatteryUsageChart
                      title="Altimetría"
                      labels={altitudeData.labels}
                      data={[{ data: altitudeData.data, label: '' }]}
                      colors={[getColors(2)[1]]}
                      displayLegend={false}
                    />
                  ) : (
                    <AirbagLoader />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col s12 section-wrapper">
          <div className="section">
            <h6 className="section-label">
              Empresa
              <Icon
                className="edit"
                icon="settings"
                onClick={() => setEditGeneralOpen(true)}
              />
            </h6>
            <div className="row no-margin">
              <div className="col s12 m6 l3">
                <div className="section-content">
                  <label>ID interno</label>
                  <p>
                    <FontAwesomeIcon
                      className="indicator"
                      icon="address-card"
                    />
                    {driver.internalCompanyId ? driver.internalCompanyId : '-'}
                  </p>
                </div>
              </div>
              <div className="col s12 m6 l3">
                <div className="section-content">
                  <label>Grupo</label>
                  <p>
                    <FontAwesomeIcon
                      className="indicator"
                      icon={['fal', 'users']}
                    />
                    {renderGroups(driver.groups)}
                  </p>
                </div>
              </div>
              <div className="col s12 m6 l3">
                <div className="section-content">
                  <label>Tiempo en la empresa</label>
                  <p>
                    <Icon icon="record" id="history" />
                    {driver.hired ? lastSeen(driver.hired) : '-'}
                  </p>
                </div>
              </div>
              <div className="col s12 m6 l3">
                <div className="section-content">
                  <label>Etapa de alta</label>
                  <p>
                    <FontAwesomeIcon className="indicator" icon="list-ol" />
                    {driver.startStage ? driver.startStage : '-'}
                  </p>
                </div>
              </div>
              <div className="col s12 m6 l3">
                <div className="section-content">
                  <label>Puesto</label>
                  <p>
                    <FontAwesomeIcon
                      icon={['fal', 'user']}
                      className="indicator"
                    />
                    {driver.jobTitle ? driver.jobTitle : '-'}
                  </p>
                </div>
              </div>
            </div>
            <h6 className="section-label">Personal</h6>
            <div className="row no-margin">
              <div className="col s12 m6 l3">
                <div className="section-content">
                  <label>Nombre</label>
                  <p>
                    <FontAwesomeIcon
                      className="indicator"
                      icon={['fal', 'user']}
                    />
                    {driver.name} {driver.lastName}
                  </p>
                </div>
              </div>
              <div className="col s12 m6 l3">
                <div className="section-content">
                  <label>Género</label>
                  <p>
                    <FontAwesomeIcon className="indicator" icon="venus-mars" />
                    {driver.gender ? sanitizeGender(driver.gender) : '-'}
                  </p>
                </div>
              </div>
              <div className="col s12 m6 l3">
                <div className="section-content">
                  <label>Edad</label>
                  <p>
                    <FontAwesomeIcon
                      className="indicator"
                      icon="birthday-cake"
                    />
                    {driver.birthdate ? lastSeen(driver.birthdate) : '-'}
                  </p>
                </div>
              </div>
              <div className="col s12 m6 l3">
                <div className="section-content">
                  <label>Estado Civil</label>
                  <p>
                    <FontAwesomeIcon className="indicator" icon="heart" />
                    {driver.civilStatus
                      ? sanitizeCivilStatus(driver.civilStatus)
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="col s12 m6 l3">
                <div className="section-content">
                  <label>Nacionalidad</label>
                  <p>
                    <FontAwesomeIcon className="indicator" icon="flag" />
                    {driver.nationality
                      ? sanitizeNationality(driver.nationality)
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="col s12 m6 l3">
                <div className="section-content">
                  <label>ID nacional</label>
                  <p>
                    <FontAwesomeIcon
                      className="indicator"
                      icon="address-card"
                    />
                    {driver.nationalId ? driver.nationalId : '-'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col s12 l8 xl7 section-wrapper">
          <div className="section">
            <h6 className="section-label">Sistema</h6>
            <div className="row">
              <div className="col s12 m6">
                <div className="section-content">
                  <label>Registro</label>
                  <p>
                    <FontAwesomeIcon
                      className="indicator"
                      icon="address-card"
                    />
                    {dayjs(driver.created).format('D [de] MMMM, YYYY HH:mm')}
                  </p>
                </div>
              </div>
              <div className="col s12 m6">
                <div className="section-content">
                  <label>Modelo de celular</label>
                  <p>
                    <FontAwesomeIcon className="indicator" icon="mobile-alt" />
                    {driver.phoneModel ? driver.phoneModel : '-'}
                  </p>
                </div>
              </div>
              <div className="col s12 m6">
                <div className="section-content">
                  <label>Primer ingreso</label>
                  <p>
                    <Icon icon="record" id="history" />
                    {renderFirstLogin(driver.firstLoginDate)}
                  </p>
                </div>
              </div>
              <div className="col s12 m6">
                <div className="section-content">
                  <label>Ingreso más reciente</label>
                  <p>
                    <Icon icon="record" id="history" />
                    {renderLastLogin(driver.lastLogin)}
                  </p>
                </div>
              </div>
            </div>
            <h6 className="section-label center-mobile">
              Historial de notificaciones
            </h6>
            <div className="row">
              <div className="col s12">
                <div className="section-content">
                  <DriverNotifications driverId={driverId} />
                </div>
              </div>
            </div>
            <div className="divider"></div>
            <h6 className="section-label center-mobile">
              Historial de activaciones
            </h6>
            <div className="row">
              <div className="col s12">
                <div className="section-content">
                  <DriverActivations driverId={driverId} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col s12 l4 xl5 section-wrapper">
          <div className="section">
            <h6 className="section-label">
              Banderas
              <Icon
                className="edit"
                icon="settings"
                onClick={() => setEditFlagsOpen(true)}
              />
            </h6>
            Aquí puedes controlar las secciones y/o elementos que se muestran y
            esconden en la aplicación móvil del conductor.
            <div className="flag-row">
              <FontAwesomeIcon
                icon="question-circle"
                // eslint-disable-next-line max-len
                data-tip="Indica si el operador tiene la opción de activarse y desactivarse por si mismo desde su app."
              />
              Autoactivación:
              {driver.activationMode === 'SINGLE_MODE' ? (
                <span className="ok">Prendida</span>
              ) : (
                <span className="notok">Apagada</span>
              )}
            </div>
            <div className="flag-row">
              <FontAwesomeIcon
                icon="question-circle"
                // eslint-disable-next-line max-len
                data-tip="Se muestra u oculta la vista con las calificaciones del conductor"
              />
              Calificaciones:
              {driver.showMobileScores ? (
                <span className="ok">Prendida</span>
              ) : (
                <span className="notok">Apagada</span>
              )}
            </div>
            <div className="flag-row">
              <FontAwesomeIcon
                icon="question-circle"
                // eslint-disable-next-line max-len
                data-tip="Se muestra u oculta la vista con los viajes realizados por el conductor"
              />
              Viajes realizados:
              {driver.showMobileTrips ? (
                <span className="ok">Prendida</span>
              ) : (
                <span className="notok">Apagada</span>
              )}
            </div>
            <div className="flag-row">
              <FontAwesomeIcon
                icon="question-circle"
                // eslint-disable-next-line max-len
                data-tip="Al estar prendida, habilitamos el registro de inicio y fin de jornada laboral"
              />
              Jornada laboral:
              {driver.showMobileWorkDay ? (
                <span className="ok">Prendida</span>
              ) : (
                <span className="notok">Apagada</span>
              )}
            </div>
          </div>
        </div>
        <div className="col s12" id="actions">
          <Button
            variant="red-button"
            loading={showLoaderFullscreen}
            disabled={showLoaderFullscreen}
            type="button"
            onClick={() => handleDeleteDriverClick()}
          >
            Eliminar conductor
          </Button>
          <Button
            variant="yellow-button"
            loading={showLoaderFullscreen}
            disabled={showLoaderFullscreen}
            type="button"
            onClick={() => handleIsRestingDriverClick()}
          >
            {driver.isResting
              ? 'Quitar de En Descanso'
              : 'Cambiar a En Descanso'}
          </Button>
          <Button
            variant="yellow-button"
            loading={showLoaderFullscreen}
            disabled={showLoaderFullscreen}
            type="button"
            onClick={() => handleDisableDriverClick()}
          >
            Deshabilitar conductor
          </Button>
        </div>
        {showLoaderFullscreen && <LoaderFullscreen />}
      </div>

      {/* EDIT SCHEDULE */}
      <SideModal isOpen={editScheduleOpen} setIsOpen={setEditScheduleOpen}>
        {editScheduleOpen && (
          <EditSchedule
            driverId={driverId}
            driver={driver}
            setDriver={setDriver}
            setEditScheduleOpen={setEditScheduleOpen}
            {...props}
          />
        )}
      </SideModal>

      {/* Edit general */}
      <SideModal isOpen={editGeneralOpen} setIsOpen={setEditGeneralOpen}>
        <EditGeneral
          driver={driver}
          setDriver={setDriver}
          setEditGeneralOpen={setEditGeneralOpen}
        />
      </SideModal>

      {/* Edit emergency contact */}
      <SideModal isOpen={editContactOpen} setIsOpen={setEditContactOpen}>
        <EditEmergencyContact
          driver={driver}
          setDriver={setDriver}
          setEditContactOpen={setEditContactOpen}
        />
      </SideModal>

      {/* Edit flags */}
      <SideModal isOpen={editFlagsOpen} setIsOpen={setEditFlagsOpen}>
        <EditFlags
          driver={driver}
          setDriver={setDriver}
          setEditFlagsOpen={setEditFlagsOpen}
        />
      </SideModal>

      {/* Tooltips */}
      <ReactTooltip />
    </div>
  );
}

export default withRouter(DriverInfo);
