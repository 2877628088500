import React, { useState } from 'react';
import LoaderInner from '../../../common/loader-inner/LoaderInner';
import { logsDefinitions } from '../../../../utils/logs';
import Filters from '../filters/Filters';
import HereMap from '../../../common/here-map/HereMap';
import './TripMap.scss';

function Map(props) {
  const { trip, logs, locationLogs, polylines, focus, applyBoundingBox } =
    props;
  const [filters, setFilters] = useState(
    logsDefinitions.map((log) => log.slug)
  );
  const [showLocationLogs, setShowLocationLogs] = useState(false);

  // Waiting for info
  if (!logs || !locationLogs) return <LoaderInner />;

  return (
    <div className="row" id="_trip-map_">
      <div className="col s12">
        <Filters
          filters={filters}
          setFilters={setFilters}
          showLocationLogs={showLocationLogs}
          setShowLocationLogs={setShowLocationLogs}
        />
      </div>
      <div className="col s12">
        <div className="box-container" id="map">
          <HereMap
            center={{
              lat: focus ? focus.lat : 19.432541,
              lng: focus ? focus.lon : -99.133403
            }}
            height={500}
            trip={{ ...trip, logs, locationLogs, polylines }}
            filters={filters}
            applyBoundingBox={applyBoundingBox.current}
            id="here-map-anchor"
          />
        </div>
      </div>
    </div>
  );
}

export default Map;
