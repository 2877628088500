/* eslint max-len: 0 */

import React from 'react';

function Favorite(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path
          className="cls-1"
          d="M146.64,38.13l32.07,65,71.72,10.42a5.47,5.47,0,0,1,3,9.33l-51.9,50.59,12.26,71.42a5.48,5.48,0,0,1-7.94,5.77l-64.15-33.72L77.59,250.65a5.47,5.47,0,0,1-7.94-5.77L81.9,173.46,30,122.87a5.47,5.47,0,0,1,3-9.33l71.71-10.42,32.08-65A5.47,5.47,0,0,1,146.64,38.13Z"
        />
      </g>
    </svg>
  );
}

export default Favorite;
