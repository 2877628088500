import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import M from 'materialize-css';
import axiosAuth from '../../../../utils/axiosAuth';
import ActivitiesList from '../../../common/activities-list/ActivitiesList';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import HereMap from '../../../common/here-map/HereMap';
import Icon from '../../../common/airbag-icons/Icon';
import Error from '../../../common/error/Error';
import Button from '../../../common/button/Button';
import './DriverReports.scss';

function DriverReports(props) {
  const { driver } = props;
  const valores = window.location.search;
  const urlParams = new URLSearchParams(valores);
  const startDate = urlParams.get('startDate')
    ? urlParams.get('startDate')
    : '';
  const endDate = urlParams.get('endDate') ? urlParams.get('endDate') : '';
  const [error, setError] = useState(null);
  const [activities, setActivities] = useState(null);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [date, setDate] = useState('');
  const [month, setMonth] = useState(dayjs().startOf('m').format('YYYY-MM'));
  const [focus, setFocus] = useState();
  const [type, setType] = useState();
  const [filter, setFilter] = useState(
    startDate && endDate ? 'period' : 'month'
  );
  const [startDateFilter, setStartDateFilter] = useState(startDate);
  const [endDateFilter, setEndDateFilter] = useState(endDate);

  // Data to send to component CalendarHeatmap
  // startDate = CalendarStart
  const [CalendarStart, setCalendarStart] = useState(
    dayjs().startOf('month').subtract(1, 'D').format('YYYY-MM-DD')
  );
  // endDate = CalendarEnd
  const [CalendarEnd, setCalendarEnd] = useState(
    dayjs().endOf('month').add(1, 'D').format('YYYY-MM-DD')
  );
  // Holder to api's result
  const [monthSchedule, setMonthSchedule] = useState([]);

  // Fetch activities on first load
  useEffect(() => {
    fetchActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, month, filter]);

  function fetchActivities() {
    /* Build query parameters */
    const queryParameters = {};
    // User ID
    queryParameters.driverId = driver.id;

    // Build params based on filter
    if (filter === 'month') {
      queryParameters.month = month;
      if (date) {
        queryParameters.date = date;
      }
    } else if (filter === 'period') {
      queryParameters.startDate = startDateFilter;
      queryParameters.endDate = endDateFilter;
    }

    // Get activities from server
    axiosAuth
      .get(`/api/activities`, {
        params: queryParameters
      })
      .then((res) => {
        const { activities: activitiesRes } = res.data;
        setMediaLoading(true);

        // Here you can process array's information
        const processedActivities = activitiesRes;

        // Set new activities array
        setActivities(processedActivities);
        // Fetch media as lazy loading
        fetchMedia(processedActivities);

        if (filter === 'month') {
          let x = 0;
          // Hold the activities to debug
          const arraySchedule = [];

          processedActivities.forEach((element) => {
            const dayTimeZone = dayjs(element.created).format('YYYY-MM-DD');
            arraySchedule.push({
              // date, count and type is all
              // parameters to calendarHeatmap component
              date: dayTimeZone,
              time: dayjs(element.created).format('hh:mm:ss'),
              count: (x = x + 1),
              type: element.type
            });
          });
          setMonthSchedule(arraySchedule);
        }
      })
      .catch((err) => {
        console.log(err);

        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setActivities([]);
      });
  }

  function fetchMedia(activitiesParam) {
    axiosAuth
      .post(`/api/activities/media`, { activitiesParam })
      .then((res) => {
        setMediaLoading(true);
        const { activitiesMedia } = res.data;

        setActivities(activitiesMedia);
        setMediaLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }

  function renderActivitiesTable() {
    // Loader
    if (!activities) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No activities means no data
    if (!activities.length) {
      return (
        <div className="airbag-table-container">
          <div className="graph-message" id="no-data">
            <Icon icon="eventRegister" className="graph-icon-indicator" />
            <p>Sin reportes</p>
          </div>
        </div>
      );
    }

    return (
      <ActivitiesList
        activities={activities}
        setFocus={setFocus}
        applyBoundingBox={null}
        setType={setType}
        mediaLoading={mediaLoading}
        userId={driver.id}
      />
    );
  }

  const renderCalendar = () => {
    function renderCalendarSpan() {
      if (date) {
        return (
          <div className="fake-input">
            <span>{dayjs(date).format('DD - MMMM - YYYY')}</span>
            <FontAwesomeIcon
              icon="times"
              id="date-clear"
              onClick={() => {
                setActivities(null);
                setDate('');
              }}
            />
          </div>
        );
      }
      return null;
    }

    if (!activities) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    return (
      <div className="container calendar-container">
        {renderCalendarSpan()}
        <CalendarHeatmap
          startDate={CalendarStart}
          endDate={CalendarEnd}
          values={monthSchedule}
          onClick={(e) => {
            if (e) setDate(new Date(e.date + 'T00:00'));
          }}
          tooltipDataAttrs={(value) => {
            if (value) {
              return {
                'data-tip': value.date
                  ? `${dayjs(value.date).format('DD-MMM-YYYY')}`
                  : ''
              };
            }
          }}
          horizontal={false}
          showMonthLabels={false}
          gutterSize={2}
        />
        <ReactTooltip />
      </div>
    );
  };

  function renderSecondaryFilters() {
    if (filter === 'month') {
      return (
        <span className="input-field item-container year-container">
          <label htmlFor="status-select" className="active">
            Mes
          </label>
          <input
            id="yearInput"
            type="month"
            value={month}
            onChange={(e) => {
              setDate('');
              setMonth(e.target.value);
              setCalendarStart(
                dayjs(e.target.value)
                  .startOf('month')
                  .subtract(1, 'D')
                  .format('YYYY-MM-DD')
              );
              setCalendarEnd(
                dayjs(e.target.value)
                  .endOf('month')
                  .add(1, 'D')
                  .format('YYYY-MM-DD')
              );
            }}
            min={dayjs().subtract(12, 'month').format('YYYY-MM')}
            max={dayjs().format('YYYY-MM')}
          />
        </span>
      );
    } else if (filter === 'period') {
      return (
        <>
          <span className="input-field item-container year-container">
            <label htmlFor="status-select" className="active">
              Fecha de inicio
            </label>
            <input
              id="yearInput"
              type="date"
              value={startDateFilter}
              onChange={(e) => {
                setStartDateFilter(e.target.value);
              }}
              min={dayjs().subtract(12, 'month').format('YYYY-MM-DD')}
              max={
                endDateFilter
                  ? dayjs(endDateFilter).format('YYYY-MM-DD')
                  : dayjs().format('YYYY-MM-DD')
              }
            />
          </span>
          <span className="input-field item-container year-container">
            <label htmlFor="status-select" className="active">
              Fecha de final
            </label>
            <input
              id="yearInput"
              type="date"
              value={endDateFilter}
              onChange={(e) => {
                setEndDateFilter(e.target.value);
              }}
              min={
                startDateFilter
                  ? dayjs(startDateFilter).format('YYYY-MM-DD')
                  : dayjs().subtract(3, 'month').format('YYYY-MM-DD')
              }
              max={dayjs().format('YYYY-MM-DD')}
            />
          </span>
          <div className="button-container">
            <Button
              variant="yellow-button"
              type="button"
              disabled={!startDateFilter || !endDateFilter}
              onClick={() => {
                setActivities(null);
                fetchActivities();
              }}
            >
              Buscar
            </Button>
            <Button
              variant="red-button"
              type="button"
              disabled={!startDateFilter || !endDateFilter}
              onClick={() => {
                setEndDateFilter('');
                setStartDateFilter('');
                setActivities([]);
              }}
            >
              Limpiar
            </Button>
          </div>
        </>
      );
    }
  }

  // API error handler
  if (error) return <Error error={error} />;

  return (
    <div id="_driver-reports_">
      <div id="activities-table">
        <div className="filters">
          <div className="dp-wrap">
            <div className="search-filters">
              <span className="input-field item-container">
                <label htmlFor="status-select" className="active">
                  Selecciona tu filtro
                </label>
                <select
                  className="browser-default"
                  id="status-select"
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                  }}
                >
                  <option value="month">Por mes</option>
                  <option value="period">Por rango de fechas</option>
                </select>
              </span>
              {renderSecondaryFilters()}
            </div>
            {filter === 'month' ? (
              <div className="dp-wrap">{renderCalendar()}</div>
            ) : null}
          </div>
        </div>

        <div className="col s12 m6" id="map-container">
          {focus ? (
            <div>
              <HereMap
                center={{
                  lat: focus.lat,
                  lng: focus.lon
                }}
                zoom={15}
                reportMarker={{ lng: focus.lon, lat: focus.lat, type: type }}
              />
            </div>
          ) : (
            <div id="map-message">
              <p>
                Pulsa el botón
                <FontAwesomeIcon
                  icon={['fal', 'map-location-dot']}
                  id="act-tag-focus"
                />
                para mostrar la ubicación del reporte
              </p>
            </div>
          )}
        </div>

        <div className="col s12 m6">{renderActivitiesTable()}</div>
      </div>
    </div>
  );
}

export default DriverReports;
