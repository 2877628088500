/* eslint max-len: 0 */
import React from 'react';

function Night(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path
          className="cls-1"
          d="M191.42,170.08A77.94,77.94,0,0,1,146.48,28.45c-1.57-.06-3.16-.1-4.75-.1A113.39,113.39,0,1,0,255.12,141.73c0-1.52,0-3-.1-4.55A77.86,77.86,0,0,1,191.42,170.08Z"
        />
      </g>
    </svg>
  );
}

export default Night;
