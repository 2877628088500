import React, { useState, useEffect, useMemo, useCallback } from 'react';
import M from 'materialize-css';
import axiosAuth from '../../../utils/axiosAuth';
import HeaderSection from '../../common/header-section/HeaderSection';
import Icon from '../../common/airbag-icons/Icon';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import './Forms.scss';
import { FormsItem } from './FormsItem';

function Forms() {
  const [forms, setForms] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchForms = async () => {
    try {
      const { data } = await axiosAuth.get(`/api/forms`);
      setForms(data.forms);
    } catch (err) {
      let msg = 'Tuvimos un error, intenta de nuevo más tarde';
      if (
        err.response &&
        err.response.data &&
        typeof err.response.data === 'string'
      ) {
        msg = err.response.data;
      }

      M.toast({
        html: msg,
        classes: 'error-toast'
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchForms();
  }, []);

  const tableRows = useMemo(() => {
    return forms?.map((form, i) => (
      <FormsItem {...form} key={form.id} idx={i} />
    ));
  }, [forms]);

  // use of 'useCallback' to o avoid recreating the
  // 'renderFormsTable' function on every render
  const renderFormsTable = useCallback(() => {
    if (isLoading) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    if (!forms || !forms.length) {
      return (
        <div id="no-data">
          <Icon icon="crash" id="error-icon" />
          <p>Sin formularios</p>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="forms-table">
        <table className="airbag-table">
          <thead className="table-header">
            <tr>
              <th>Nombre</th>
              <th>Fecha</th>
              <th>Conductor</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
    );
  }, [isLoading, forms, tableRows]);

  return (
    <div id="_company-forms_">
      <HeaderSection
        title="Formularios"
        body="Consulta los formularios enviados por tus operadores."
      />
      <div className="row no-margin">
        <div className="col s12">{renderFormsTable()}</div>
      </div>
    </div>
  );
}

export default Forms;
