import reverseGeocoder from '../reverseGeocoder';
import getMarker from './getMarker';

async function dragableMarker(
  mapObj,
  behavior,
  H,
  markersDragable,
  setMarkersDragable,
  setWaypoints,
  waypoints,
  setLoading
) {
  let getMarkerPosition = 0;
  const newDragables = [];
  let waypointsCopy = [];

  function setMarkersInfo() {
    const currentObjects = mapObj.current.getObjects();
    mapObj.current.removeObjects(currentObjects);
    const icon = new H.map.Icon(getMarker('location-ok'), {
      anchor: new H.math.Point(29, 85)
    });
    if (newDragables.length < markersDragable.length) {
      waypointsCopy = waypoints;
      markersDragable.map(async (markerDrag, id) => {
        newDragables.push(markerDrag);
        // Marker object
        const marker = new H.map.Marker(
          markerDrag,
          { icon },
          {
            // mark the object as volatile for the smooth dragging
            volatility: true
          }
        );
        // Ensure that the marker can receive drag events
        marker.draggable = true;
        mapObj.current.addObject(marker);
        // Marker object
      });
    }
  }

  // Get the new marker's position and
  // get the new marker's info to
  // Save it and chenge waypointsList
  function updateDataMarker(target) {
    setLoading(true);
    waypoints.map(async (point, id) => {
      // Compares the markers position to change
      // the marker info
      if (
        point.position.lat == getMarkerPosition.lat &&
        point.position.lon == getMarkerPosition.lng
      ) {
        // Getting the new markers info
        const hereInfo = await reverseGeocoder(
          target.getGeometry().lat,
          target.getGeometry().lng
        );
        console.log(hereInfo);
        // Changing  the new marker position
        newDragables[id] = {
          lat: target.getGeometry().lat,
          lng: target.getGeometry().lng
        };
        // Save the new marker position to change the
        // new direction on waypointList
        const markerInfo = {
          address_components: [
            {
              long_name: hereInfo.houseNumber,
              short_name: hereInfo.houseNumber,
              types: ['street_number']
            },
            {
              long_name: hereInfo.city,
              short_name: hereInfo.city,
              types: ['route']
            },
            {
              long_name: '',
              short_name: '',
              types: ['neighborhood', 'political']
            },
            {
              long_name: hereInfo.district,
              short_name: hereInfo.district,
              types: ['sublocality_level_1', 'sublocality', 'political']
            },
            {
              long_name: hereInfo.state,
              short_name: hereInfo.stateShort,
              types: ['locality', 'political']
            },
            {
              long_name: hereInfo.state,
              short_name: hereInfo.stateShort,
              types: ['administrative_area_level_1', 'political']
            },
            {
              long_name: hereInfo.country,
              short_name: hereInfo.country,
              types: ['country', 'political']
            },
            {
              long_name: hereInfo.postalCode,
              short_name: hereInfo.postalCode,
              types: ['postal_code']
            }
          ],
          address_components_mx: {
            city: hereInfo.city,
            country: hereInfo.country,
            country_code: hereInfo.country,
            state: hereInfo.state,
            street: hereInfo.street,
            street_number: hereInfo.houseNumber,
            sublocality: hereInfo.district,
            zip: hereInfo.postalCode
          },
          arrivalTime: waypoints[id].arrivalTime,
          extra_info: waypoints[id].extra_info,
          formatted_address: hereInfo.label,
          name: `${hereInfo.city}`,
          position: {
            lat: target.getGeometry().lat,
            lon: target.getGeometry().lng
          },
          waitingTime: {
            minutes: waypoints[id].waitingTime.minutes,
            hours: waypoints[id].waitingTime.hours
          }
        };
        console.log(waypointsCopy[id]);
        waypointsCopy[id] = markerInfo;
        setWaypoints(waypointsCopy);
        setLoading(false);
        return;
      }
    });
  }

  // Setting the markers
  await setMarkersInfo();

  // Disable the default draggability of the underlying map
  // And calculate the offset between mouse and target's position
  // When starting to drag a marker object:
  mapObj.current.addEventListener(
    'dragstart',
    function (ev) {
      const target = ev.target;
      const pointer = ev.currentPointer;
      if (target !== mapObj.current) {
        if (target instanceof H.map.Marker) {
          getMarkerPosition = {
            lat: target.getGeometry().lat,
            lng: target.getGeometry().lng
          };
          const targetPosition = mapObj.current.geoToScreen(
            target.getGeometry()
          );
          target['offset'] = new H.math.Point(
            pointer.viewportX - targetPosition.x,
            pointer.viewportY - targetPosition.y
          );

          behavior.current.disable();
        }
      }
    },
    false
  );

  // Re-enable the default draggability of the underlying map
  // When dragging has completed
  mapObj.current.addEventListener(
    'dragend',
    async function async(ev) {
      const target = ev.target;
      if (target !== mapObj.current) {
        if (target instanceof H.map.Marker) {
          updateDataMarker(target);
          behavior.current.enable();
        }
      }
    },
    false
  );

  // Listen to the drag event and move the position of the marker
  // As necessary
  mapObj.current.addEventListener(
    'drag',
    function (ev) {
      const target = ev.target;
      const pointer = ev.currentPointer;
      if (target !== mapObj.current) {
        if (target instanceof H.map.Marker) {
          target.setGeometry(
            mapObj.current.screenToGeo(
              pointer.viewportX - target['offset'].x,
              pointer.viewportY - target['offset'].y
            )
          );
        }
      }
    },
    false
  );
}
export default dragableMarker;
