import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import axiosAuth from '../../../utils/axiosAuth';
import durationToString from '../../../utils/durationToString';
import roundNumber from '../../../utils/roundNumber';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import Icon from '../../common/airbag-icons/Icon';
import Error from '../../common/error/Error';
import HeaderSection from '../../common/header-section/HeaderSection';
import Filters from './filters/Filters';
import './Trips.scss';

function Trips() {
  const lastTripRef = useRef(null);
  const lastTripId = useRef('');
  const lastDriverId = useRef('');
  const keepLooking = useRef(true);
  const loading = useRef(false);
  const [error, setError] = useState(null);
  const [trips, setTrips] = useState(null);
  const [riskProfile, setRiskProfile] = useState('');

  // Fetch trips on first load
  useEffect(() => {
    // Reset variables
    setTrips(null);
    lastTripId.current = '';
    lastDriverId.current = '';

    // Fetch trip
    fetchTrips(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskProfile]);

  useEffect(() => {
    const onScroll = (e) => {
      // If reference to last trip row exists and its in view and
      // fetchTrips keeps returning result
      if (
        trips &&
        lastTripRef &&
        lastTripRef.current &&
        isLastTripInViewport() &&
        !loading.current &&
        keepLooking.current
      ) {
        fetchTrips();
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trips, lastTripRef, loading.current, keepLooking]);

  function fetchTrips(cleanTripsArray) {
    // Turn on loading flag
    loading.current = true;

    /* Build query parameters */
    const queryParameters = {};
    // Pagination
    if (lastTripId.current && lastDriverId.current) {
      queryParameters.lastTripId = lastTripId.current;
      queryParameters.lastDriverId = lastDriverId.current;
    }
    // Risk profile filter
    if (riskProfile) {
      queryParameters.riskProfile = riskProfile;
    }

    // Get trips from server
    axiosAuth
      .get(`/api/trips/all`, {
        params: queryParameters
      })
      .then((res) => {
        const { trips: tripsRes } = res.data;

        // Save last trip id for pagination
        let lastTripIdCopy = null;
        let lastDriverIdCopy = null;
        if (tripsRes.length) {
          lastTripIdCopy = tripsRes[tripsRes.length - 1].id;
          lastDriverIdCopy = tripsRes[tripsRes.length - 1].user;
        }

        // Here you can process array's information
        const processedTrips = tripsRes;

        if (cleanTripsArray) {
          // Reset information
          setTrips(processedTrips);
        } else {
          // Append new trips response to trips' array
          const tripsCopy = trips
            ? [...trips, ...processedTrips]
            : [...processedTrips];
          // Set new trips array
          setTrips(tripsCopy);
        }

        // Stop or resume infinite scrolling | The < 30 is hardcoded
        // and must be changed (results per page)
        if (!tripsRes.length || tripsRes.length < 30) {
          keepLooking.current = false;
          lastTripId.current = '';
          lastDriverId.current = '';
        } else {
          keepLooking.current = true;
          // Save last driver's id and trip id to continue query
          lastTripId.current = lastTripIdCopy;
          lastDriverId.current = lastDriverIdCopy;
        }

        // Stop loader
        loading.current = false;
      })
      .catch((err) => {
        console.log(err);
        loading.current = false;
        setError(err);
      });
  }

  function isLastTripInViewport() {
    // Set an offset to trigger action before target element is in view
    const offset = 100;

    const top = lastTripRef.current.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  }

  function handleScoreColor(number) {
    if (number >= 80) {
      return 'good';
    } else if (number >= 60) {
      return 'regular';
    } else if (number > 0 && number < 60) {
      return 'bad';
    }
    return 'na';
  }

  function renderDateRow(date) {
    const d = dayjs(date);
    const ret = d.format('dddd DD [de] MMMM');
    return ret;
  }

  function renderTripsTable() {
    // Loader
    if (!trips) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No trips means no data
    if (!trips.length) {
      return (
        <div id="no-data">
          <Icon icon="crash" id="error-icon" />
          <p>Sin viajes</p>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="trips-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th className="center-align">
                <div>Calificación</div>
                <div id="trip-scores-exp">
                  <span className="score-item">
                    <FontAwesomeIcon
                      icon={['fal', 'mobile-button']}
                      data-tip="Uso de celular"
                    />
                  </span>
                  <span className="score-item">
                    <FontAwesomeIcon
                      icon={['fal', 'gauge-max']}
                      data-tip="Exceso de velocidad"
                    />
                  </span>
                  <span className="score-item">
                    <FontAwesomeIcon
                      icon={['fal', 'diamond-turn-right']}
                      data-tip="Curvas"
                    />
                  </span>
                  <span className="score-item">
                    <FontAwesomeIcon
                      icon={['fal', 'gauge-simple-max']}
                      data-tip="Aceleración"
                    />
                  </span>
                  <span className="score-item">
                    <FontAwesomeIcon
                      icon={['fal', 'gauge-simple-min']}
                      data-tip="Frenado"
                    />
                  </span>
                </div>
              </th>
              <th>Distancia</th>
              <th>Duración</th>
              <th>Inicio</th>
              <th>Conductor</th>
            </tr>
          </thead>
          <tbody>
            {trips.map((trip, i) => {
              // This is our return object.
              const tripRow = [];

              // Always render date before first trip
              if (!i) {
                tripRow.push(
                  <tr key="first-row" className="date-row">
                    <td>{renderDateRow(trip.startDate)}</td>
                  </tr>
                );
              } else {
                // Get previous trip
                const prevTrip = trips[i - 1];

                // Get dates for validation
                const currentDate = dayjs(trip.startDate).format('YYYY-MM-DD');
                const prevDate = dayjs(prevTrip.startDate).format('YYYY-MM-DD');

                // Add row for change in day
                if (currentDate !== prevDate) {
                  tripRow.push(
                    <tr key={i} className="date-row">
                      <td>{renderDateRow(currentDate)}</td>
                    </tr>
                  );
                }
              }

              // Add trip data
              tripRow.push(
                <tr
                  key={trip.id}
                  className="trip-row"
                  ref={i === trips.length - 1 ? lastTripRef : null}
                >
                  <td className="trip-score">
                    <Link
                      to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                    >
                      <span
                        className={`overall-score score-item ${handleScoreColor(
                          trip.score.overall
                        )}`}
                      >
                        {trip.score.overall}
                      </span>
                    </Link>
                    <span className="scores-icons">
                      <span
                        className={`score-item ${handleScoreColor(
                          trip.score.phoneDistraction
                        )}`}
                      >
                        <FontAwesomeIcon icon={['fal', 'mobile-button']} />
                      </span>
                      <span
                        className={`score-item ${handleScoreColor(
                          trip.score.speeding
                        )}`}
                      >
                        <FontAwesomeIcon icon={['fal', 'gauge-max']} />
                      </span>
                      <span
                        className={`score-item ${handleScoreColor(
                          trip.score.cornering
                        )}`}
                      >
                        <FontAwesomeIcon icon={['fal', 'diamond-turn-right']} />
                      </span>
                      <span
                        className={`score-item ${handleScoreColor(
                          trip.score.acceleration
                        )}`}
                      >
                        <FontAwesomeIcon icon={['fal', 'gauge-simple-max']} />
                      </span>
                      <span
                        className={`score-item ${handleScoreColor(
                          trip.score.braking
                        )}`}
                      >
                        <FontAwesomeIcon icon={['fal', 'gauge-simple-min']} />
                      </span>
                    </span>
                  </td>
                  <td>
                    <Link
                      to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                    >
                      {trip.distance > 10
                        ? `${roundNumber(trip.distance)} km`
                        : `${trip.distance.toFixed(1)} km`}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                    >
                      {durationToString(dayjs.duration(trip.duration, 'm'))}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                    >
                      {dayjs(trip.startDate).format('HH:mm')}
                    </Link>
                  </td>
                  <td>
                    <Link to={`/conductor?driverId=${trip.user}`}>
                      {trip.driverName}
                    </Link>
                  </td>
                </tr>
              );

              // Return array
              return tripRow;
            })}
          </tbody>
        </table>
        <ReactTooltip />
      </div>
    );
  }

  // API error handler
  if (error) return <Error error={error} />;

  return (
    <div id="_trips_">
      <HeaderSection
        title="Viajes realizados"
        // eslint-disable-next-line max-len
        body="Te mostramos los últimos viajes realizados por tus operadores con opción de filtrarlos por nivel de riesgo."
      />
      <Filters riskProfile={riskProfile} setRiskProfile={setRiskProfile} />
      <div className="row no-margin">
        <div className="col s12">{renderTripsTable()}</div>
      </div>
    </div>
  );
}

export default Trips;
