/* eslint max-len: 0 */

import React from 'react';

function Eye(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1px"
      {...props}
    >
      <path d="M29 16C29 16 23.2 24 16 24C8.8 24 3 16 3 16C3 16 8.8 8 16 8C23.2 8 29 16 29 16Z" />
      <path d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z" />
    </svg>
  );
}

export default Eye;
