import React from 'react';

function Velocimeter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path
          className="cls-1"
          d="M61.56,221.91a113.39,113.39,0,1,1,160.35,0"
        />
        <path
          className="cls-1"
          d="M43.52,198.43a112.65,112.65,0,0,1-11.6-28.35"
        />
        <line className="cls-1" x1="141.73" y1="49.61" x2="141.73" y2="28.35" />
        <line className="cls-1" x1="49.61" y1="141.73" x2="28.35" y2="141.73" />
        <line
          className="cls-1"
          x1="233.86"
          y1="141.73"
          x2="255.12"
          y2="141.73"
        />
        <line className="cls-1" x1="77.87" y1="75.34" x2="63.13" y2="60.02" />
        <line className="cls-1" x1="205.6" y1="75.34" x2="220.34" y2="60.02" />
        <line
          className="cls-1"
          x1="183.96"
          y1="95.65"
          x2="142.08"
          y2="140.24"
        />
      </g>
    </svg>
  );
}

export default Velocimeter;
