import React, { useState } from 'react';
import M from 'materialize-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../common/button/Button';
import axiosAuth from '../../../../../utils/axiosAuth';
// eslint-disable-next-line max-len
import SearchMultipleUserInput from '../../../../common/search-multiple-user-input/SearchMultipleUserInput';
// eslint-disable-next-line max-len
import SearchManagerInput from '../../../../common/search-multiple-manager-input/SearchManagerInput';
import './EditUserGroup.scss';

function EditGroup(props) {
  const { companyGroup, fetchGroup, id, setIsOpen } = props;
  const [loading, setLoading] = useState(false);
  const [removedDrivers, setRemovedDrivers] = useState([]);
  const [removedLeaders, setRemovedLeaders] = useState([]);
  const [data, setData] = useState({
    id: id ? id : '',
    name: companyGroup.name ? companyGroup.name : '',
    drivers: companyGroup.drivers ? [...companyGroup.drivers] : [],
    leaders: companyGroup.leaders ? [...companyGroup.leaders] : []
  });

  function editGroup(e) {
    e.preventDefault();
    setLoading(true);
    // Make request
    axiosAuth
      .post('/api/user-groups/edit', {
        ...data,
        removedDrivers,
        removedLeaders
      })
      .then((res) => {
        M.toast({
          html: 'Grupo editado con éxito',
          classes: 'success-toast'
        });
        fetchGroup();
        setLoading(false);
        setIsOpen(false);
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  // Function to add a driver to the selected list
  function addDriver(user) {
    const driversCopy = [...data.drivers];
    let found = false;
    for (let i = 0; i < driversCopy.length; i++) {
      if (user.userId === driversCopy[i].userId) {
        found = true;
      }
    }
    if (!found) {
      // If not found add it to the lists
      driversCopy.push(user);
      setData({ ...data, drivers: driversCopy });
    }
  }

  // Function to delete a driver from the selected list
  function deleteDriver(user) {
    const drivers = [...data.drivers];
    const index = drivers.indexOf(user);
    if (!removedDrivers.includes(user)) {
      const copy = [...removedDrivers];
      copy.push(user);
      setRemovedDrivers([...copy]);
    }
    drivers.splice(index, 1);
    setData({ ...data, drivers: drivers });
  }

  // Function to add a leader to the selected list
  function addLeader(user) {
    const leadersCopy = [...data.leaders];
    let found = false;
    for (let i = 0; i < leadersCopy.length; i++) {
      if (user.userId === leadersCopy[i].userId) {
        found = true;
      }
    }
    if (!found) {
      leadersCopy.push(user);
      setData({ ...data, leaders: leadersCopy });
    }
  }

  // Function to delete a leader from the selected list
  function deleteLeader(user) {
    const leadersCopy = [...data.leaders];
    const index = leadersCopy.indexOf(user);
    if (!removedLeaders.includes(user)) {
      const copy = [...removedLeaders];
      copy.push(user);
      setRemovedLeaders([...copy]);
    }
    leadersCopy.splice(index, 1);
    setData({ ...data, leaders: leadersCopy });
  }

  return (
    <div id="_edit-user-group_">
      <h5 id="main-t">Editar grupo</h5>
      <form onSubmit={editGroup}>
        {/* INFORMACIÓN GENERAL */}
        <div className="row big-label">
          <div className="col s12">
            <p>Información general</p>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m6 input-field">
            <label className="active">Nombre</label>
            <input
              type="text"
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </div>
        </div>
        <div className="row big-label">
          <div className="col s12">
            <p>Líder/es</p>
          </div>
        </div>

        <SearchManagerInput addUser={addLeader} />
        <div className="row" id="users">
          <div className="col s12" id="users-container">
            {data.leaders.map((leader) => {
              return (
                <p id="label-user" key={`${leader.userId}`}>
                  {leader.name} {leader.lastName}
                  <FontAwesomeIcon
                    icon="times"
                    color="#f55442"
                    size="lg"
                    className="delete"
                    onClick={() => deleteLeader(leader)}
                  />
                </p>
              );
            })}
          </div>
        </div>
        <div className="row big-label">
          <div className="col s12">
            <p>Conductor/es</p>
          </div>
        </div>
        <SearchMultipleUserInput addUser={addDriver} />

        <div className="row" id="users">
          <div className="col s12" id="users-container">
            {data.drivers.map((driver) => {
              return (
                <p id="label-user" key={`${driver.userId}`}>
                  {driver.name} {driver.lastName}
                  <FontAwesomeIcon
                    icon="times"
                    color="#f55442"
                    size="lg"
                    className="delete"
                    onClick={() => deleteDriver(driver)}
                  />
                </p>
              );
            })}
          </div>
        </div>

        <div className="center-align">
          <Button
            variant="yellow-button"
            loading={loading}
            disabled={loading}
            type="submit"
          >
            Guardar cambios
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditGroup;
