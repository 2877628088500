/* eslint max-len: 0 */

import React from 'react';

function Traffic(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <path d="M63.09,156.7a15,15,0,1,1,15-15A15,15,0,0,1,63.09,156.7Zm0-19.94a5,5,0,1,0,5,5A5,5,0,0,0,63.09,136.76Z" />
      <path d="M220.38,187.92a15,15,0,1,1,15-15A15,15,0,0,1,220.38,187.92Zm0-19.94a5,5,0,1,0,5,5A5,5,0,0,0,220.38,168Z" />
      <path d="M130.64,186.62a15,15,0,1,1,15-15A15,15,0,0,1,130.64,186.62Zm0-20a5,5,0,1,0,5,5A5,5,0,0,0,130.64,166.67Z" />
      <path d="M239.63,132.12,233.55,95.6a28.26,28.26,0,0,0-28-23.68H167l-1-6.23A28.25,28.25,0,0,0,138,42H77.87A28.24,28.24,0,0,0,49.92,65.69l-6.09,36.52a24.93,24.93,0,0,0-20.64,24.53v49.89a5,5,0,0,0,5,5H43.13v15.23a14.69,14.69,0,0,0,14.68,14.68H68.36A14.69,14.69,0,0,0,83,196.86V181.63h7.7v24.91a5,5,0,0,0,5,5h15v15.24a14.69,14.69,0,0,0,14.68,14.67h10.55a14.69,14.69,0,0,0,14.68-14.67V211.54h49.82v15.24a14.69,14.69,0,0,0,14.68,14.67h10.56a14.69,14.69,0,0,0,14.67-14.67V211.54h14.95a5,5,0,0,0,5-5V156.65A24.94,24.94,0,0,0,239.63,132.12ZM223.68,97.24l5.75,34.51H121.59l5.75-34.51a18.29,18.29,0,0,1,18.09-15.32H205.6A18.28,18.28,0,0,1,223.68,97.24ZM59.78,67.33A18.28,18.28,0,0,1,77.87,52H138a18.28,18.28,0,0,1,18.09,15.32l.76,4.59H145.43A28.24,28.24,0,0,0,117.48,95.6l-1,6.23H54ZM73,196.86a4.69,4.69,0,0,1-4.68,4.68H57.81a4.69,4.69,0,0,1-4.68-4.68V181.63H73Zm17.7-40.21v15H33.19V126.74a14.92,14.92,0,0,1,14.9-14.91h66.68l-3.38,20.29A24.94,24.94,0,0,0,90.74,156.65Zm49.86,70.13a4.68,4.68,0,0,1-4.68,4.67H125.37a4.68,4.68,0,0,1-4.68-4.67V211.54H140.6Zm89.73,0a4.67,4.67,0,0,1-4.67,4.67H215.1a4.68,4.68,0,0,1-4.68-4.67V211.54h19.91Zm19.95-25.24H100.74V156.65a14.92,14.92,0,0,1,14.91-14.9H235.37a14.92,14.92,0,0,1,14.91,14.9Z" />
    </svg>
  );
}

export default Traffic;
