import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import axiosAuth from '../../../utils/axiosAuth';
import roundNumber from '../../../utils/roundNumber';
import Error from '../../common/error/Error';
import MessageCourtain from '../../common/message-courtain/MessageCourtain';
import AirbagLoaderLines from '../../common/airbag-loader/AirbagLoaderLines';
import ActivitiesList from '../../common/activities-list/ActivitiesList';
import TripInfo from './trip-info/TripInfo';
import TripMap from './trip-map/TripMap';
import TripAnalytics from './trip-analytics/TripAnalytics';
import TripWeather from './trip-weather/TripWeather';
import './CompanyTrip.scss';

function CompanyTrip(props) {
  const { company } = props;
  // Get all data after mian url
  const queryString = window.location.search;
  // Get all params
  const urlParams = new URLSearchParams(queryString);
  const user = urlParams.get('userId');
  const tripId = urlParams.get('tripId');
  const [error, setError] = useState(null);
  const [driver, setDriver] = useState({});
  const [trip, setTrip] = useState({});
  const [logs, setLogs] = useState(null);
  const [locationLogs, setLocationLogs] = useState(null);
  const [polylines, setPolylines] = useState(null);
  const [speedComparison, setSpeedComparison] = useState(null);
  const [altimetry, setAtlimetry] = useState(null);
  const [phoneUsageGraph, setPhoneUsageGraph] = useState(null);
  const [speedingGraph, setSpeedingGraph] = useState(null);
  const [activities, setActivities] = useState(null);
  const [focus, setFocus] = useState(null);
  const [latestHeartbeat, setLatestHeartbeat] = useState(null);
  // At first load, we zoom to fit entire route
  const applyBoundingBox = useRef(true);
  const DELETED_LOGS_DATE = '2022-10-03T01:01:05.404Z';

  useEffect(() => {
    setTrip({});
    setDriver({});

    // Get trip and driver data
    axiosAuth
      .get(`/api/trips/${user}/trip/${tripId}`)
      .then((res) => {
        const { trip, driver, latestHeartbeat: latestHeartbeatRes } = res.data;
        setTrip(trip);
        setDriver(driver);
        setLatestHeartbeat(latestHeartbeatRes);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });

    // Get logs and location-logs data
    axiosAuth
      .get(`/api/trips/${user}/trip/${tripId}/data`)
      .then((res) => {
        const {
          canHandlePolylinesColors,
          logs,
          locationLogs,
          polylines,
          phoneUsageGraph,
          speedingGraph,
          activities,
          altimetry
        } = res.data;
        setLogs(logs);
        setLocationLogs(locationLogs);
        setPolylines(polylines);
        setPhoneUsageGraph(phoneUsageGraph);
        setSpeedingGraph(speedingGraph);
        setAtlimetry(altimetry);
        setActivities(activities);

        // Check if trip has complete info for graphs
        if (canHandlePolylinesColors) {
          // Declare object to hold speed comparison data
          const spdComp = {
            speed: [],
            speedLimit: [],
            labels: []
          };

          // Loop through location logs to fill graphs data
          for (let i = 0; i < locationLogs.length; i++) {
            const log = locationLogs[i];

            // Handle speed and speedLimit
            if (log.position.spd && log.position.speedLimit) {
              // Only get half of logs for optimization purposes
              if (i % 2) {
                // When no speed limit is present, it gets value of 1000
                let sLimit = log.position.speedLimit;
                if (sLimit > 200) sLimit = NaN;

                spdComp.speed.push(roundNumber(log.position.spd));
                spdComp.speedLimit.push(sLimit);
                spdComp.labels.push(dayjs(log.created).format('HH:mm'));
              }
            }
          }
          setSpeedComparison(spdComp);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, tripId]);

  // API error handler
  if (error) return <Error error={error} />;

  // Not found
  if (!trip || !driver) {
    return (
      <MessageCourtain
        message="No se encontró el viaje"
        title="¡Oops!"
        link={`/conductor?driverId=${user}`}
      />
    );
  }

  // Info loading
  if (!Object.keys(trip).length && !Object.keys(driver).length) {
    return <AirbagLoaderLines />;
  }

  return (
    <div id="_company-trip_">
      {/* Trip's general info and scoring */}
      <TripInfo
        trip={trip}
        driver={driver}
        latestHeartbeat={latestHeartbeat}
        company={company}
      />

      {/* Trip's weather info */}
      <TripWeather
        startWeather={trip.startWeather}
        endWeather={trip.endWeather}
        trip={trip}
      />

      {/* Map and filters */}
      {dayjs(trip.startDate).isAfter(DELETED_LOGS_DATE) ? (
        <TripMap
          trip={trip}
          logs={logs}
          locationLogs={locationLogs}
          polylines={polylines}
          focus={focus}
          setFocus={setFocus}
          applyBoundingBox={applyBoundingBox}
        />
      ) : null}

      {/* Activities */}
      {activities && (
        <div className="row" id="activities-list-wrapper">
          <div className="col s12">
            <h6>Actividades registradas</h6>
            <ActivitiesList
              activities={activities}
              setFocus={setFocus}
              applyBoundingBox={applyBoundingBox}
              userId={user}
            />
          </div>
        </div>
      )}

      {/* Graphs and analytics */}
      {dayjs(trip.startDate).isAfter(DELETED_LOGS_DATE) ? (
        <TripAnalytics
          logs={logs}
          trip={trip}
          speedComparison={speedComparison}
          altimetry={altimetry}
          phoneUsageGraph={phoneUsageGraph}
          speedingGraph={speedingGraph}
        />
      ) : null}
    </div>
  );
}

export default withRouter(CompanyTrip);
