const activitiesDefinitions = [
  /* NOM-087 */
  {
    name: 'Pausa / Parada',
    slug: 'stop',
    type: 'stop',
    enum: 'STOP',
    icon: 'bus',
    airbagIcon: 'stop',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'parada'
  },
  {
    name: 'Descanso',
    slug: 'break',
    type: 'break',
    enum: 'BREAK',
    icon: 'bed',
    airbagIcon: 'break',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'break'
  },
  /* Aux activities */
  {
    name: 'Gasolina',
    slug: 'gas',
    type: 'aux-activity',
    enum: 'GAS',
    icon: 'gas-pump',
    airbagIcon: 'gas',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'gas'
  },
  {
    name: 'Limpieza o mantenimiento',
    slug: 'maintenance',
    type: 'aux-activity',
    enum: 'MAINTENANCE',
    icon: 'cogs',
    airbagIcon: 'maintenance',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'maintenance'
  },
  {
    name: 'Obligaciones administrativas',
    slug: 'administrative',
    type: 'aux-activity',
    enum: 'ADMINISTRATIVE',
    icon: 'cogs',
    airbagIcon: 'report',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'administrative'
  },
  {
    name: 'Carga de mercancía',
    slug: 'load-handle',
    type: 'aux-activity',
    enum: 'LOAD_HANDLE',
    icon: 'dolly',
    airbagIcon: 'loadHandle',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'load_handle'
  },
  {
    name: 'Descarga de mercancía',
    slug: 'UNLOAD_HANDLE',
    type: 'aux-activity',
    enum: 'UNLOAD_HANDLE',
    icon: 'dolly',
    airbagIcon: 'loadHandle',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'load_handle'
  },
  {
    name: 'Actividad auxiliar',
    slug: 'other-aux',
    type: 'aux-activity',
    enum: 'OTHER_AUX',
    icon: 'cogs',
    airbagIcon: 'auxActivity',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'aux_activity'
  },
  /* Delivery */
  {
    name: 'Entrega',
    slug: 'entrega',
    type: 'entrega',
    enum: 'DELIVERY',
    icon: 'box-open',
    airbagIcon: 'delivery',
    color: '#ff9800',
    colorLight: '#ffeed5',
    markerImg: 'entrega'
  },
  /* Events */
  {
    name: 'Construcción',
    slug: 'construction',
    type: 'report',
    enum: 'CONSTRUCTION',
    icon: 'tools',
    airbagIcon: 'construction',
    color: '#a39be2',
    colorLight: '#f3f2ff',
    markerImg: 'construction'
  },
  {
    name: 'Vialidad cerrada',
    slug: 'closed_road',
    type: 'report',
    enum: 'CLOSED_ROAD',
    icon: 'road',
    airbagIcon: 'closedRoad',
    color: '#a39be2',
    colorLight: '#f3f2ff',
    markerImg: 'closed_road'
  },
  {
    name: 'Tráfico',
    slug: 'traffic',
    type: 'report',
    enum: 'TRAFFIC',
    icon: 'car-alt',
    airbagIcon: 'traffic',
    color: '#a39be2',
    colorLight: '#f3f2ff',
    markerImg: 'traffic'
  },
  {
    name: 'Problema técnico',
    slug: 'tech_problem',
    type: 'report',
    enum: 'TECH_PROBLEM',
    icon: 'cogs',
    airbagIcon: 'techProblem',
    color: '#a39be2',
    colorLight: '#f3f2ff',
    markerImg: 'tech_problem'
  },
  {
    name: 'Accidente Vial',
    slug: 'road_accident',
    type: 'report',
    enum: 'ROAD_ACCIDENT',
    icon: 'crash',
    airbagIcon: 'crash',
    color: '#a39be2',
    colorLight: '#f3f2ff',
    markerImg: 'road_accident'
  },
  {
    name: 'Reporte',
    slug: 'other-report',
    type: 'report',
    enum: 'OTHER_REPORT',
    icon: 'warning',
    airbagIcon: 'auxActivity',
    color: '#a39be2',
    colorLight: '#f3f2ff',
    markerImg: 'report'
  },
  /* Workday */
  {
    name: 'Inicio de jornada laboral',
    slug: 'start_workday',
    type: 'workday',
    enum: 'START_WORKDAY',
    icon: 'signal',
    airbagIcon: 'truck',
    color: '#008e6c',
    colorLight: '#c3eddc',
    markerImg: 'start_workday'
  },
  {
    name: 'Fin de jornada laboral',
    slug: 'end_workday',
    type: 'workday',
    enum: 'END_WORKDAY',
    icon: 'signal',
    airbagIcon: 'truck',
    color: '#c91316',
    colorLight: '#ffdad6',
    markerImg: 'end_workday'
  },
  /* Manual activations / deactivations */
  {
    name: 'Activación manual',
    slug: 'manual_activation',
    type: 'activation',
    enum: 'MANUAL_ACTIVATION',
    icon: 'signal',
    airbagIcon: 'truck',
    color: '#008e6c',
    colorLight: '#c3eddc',
    markerImg: 'start_workday'
  },
  {
    name: 'Desactivación manual',
    slug: 'manual_deactivation',
    type: 'activation',
    enum: 'MANUAL_DEACTIVATION',
    icon: 'signal',
    airbagIcon: 'truck',
    color: '#c91316',
    colorLight: '#ffdad6',
    markerImg: 'end_workday'
  },
  /* Others (this is more like a fallback) */
  {
    name: 'Otro',
    slug: 'other',
    type: 'other',
    enum: 'OTHER',
    icon: 'cogs',
    airbagIcon: 'techProblem',
    color: '#667be2',
    colorLight: '#dbe1ff',
    markerImg: 'tech_problem'
  }
];

export default activitiesDefinitions;
