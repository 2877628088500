import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import numeral from 'numeral';
import axiosAuth from '../../../../utils/axiosAuth';
import roundNumber from '../../../../utils/roundNumber';
import { getColors } from '../../../../utils/colors';
import durationToString from '../../../../utils/durationToString';
import Stat from '../../../common/stat/Stat';
import Error from '../../../common/error/Error';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import ScoresChart from '../../../common/chartjs/ScoresChart';
import DrivingTimes from '../../../common/chartjs/DrivingTimes';
import RiskByLocation from '../../../common/chartjs/RiskByLocation';
import ScoresByLocation from '../../../common/chartjs/ScoresByLocation';
import CoinsChart from '../../../common/chartjs/CoinsChart';
import CoinsCumulativeChart from '../../../common/chartjs/CoinsCumulativeChart';
import cssVars from '../../../css-variables/css-variables-js';
import './DriverMetrics.scss';

const initialMetrics = {
  general: { mileage: 0, tripsCount: 0, avgSpeed: 0, drivingTime: 0 },
  scores: {
    acceleration: 0,
    braking: 0,
    speeding: 0,
    distracted: 0,
    cornering: 0,
    overall: 0
  },
  ecoScores: {
    fuel: 0,
    tyres: 0,
    brakes: 0,
    overall: 0
  }
};

const DAYS_COUNT = 14;

// When no data is present, fill array with no values
function fillNoDataArr(len) {
  const ret = [];
  for (let i = 0; i < len; i++) ret.push(0);
  return ret;
}

function fillNoLabelsArr(len) {
  const ret = [];
  for (let i = len; i > 0; i--) {
    ret.push(dayjs().subtract(i, 'd').format('DD MMM'));
  }
  return ret;
}

function DriverMetrics(props) {
  const { driver, company } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metrics, setMetrics] = useState(initialMetrics);
  const [scoresLabels, setScoresLabels] = useState(null);
  const [scores, setScores] = useState(null);
  const [tripsByRisk, setTripsByRisk] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [tripsCountW, setTripsCountW] = useState(null);
  const [riskByNeighb, setRiskByNeighb] = useState(null);
  const [scoresByNeighb, setScoresByNeighb] = useState(null);
  const [locationLabels, setLocationLabels] = useState(null);
  const [drivingTimes, setDrivingTimes] = useState(null);
  const [coins, setCoins] = useState(null);
  const [coinsLabel, setCoinsLabels] = useState(null);
  const [ranking, setRanking] = useState(null);
  const [zoneFilter, setZoneFilter] = useState('neighborhood');

  useEffect(() => {
    // Get driver's metric info
    fetchDriverMetrics();

    // Get charts info
    axiosAuth
      .get(`/api/charts/driver/${driver.id}/scores`)
      .then((res) => {
        const { driverScores, labels } = res.data;

        // Set labels
        if (labels && labels.length) {
          setScoresLabels(labels.map((day) => dayjs(day).format('DD MMM')));
        } else {
          setScoresLabels(fillNoLabelsArr(DAYS_COUNT));
        }

        // Set scores
        const { overall } = driverScores;
        if (overall && overall.data && overall.data.length) {
          setScores(overall.data.map((score) => roundNumber(score)));
        } else {
          setScores(fillNoDataArr(DAYS_COUNT));
        }

        // Distance and duration
        const { distance, duration } = driverScores;
        if (distance && distance.data && distance.data.length) {
          setDistance(distance.data.map((num) => roundNumber(num)));
        } else {
          setDistance(fillNoDataArr(DAYS_COUNT));
        }
        if (duration && duration.data && duration.data.length) {
          setDuration(duration.data.map((num) => roundNumber(num)));
        } else {
          setDuration(fillNoDataArr(DAYS_COUNT));
        }

        // Trips by risk label
        const { riskProfileLow, riskProfileMid, riskProfileHigh } =
          driverScores;
        if (riskProfileLow && riskProfileMid && riskProfileHigh) {
          setTripsByRisk({
            low: riskProfileLow.data,
            mid: riskProfileMid.data,
            high: riskProfileHigh.data
          });
        } else {
          setTripsByRisk({
            low: fillNoDataArr(DAYS_COUNT),
            mid: fillNoDataArr(DAYS_COUNT),
            high: fillNoDataArr(DAYS_COUNT)
          });
        }

        // Trips count
        const { trips } = driverScores;
        if (trips && trips.data && trips.data.length) {
          setTripsCountW(trips.data);
        } else {
          setTripsCountW(fillNoDataArr(DAYS_COUNT));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // Get driving times info
    axiosAuth
      .get(`/api/charts/driver/${driver.id}/driving-times`)
      .then((res) => {
        const { driverDrivingTimes: drivingTimes } = res.data;
        // Sum values in array for day and night
        const sumDay = drivingTimes.day.reduce(
          (accumulator, a) => accumulator + a,
          0
        );
        const sumNight = drivingTimes.night.reduce(
          (accumulator, a) => accumulator + a,
          0
        );
        if (sumDay && sumNight) {
          setDrivingTimes([sumDay, sumNight]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // Get coins info
    axiosAuth
      .get(`/api/charts/driver/${driver.id}/coins`)
      .then((res) => {
        const { driverCoins, labels } = res.data;
        // Set labels
        if (labels && labels.length) {
          setCoinsLabels(labels.map((day) => dayjs(day).format('DD MMM')));
        } else {
          setCoinsLabels(fillNoLabelsArr(DAYS_COUNT));
        }

        const newCoins = {
          daily: null,
          cumulative: null
        };

        // Set first graph coins
        const { daily } = driverCoins;
        if (daily && daily.length) {
          newCoins['daily'] = daily;
        } else {
          newCoins['daily'] = fillNoDataArr(DAYS_COUNT);
        }

        // Set second graph coins
        const { cumulative } = driverCoins;
        if (cumulative && cumulative.length) {
          newCoins['cumulative'] = cumulative;
        } else {
          newCoins['cumulative'] = fillNoDataArr(DAYS_COUNT);
        }

        setCoins(newCoins);
      })
      .catch((err) => {
        console.log(err);
      });

    // Get frauds info
    // axiosAuth
    //   .get(`/api/charts/driver/${driver.id}/frauds`)
    //   .then((res) => {
    //     console.log(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch location metrics for graph
  useEffect(() => {
    fetchLocationMetrics();
  }, [zoneFilter]);

  function fetchLocationMetrics() {
    setRiskByNeighb(null);
    setScoresByNeighb(null);
    setLocationLabels(null);

    axiosAuth
      .get(`/api/charts/driver/${driver.id}/locations`, {
        params: { zoneFilter }
      })
      .then((res) => {
        const { driverLocations, labels } = res.data;
        // Only 10 states for now
        const locationArr = driverLocations.slice(0, 10);

        // Set risk by state
        setRiskByNeighb({
          riskHigh: locationArr.map((loc) => loc.riskProfileHigh),
          riskMid: locationArr.map((loc) => loc.riskProfileMid),
          riskLow: locationArr.map((loc) => loc.riskProfileLow)
        });
        // Set scores by state
        setScoresByNeighb({
          overall: locationArr.map((loc) => roundNumber(loc.overall)),
          speeding: locationArr.map((loc) => roundNumber(loc.speeding)),
          acc: locationArr.map((loc) => roundNumber(loc.acceleration)),
          braking: locationArr.map((loc) => roundNumber(loc.braking)),
          cornering: locationArr.map((loc) => roundNumber(loc.cornering)),
          phoneDistraction: locationArr.map((loc) =>
            roundNumber(loc.phonedistraction)
          )
        });
        // Set states labels
        setLocationLabels(labels.slice(0, 10));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function fetchDriverMetrics() {
    axiosAuth
      .get(`/api/users/${driver.id}/metrics`)
      .then((res) => {
        const { scores, ecoScores, general, ranking } = res.data;

        const data = {};
        if (scores && Object.keys(scores).length) data.scores = scores;
        if (ecoScores && Object.keys(ecoScores).length) {
          data.ecoScores = ecoScores;
        }
        if (general && Object.keys(general).length) data.general = general;

        setMetrics({ ...metrics, ...data });
        setRanking(ranking);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }

  function handleScoreColor(number, returnHex) {
    if (number >= 80) {
      if (returnHex) return cssVars['graph-successful'];
      return 'green';
    } else if (number >= 60) {
      if (returnHex) return cssVars['graph-attention'];
      return 'yellow';
    } else if (number > 0 && number < 60) {
      if (returnHex) return cssVars['graph-error'];
      return 'red';
    } else {
      if (returnHex) return '#b5b5b5';
      return 'na';
    }
  }

  function getMaxValueTripRisks(obj) {
    const { low, mid, high } = obj;
    const maxAll = [];
    // Get max value for each risk level
    maxAll.push(low.length ? Math.max(...low) : 100);
    maxAll.push(mid.length ? Math.max(...mid) : 100);
    maxAll.push(high.length ? Math.max(...high) : 100);
    // Get max value of max values
    const maxValue = Math.max(...maxAll);

    return maxValue;
  }

  function getAverage(arr, rounded) {
    // Handle no valid array
    if (!arr || !arr.length) return 0;

    // Remove 0 from array
    const newArr = [];
    arr.forEach((item) => {
      if (item) newArr.push(item);
    });

    // Nothing added means no data
    if (!newArr.length) return 0;

    // Get average
    let average = newArr.reduce((a, b) => a + b, 0) / newArr.length;

    // Round Number
    if (rounded) {
      average = roundNumber(average);
    }

    return average;
  }

  // API error handler
  if (error) return <Error error={error} />;

  function renderZoneFilters() {
    if (company.country === 'mex') {
      return (
        <select
          id="zoneFilter"
          className="browser-default"
          value={zoneFilter}
          onChange={(e) => setZoneFilter(e.target.value)}
        >
          <option value="street">Calle</option>
          <option value="neighborhood">Municipio</option>
          <option value="city">Ciudad</option>
          <option value="state">Estado</option>
          <option value="country">País</option>
        </select>
      );
    } else if (company.country === 'col') {
      return (
        <select
          id="zoneFilter"
          className="browser-default"
          value={zoneFilter}
          onChange={(e) => setZoneFilter(e.target.value)}
        >
          <option value="street">Calle</option>
          <option value="neighborhood">Zona</option>
          <option value="city">Ciudad</option>
          <option value="county">Departamento</option>
          <option value="country">País</option>
        </select>
      );
    }
  }

  function renderMetrics() {
    // Loader
    if (loading) return <AirbagLoader />;

    // Get score values
    const { acceleration, braking, speeding, distracted, cornering, overall } =
      metrics.scores;
    // Get eco score values
    const { fuel, tyres, brakes, overall: ecoOverall } = metrics.ecoScores;
    // Get general info values
    const { mileage, tripsCount, avgSpeed, drivingTime } = metrics.general;

    return (
      <>
        {/* METRICS */}
        <div className="row metrics-table">
          <div className="col s12">
            <p className="table-title">Información general</p>
          </div>
          <div className="col s6 l4 wrapp">
            <p className="stat-title less-margin">Ranking</p>
            <p className="stat-value">
              {ranking.value ? `# ${ranking.place}` : '-'}
            </p>
          </div>
          <div className="col s6 l4 wrapp">
            <p className="stat-title less-margin">Viajes realizados</p>
            <p className="stat-value">{tripsCount}</p>
          </div>
          <div className="col s6 l4 wrapp">
            <p className="stat-title less-margin">Distancia recorrida</p>
            <p className="stat-value">
              {numeral(roundNumber(mileage)).format('0,0')} km
            </p>
          </div>
          <div className="col s6 l4 wrapp">
            <p className="stat-title less-margin">Tiempo manejando</p>
            <p className="stat-value">
              {durationToString(dayjs.duration(drivingTime, 'm'), true)}
            </p>
          </div>
          <div className="col s6 l4 wrapp">
            <p className="stat-title less-margin">Velocidad promedio</p>
            <p className="stat-value">{roundNumber(avgSpeed)} km/h</p>
          </div>
        </div>

        {/* SCORING */}
        <div className="row metrics-table">
          <div className="col s12">
            <p className="table-title">Hábitos de conducción</p>
          </div>
          <div className="col s12 l2 wrapp">
            <Stat
              value={overall ? overall : 0}
              color={handleScoreColor(overall, false)}
            />
          </div>
          <div className="col s6 m4 l2 wrapp">
            <p className="stat-title">Aceleración</p>
            <p
              className={`stat-value st-${handleScoreColor(
                acceleration,
                false
              )}`}
            >
              {acceleration ? acceleration : '-'} <span>/ 100</span>
            </p>
          </div>
          <div className="col s6 m4 l2 wrapp">
            <p className="stat-title">Frenado</p>
            <p className={`stat-value st-${handleScoreColor(braking, false)}`}>
              {braking ? braking : '-'} <span>/ 100</span>
            </p>
          </div>
          <div className="col s6 m4 l2 wrapp">
            <p className="stat-title">Curvas</p>
            <p
              className={`stat-value st-${handleScoreColor(cornering, false)}`}
            >
              {cornering ? cornering : '-'} <span>/ 100</span>
            </p>
          </div>
          <div className="col s6 m6 l2 wrapp">
            <p className="stat-title">Uso de celular</p>
            <p
              className={`stat-value st-${handleScoreColor(distracted, false)}`}
            >
              {distracted ? distracted : '-'} <span>/ 100</span>
            </p>
          </div>
          <div className="col s12 m6 l2 wrapp">
            <p className="stat-title">Exceso velocidad</p>
            <p className={`stat-value st-${handleScoreColor(speeding, false)}`}>
              {speeding ? speeding : '-'} <span>/ 100</span>
            </p>
          </div>
        </div>

        {/* ECO SCORING */}
        <div className="row metrics-table">
          <div className="col s12">
            <p className="table-title">Conducción ecológica</p>
          </div>
          <div className="col s12 l2 wrapp">
            <Stat
              value={ecoOverall ? ecoOverall : 0}
              color={handleScoreColor(ecoOverall, false)}
            />
          </div>
          <div className="col s6 l2 wrapp">
            <p className="stat-title">Combustible</p>
            <p className={`stat-value st-${handleScoreColor(fuel, false)}`}>
              {fuel ? fuel : '-'} <span>/ 100</span>
            </p>
          </div>
          <div className="col s6 l2 wrapp">
            <p className="stat-title">Llantas</p>
            <p className={`stat-value st-${handleScoreColor(tyres, false)}`}>
              {tyres ? tyres : '-'} <span>/ 100</span>
            </p>
          </div>
          <div className="col s6 l2 wrapp">
            <p className="stat-title">Frenos</p>
            <p className={`stat-value st-${handleScoreColor(brakes, false)}`}>
              {brakes ? brakes : '-'} <span>/ 100</span>
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    <div id="_driver-metrics_">
      {renderMetrics()}

      {/* GRAPHS */}
      <div className="row">
        <div className="col s12 l6">
          <div className="graph-box scores-data">
            {scores && scoresLabels ? (
              <ScoresChart
                title={`Calificación promedio`}
                labels={scoresLabels}
                data={[{ data: scores, label: '' }]}
                colors={[handleScoreColor(getAverage(scores, true), true)]}
                displayLegend={false}
                max={100}
                showSingleValueInTooltip={true}
              />
            ) : (
              <AirbagLoader />
            )}
          </div>
        </div>
        <div className="col s12 l6">
          <div className="graph-box scores-data">
            {tripsByRisk && scoresLabels ? (
              <ScoresChart
                title={`Número de viajes por nivel de riesgo`}
                labels={scoresLabels}
                data={[
                  { data: tripsByRisk.high, label: 'Riesgo Alto' },
                  { data: tripsByRisk.mid, label: 'Riesgo Medio' },
                  { data: tripsByRisk.low, label: 'Riesgo Bajo' }
                ]}
                colors={[
                  cssVars['graph-error'],
                  cssVars['graph-attention'],
                  cssVars['graph-successful']
                ]}
                displayLegend={true}
                max={getMaxValueTripRisks(tripsByRisk) + 1}
                showSingleValueInTooltip={false}
              />
            ) : (
              <AirbagLoader />
            )}
          </div>
        </div>
        <div className="col s12 l6">
          <div className="graph-box scores-data">
            {distance && scoresLabels ? (
              <ScoresChart
                title={`Kilómetros recorridos`}
                labels={scoresLabels}
                data={[{ data: distance, label: '' }]}
                colors={[getColors(2)[1]]}
                displayLegend={false}
                showSingleValueInTooltip={true}
                max={Math.max(...distance) + 10}
              />
            ) : (
              <AirbagLoader />
            )}
          </div>
        </div>
        <div className="col s12 l6">
          <div className="graph-box scores-data">
            {tripsCountW && scoresLabels ? (
              <ScoresChart
                title={`Número de viajes realizados`}
                labels={scoresLabels}
                data={[{ data: tripsCountW, label: '' }]}
                colors={[getColors(5)[4]]}
                displayLegend={false}
                max={Math.max(...tripsCountW) + 1}
                showSingleValueInTooltip={true}
              />
            ) : (
              <AirbagLoader />
            )}
          </div>
        </div>
        <div className="col s12 l6">
          <div className="graph-box scores-data">
            {duration && scoresLabels ? (
              <ScoresChart
                title={`Minutos manejando`}
                labels={scoresLabels}
                data={[{ data: duration, label: '' }]}
                colors={[getColors(3)[2]]}
                displayLegend={false}
                showSingleValueInTooltip={true}
                max={Math.max(...duration) + 1}
              />
            ) : (
              <AirbagLoader />
            )}
          </div>
        </div>
        {drivingTimes ? (
          <div className="col s12 l6">
            <div className="graph-box" id="driving-times">
              <DrivingTimes
                title={`Tiempo de manejo últ. 14 días`}
                labels={['Día', 'Noche']}
                data={drivingTimes}
                colors={[getColors(2)[1], getColors(2)[0]]}
              />
            </div>
          </div>
        ) : null}
        <div className="col s12 l8">
          <div className="graph-box coins-graphs">
            {coins && coins.daily && coinsLabel ? (
              <CoinsChart
                title={`Puntos otorgados`}
                labels={[...coinsLabel]}
                data={[{ data: coins.daily }]}
                colors={[getColors(1)[0]]}
                displayLegend={false}
                max={Math.max(...coins.daily) + 5}
              />
            ) : (
              <AirbagLoader />
            )}
          </div>
        </div>
        <div className="col s12 l4">
          <div className="graph-box coins-graphs">
            {coins && coins.cumulative && coinsLabel ? (
              <CoinsCumulativeChart
                title={`Puntos acumulados`}
                labels={[...coinsLabel]}
                data={[{ data: [...coins.cumulative], label: '' }]}
                colors={[getColors(12)[11]]}
                displayLegend={false}
                max={Math.max(...coins.cumulative) + 5}
              />
            ) : (
              <AirbagLoader />
            )}
          </div>
        </div>
        <div className="col s12" id="location-graphs">
          <div id="filters">
            <label className="active" htmlFor="zoneFilter">
              Ubicación
            </label>
            {renderZoneFilters()}
          </div>
          <div className="graph-box risk-by-location">
            {riskByNeighb && locationLabels ? (
              <RiskByLocation
                title={`Viajes realizados por ubicación por nivel de riesgo`}
                labels={locationLabels}
                data={[
                  { data: riskByNeighb.riskLow, label: 'Riesgo bajo' },
                  { data: riskByNeighb.riskMid, label: 'Riesgo medio' },
                  { data: riskByNeighb.riskHigh, label: 'Riesgo alto' }
                ]}
                colors={['#c3eddc', '#fef1d0', '#ffdad6']}
                displayLegend={true}
              />
            ) : (
              <AirbagLoader />
            )}
          </div>
          <div className="graph-box risk-by-location">
            {scoresByNeighb && locationLabels ? (
              <ScoresByLocation
                title={`Calificación por hábito por ubicación`}
                labels={locationLabels}
                data={[
                  { data: scoresByNeighb.overall, label: 'General' },
                  {
                    data: scoresByNeighb.speeding,
                    label: 'Exceso de velocidad'
                  },
                  { data: scoresByNeighb.acc, label: 'Aceleración' },
                  { data: scoresByNeighb.braking, label: 'Frenado' },
                  { data: scoresByNeighb.cornering, label: 'Curvas' },
                  {
                    data: scoresByNeighb.phoneDistraction,
                    label: 'Uso de celular'
                  }
                ]}
                colors={getColors(6)}
                displayLegend={true}
              />
            ) : (
              <AirbagLoader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverMetrics;
