// Build actions array by merging start/end arrays
export function buildScheduleforFrontEnd(rawSchedule) {
  // Schedule is an array where index represents the day
  // (0 -> Monday, 1 -> Tuesday..)
  const schedule = [null, null, null, null, null, null, null];

  Object.keys(rawSchedule).forEach((key, idx) => {
    // Exclude not related keys
    if (parseInt(key) >= 0 && parseInt(key) <= 6) {
      // Key is the day index now
      const day = key;

      // Merge both activations and deactivations in single array
      const actions = [];
      rawSchedule[day].start.forEach((hour) =>
        actions.push({ type: 'activation', hour })
      );
      rawSchedule[day].end.forEach((hour) =>
        actions.push({ type: 'deactivation', hour })
      );

      // Sort merged actions by ascending order (00:00 to 23:59)
      actions.sort(function compare(a, b) {
        if (a.hour < b.hour) return -1;
        if (a.hour > b.hour) return 1;
        return 0;
      });

      // Save processed data in corresponding index
      schedule[day] = { active: rawSchedule[day].active, actions };
    }
  });

  return schedule;
}
