/* eslint max-len: 0 */

import React from 'react';

function Stop(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <path d="M188.7,260.12H94.77a5,5,0,0,1-3.54-1.47L24.81,192.23a5,5,0,0,1-1.46-3.53V94.77a5,5,0,0,1,1.46-3.54L91.23,24.81a5,5,0,0,1,3.54-1.46H188.7a5,5,0,0,1,3.53,1.46l66.42,66.42a5,5,0,0,1,1.47,3.54V188.7a5,5,0,0,1-1.47,3.53l-66.42,66.42A5,5,0,0,1,188.7,260.12Zm-91.86-10h89.79l63.49-63.49V96.84L186.63,33.35H96.84L33.35,96.84v89.79Z" />
    </svg>
  );
}

export default Stop;
