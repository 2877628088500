import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const formatPrice = (price) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price);
};

export const GeneralBillingInfo = ({
  billing,
  billingTotal,
  deletedBilling,
  deletedBillingTotal,
  prorated,
  proratedTotal,
  nonBillable,
  total,
  prizes
}) => {
  return (
    <div className="general-billing-container">
      <div className="general-billing-item kpi-container">
        <p className="kpi"></p>
        <span className="kpi">Precio unitario</span>
        <span className="kpi">Cantidad</span>
        <span className="kpi">Total</span>
      </div>
      <div className="general-billing-item kpi-container">
        <p className="kpi">
          <FontAwesomeIcon
            icon={['fal', 'money-check-dollar']}
            className="kpi-icon"
          />
          <span>Usuarios cobrables completos</span>
        </p>
        <span>${formatPrice(200)}</span>
        <span>{billing}</span>
        <span>${formatPrice(billingTotal)}</span>
      </div>
      <div className="general-billing-item kpi-container">
        <p className="kpi">
          <FontAwesomeIcon icon={['fal', 'user-tag']} className="kpi-icon" />
          <span>Usuarios con prorrateo</span>
        </p>
        <span>-</span>
        <span>{prorated}</span>
        <span>${formatPrice(proratedTotal.toFixed(2))}</span>
      </div>
      <div className="general-billing-item kpi-container">
        <p className="kpi">
          <FontAwesomeIcon
            icon={['fal', 'user-large-slash']}
            className="kpi-icon"
          />
          <span>Usuarios eliminados cobrables</span>
        </p>
        <span>${formatPrice(200)}</span>
        <span>{deletedBilling}</span>
        <span>${formatPrice(deletedBillingTotal)}</span>
      </div>
      <div className="general-billing-item kpi-container">
        <p className="kpi">
          <FontAwesomeIcon icon={['fal', 'user-xmark']} className="kpi-icon" />
          <span>Usuarios no cobrables</span>
        </p>
        <span>${formatPrice(0)}</span>
        <span>{nonBillable}</span>
        <span>${formatPrice(0)}</span>
      </div>
      <div className="general-billing-item kpi-container">
        <p className="kpi">
          <FontAwesomeIcon icon={['fal', 'coins']} className="kpi-icon" />
          <span>Puntos Airbag</span>
        </p>
        <span>${formatPrice(1)}</span>
        <span>{prizes}</span>
        <span>${formatPrice(prizes)}</span>
      </div>

      <div className="general-billing-item kpi-container">
        <p className="kpi"></p>
        <span></span>
        <span></span>
        <span>Total ${formatPrice(total + prizes)}</span>
      </div>
    </div>
  );
};
