import React from 'react';
import './DriverGame.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';

export const StreakDay = ({ streak, date, dayCharacter }) => {
  const status = () => {
    if (streak === 'IN_STREAK_INACTIVE') {
      return 'inactive';
    } else if (streak === 'IN_STREAK_ACTIVE') {
      return 'active';
    }
    return 'default';
  };

  return (
    <li>
      <div className="day">
        <h4>{dayCharacter}</h4>
        <div
          className={`streak-day ${status()}`}
          data-tip={dayjs(date).add(6, 'hour').format('DD/MM/YY')}
        >
          <FontAwesomeIcon
            icon={streak === 'OUT_OF_STREAK' ? `times` : `check`}
          />

          <ReactTooltip place="bottom" type="dark" effect="solid" />
        </div>
      </div>
    </li>
  );
};
