import { combineReducers } from 'redux';
import user from './user';
import company from './company';
import companyGroup from './companyGroup';
import skin from './skin';

const rootReducer = combineReducers({
  user,
  company,
  companyGroup,
  skin
});

export default rootReducer;
