import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NotAuthorized.scss';

class NotAuthorized extends React.Component {
  render() {
    return (
      <div id="not-found" className="valign-wrapper">
        <div id="not-found-content">
          <FontAwesomeIcon icon="car-crash" />
          <h4>No estás autorizado para ver esto</h4>
          <Link to="/">
            <button className="button yellow-button" href="/">
              Ir a la pagina principal
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default NotAuthorized;
