import React, { useState } from 'react';
import UsageReports from './usage-reports/UsageReports';
import ManualVideo from '../../common/manual-video/ManualVideo';
import HeaderSection from '../../common/header-section/HeaderSection';

function CompanyReports(props) {
  const [playVideo, setPlayVideo] = useState(false);
  const videoUrl =
    'https://www.youtube.com/embed/Ry7Oc3ueuAg?list=PLPbFMY-3HtQvwCS0QjdMy56MiOY3Q9wJQ';

  return (
    <div id="_company-reports_">
      <HeaderSection
        title="Reportes de uso"
        // eslint-disable-next-line max-len
        body="Solicita o descarga un reporte de la información de tu flota en un rango de fechas."
        videoUrl={videoUrl}
      />
      {playVideo && (
        <ManualVideo
          videoUrl={videoUrl}
          playVideo={playVideo}
          setPlayVideo={setPlayVideo}
        />
      )}
      <UsageReports props={props} />
    </div>
  );
}

export default CompanyReports;
