import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import Icon from '../../../common/airbag-icons/Icon';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import TripsByRiskBar from '../../../common/chartjs/TripsByRiskBar';
import roundNumber from '../../../../utils/roundNumber';
import cssVars from '../../../css-variables/css-variables-js';
import './Indicators.scss';

function Indicators(props) {
  const { score, activeDrivers, distance, givenCoins, tripsByRisk } = props;

  function handleScoreColor(number, returnHex) {
    if (number >= 80) {
      if (returnHex) return '#c3eddc';
      return 'good';
    } else if (number >= 60) {
      if (returnHex) return '#fef1d0';
      return 'regular';
    } else if (number > 0 && number < 60) {
      if (returnHex) return '#ffdad6';
      return 'bad';
    } else {
      if (returnHex) return '#b5b5b5';
      return '';
    }
  }

  // Loading
  if (
    score === null ||
    activeDrivers === null ||
    distance === null ||
    givenCoins === null ||
    tripsByRisk === null
  ) {
    return <AirbagLoader />;
  }

  return (
    <div id="_home-indicators_" className="row">
      <div className="col s12 m12 l8">
        <div className="row no-margin no-margin-fix">
          <div className="col s6 m4 l4">
            <div className="kpi">
              <p className={`value ${handleScoreColor(score)}`}>
                <Icon icon="ranking" className="kpi-icon custom" />
                <CountUp end={score} />
              </p>
              <label>Calificación general</label>
            </div>
          </div>
          <div className="col s6 m4 l4">
            <div className="kpi">
              <p className="value">
                <Icon icon="profile" className="kpi-icon custom" />
                <CountUp end={activeDrivers} />
              </p>
              <label>Conductores activos</label>
            </div>
          </div>
          <div className="col s6 m4 l4">
            <div className="kpi">
              <p className="value">
                <Icon icon="routes" className="kpi-icon custom" />
                <CountUp end={roundNumber(distance)} /> km
              </p>
              <label>Distancia recorrida</label>
            </div>
          </div>
          <div className="col s6 m4 l4">
            <div className="kpi">
              <p className="value">
                <FontAwesomeIcon icon={['fal', 'coins']} className="kpi-icon" />
                <CountUp end={roundNumber(givenCoins)} />
              </p>
              <label>Puntos otorgados</label>
            </div>
          </div>
          <div className="col s6 m4 l4">
            <div className="kpi">
              <p className={`value ${handleScoreColor(50)}`}>
                <Icon icon="alert" className="kpi-icon custom" />
                <CountUp end={tripsByRisk.high} />
              </p>
              <label>Viajes en riesgo alto</label>
            </div>
          </div>
          <div className="col s6 m4 l4">
            <div className="kpi">
              <p className={`value ${handleScoreColor(70)}`}>
                <Icon icon="alert" className="kpi-icon custom" />
                <CountUp end={tripsByRisk.mid} />
              </p>
              <label>Viajes en riesgo medio</label>
            </div>
          </div>
        </div>
      </div>
      <div className="col s12 m12 l4">
        <div className="graph-box" id="trips-chart">
          <div className="graph-top">
            <span>Viajes por riesgo</span>
          </div>
          {tripsByRisk ? (
            tripsByRisk.high || tripsByRisk.mid || tripsByRisk.low ? (
              <div id="trips-chart-container">
                <TripsByRiskBar
                  data={[tripsByRisk.high, tripsByRisk.mid, tripsByRisk.low]}
                  labels={['Alto', 'Medio', 'Bajo']}
                  colors={[
                    cssVars['graph-error'],
                    cssVars['graph-attention'],
                    cssVars['graph-successful']
                  ]}
                  displayLegend={true}
                />
              </div>
            ) : (
              <div className="graph-message">
                <Icon icon="routes" className="graph-icon-indicator" />
                <p>Sin viajes registrados</p>
              </div>
            )
          ) : (
            <AirbagLoader />
          )}
        </div>
      </div>
    </div>
  );
}

export default Indicators;
