/* eslint max-len: 0 */

import React from 'react';

function Phone(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <path d="M181.37,260.12H102.09A29.18,29.18,0,0,1,73,231V52.49a29.18,29.18,0,0,1,29.14-29.14h79.28a29.17,29.17,0,0,1,29.14,29.14V231A29.18,29.18,0,0,1,181.37,260.12ZM102.09,33.35A19.16,19.16,0,0,0,83,52.49V231a19.17,19.17,0,0,0,19.14,19.14h79.28A19.17,19.17,0,0,0,200.51,231V52.49a19.16,19.16,0,0,0-19.14-19.14Z" />
      <circle cx="141.73" cy="219.69" r="7.09" />
    </svg>
  );
}

export default Phone;
