import React, { useMemo } from 'react';
import { StreakDay } from './StreakDay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const weekDays = [
  { dayCharacter: 'L', dayOfWeek: 'monday' },
  { dayCharacter: 'M', dayOfWeek: 'tuesday' },
  { dayCharacter: 'X', dayOfWeek: 'wednesday' },
  { dayCharacter: 'J', dayOfWeek: 'thursday' },
  { dayCharacter: 'V', dayOfWeek: 'friday' },
  { dayCharacter: 'S', dayOfWeek: 'saturday' },
  { dayCharacter: 'D', dayOfWeek: 'sunday' }
];

export const StreakWeek = ({
  streak,
  totalDaysOfStreak,
  maxHistoricalStreak
}) => {
  let currentStreak;
  // reverse the array
  const orderedStreak = [...streak].reverse();

  // checks the current day status based on the last two days
  if (
    streak[0].streak === 'IN_STREAK_INACTIVE' &&
    streak[1].streak === 'IN_STREAK_INACTIVE'
  ) {
    currentStreak = 'OUT_OF_STREAK';
  } else if (
    streak[0].streak === 'IN_STREAK_INACTIVE' &&
    streak[1].streak === 'IN_STREAK_ACTIVE'
  ) {
    currentStreak = 'IN_STREAK_INACTIVE';
  } else if (streak[0].streak === 'IN_STREAK_ACTIVE') {
    currentStreak = 'IN_STREAK_INACTIVE';
  } else currentStreak = 'OUT_OF_STREAK';

  const isOnStreak =
    currentStreak === 'IN_STREAK_INACTIVE' ||
    currentStreak === 'IN_STREAK_ACTIVE';

  // finds the previous day index in the 'weekDays' array
  const prevDayIdx = weekDays.findIndex(
    (day) => orderedStreak[streak.length - 1].dayCharacter === day.dayCharacter
  );

  // uses the previous index to get the data of 'today'
  // if the index is the last one of 'weekDays', it sets today's index to 0
  const todayData =
    prevDayIdx < weekDays.length - 1 ? weekDays[prevDayIdx + 1] : weekDays[0];

  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);

  // creates a new element with the 'today' information to add at the end
  // of the 'orderedStreak' array in order to complete the week
  const today = {
    date: todayDate.toISOString(),
    dayCharacter: todayData.dayCharacter,
    dayOfWeek: todayData.dayOfWeek,
    streak: currentStreak
  };
  orderedStreak.push(today);

  // use memoization to avoid unnecessary re-renders
  const days = useMemo(() => {
    return orderedStreak.map((day, idx) => {
      return <StreakDay {...day} key={idx} />;
    });
  }, [orderedStreak]);

  return (
    <div className="streak-container">
      <div className="streak-legend">
        <FontAwesomeIcon
          icon={['fal', 'fire-flame-curved']}
          className={`streak-icon ${
            currentStreak === 'OUT_OF_STREAK' ? 'off' : 'on'
          }`}
        />
        <p>{isOnStreak ? '' : 'NO'} EN RACHA</p>
        {totalDaysOfStreak >= 1 && isOnStreak && (
          <p>
            <span>{totalDaysOfStreak}</span> día
            {totalDaysOfStreak > 1 ? 's ' : ' '}
            de racha
          </p>
        )}
        <p>
          Racha máxima: {maxHistoricalStreak} día
          {maxHistoricalStreak > 1 || maxHistoricalStreak === 0 ? 's ' : ''}
        </p>
      </div>
      <div className="streak-week-container">
        <p>Últimos 7 días</p>
        <ul className="streak-week">{days}</ul>
      </div>
    </div>
  );
};
