import React, { useState, useEffect } from 'react';
import axiosAuth from '../../../../utils/axiosAuth';
import { getColors } from '../../../../utils/colors';
import roundNumber from '../../../../utils/roundNumber';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import DrivingTimes from '../../../common/chartjs/DrivingTimes';
import './DrivingTimesChart.scss';

function DrivingTimesChart(props) {
  const { user, group } = props;
  const [drivingTimes, setDrivingTimes] = useState(null);

  useEffect(() => {
    // Build query params
    const queryParams = {
      days: 30 // Default last 30 days
    };
    // Add groupId if manager is group-limited
    if (user.authRole === 'group-limited') {
      queryParams.groupId = group;
    }

    // Get driving times info
    axiosAuth
      .get(`/api/charts/company/driving-times`, { params: queryParams })
      .then((res) => {
        const { drivingTimes: drivingTimesRes } = res.data;

        // Sum values in array for day and night
        const sumDay = drivingTimesRes.day.reduce(
          (accumulator, a) => accumulator + a,
          0
        );
        const sumNight = drivingTimesRes.night.reduce(
          (accumulator, a) => accumulator + a,
          0
        );
        setDrivingTimes([roundNumber(sumDay), roundNumber(sumNight)]);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  if (!drivingTimes) return <AirbagLoader />;

  if (drivingTimes.length === 2) {
    return (
      <div className="graph-box scores-data">
        <DrivingTimes
          title={`Tiempo de manejo últimos 30 días`}
          labels={['Día', 'Noche']}
          data={drivingTimes}
          colors={[getColors(2)[1], getColors(2)[0]]}
        />
      </div>
    );
  }

  return null;
}

export default DrivingTimesChart;
