const colors = [
  '#9ac4e5',
  '#ffc880',
  '#aadf9f',
  '#aa99e6',
  '#ff80bf',
  '#ff8080',
  '#ffa480',
  '#ffce56',
  '#bff985',
  '#ff99ff',
  '#dda2d2',
  '#b1cec6',
  '#1cbbd7',
  '#E49B0F',
  '#aff748',
  '#9966ff',
  '#ff0080',
  '#a52a2a',
  '#015073',
  '#ff5f1f',
  '#33d68a',
  '#702963',
  '#ffc0cb',
  '#ff9f40',
  '#1a9ad3',
  '#daa520',
  '#6ba394',
  '#bf40bf',
  '#f33a6a',
  '#f5deb3'
];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Get colors in order
export function getColors(length) {
  const ret = [];

  // Limit to colors length array
  if (length > colors.length) return ret;

  // Return colors in order
  for (let i = 0; i < length; i++) {
    ret.push(colors[i]);
  }

  return ret;
}

// Get random colors
export function getRandomColors(length) {
  const ret = [];
  const colorsCopy = [...colors];

  // Limit to colorsCopy length array
  if (length > colorsCopy.length) return ret;

  while (ret.length < length) {
    // Get color from random index
    const color = colorsCopy[getRandomInt(0, colorsCopy.length - 1)];

    // Push color to array
    ret.push(color);

    // Get color index in colorsCopy array
    const index = colorsCopy.indexOf(color);
    // Remove color from colorsCopy array
    colorsCopy.splice(index, 1);
  }

  return ret;
}
