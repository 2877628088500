import React from 'react';
import './Loader.scss';
import AirbagLoaderLines from '../airbag-loader/AirbagLoaderLines';

function Loader() {
  return (
    <div className="valign-wrapper" id="_loader_">
      <AirbagLoaderLines />
    </div>
  );
}

export default Loader;
