import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import Swal from 'sweetalert2';
import Loader from '../common/loader/Loader';
import axiosAuth from '../../utils/axiosAuth';
import NotAuthorized from '../not-authorized/NotAuthorized';
import Footer from './footer/Footer';
import CompanySidenav from './sidenav/CompanySidenav';
import CompanyHome from './home/CompanyHome';
import CompanyDrivers from './drivers/CompanyDrivers';
import CompanyDriver from './driver/CompanyDriver';
import CompanyTrip from './trip/CompanyTrip';
import CompanyVehicle from './vehicle/CompanyVehicle';
import CompanyVehicles from './vehicles/CompanyVehicles';
import CompanyRanking from './ranking/CompanyRanking';
import CompanyAnalytics from './analytics/CompanyAnalytics';
import CompanyReports from './usage-reports/CompanyReports';
import CompanyProfile from './profile/CompanyProfile';
import UserGroup from './user-group/UserGroup';
import UserGroups from './user-groups/UserGroups';
import CompanyMap from './map/CompanyMap';
import CompanyManuals from './manuals/CompanyManuals';
import Trips from './trips/Trips';
import Activities from './activities/Activities';
import Activity from './activity/Activity';
import Game from './game/Game';
import { Billing } from './billing/Billing';
import Forms from './forms/Forms';
import Form from './form/Form';
import './CompanyMain.scss';

const ANALYTICS_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS;
const POSTHOG_ID = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;

function CompanyMain(props) {
  const { user, section } = props;
  const [sidenavNotCollapsed, setSidenavNotCollapsed] = useState(
    window.innerWidth > 992 ? false : true
  ); // Open default in mobile
  const location = useLocation();

  const sectionToRender = {
    home: <CompanyHome {...props} />,
    drivers: <CompanyDrivers {...props} />,
    driver: <CompanyDriver {...props} />,
    trip: <CompanyTrip {...props} />,
    vehicle: <CompanyVehicle {...props} />,
    map: <CompanyMap {...props} />,
    vehicles: <CompanyVehicles {...props} />,
    ranking: <CompanyRanking {...props} />,
    analytics: <CompanyAnalytics {...props} />,
    reports: user.authRole === 'admin' ? <CompanyReports {...props} /> : null,
    profile: !user.isInvitee ? <CompanyProfile {...props} /> : null,
    group: user.authRole === 'admin' ? <UserGroup {...props} /> : null,
    groups: user.authRole === 'admin' ? <UserGroups {...props} /> : null,
    manuals: <CompanyManuals {...props} />,
    trips: user.authRole === 'admin' ? <Trips {...props} /> : null,
    activities: user.authRole === 'admin' ? <Activities {...props} /> : null,
    activity: user.authRole === 'admin' ? <Activity {...props} /> : null,
    game: <Game {...props} />,
    forms: <Forms {...props} />,
    form: <Form {...props} />,
    billing: user.authRole === 'admin' ? <Billing {...props} /> : null
  };

  // Handle auth
  useEffect(() => {
    const aat = localStorage.getItem('aat');

    // If no Access Token is found, redirect user to login
    if (!aat) {
      props.history.push('/login');
    } else {
      axiosAuth
        .post('/api/auth/manager/login-with-token')
        .then((res) => {
          const { user, company, companyGroup } = res.data;

          // Check that user is valid
          if (!user) {
            // Delete Access Token
            localStorage.removeItem('aat');
            // Delete Refresh token
            localStorage.removeItem('art');
            // Delete company ID
            localStorage.removeItem('currentCompanyId');
            // Redirect to login
            props.history.push('/login');
          } else {
            /* PostHog */
            if (POSTHOG_ID && user.id) {
              const { name, lastName } = user;

              posthog.identify(user.id, {
                name: `${name} ${lastName} (${company.name})`
              });
              posthog.group('company', company.id, {
                name: company.name,
                value: company.id
              });
            }

            /* Google Analytics */
            if (ANALYTICS_TRACKING_ID && user.id) {
              // Init Google Analytics
              ReactGA.initialize(ANALYTICS_TRACKING_ID, {
                gaOptions: { userId: user.id, clientId: company.id }
              });
              // On first page load, we want to track user's activity
              ReactGA.send({
                hitType: 'pageview',
                page: location.pathname
              });
            }

            // Save information in store
            props.setCompany(company);
            props.setUser(user);
            props.setCompanyGroup(companyGroup);
          }
        })
        .catch((err) => {
          console.log(err);
          // In production, delete data
          if (process.env.REACT_APP_ENV === 'production') {
            // Delete Access Token
            localStorage.removeItem('aat');
            // Delete Refresh token
            localStorage.removeItem('art');
            // Delete company ID
            localStorage.removeItem('currentCompanyId');

            // Delete information in store
            props.unsetCompany();
            props.unsetUser();
            props.unsetCompanyGroup();

            if (
              err.response &&
              err.response.data &&
              typeof err.response.data === 'string' &&
              err.response.data === 'company-churn'
            ) {
              Swal.fire({
                icon: 'error',
                title: 'Cuenta deshabilitada',
                // eslint-disable-next-line max-len
                text: 'Ponte en contacto con el equipo de ventas para reanudar el servicio',
                confirmButtonText:
                  '<span class="dark-color-text">Aceptar</span>',
                confirmButtonColor: '#f6c700'
              });
            }

            props.history.push('/login');
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect is for tracking when a user changes location, because
  // on first page load its data won't be available
  useEffect(() => {
    /* PostHog */
    if (POSTHOG_ID && user.id) {
      // Capture new location
      posthog.capture('$pageview');
    }

    /* Google Analytics */
    if (ANALYTICS_TRACKING_ID && user.id) {
      // Send location change
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Wait for user data
  if (!Object.keys(user).length) return <Loader />;

  return (
    <div id="company-main">
      <CompanySidenav
        {...props}
        sidenavNotCollapsed={sidenavNotCollapsed}
        setSidenavNotCollapsed={setSidenavNotCollapsed}
      />
      <div
        id="company-content"
        className={classNames({
          collapsed: !sidenavNotCollapsed,
          'not-collapsed': sidenavNotCollapsed
        })}
      >
        <div id="section-container">
          {sectionToRender[section] || <NotAuthorized />}
        </div>
      </div>
      <Footer {...props} />
    </div>
  );
}

export default CompanyMain;
