import axios from 'axios';

// Function to get new access token from refresh token
async function refreshAccessToken() {
  // Get refresh and access token from localStorage
  const art = localStorage.getItem('art');
  const currentCompanyId = localStorage.getItem('currentCompanyId');

  try {
    // Make request to API
    const newAccessTokenRes = await axios.post(
      '/api/auth/refresh-access-token',
      {
        art,
        currentCompanyId
      }
    );

    return newAccessTokenRes.data.aat;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export default refreshAccessToken;
