import React from 'react';
import Icon from '../../common/airbag-icons/Icon';
import './ManualVideo.scss';

function ManualVideo(props) {
  const { videoUrl, setPlayVideo } = props;
  const iframeAllowstring =
    'accelerometer; autoplay; clipboard-write; ' +
    'encrypted-media; gyroscope; picture-in-picture';

  return (
    <div id="_manual-video_">
      <div
        id="manuals-courtain"
        onClick={() => {
          setPlayVideo(false);
          document.body.classList.remove('no-scroll');
        }}
      >
        <Icon icon="close" id="close-icon" />
        <div id="courtain-content">
          <iframe
            src={videoUrl}
            frameBorder="0"
            allow={iframeAllowstring}
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
}

export default ManualVideo;
