/* eslint max-len: 0 */

import React from 'react';

function CallOut(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <path d="M191.34,260.12H92.13A19.2,19.2,0,0,1,73,240.94V42.52A19.19,19.19,0,0,1,92.13,23.35h99.21a19.19,19.19,0,0,1,19.17,19.17V80.87a5,5,0,0,1-10,0V42.52a9.18,9.18,0,0,0-9.17-9.17H92.13A9.18,9.18,0,0,0,83,42.52V240.94a9.19,9.19,0,0,0,9.18,9.18h99.21a9.18,9.18,0,0,0,9.17-9.18V188.43a5,5,0,0,1,10,0v52.51A19.19,19.19,0,0,1,191.34,260.12Z" />
      <circle cx="141.73" cy="219.69" r="7.09" />
      <path d="M248.2,101.28H205.68a5,5,0,0,0,0,10H237.9l-52.37,52.37a5,5,0,0,0,7.07,7.07l50.6-50.6V148.8a5,5,0,0,0,10,0V106.28A5,5,0,0,0,248.2,101.28Z" />
    </svg>
  );
}

export default CallOut;
