import React, { useState, useEffect } from 'react';
import axiosAuth from '../../../../utils/axiosAuth';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import Stat from '../../../common/stat/Stat';
import './VehicleGeneralScores.scss';

function VehicleGeneralScores(props) {
  const vehicleId = props.vehicleId;
  const [scores, setScores] = useState({});
  const [ecoScores, setEcoScores] = useState({});
  const [loadingScores, setLoadingScores] = useState(true);

  useEffect(() => {
    // Get vehicle info
    fetchScores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchScores() {
    axiosAuth
      .get(`/api/vehicles/${vehicleId}/scores`)
      .then((res) => {
        const { scores, ecoScores } = res.data;
        setScores(scores);
        setEcoScores(ecoScores);
        setLoadingScores(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleScoreColor(number) {
    if (number >= 80) {
      return 'green';
    } else if (number >= 60) {
      return 'yellow';
    } else if (number > 0 && number < 60) {
      return 'red';
    }
    return 'na';
  }

  // Loader
  if (loadingScores) return <AirbagLoader />;

  return (
    <div className="row">
      <div className="col s12">
        <div id="_vehicle-metrics_">
          {/* SCORING */}
          <div className="row metrics-table">
            <div className="col s12">
              <p className="table-title">Hábitos de conducción</p>
            </div>
            <div className="col s12 l2 wrapp">
              <Stat
                value={scores.overall ? scores.overall : 0}
                color={handleScoreColor(scores.overall)}
              />
            </div>
            <div className="col s6 m4 l2 wrapp">
              <p className="stat-title">Aceleración</p>
              <p
                className={`stat-value st-${handleScoreColor(
                  scores.acceleration
                )}`}
              >
                {scores.acceleration ? scores.acceleration : '-'}{' '}
                <span>/ 100</span>
              </p>
            </div>
            <div className="col s6 m4 l2 wrapp">
              <p className="stat-title">Frenado</p>
              <p
                className={`stat-value st-${handleScoreColor(scores.braking)}`}
              >
                {scores.braking ? scores.braking : '-'} <span>/ 100</span>
              </p>
            </div>
            <div className="col s6 m4 l2 wrapp">
              <p className="stat-title">Curvas</p>
              <p
                className={`stat-value st-${handleScoreColor(
                  scores.cornering
                )}`}
              >
                {scores.cornering ? scores.cornering : '-'} <span>/ 100</span>
              </p>
            </div>
            <div className="col s6 m6 l2 wrapp">
              <p className="stat-title">Uso de celular</p>
              <p
                className={`stat-value st-${handleScoreColor(
                  scores.phoneDistraction
                )}`}
              >
                {scores.phoneDistraction ? scores.phoneDistraction : '-'}{' '}
                <span>/ 100</span>
              </p>
            </div>
            <div className="col s12 m6 l2 wrapp">
              <p className="stat-title">Exceso velocidad</p>
              <p
                className={`stat-value st-${handleScoreColor(scores.speeding)}`}
              >
                {scores.speeding ? scores.speeding : '-'} <span>/ 100</span>
              </p>
            </div>
          </div>
          {/* ECO SCORING */}
          <div className="row metrics-table">
            <div className="col s12">
              <p className="table-title">Conducción ecológica</p>
            </div>
            <div className="col s12 l2 wrapp">
              <Stat
                value={ecoScores.overall ? ecoScores.overall : 0}
                color={handleScoreColor(ecoScores.overall)}
              />
            </div>
            <div className="col s6 l2 wrapp">
              <p className="stat-title">Combustible</p>
              <p
                className={`stat-value st-${handleScoreColor(ecoScores.fuel)}`}
              >
                {ecoScores.fuel ? ecoScores.fuel : '-'} <span>/ 100</span>
              </p>
            </div>
            <div className="col s6 l2 wrapp">
              <p className="stat-title">Llantas</p>
              <p
                className={`stat-value st-${handleScoreColor(ecoScores.tires)}`}
              >
                {ecoScores.tires ? ecoScores.tires : '-'} <span>/ 100</span>
              </p>
            </div>
            <div className="col s6 l2 wrapp">
              <p className="stat-title">Frenos</p>
              <p
                className={`stat-value st-${handleScoreColor(
                  ecoScores.brakes
                )}`}
              >
                {ecoScores.brakes ? ecoScores.brakes : '-'} <span>/ 100</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleGeneralScores;
