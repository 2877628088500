import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import HereMap from '../../common/here-map/HereMap';
import axiosAuth from '../../../utils/axiosAuth';
import './CompanyMap.scss';

const center = {
  lat: 22.544875,
  lng: -101.692879
};

function getGroupFromArray(arr) {
  let ret = null;

  if (arr && typeof arr === 'object' && arr.length) {
    // Assign first group in array
    ret = arr[0].id;
  }

  return ret;
}

function CompanyMap(props) {
  const { user } = props;
  const { groups } = user;
  const [group, setGroup] = useState(getGroupFromArray(groups));
  const [driversMarkers, setDriversMarkers] = useState(null);

  useEffect(() => {
    // Reset values to show loaders
    setDriversMarkers(null);

    // Build query params to add groupId if manager is group-limited
    const queryParams = {};
    if (user.authRole === 'group-limited') {
      queryParams.groupId = group;
    }

    // Get active drivers
    axiosAuth
      .get(`/api/users/get-locations`, { params: queryParams })
      .then((res) => {
        const { users } = res.data;
        addDriversMarkers(users);
      })
      .catch((error) => {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  function addDriversMarkers(drivers) {
    const markersArray = [];

    // Add the markers to an array, this array will be passed to the
    // Here Maps to render the markers
    if (drivers) {
      drivers.forEach((driver) => {
        if (driver.position && driver.position.lat !== 0) {
          const today = dayjs();
          const logDate = dayjs(driver.position.created);
          const diff = today.diff(logDate, 'h');

          // Handle marker color
          let markerType = 'good';
          if (diff >= 24 && diff <= 120) markerType = 'regular';
          else if (diff > 120) markerType = 'bad';

          const markerObject = {
            lat: driver.position.lat,
            lng: driver.position.lon,
            driver: driver,
            markerType: markerType
          };
          markersArray.push(markerObject);
        }
      });
      setDriversMarkers([...markersArray]);
    }
  }

  return (
    <div id="_company-map_">
      {driversMarkers ? (
        <div id="map-container">
          <div id="top-elements">
            <div id="info-tags">
              <span className="info-tag">Última conexión</span>
              <span className="info-tag good">&lt; 24 horas</span>
              <span className="info-tag regular">de 1 a 5 días</span>
              <span className="info-tag bad">&gt; 5 días</span>
            </div>
            {user.authRole === 'group-limited' ? (
              <div id="group-filter">
                <label htmlFor="groups-select" className="active">
                  Grupo
                </label>
                {group ? (
                  <select
                    className="browser-default"
                    id="groups-select"
                    value={group}
                    onChange={(e) => setGroup(e.target.value)}
                  >
                    {groups.map((gp) => {
                      return (
                        <option key={gp.id} value={gp.id}>
                          {gp.name}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <select className="browser-default" id="groups-select">
                    <option value="" disabled>
                      Sin grupos
                    </option>
                  </select>
                )}
              </div>
            ) : null}
          </div>
          <HereMap
            height="100%"
            zoom={5}
            center={center}
            showTraffic={true}
            driversMarkers={driversMarkers}
          />
        </div>
      ) : (
        <AirbagLoader />
      )}
    </div>
  );
}

export default CompanyMap;
