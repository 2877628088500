import airbagLogoNegro from '../assets/logos/airbag_logo_negro.png';
import airbagLogoBlanco from '../assets/logos/airbag_logo_blanco.png';
import retoEnRuta from '../assets/logos/reto_en_ruta.png';

const skins = {
  localhost: {
    companyName: 'Airbag Technologies',
    companyShortName: 'Airbag',
    logoBlack: airbagLogoNegro,
    logoWhite: airbagLogoBlanco,
    contactEmail: 'contacto@airbagtech.io',
    domain: 'airbagtech.io'
  },
  'airbagtech.io': {
    companyName: 'Airbag Technologies',
    companyShortName: 'Airbag',
    logoBlack: airbagLogoNegro,
    logoWhite: airbagLogoBlanco,
    contactEmail: 'contacto@airbagtech.io',
    domain: 'airbagtech.io'
  },
  'retoenruta.com': {
    companyName: 'Reto en ruta',
    companyShortName: 'Reto en ruta',
    logoBlack: retoEnRuta,
    logoWhite: retoEnRuta,
    contactEmail: 'contacto@retoenruta.com',
    domain: 'retoenruta.com'
  }
};

export default skins;
