import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

function ActiveUsers(props) {
  const { labels, data, colors } = props;
  const chartContainer = useRef(null);

  const chartConfig = {
    type: 'doughnut',
    data: {
      labels,
      datasets: [
        {
          data: data,
          backgroundColor: colors,
          borderWidth: 1
        }
      ]
    },
    options: {
      cutout: 65,
      responsive: true,
      maintainAspectRatio: false,
      resizeDelay: 800,
      plugins: {
        // https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
        tooltip: {
          callbacks: {
            title: (tooltipItems, data) => {
              return null;
            },
            label: (tooltipItem, data) => {
              return tooltipItem.label;
            },
            afterLabel: (tooltipItems, data) => {
              return null;
            }
          },
          backgroundColor: '#464646',
          padding: 12,
          displayColors: false
        },
        legend: {
          position: 'right',
          labels: {
            boxWidth: 8,
            boxHeight: 8,
            padding: 14,
            usePointStyle: true,
            pointStyle: 'circle',
            color: '#464646',
            font: {
              size: 12
            }
          }
        },
        title: {
          display: false
        }
      }
    }
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      new Chart(chartContainer.current, chartConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContainer]);

  return <canvas ref={chartContainer} />;
}

export default ActiveUsers;
