import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import axiosAuth from '../../../utils/axiosAuth';
import Icon from '../../common/airbag-icons/Icon';
import AirbagLoader from '../../common/airbag-loader/AirbagLoader';
import Error from '../../common/error/Error';
import './CompanyManuals.scss';

function CompanyManuals(props) {
  const { user } = props;
  const [error, setError] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [manuals, setManuals] = useState(null);

  useEffect(() => {
    // Get manuals
    axiosAuth
      .get('/api/manuals')
      .then((res) => {
        const { manuals: manualsRes } = res.data;
        const manualsProcessed = [];

        // Loop through manuals
        for (const man of manualsRes) {
          // Handle group-limited admins
          if (user.authRole === 'group-limited') {
            const mansToAvoid = ['groups', 'routes', 'ranking', 'reports'];
            if (mansToAvoid.includes(man.slug)) {
              continue;
            }
          }

          // Add manual to final array
          manualsProcessed.push(man);
        }

        setManuals(manualsProcessed);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function registerWebAction(slug) {
    // Register manual opened
    axiosAuth.post('/api/web-actions/manual-open', { slug }).catch((err) => {
      console.log(err);
    });
  }

  function renderManual(manual, i) {
    // Default values to video
    let typeIcon = 'file-video';
    let onClick = () => {
      // Validate url exists
      if (manual.url) {
        // Assign video url
        setVideoUrl(manual.url);
        // Remove scroll since modal is opened
        document.body.classList.add('no-scroll');
      }

      // Register web action
      registerWebAction(manual.slug);
      // Google Analytics
      ReactGA.event({
        category: 'manuals',
        action: manual.analytics_action
      });

      // Posthog
      posthog.capture(manual.analytics_action, {
        category: 'manuals',
        isCustomEvent: true
      });
    };

    if (manual.type === 'document') {
      // Change icon to match document
      typeIcon = 'file';
      // Change onClick handler
      onClick = () => {
        // Open in new tab
        window.open(manual.url, '_blank');
        // Register web action
        registerWebAction(manual.slug);
        // Google Analytics
        ReactGA.event({
          category: 'manuals',
          action: manual.analytics_action
        });
        // Posthog
        posthog.capture(manual.analytics_action, {
          category: 'manuals',
          isCustomEvent: true
        });
      };
    }

    return (
      <div className="col s6 m4 l3 manual-container" key={manual.id}>
        <div className="box" onClick={onClick}>
          <div className="top-info">
            <FontAwesomeIcon className="top-icon" icon={typeIcon} />
            <span>{manual.duration} min</span>
          </div>
          <div>
            <div className="icon-container">
              <Icon icon={manual.icon} className="manual-icon" />
            </div>
            <div className="content">
              <p className="title">
                {manual.type === 'video' && i + '.'} {manual.title}
              </p>
              <p className="description">{manual.description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderManuals() {
    if (!manuals) {
      return <AirbagLoader />;
    }

    if (!manuals.length) {
      return (
        <div className="row">
          <div className="col s12">
            <p>Sin manuales</p>
          </div>
        </div>
      );
    }

    return (
      <div className="row" id="manuals-content">
        {manuals.map((manual, i) => renderManual(manual, i))}
      </div>
    );
  }

  // API error handler
  if (error) return <Error error={error} />;

  const iframeAllowstring =
    'accelerometer; autoplay; clipboard-write; ' +
    'encrypted-media; gyroscope; picture-in-picture';

  return (
    <div id="_manuals_">
      <div className="row" id="main-title">
        <div className="col s12">
          <h5>Manuales de uso</h5>
          <p>
            En esta sección puedes encontrar recursos que te explicarán el uso
            de las diferentes secciones y funcionalidades de la plataforma.
          </p>
        </div>
      </div>
      {renderManuals()}
      {videoUrl && (
        <div
          id="manuals-courtain"
          onClick={() => {
            setVideoUrl(null);
            document.body.classList.remove('no-scroll');
          }}
        >
          <Icon icon="close" id="close-icon" />
          <div id="courtain-content">
            <iframe
              src={videoUrl}
              title="Inicio DMS"
              frameBorder="0"
              allow={iframeAllowstring}
              allowFullScreen
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CompanyManuals;
