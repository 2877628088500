/* eslint max-len: 0 */

import React from 'react';

function TechProblem(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <path d="M193.78,236.88H89.69a5,5,0,0,1-4.33-2.5l-52-90.15a5,5,0,0,1,0-5L85.36,49.09a5,5,0,0,1,4.33-2.5H193.78a5,5,0,0,1,4.33,2.5l52,90.14a5,5,0,0,1,0,5l-52,90.15A5,5,0,0,1,193.78,236.88Zm-101.21-10h98.32l49.16-85.15L190.89,56.59H92.57L43.42,141.73Z" />
      <path d="M141.73,196.34a54.61,54.61,0,1,1,54.61-54.61A54.67,54.67,0,0,1,141.73,196.34Zm0-99.21a44.61,44.61,0,1,0,44.61,44.6A44.65,44.65,0,0,0,141.73,97.13Z" />
    </svg>
  );
}

export default TechProblem;
