/* eslint max-len: 0 */

import React from 'react';

function Download(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <polyline
          className="cls-1"
          points="85.04 99.21 28.35 99.21 28.35 255.12 255.12 255.12 255.12 99.21 198.43 99.21"
        />
        <line
          className="cls-1"
          x1="141.73"
          y1="28.35"
          x2="141.73"
          y2="198.43"
        />
        <polyline
          className="cls-1"
          points="99.21 155.91 141.73 198.43 184.25 155.91"
        />
      </g>
    </svg>
  );
}

export default Download;
