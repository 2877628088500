import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import M from 'materialize-css';
import ReactGA from 'react-ga4';
import Swal from 'sweetalert2';
import { posthog } from 'posthog-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axiosAuth from '../../../../utils/axiosAuth';
import Error from '../../../common/error/Error';
import Icon from '../../../common/airbag-icons/Icon';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import Button from '../../../common/button/Button';
import './UsageReports.scss';

function UsageReports(props) {
  const [reports, setReports] = useState(null);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [disableNewReport, setDisableNewReport] = useState(false);
  // Save new report's start date
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, 'day').startOf('day')
  );
  // Save new report's End date
  const [endDate, setEndDate] = useState(dayjs().endOf());
  // Company id
  const companyId = props.props.company.id;

  useEffect(() => {
    // Fetch usage reports
    fetchUsageReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
  function base64toBlob(base64String, sliceSize = 512) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
      type:
        'vnd.openxmlformats-officedocument' +
        '.spreadsheetml.sheet;charset=utf-8'
    });
    return blob;
  }

  function fetchUsageReports() {
    axiosAuth
      .get('/api/reports/usage')
      .then((res) => {
        const { reports } = res.data;
        setReports(reports);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }

  function downloadReport(base64xlsx, fileName) {
    // Convert base64 to blob for file download
    const blobUrl = URL.createObjectURL(base64toBlob(base64xlsx));
    // Save file with FileSaver package
    FileSaver.saveAs(blobUrl, fileName);
  }

  function requestDownloadReport(reportId) {
    M.toast({
      html: 'Descargando reporte, este proceso puede tardar varios segundos',
      classes: 'info-toast'
    });

    // Google analytics
    ReactGA.event({
      category: 'reports',
      action: 'download_usage_report'
    });
    // Posthog
    posthog.capture('download_usage_report', {
      category: 'reports',
      isCustomEvent: true
    });

    axiosAuth
      .get('/api/reports/usage/download', { params: { reportId } })
      .then((res) => {
        const { base64xlsx, fileName } = res.data;

        // Download report
        downloadReport(base64xlsx, fileName);
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: 'Tuvimos un error, intenta de nuevo más tarde',
          classes: 'error-toast'
        });
      });
  }

  function requestDeleteReport(reportId) {
    setReports(null);

    // Google analytics
    ReactGA.event({
      category: 'reports',
      action: 'delete_usage_report'
    });

    axiosAuth
      .delete('/api/reports/usage/delete', { params: { reportId } })
      .then((res) => {
        M.toast({
          html: res.data.message,
          classes: 'success-toast'
        });

        fetchUsageReports();
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: 'Tuvimos un error, intenta de nuevo más tarde',
          classes: 'error-toast'
        });
      });
  }

  function handleDeleteReportUsageClick(reportId) {
    Swal.fire({
      icon: 'warning',
      title: '¿Deseas eliminar el reporte?',
      text: 'Esta acción no se puede deshacer',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f6c700',
      confirmButtonText: '<span class="dark-color-text">Aceptar</span>'
    }).then((result) => {
      if (result.isConfirmed) {
        requestDeleteReport(reportId);
      }
    });
  }

  // Valid 3 months between startDate - endDate
  // and startDate is more than createDate
  function errorLimitReportsDates(startDate, endDate) {
    const dateDiff = startDate.diff(endDate, 'month');

    if (dateDiff < -3) {
      M.toast({
        html: `Los reportes tienen una rango de fecha limite de 3 meses`,
        classes: ' error-toast'
      });
    } else if (dateDiff > 0) {
      M.toast({
        html: `Escoge un rango de fechas válido`,
        classes: ' error-toast'
      });
    } else {
      setDisableNewReport(false);
    }
  }

  // Request new usage report
  function createUsageReport(startDate, endDate) {
    const startDateQuery = dayjs(startDate).startOf('day').format('YYYY-MM-DD');
    const endDateQuery = dayjs(endDate).startOf('day').format('YYYY-MM-DD');
    setDisableNewReport(true);

    // Google analytics
    ReactGA.event({
      category: 'reports',
      action: 'request_usage_report'
    });
    // Posthog
    posthog.capture('request_usage_report', {
      category: 'reports',
      isCustomEvent: true
    });

    // Make Request
    axiosAuth
      .post('/api/reports/create-report-usage', {
        companyId,
        startDate: startDateQuery,
        endDate: endDateQuery
      })
      .then((res) => {
        M.toast({
          html: res.data,
          classes: 'info-toast'
        });
        setDisableNewReport(false);
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: err,
          classes: 'error-toast'
        });
        setDisableNewReport(false);
      });
  }

  // Open modal
  function renderNewReportModal() {
    if (modalOpen) {
      return (
        <div className="airbag-table-container modalReport" id="newReportForm">
          <div className="row">
            <div className="closeModalContainer">
              <FontAwesomeIcon
                icon={['fal', 'xmark']}
                id="close-modal-button"
                onClick={() => {
                  setModalOpen();
                }}
              />
            </div>
            <div className="col s10 reportFormContainer">
              <h4>Nuevo reporte</h4>
              <div className="row">
                <div className="col s12 m4">
                  <label>Fecha inicial</label>
                  <input
                    className="inputReport"
                    type="date"
                    defaultValue={dayjs(startDate).format('YYYY-MM-DD')}
                    onChange={(e) => {
                      setStartDate(dayjs(e.target.value).startOf('day'));
                      errorLimitReportsDates(
                        dayjs(e.target.value).startOf('day'),
                        endDate
                      );
                    }}
                  ></input>
                </div>

                <div className=" col s12 m4">
                  <label>Fecha final</label>
                  <input
                    className="inputReport"
                    type="date"
                    defaultValue={dayjs(endDate).format('YYYY-MM-DD')}
                    onChange={(e) => {
                      setEndDate(dayjs(e.target.value).endOf('day'));
                      errorLimitReportsDates(
                        startDate,
                        dayjs(e.target.value).endOf('day')
                      );
                    }}
                  ></input>
                </div>
                <div className=" col s12 m4 newReportButton">
                  <Button
                    variant="yellow-button"
                    id="add-report-btn"
                    onClick={() => createUsageReport(startDate, endDate)}
                    type="button"
                    disabled={disableNewReport}
                    loading={disableNewReport}
                  >
                    Solicitar reporte
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col s12">
                  <p id="exp">
                    * El intervalo de tiempo máximo para generar un reporte es
                    de 3 meses
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  function renderReportName(report) {
    // Render period dates or default to fileName
    if (report.startDate && report.endDate) {
      return `${dayjs(report.startDate).format('D [de] MMMM')} - ${dayjs(
        report.endDate
      ).format('D [de] MMMM')}`;
    }

    return report.fileName;
  }

  function renderReportsTable() {
    // Show loader
    if (!reports) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No reports
    if (!reports.length) {
      return (
        <div id="no-data">
          <Icon icon="crash" id="error-icon" />
          <p>Sin reportes</p>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="reports-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>Periodo evaluado</th>
              <th>Creado por</th>
              <th className="center-align">Descargar</th>
              <th className="center-align">Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report, i) => (
              <tr key={report.id} className="report-row">
                <td>
                  <span className="pos-index">{i + 1}.</span>
                  {renderReportName(report)}
                </td>
                <td>{report.createdBy ? report.createdBy : ''}</td>
                <td className="center-align">
                  <FontAwesomeIcon
                    icon={['fal', 'download']}
                    className="download-icon"
                    onClick={() => requestDownloadReport(report.id)}
                  />
                </td>
                <td className="center-align">
                  <FontAwesomeIcon
                    icon={['fal', 'xmark']}
                    className="close-icon"
                    onClick={() => handleDeleteReportUsageClick(report.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  // API error handler
  if (error) return <Error error={error} />;

  return (
    <div id="_usage-reports_">
      <div className="row">
        <div className="col s12 right-align">
          {!modalOpen && (
            <Button
              variant="yellow-button"
              id="open-add-report"
              onClick={() => setModalOpen(true)}
              type="button"
            >
              Solicitar reporte
              <Icon icon="more" id="add-report-icon" />
            </Button>
          )}
        </div>
        <div className="col s12">{renderNewReportModal()}</div>
      </div>
      <div className="row">
        <div className="col s12">{renderReportsTable()}</div>
      </div>
    </div>
  );
}

export default UsageReports;
