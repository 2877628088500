import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import './ProfileDropdown.scss';

function ProfileDropdown(props) {
  const { unsetUser, unsetCompany, unsetCompanyGroup, user, history } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const menuBoxRef = useRef();

  // Close menu when there is a click outside the component
  useEffect(() => {
    const handler = (event) => {
      if (!menuBoxRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  function logout() {
    // Remove user from store
    unsetUser();
    // Remove company from store
    unsetCompany();
    // Remove company group from store
    unsetCompanyGroup();

    // Reset PostHog in production
    if (process.env.REACT_APP_PUBLIC_POSTHOG_KEY) {
      posthog.reset();
    }

    // Delete Access Token
    localStorage.removeItem('aat');
    // Delete Refresh token
    localStorage.removeItem('art');
    // Delete company ID
    localStorage.removeItem('currentCompanyId');

    // Redirect to login
    history.push('/login');
  }

  return (
    <div ref={menuBoxRef} id="_profile-menu-wrapper_">
      <div id="menu-preview" onClick={() => setMenuOpen(!menuOpen)}>
        <div>
          <div>
            <p className="u-name">
              {user.name} {user.lastName}
            </p>
            <p className="u-email">{user.email}</p>
          </div>
          <FontAwesomeIcon id="caret" icon={['fal', 'angle-down']} />
        </div>
      </div>
      <div id="dropdown-menu-wrapper">
        {menuOpen ? (
          <div id="dropdown-menu">
            {!user.isInvitee && !user.isSam ? (
              <div
                className="row-item"
                onClick={() => {
                  setMenuOpen(false);
                  history.push('/perfil');
                }}
              >
                <p>
                  <FontAwesomeIcon
                    className="menu-icon"
                    icon={['fal', 'pen-to-square']}
                  />{' '}
                  Editar perfil
                </p>
              </div>
            ) : null}
            <div
              className="row-item"
              onClick={() => {
                setMenuOpen(false);
                logout();

                // Google Analytics
                ReactGA.event({
                  category: 'profile',
                  action: 'logout'
                });
                // Posthog
                posthog.capture('logout', {
                  category: 'profile',
                  isCustomEvent: true
                });
              }}
            >
              <p id="logout">
                <FontAwesomeIcon
                  className="menu-icon"
                  icon={['fal', 'arrow-right-from-bracket']}
                />
                Cerrar sesión
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ProfileDropdown;
