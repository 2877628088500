function user(state = {}, action) {
  switch (action.type) {
    case 'SET_USER': {
      state = { ...state, ...action.payload };
      break;
    }
    case 'UNSET_USER': {
      state = {};
      break;
    }
    default:
      break;
  }
  return state;
}

export default user;
