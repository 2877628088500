import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getDomainName from '../../../utils/getDomainName';
import * as Sentry from '@sentry/react';
import './Error.scss';

function Error(props) {
  const { hideLink, error } = props;
  const domainName = getDomainName();

  useEffect(() => {
    // Log error in Sentry
    if (error) {
      Sentry.captureException(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="_error_">
      <FontAwesomeIcon icon="bomb" />
      <h5>¡Oops! Algo salió mal.</h5>
      <p>
        No te preocupes, no fue un problema tuyo. Nuestro equipo de desarrollo
        ya fue notificado de este incidente.
      </p>
      {!hideLink && (
        <a href={`https://app.${domainName}`}>
          <button className="button yellow-button" href="/">
            Regresar al inicio
          </button>
        </a>
      )}
    </div>
  );
}

export default Error;
