// Functions to determine manager / company relationship
// in a company group.

// Admin manager is in parent company
function isManagerInParentCompany(company, user, companyGroup) {
  if (!user.isInvitee && company.id === companyGroup.parentId) {
    return true;
  }

  return false;
}

// Admin manager is in child company
function isManagerInChildCompany(company, user, companyGroup) {
  if (!user.isInvitee && company.id !== companyGroup.parentId) {
    return true;
  }

  return false;
}

export { isManagerInParentCompany, isManagerInChildCompany };
