import React, { useState, useEffect } from 'react';
import Stat from '../../../../common/stat/Stat';
import LoaderInner from '../../../../common/loader-inner/LoaderInner';
import './ActivityInfo.scss';

function ActivityInfo(props) {
  const { trips } = props;
  const [loading, setLoading] = useState(true);
  const [scores, setScores] = useState({});
  const [ecoScores, setEcoScores] = useState({});

  useEffect(() => {
    // Get scoring averages
    const scoresObj = {};
    const ecoScoresObj = {};
    trips.forEach((trip) => {
      // Sum the scores properties
      Object.keys(trip.score).forEach((key) => {
        // Add key to object if it doesn't exist
        if (!scoresObj[key]) scoresObj[key] = 0;
        // Sum counter
        scoresObj[key] += trip.score[key];
      });
      // Sum the eco scores properties
      Object.keys(trip.ecoScore).forEach((key) => {
        // Add key to object if it doesn't exist
        if (!ecoScoresObj[key]) ecoScoresObj[key] = 0;
        // Sum counter
        ecoScoresObj[key] += trip.ecoScore[key];
      });
    });
    // Get the scores average
    Object.keys(scoresObj).forEach((key) => {
      scoresObj[key] = Math.round(scoresObj[key] / trips.length);
    });
    // Get the eco scores average
    Object.keys(ecoScoresObj).forEach((key) => {
      ecoScoresObj[key] = Math.round(ecoScoresObj[key] / trips.length);
    });

    setScores(scoresObj);
    setEcoScores(ecoScoresObj);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleScoreColor(number) {
    if (number >= 80) {
      return 'green';
    } else if (number >= 60) {
      return 'yellow';
    } else if (number < 60) {
      return 'red';
    }
  }

  // Info loading
  if (loading) return <LoaderInner />;

  return (
    <div id="_activity-info_">
      <div className="row scores">
        <div className="col s12">
          <p className="table-title">Hábitos de conducción</p>
        </div>
        <div className="col s12 l2 wrapp">
          <Stat
            value={scores.overall || 0}
            color={handleScoreColor(scores.overall)}
          />
        </div>
        <div className="col s6 m4 l2 wrapp">
          <p className="stat-title">Aceleración</p>
          <p
            className={`stat-value st-${handleScoreColor(scores.acceleration)}`}
          >
            {scores.acceleration || 0} <span>/ 100</span>
          </p>
        </div>
        <div className="col s6 m4 l2 wrapp">
          <p className="stat-title">Frenado</p>
          <p className={`stat-value st-${handleScoreColor(scores.braking)}`}>
            {scores.braking || 0} <span>/ 100</span>
          </p>
        </div>
        <div className="col s6 m4 l2 wrapp">
          <p className="stat-title">Curvas</p>
          <p className={`stat-value st-${handleScoreColor(scores.cornering)}`}>
            {scores.cornering || 0} <span>/ 100</span>
          </p>
        </div>
        <div className="col s6 m6 l2 wrapp">
          <p className="stat-title">Uso de celular</p>
          <p
            className={`stat-value st-${handleScoreColor(
              scores.phoneDistraction
            )}`}
          >
            {scores.phoneDistraction || 0} <span>/ 100</span>
          </p>
        </div>
        <div className="col s12 m6 l2 wrapp">
          <p className="stat-title">Exceso de velocidad</p>
          <p className={`stat-value st-${handleScoreColor(scores.speeding)}`}>
            {scores.speeding || 0} <span>/ 100</span>
          </p>
        </div>
      </div>
      <div className="row scores">
        <div className="col s12">
          <p className="table-title">Conducción ecológica</p>
        </div>
        <div className="col s12 l2 wrapp">
          <Stat
            value={ecoScores.overall || 0}
            color={handleScoreColor(ecoScores.overall)}
          />
        </div>
        <div className="col s6 l2 wrapp">
          <p className="stat-title">Combustible</p>
          <p className={`stat-value st-${handleScoreColor(ecoScores.fuel)}`}>
            {ecoScores.fuel || 0} <span>/ 100</span>
          </p>
        </div>
        <div className="col s6 l2 wrapp">
          <p className="stat-title">Llantas</p>
          <p className={`stat-value st-${handleScoreColor(ecoScores.tyres)}`}>
            {ecoScores.tyres || 0} <span>/ 100</span>
          </p>
        </div>
        <div className="col s6 l2 wrapp">
          <p className="stat-title">Frenos</p>
          <p className={`stat-value st-${handleScoreColor(ecoScores.brakes)}`}>
            {ecoScores.brakes || 0} <span>/ 100</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ActivityInfo;
