import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import ReactGA from 'react-ga4';
import { posthog } from 'posthog-js';
import M from 'materialize-css';
import lastSeen from '../../../../../utils/lastSeen';
import { sanitizeAppStatus } from '../../../../../utils/sanitizers';
import axiosAuth from '../../../../../utils/axiosAuth';
import Icon from '../../../../common/airbag-icons/Icon';
import AirbagLoader from '../../../../common/airbag-loader/AirbagLoader';

function RenderDriversTable(props) {
  const { group, setGroup } = props;
  const [loadingCAS, setLoadingCAS] = useState(false);

  function renderDriverStatus(driver) {
    const { firstLogin, isResting, appStatus, isSDKEnabled, status } = driver;

    // Discard disabled drivers
    if (status === 'inactive') {
      return <span className="pill red-s">Deshabilitado</span>;
    }

    // First validation, check if user has logged in
    if (firstLogin) {
      return <span className="pill red-s">Sin configurar</span>;
    }

    // Handle users that are resting only for TMS
    if (isResting) {
      return <span className="pill grey-s">En descanso</span>;
    }

    // Default is active or inactive
    return (
      <span
        className={classNames('pill', {
          'green-s': appStatus === 'active',
          'grey-s': appStatus === 'inactive'
        })}
      >
        {sanitizeAppStatus(appStatus, isSDKEnabled)}
      </span>
    );
  }

  function renderLastSeenText(driver) {
    const { position } = driver;
    if (!position.dateTime) {
      return '';
    }

    return (
      <span className={`last-seen ${renderLastSeenColor(driver)}`}>
        {lastSeen(position.dateTime)}
      </span>
    );
  }

  function renderDriverConnection(driver) {
    const { firstLogin, isResting, appStatus, lastSeenSince, status } = driver;

    // Discard inactive, not configured and resting drivers
    if (status === 'inactive' || firstLogin || isResting) {
      return null;
    }

    // For inactive drivers
    if (appStatus === 'inactive') {
      return null;
    }

    // Handle if user is lost
    if (lastSeenSince >= 7200) {
      return <span className="pill red-s">Perdido</span>;
    }

    // Handle if user is missing
    if (lastSeenSince < 7200 && lastSeenSince >= 1440) {
      return <span className="pill yellow-s">Por perderse</span>;
    }

    // Handle if user is ok
    if (lastSeenSince < 1440) {
      return <span className="pill green-s">Ok</span>;
    }

    return null;
  }

  function renderLastSeenColor(driver) {
    const { isResting, lastSeenSince } = driver;

    // Skip resting drivers
    if (isResting) {
      return 'none';
    }

    // Handle color based on minutes
    if (lastSeenSince < 1440) {
      return 'good';
    }
    if (lastSeenSince >= 1440 && lastSeenSince < 7200) {
      return 'regular';
    }
    if (lastSeenSince > 7200) {
      return 'bad';
    }

    return 'none';
  }

  function handleAppStatusClick(driver) {
    Swal.fire({
      icon: 'question',
      title: `¿Deseas ${
        driver.appStatus === 'active' ? 'desactivar' : 'activar'
      } a ${driver.fullName}?`,
      showCancelButton: true,
      confirmButtonText: `<span class="dark-color-text">Si, ${
        driver.appStatus === 'active' ? 'desactivar' : 'activar'
      }</span>`,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f6c700'
    }).then((result) => {
      if (result.isConfirmed) {
        changeAppStatus(driver.userId, driver.pushToken);

        // Google analytics
        if (driver.appStatus === 'active') {
          ReactGA.event({
            category: 'drivers_view',
            action: 'deactivate_driver_from_group_view'
          });
          posthog.capture(`deactivate_driver_from_group_view`, {
            category: 'drivers_view',
            isCustomEvent: true
          });
        } else {
          ReactGA.event({
            category: 'drivers_view',
            action: 'activate_driver_from_group_view'
          });
          posthog.capture(`activate_driver_from_group_view`, {
            category: 'drivers_view',
            isCustomEvent: true
          });
        }
      }
    });
  }

  function changeAppStatus(userId, pushToken) {
    setLoadingCAS(true);

    axiosAuth
      .post(`/api/users/change-app-status`, {
        driverId: userId,
        pushToken
      })
      .then((res) => {
        const { newAppStatus } = res.data;
        M.toast({
          html: `Usuario ${
            newAppStatus === 'active' ? 'activado' : 'desactivado'
          } correctamente`,
          classes: 'success-toast'
        });

        // Search affected user in array and update
        const driversCopy = [...group.drivers];
        driversCopy.forEach((driver) => {
          if (driver.id === userId) {
            driver.appStatus = newAppStatus;
          }
        });
        setGroup({ ...group, drivers: driversCopy });

        setLoadingCAS(false);
      })
      .catch((err) => {
        console.log(err);
        M.toast({
          html: 'Tuvimos un error, intenta de nuevo más tarde',
          classes: 'error-toast'
        });
        setLoadingCAS(false);
      });
  }

  function renderDriversTable() {
    // Loader en lo que cargan los conductores
    if (!group.drivers) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // Si no hay conductores
    if (!group.drivers.length) {
      return (
        <div id="no-data">
          <Icon icon="error" id="error-icon" />
          <p>Sin conductores</p>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="drivers-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>ID</th>
              <th>Contacto</th>
              <th>Puntos</th>
              <th className="center-align">
                Conexión más
                <br />
                reciente hace
              </th>
              <th className="center-align">Estado app</th>
            </tr>
          </thead>
          <tbody>
            {group.drivers.map((driver, i) => {
              const { id, fullName, internalCompanyId, phone, coins, email } =
                driver;

              return (
                <tr key={id} className="driver-row">
                  <td>
                    <span className="pos-index">{i + 1}.</span>
                    <Link to={`/conductor?driverId=${id}`}>{fullName}</Link>
                  </td>
                  <td>{internalCompanyId}</td>
                  <td>{phone ? phone : email}</td>
                  <td>{coins ? Math.floor(coins) : '0'}</td>
                  <td className="pills-container">
                    {renderLastSeenText(driver)}
                    {renderDriverConnection(driver)}
                  </td>
                  <td className="pills-container">
                    {renderDriverStatus(driver)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function renderDriverSwitch() {
    // Hide if there is no drivers information
    if (!group.drivers || !group.drivers.length) {
      return null;
    }

    return (
      <div className="airbag-table-container" id="switch-table">
        <table className="airbag-table">
          <thead>
            <tr>
              <th className="center-align">
                Cambiar
                <br />
                estado
              </th>
            </tr>
          </thead>
          <tbody>
            {group.drivers.map((driver) => {
              const {
                id,
                firstLogin,
                isDriving,
                appStatus,
                lastSeenSince,
                status
              } = driver;

              // Remove switch to inactive drivers
              if (status === 'inactive') {
                return (
                  <tr key={'cta-' + id} className="switch-row">
                    <td className="switch-container"></td>
                  </tr>
                );
              }

              return (
                <tr key={'cta-' + id} className="switch-row">
                  <td className="switch-container">
                    <div
                      className={classNames({
                        'hide-switch': firstLogin,
                        'disabled-switch':
                          (isDriving &&
                            appStatus === 'active' &&
                            lastSeenSince < 1440) ||
                          loadingCAS
                      })}
                    >
                      <input
                        type="checkbox"
                        className="custom-switch"
                        id={'switch-' + id}
                        checked={appStatus === 'active'}
                        disabled={
                          loadingCAS ||
                          firstLogin ||
                          (appStatus === 'active' && isDriving)
                        }
                        onChange={() => {
                          handleAppStatusClick(driver);
                        }}
                      />
                      <label
                        htmlFor={'switch-' + id}
                        className={`${appStatus === 'active' ? 'on' : 'off'}`}
                        data-tip={
                          isDriving &&
                          appStatus === 'active' &&
                          lastSeenSince < 1440
                            ? // eslint-disable-next-line max-len
                              'El conductor no se puede desactivar ya que tiene un viaje en curso'
                            : ''
                        }
                      ></label>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="row no-margin" id="drivers-table-wrapper">
      <div className="col s9 m10 l11 xl11">{renderDriversTable()}</div>
      <div className="col s3 m2 l1 xl1 no-padding-left">
        {renderDriverSwitch()}
      </div>
    </div>
  );
}
export default RenderDriversTable;
