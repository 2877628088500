import dayjs from 'dayjs';
import { logsDefinitions } from '../logs';
import { logDescription } from '../sanitizers';
import getMarker from './getMarker';

// Function to display a log marker with its info box
function displayLogMarker(H, mapObj, mapUI, group, log) {
  // Get log type to get its info
  let key = log.type;
  // Check for log sub-type in metadata
  if (log.metadata && log.metadata.type) key = log.metadata.type;

  // Find log information
  const logInfo = logsDefinitions.find((l) => l.slug === key);

  // If log information isn't present, skip it
  if (!logInfo) {
    return;
  }

  // Get marker icon
  const icon = new H.map.Icon(getMarker(logInfo.markerImg), {
    anchor: new H.math.Point(29, 85)
  });

  // Create a marker using the corresponding log information
  const mapMarker = new H.map.Marker(
    { lat: log.position.lat, lng: log.position.lon },
    { icon }
  );

  // Set the data of the marker that will be displayed on click
  mapMarker.setData(`\
    <p class="type">${logInfo.name}</p>\
    <p class="date">${dayjs(log.created).format('hh:mm A')}</p>\
    <p class="log-description">${logDescription(log)}</p>`);

  // Handle marker info bubble
  mapMarker.addEventListener('tap', (event) => {
    const bubble = new H.ui.InfoBubble(
      { lat: log.position.lat, lng: log.position.lon },
      { content: event.target.getData() }
    );
    // Update center
    mapObj.current.setCenter({
      lat: log.position.lat,
      lng: log.position.lon
    });
    // Add the bubble to the UI
    mapUI.current.addBubble(bubble);
  });

  // Add marker to group
  group.addObject(mapMarker);
}

export default displayLogMarker;
