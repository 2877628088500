import React from 'react';

function Clock(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <polyline
          className="cls-1"
          points="142.23 85.04 142.23 141.73 184.25 170.08"
        />
        <circle className="cls-1" cx="141.73" cy="141.73" r="113.39" />
      </g>
    </svg>
  );
}

export default Clock;
