import React from 'react';
import Icon from '../../../../common/airbag-icons/Icon';

function RenderLeadersTable(props) {
  const { group } = props;
  if (!group.leaders.length) {
    return (
      <div className="no-data">
        <Icon icon="profile" className="error-icon" />
        <p>Sin administradores</p>
      </div>
    );
  }

  return (
    <div className="airbag-table-container" id="leaders-table">
      <div className="airbag-top">
        <span>Administradores</span>
      </div>
      <table className="airbag-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>Celular</th>
          </tr>
        </thead>
        <tbody>
          {group.leaders.map((leader, i) => {
            return (
              <tr key={leader.id} className="leader-row">
                <td>
                  <span className="pos-index">{i + 1}.</span>
                  {leader.fullName}
                </td>
                <td>{leader.email}</td>
                <td>{leader.phone}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
export default RenderLeadersTable;
