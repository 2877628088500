import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import Icon from '../../../common/airbag-icons/Icon';
import durationToString from '../../../../utils/durationToString';
import roundNumber from '../../../../utils/roundNumber';
import './TripsInRisk.scss';

function TripsInRisk(props) {
  const { tripsInRisk } = props;

  function renderScoreClassName(score) {
    return classNames('trip-score bold', {
      good: score >= 80,
      regular: score < 80 && score >= 60,
      bad: score < 60 && score > 0
    });
  }

  function renderTripsTable() {
    // Loader
    if (!tripsInRisk) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No trips means no data
    if (!tripsInRisk.length) {
      return (
        <div className="airbag-table-container" id="trips-in-risk-table">
          <div className="airbag-top">
            <span>Viajes en riesgo</span>
          </div>
          <div className="graph-message">
            <Icon icon="profile" className="graph-icon-indicator" />
            <p>Sin viajes</p>
          </div>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="trips-in-risk-table">
        <div className="airbag-top">
          <span>Viajes en riesgo</span>
        </div>
        <table className="airbag-table">
          <thead>
            <tr>
              <th className="center-align">Calificación</th>
              <th className="center-align">Duración</th>
              <th className="center-align">Distancia</th>
              <th>Conductor</th>
            </tr>
          </thead>
          <tbody>
            {tripsInRisk.map((trip, i) => (
              <tr key={trip.id} className="trip-row">
                <td
                  className={`center-align ${renderScoreClassName(
                    trip.score.overall
                  )}`}
                >
                  <Link
                    to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                  >
                    {trip.score.overall}
                  </Link>
                </td>
                <td className="center-align">
                  <Link
                    to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                  >
                    {durationToString(dayjs.duration(trip.duration, 'm'))}
                  </Link>
                </td>
                <td className="center-align">
                  <Link
                    to={`/viajes/viaje?userId=${trip.user}&tripId=${trip.id}`}
                  >
                    {trip.distance > 10
                      ? `${roundNumber(trip.distance)} km`
                      : `${trip.distance.toFixed(1)} km`}
                  </Link>
                </td>
                <td>
                  <Link to={`/conductor?driverId=${trip.user}`}>
                    {trip.driverName}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return <div id="_trips-in-risk-table_">{renderTripsTable()}</div>;
}

export default TripsInRisk;
