/* eslint max-len: 0 */

import React from 'react';

function CallIn(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <path d="M191.47,260.12H92.26a19.19,19.19,0,0,1-19.17-19.18V42.52A19.19,19.19,0,0,1,92.26,23.35h99.21a19.19,19.19,0,0,1,19.17,19.17V80.87a5,5,0,0,1-10,0V42.52a9.18,9.18,0,0,0-9.17-9.17H92.26a9.18,9.18,0,0,0-9.17,9.17V240.94a9.18,9.18,0,0,0,9.17,9.18h99.21a9.18,9.18,0,0,0,9.17-9.18V188.43a5,5,0,0,1,10,0v52.51A19.19,19.19,0,0,1,191.47,260.12Z" />
      <circle cx="141.87" cy="219.69" r="7.09" />
      <path d="M163.42,170.18h42.52a5,5,0,0,0,0-10H173.72l52.37-52.36a5,5,0,0,0-3.54-8.54,5,5,0,0,0-3.53,1.47l-50.6,50.59V122.66a5,5,0,0,0-10,0v42.52A5,5,0,0,0,163.42,170.18Z" />
    </svg>
  );
}

export default CallIn;
