import React, { useEffect } from 'react';
import './LoaderFullscreen.scss';

function LoaderFullscreen(props) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'unset');
  }, []);

  return <div id="_loader-fullscreen_"></div>;
}

export default LoaderFullscreen;
