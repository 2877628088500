import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NotFound.scss';

class NotFound extends React.Component {
  render() {
    return (
      <div id="not-found" className="valign-wrapper">
        <div id="not-found-content">
          <FontAwesomeIcon icon="car-crash" />
          <h3>404</h3>
          <h4>No se encontró lo que buscabas</h4>
          <Link to="/">
            <button className="button yellow-button" href="/">
              Ir a la pagina principal
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default NotFound;
