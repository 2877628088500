import React from 'react';

function More(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <line
          className="cls-1"
          x1="141.84"
          y1="28.28"
          x2="141.84"
          y2="255.12"
        />
        <line className="cls-1" x1="255.26" y1="141.7" x2="28.42" y2="141.7" />
      </g>
    </svg>
  );
}

export default More;
