import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SideModal.scss';
import Icon from '../airbag-icons/Icon';

function SideModal(props) {
  const { isOpen, setIsOpen, children } = props;

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div className="_side-modal_">
      <div
        className={classnames('side-modal', {
          open: isOpen
        })}
      >
        <div className="sidemodal-header">
          <FontAwesomeIcon
            icon="arrow-left"
            className="hide-on-med-and-up"
            onClick={() => setIsOpen(!isOpen)}
          />
          <Icon
            id="close-icon"
            icon="close"
            className="hide-on-small-only"
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
        <div className="sidemodal-body">{children}</div>
      </div>
      <div
        className={classnames('sidemodal-courtain', {
          'show-sidemodal-courtain': isOpen
        })}
        onClick={() => setIsOpen(false)}
      ></div>
    </div>
  );
}

SideModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

export default SideModal;
