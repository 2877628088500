/* eslint max-len: 0 */

import React from 'react';

function Ranking(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283.46 283.46"
      fill="none"
      stroke="#000"
      width="20px"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="10px"
      {...props}
    >
      <g id="Capa_2" data-name="Capa 2">
        <path
          className="cls-1"
          d="M70.87,28.35v99.81a70,70,0,0,0,69.8,69.8h2.13a70,70,0,0,0,69.8-69.8V28.35Z"
        />
        <path className="cls-1" d="M70.64,113.39a35.3,35.3,0,0,1,0-70.59" />
        <path className="cls-1" d="M216.21,113.39a35.3,35.3,0,0,0,0-70.59" />
        <line
          className="cls-1"
          x1="141.73"
          y1="197.96"
          x2="141.73"
          y2="255.12"
        />
        <line
          className="cls-1"
          x1="99.21"
          y1="255.12"
          x2="184.25"
          y2="255.12"
        />
      </g>
    </svg>
  );
}

export default Ranking;
