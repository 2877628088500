import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

function CoinsChart(props) {
  const { labels, data, colors } = props;
  const chartContainer = useRef(null);

  const chartConfig = {
    type: 'bar',
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor: colors
        }
      ]
    },
    options: {
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      resizeDelay: 800,
      barPercentage: 0.5,
      elements: {
        bar: {
          borderRadius: 2
        }
      },
      scales: {
        x: {
          min: 0,
          display: false,
          grid: {
            display: false
          }
        },
        y: {
          grid: {
            display: false
          }
        }
      },
      plugins: {
        // https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
        tooltip: {
          callbacks: {
            title: () => {
              return null;
            },
            label: (tooltipItem) => {
              const value = tooltipItem.formattedValue;
              return `${value}`;
            },
            afterLabel: () => {
              return null;
            }
          },
          backgroundColor: '#464646',
          padding: 12,
          displayColors: false
        },
        legend: {
          display: false
        },
        title: {
          display: false
        }
      }
    }
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      new Chart(chartContainer.current, chartConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartContainer]);

  return <canvas ref={chartContainer} />;
}

export default CoinsChart;
