import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import numeral from 'numeral';
import AirbagLoader from '../../../common/airbag-loader/AirbagLoader';
import axiosAuth from '../../../../utils/axiosAuth';
import roundNumber from '../../../../utils/roundNumber';
import durationToString from '../../../../utils/durationToString';
import './RankingTable.scss';

function handleScoreColor(number, returnHex) {
  if (number >= 80) {
    if (returnHex) return '#c3eddc';
    return 'good';
  } else if (number >= 60) {
    if (returnHex) return '#fef1d0';
    return 'regular';
  } else if (number > 0 && number < 60) {
    if (returnHex) return '#ffdad6';
    return 'bad';
  } else {
    if (returnHex) return '#b5b5b5';
    return '';
  }
}

function RankingTable(props) {
  const [ranking, setRanking] = useState(null);

  useEffect(() => {
    // Get ranking
    axiosAuth
      .get(`/api/ranking`, {
        params: {
          feature: 6,
          type: 'accumulated',
          targetDate: dayjs().subtract(1, 'd').format('YYYY-MM-DD')
        }
      })
      .then((res) => {
        const { drivers } = res.data;
        setRanking(drivers);
      })
      .catch((err) => {
        console.log(err);
        setRanking([]);
      });
  }, []);

  function renderRankingTable() {
    // Loader
    if (!ranking) {
      return (
        <div id="loader-container">
          <AirbagLoader />
        </div>
      );
    }

    // No ranking means no table
    if (!ranking.length) {
      return (
        <div className="airbag-table-container" id="ranking-table">
          <div className="airbag-top">
            <span>Ranking de {dayjs().format('MMMM')}</span>
          </div>
          <table className="airbag-table">
            <tbody>
              <tr>
                <td className="center-align">Sin información</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div className="airbag-table-container" id="ranking-table">
        <div className="airbag-top">
          <span>Ranking de {dayjs().format('MMMM')}</span>
        </div>
        <table className="airbag-table">
          <thead>
            <tr>
              <th>Conductor</th>
              <th className="center-align">Calificación</th>
              <th>Distancia recorrida</th>
              <th>Tiempo en ruta</th>
              <th>Viajes realizados</th>
            </tr>
          </thead>
          <tbody>
            {ranking.map((driver, i) => (
              <tr key={driver.userId} className="ranking-row">
                <td>
                  <span className="pos-index">{i + 1}.</span>{' '}
                  <Link to={`/conductor?driverId=${driver.userId}`}>
                    {driver.fullName}
                  </Link>
                </td>
                <td
                  className={`score ${handleScoreColor(
                    roundNumber(driver.Value)
                  )}`}
                >
                  {roundNumber(driver.Value)}
                </td>
                <td>
                  {driver.Distance
                    ? numeral(roundNumber(driver.Distance)).format('0,0')
                    : 0}{' '}
                  km
                </td>
                <td>
                  {durationToString(
                    dayjs.duration(roundNumber(driver.Duration), 'minutes')
                  )}
                </td>
                <td>{driver.Trips}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return <div id="_ranking-table_">{renderRankingTable()}</div>;
}

export default RankingTable;
