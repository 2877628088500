import dayjs from 'dayjs';
import { logsDefinitions } from '../logs';
import { logDescription } from '../sanitizers';
import getMarker from './getMarker';

// Function to display activity markers
function displayActivities(H, mapObj, mapUI, activities) {
  if (activities && activities.length) {
    // Loop through activities
    for (const act of activities) {
      let key = act.type;
      // Check for log sub-type in metadata
      if (act.metadata && act.metadata.type) key = act.metadata.type;

      // Find log information
      const logInfo = logsDefinitions.find((l) => l.slug === key);

      // If log information isn't present, skip it
      if (!logInfo) {
        continue;
      }

      // Get marker icon
      const icon = new H.map.Icon(getMarker(logInfo.markerImg), {
        anchor: new H.math.Point(29, 85)
      });

      // Create a marker using log's info
      const mapMarker = new H.map.Marker(
        { lat: act.position.lat, lng: act.position.lon },
        { icon }
      );

      // Set the data of the marker that will be displayed on click
      const mapMarkerData =
        `<b>${logInfo.name}</b>` +
        `<p id="date">${dayjs(act.created).format('DD/MM hh:mm A')}</p>` +
        `<p id="log-description">${logDescription(act)}</p>` +
        `<p id="coordinates">${act.position.lat}, ${act.position.lon}</p>`;
      mapMarker.setData(mapMarkerData);

      mapMarker.addEventListener('tap', (event) => {
        const bubble = new H.ui.InfoBubble(
          { lat: act.position.lat, lng: act.position.lon },
          {
            content: event.target.getData()
          }
        );
        mapObj.current.setCenter({
          lat: act.position.lat,
          lng: act.position.lon
        });
        // Add objects to UI and the map object
        mapUI.current.addBubble(bubble);
      });
      mapObj.current.addObject(mapMarker);
    }
  }
}

export default displayActivities;
