import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import M from 'materialize-css';
import Button from '../../common/button/Button';
import './TwoFAWizard.scss';

function TwoFAWizard(props) {
  const { user, company, password, history, skin } = props;
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState('request');
  const [deliverySource, setDeliverySource] = useState('email');
  const [otpCode, setOtpCode] = useState('');
  const [requestNew, setRequestNew] = useState(false);
  const timer = useRef(60);

  // Get user data
  const { phone, email } = user;

  // Function to count from 60 to 0
  function updateTimer() {
    // Subtract 1 second
    timer.current = timer.current - 1;

    // While time is available, keep subtracting 1 second
    if (timer.current > 0) {
      // Get timer element
      const elem = document.getElementById('timer-span');
      if (elem) {
        // Update frontend
        elem.innerHTML = timer.current;
        // Start new timeout
        setTimeout(() => {
          // Recursive call
          updateTimer();
        }, 1000);
      }
    } else {
      // Timer out, now you can request a new one
      setRequestNew(true);
    }
  }

  // Request OTP
  function requestOTP() {
    setLoading(true);

    axios
      .post('/api/auth/request-otp', {
        deliverySource,
        email,
        password
      })
      .then((res) => {
        // Change to next stage
        setStage('validate');
        // Stop loading
        setLoading(false);
        // Start timer to request another code
        updateTimer();
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  // Validate OTP
  function validateOTP() {
    setLoading(true);

    axios
      .post('/api/auth/validate-otp', {
        email,
        password,
        otpCode
      })
      .then((res) => {
        const { accessToken, refreshToken } = res.data;

        // Save both tokens in localStorage
        localStorage.setItem('aat', accessToken);
        localStorage.setItem('art', refreshToken);
        // Save company ID
        localStorage.setItem('currentCompanyId', company.id);

        // Redirect to dashboard
        history.push('/');
      })
      .catch((err) => {
        console.log(err);
        let msg = 'Tuvimos un error, intenta de nuevo más tarde';
        if (
          err.response &&
          err.response.data &&
          typeof err.response.data === 'string'
        ) {
          msg = err.response.data;
        }
        M.toast({
          html: msg,
          classes: 'error-toast'
        });
        setLoading(false);
      });
  }

  function textTrimmer(str) {
    return '******' + str.slice(-4);
  }

  return (
    <div id="_two-FA-wizard_">
      <div id="logo-container">
        <img id="logoBlack" src={skin.logoBlack} alt="Company logo" />
      </div>
      {stage === 'request' ? (
        <div>
          <h2 id="formTitle">Solicita un código</h2>
          <p>
            Enviaremos un código de 6 dígitos por el medio que nos indiques para
            validar tu identidad
          </p>
          <div className="input-field">
            <select
              defaultValue={deliverySource}
              onChange={(e) => setDeliverySource(e.target.value)}
            >
              <option value="email">Correo a {email}</option>
              <option value="sms">SMS a {textTrimmer(phone)}</option>
            </select>
            <Button
              variant="yellow-button"
              className="big-button"
              loading={loading}
              disabled={loading}
              type="button"
              onClick={() => requestOTP()}
            >
              Enviar código
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <h2 id="formTitle">Ingresa el código que enviamos</h2>
          <div className="input-field">
            <input
              id="otp"
              type="text"
              value={otpCode}
              onChange={(e) => setOtpCode(e.target.value)}
              placeholder="123456"
            />
            <Button
              variant="yellow-button"
              className="big-button"
              loading={loading}
              disabled={loading}
              type="button"
              onClick={() => validateOTP()}
            >
              Validar
            </Button>
          </div>
          <div id="counter">
            {requestNew ? (
              <p>
                <span
                  id="request-new"
                  onClick={() => {
                    setStage('request');
                  }}
                >
                  Solicita un código nuevo
                </span>
              </p>
            ) : (
              <p>
                Puedes solicitar uno nuevo en{' '}
                <span id="timer-span">{timer.current}</span> segundos
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default TwoFAWizard;
